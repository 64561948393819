import React from 'react';
import * as S from './plan-history.styled';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import Skeleton from 'react-loading-skeleton';
import PlanModal from './plan-modal/plan-modal';
import { ConfirmDeleteModal } from './confirm-delete-modal';
import { Button, RPTable } from '@ratedpower/components';
import { usePlanHistory } from './use-plan-history';
import { useTranslation } from 'react-i18next';
import { ISubscriptionPlanDTO, ISubscriptionPlanInputs } from 'types/subscription-plan';
import { Table } from '@tanstack/react-table';

interface IProps {
  loading: boolean;
  error: boolean;
  table: Table<ISubscriptionPlanDTO>;
}

const TableComponent: React.FC<IProps> = ({ table, loading, error }) => {
  const { t } = useTranslation();
  if (loading) return <Skeleton height={100} />;
  if (error) return <ErrorRetrievingData />;
  return <RPTable table={table as any} zeroResultsFoundText={t('zero-results-found')} highlightOnHover={false} />;
};

const PlanHistory: React.FC = () => {
  const { t } = useTranslation();
  const {
    inputs,
    formError,
    loading,
    error,
    table,
    confirmDeleteModal,
    editPlanModal,
    upgradePlanModal,
    newPlanModal,
    handleCreateNewPlan,
    handleInputChangeManual,
    setFormError,
    handleDeletePlan,
    handleEditPlan,
    handleUpgradePlan,
  } = usePlanHistory();

  const modalFormProps = {
    inputs,
    handleInputChangeManual,
    setFormError,
    formError,
  };

  return (
    <S.PlanHistoryWrapper>
      <Button
        className="renew-plan-btn"
        variant="accent"
        text={t('support:renew-plan')}
        onClick={() => newPlanModal.setModalOpen(true)}
      />
      <S.PlanHistoryTable>
        <TableComponent table={table} loading={loading} error={!!error} />
        {newPlanModal.isOpen && (
          <PlanModal
            {...modalFormProps}
            title={t('company:renew-current-plan')}
            isActive={newPlanModal.isOpen}
            onClose={() => newPlanModal.setModalOpen(false)}
            handleSubmitForm={handleCreateNewPlan}
            legacyPlanNeedsChange
            plansInfo={t('support:legacy-plans-cant-be-renewed')}
          />
        )}
        {confirmDeleteModal.isOpen && (
          <ConfirmDeleteModal
            isActive={confirmDeleteModal.isOpen}
            onClose={() => confirmDeleteModal.setModalOpen(false)}
            handleDeletePlan={handleDeletePlan}
          />
        )}
        {editPlanModal.isOpen && (
          <PlanModal
            {...modalFormProps}
            title={t('company:edit-plan')}
            isActive={editPlanModal.isOpen}
            onClose={() => editPlanModal.setModalOpen(false)}
            handleSubmitForm={(inputs: ISubscriptionPlanInputs) => handleEditPlan(inputs, editPlanModal.data.planId)}
            disablePlanChangeIfLegacy
          />
        )}
        {upgradePlanModal.isOpen && (
          <PlanModal
            {...modalFormProps}
            title={t('company:upgrade-plan')}
            isActive={upgradePlanModal.isOpen}
            onClose={() => upgradePlanModal.setModalOpen(false)}
            handleSubmitForm={(inputs: ISubscriptionPlanInputs) =>
              handleUpgradePlan(inputs, upgradePlanModal.data.planId)
            }
            plansInfo={t('support:legacy-plans-cant-be-upgraded')}
            legacyPlanNeedsChange
          />
        )}
      </S.PlanHistoryTable>
    </S.PlanHistoryWrapper>
  );
};

export default PlanHistory;
