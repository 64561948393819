import { useEffect } from 'react';
import { IUseCapexFormObject, ICapexTemplate } from 'types/capex';
import { useKeyboardShortcut } from '../use-keyboard-shortcut';
import { useHistory as useStateHistory } from 'utils/hooks/use-history';
import { useCapexForm } from './use-capex-data';
import { SIMULATION_CATEGORY } from 'types/design';

export const useCapexTemplateState = (
  templateData?: ICapexTemplate,
  skip?: boolean,
  simulationCategory?: SIMULATION_CATEGORY
) => {
  const {
    state: editableTemplate,
    set: setEditableTemplate,
    undo,
    redo,
    clear,
    canUndo,
  } = useStateHistory(templateData, 10);

  useKeyboardShortcut(['z'], () => editableTemplate !== undefined && undo(), null, { ctrlKey: true });
  useKeyboardShortcut(['z'], redo, null, { ctrlKey: true, shiftKey: true });
  useKeyboardShortcut(['y'], redo, null, { ctrlKey: true });

  useEffect(() => {
    if (templateData) {
      clear(templateData);
    }
  }, [templateData?.id]);

  const useCapexFormObject: IUseCapexFormObject = useCapexForm(
    editableTemplate?.entries || templateData?.entries,
    simulationCategory,
    skip
  );
  const changeTemplateProperty = (newProperty: keyof ICapexTemplate, value: any) => {
    const setTemplate = (template?: ICapexTemplate) => {
      setEditableTemplate({ ...template, [newProperty]: value });
    };
    if (!editableTemplate) {
      setTemplate(templateData);
      return;
    }
    setTemplate(editableTemplate);
  };

  const resetTemplate = (newState: ICapexTemplate | undefined) => {
    clear(newState);
  };

  return {
    editableTemplate,
    useCapexFormObject,
    canUndo,
    changeTemplateProperty,
    resetTemplate,
    setEditableTemplate,
  };
};
