import {
  AreaParametricInputs,
  DesignInputs,
  DrawableRectangle,
  Line,
  Point,
  PowerStationElementWithId,
  SelectedElement,
  StructureElementWithId,
  PowerStationKPIData,
  Area,
  Setback,
  Edge,
  AvailableAreaElement,
  KPISortByValue,
  SortDirection,
} from './ilc-types';
import * as THREE from 'three';
import { CameraControls } from '@react-three/drei';

export const movePointAlongLineUsingAngleAndDistance = (point: Point, angle: number, distance: number) => {
  const dir_X = Math.cos(angle);
  const dir_Y = Math.sin(angle);

  return {
    x: point.x + distance * dir_X,
    y: point.y + distance * dir_Y,
  };
};

export const createRectangleWithCenter = (center: Point, width: number, length: number) => {
  const halfLength = length / 2;
  const halfWidth = width / 2;

  const topLeft = {
    x: center.x - halfLength,
    y: center.y + halfWidth,
  };

  const topRight = {
    x: center.x + halfLength,
    y: center.y + halfWidth,
  };

  const bottomRight = {
    x: center.x + halfLength,
    y: center.y - halfWidth,
  };

  const bottomLeft = {
    x: center.x - halfLength,
    y: center.y - halfWidth,
  };

  return { topLeft, topRight, bottomRight, bottomLeft };
};

export const createRectangleWithCenterWithAngle = (center: Point, width: number, length: number, angle: number) => {
  const { topLeft, topRight, bottomRight, bottomLeft } = createRectangleWithCenter(center, width, length);

  const rotatedTopLeft = rotatePoint(topLeft, center, angle);
  const rotatedTopRight = rotatePoint(topRight, center, angle);
  const rotatedBottomRight = rotatePoint(bottomRight, center, angle);
  const rotatedBottomLeft = rotatePoint(bottomLeft, center, angle);

  return [rotatedTopLeft, rotatedTopRight, rotatedBottomRight, rotatedBottomLeft];
};

export const getMidPointsOfRectangleShortSide = (center: Point, length: number, angle: number): [Point, Point] => {
  const halfLength = length / 2;

  const leftSide = {
    x: center.x - halfLength,
    y: center.y,
  };

  const rightSide = {
    x: center.x + halfLength,
    y: center.y,
  };

  const rotatedLeftSide = rotatePoint(leftSide, center, angle);
  const rotatedRightSide = rotatePoint(rightSide, center, angle);

  return [rotatedLeftSide, rotatedRightSide];
};

export const rotatePoint = (point: Point, center: Point, angle: number) => {
  const s = Math.sin(angle);
  const c = Math.cos(angle);

  const translatedPoint = {
    x: point.x - center.x,
    y: point.y - center.y,
  };

  const xnew = translatedPoint.x * c - translatedPoint.y * s;
  const ynew = translatedPoint.x * s + translatedPoint.y * c;

  return {
    x: xnew + center.x,
    y: ynew + center.y,
  };
};

export function darkenHexColor(hex: string, amount: number): string {
  // Convert HEX to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Darken RGB values
  r = Math.max(0, Math.round(r * (1 - amount)));
  g = Math.max(0, Math.round(g * (1 - amount)));
  b = Math.max(0, Math.round(b * (1 - amount)));

  // Convert RGB back to HEX
  const convertedHex = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;

  return convertedHex;
}

const getStructureRectangleMeasurements = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  return {
    length: areaParametricInputs.distanceBetweenStructures * 2 + selectedReferenceElement.length,
    width: areaParametricInputs.clearanceDistance * 2 + selectedReferenceElement.width,
  };
};

const getEastWestStructureRectangleMeasurements = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  const elementWidth = areaParametricInputs.distanceBetweenTables + selectedReferenceElement.width * 2;

  return {
    length: areaParametricInputs.distanceBetweenStructures * 2 + selectedReferenceElement.length,
    width: areaParametricInputs.clearanceDistance * 2 + elementWidth,
  };
};

const getPowerStationRectangleMeasurements = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  return {
    length: areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElement.length,
    width: areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElement.width,
  };
};

const getRectangleMeasurements = (
  element: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  if (element.type === 'POWER_STATIONS') {
    return getPowerStationRectangleMeasurements(element, areaParametricInputs);
  }
  if (areaParametricInputs.eastWest) {
    return getEastWestStructureRectangleMeasurements(element, areaParametricInputs);
  }
  return getStructureRectangleMeasurements(element, areaParametricInputs);
};

const getReferenceRectangleMeasurementsForRoads = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs,
  roadWidth: number
) => {
  switch (selectedReferenceElement.type) {
    case 'STRUCTURES': {
      return {
        length: areaParametricInputs.distanceRoadToStructure * 2 + selectedReferenceElement.length + roadWidth,
        width: areaParametricInputs.distanceRoadToStructure * 2 + selectedReferenceElement.width + roadWidth,
      };
    }
    case 'POWER_STATIONS': {
      return {
        length: areaParametricInputs.distancePsToRoad * 2 + selectedReferenceElement.length + roadWidth,
        width: areaParametricInputs.distancePsToRoad * 2 + selectedReferenceElement.width + roadWidth,
      };
    }
  }
};

type SelectedReferenceElement = PowerStationElementWithId | StructureElementWithId | null;

export const calculateSelectedReferenceRectangle = (
  selectedReferenceElement: SelectedReferenceElement,
  designInputs: DesignInputs | null,
  selectedElements: SelectedElement[]
): Array<Point> | null => {
  if (!selectedReferenceElement || !designInputs) return null;

  const areaParametricInputs = designInputs.areaParametricInputs[selectedReferenceElement.areaKey];
  const center = selectedReferenceElement.centroid;

  const calculateForPowerStations =
    selectedReferenceElement.type === 'STRUCTURES' &&
    selectedElements.every((element) => element.type === 'POWER_STATIONS');

  if (calculateForPowerStations) {
    const [selectedReferenceElementLength, selectedReferenceElementWidth] = getElementSize(
      selectedReferenceElement,
      areaParametricInputs
    );
    const length = areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElementLength;
    const width = areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElementWidth;
    return createRectangleWithCenterWithAngle(center, width, length, selectedReferenceElement.angle);
  }
  const { width, length } = getRectangleMeasurements(selectedReferenceElement, areaParametricInputs);
  return createRectangleWithCenterWithAngle(center, width, length, selectedReferenceElement.angle);
};

export const calculateSelectedReferenceRectangleForRoads = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId | null,
  designInputs: DesignInputs | null,
  roadWidth: number | null
): Array<Point> | null => {
  if (!selectedReferenceElement || !designInputs || !roadWidth) return null;

  const areaParametricInputs = designInputs.areaParametricInputs[selectedReferenceElement.areaKey];
  const center = selectedReferenceElement.centroid;
  const { width, length } = getReferenceRectangleMeasurementsForRoads(
    selectedReferenceElement,
    areaParametricInputs,
    roadWidth
  );

  return createRectangleWithCenterWithAngle(center, width, length, selectedReferenceElement.angle);
};

export const calculateAdjacentStructureReferenceRectangle = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId | null,
  designInputs: DesignInputs | null,
  structureTypeLength: number,
  structureTypeWidth: number
): Array<Point> | null => {
  if (!selectedReferenceElement || !designInputs) return null;

  const areaParametricInputs = designInputs.areaParametricInputs[selectedReferenceElement.areaKey];
  const center = selectedReferenceElement.centroid;
  const { width, length } = getRectangleMeasurements(selectedReferenceElement, areaParametricInputs);

  const correctedTypeWidth = areaParametricInputs.eastWest
    ? structureTypeWidth * 2 + areaParametricInputs.distanceBetweenTables
    : structureTypeWidth;

  return createRectangleWithCenterWithAngle(
    center,
    width + correctedTypeWidth,
    length + structureTypeLength,
    selectedReferenceElement.angle
  );
};

export const getMidpoints = (userCoords: Array<Point>) => {
  const midpoints: any = [];
  for (let i = 0; i < userCoords.length; i++) {
    const p1 = userCoords[i];
    const p2 = userCoords[(i + 1) % userCoords.length];
    const newMidPoint = getMidpoint(p1.x, p1.y, p2.x, p2.y);
    midpoints.push(newMidPoint);
  }
  return midpoints;
};

export function getMidpoint(x1: number, y1: number, x2: number, y2: number) {
  return { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
}

export const pointInPolygon = (point: Point, polygon: Point[]): boolean => {
  let isInside = false;
  let j = polygon.length - 1;

  for (let i = 0; i < polygon.length; i++) {
    if (
      polygon[i].y > point.y !== polygon[j].y > point.y &&
      point.x <
        ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y)) / (polygon[j].y - polygon[i].y) + polygon[i].x
    ) {
      isInside = !isInside;
    }
    j = i;
  }

  return isInside;
};

export const pointIsWithinAvailableArea = (point: Point, availableArea: AvailableAreaElement): boolean => {
  const polygon = availableArea.exteriorRing;
  return pointInPolygon(point, polygon);
};

export const assignLineColor = (setbackType: Setback['type']) => {
  if (setbackType.endsWith('MIN')) {
    return '#1B1F18';
  }
  return '#878C84';
};

export const polygonToIndividualLines = (points: Point[], setbackType: Setback['type']): Line[] => {
  const lines: Line[] = [];
  for (let i = 0; i < points.length; i++) {
    const point1 = points[i];
    const point2 = points[i + 1] || points[0];
    lines.push({
      start: { ...point1, z: 5 },
      end: { ...point2, z: 5 },
      id: `${point1.x}_${point1.y}_${point2.x}_${point2.y}`,
      color: assignLineColor(setbackType),
    });
  }
  return lines;
};

export const getElementSize = (element: SelectedElement, areaParametricInputs: AreaParametricInputs) => {
  const elementWidth = areaParametricInputs?.eastWest
    ? areaParametricInputs.distanceBetweenTables + element.width * 2
    : element.width;
  return [element.length, elementWidth];
};

export const selectedElementToRectangleWithPosition = (
  element: SelectedElement,
  position: Point,
  designInputs: DesignInputs
): DrawableRectangle => {
  const [length, width] = getElementSize(element, designInputs.areaParametricInputs[element.areaKey]);
  return {
    points: createRectangleWithCenterWithAngle(
      { x: element.centroid.x + position.x, y: element.centroid.y + position.y },
      width,
      length,
      element.angle
    ),
    id: element.id,
    center: { x: element.centroid.x + position.x, y: element.centroid.y + position.y },
  };
};

export const movePointAlongLineUsingDirectionVector = (point: Point, directionVector: Point, targetPoint: Point) => {
  const movementVector = {
    x: targetPoint.x - point.x,
    y: targetPoint.y - point.y,
  };

  const dotProduct = directionVector.x * movementVector.x + directionVector.y * movementVector.y;

  const scalarProjection = dotProduct / (directionVector.x * directionVector.x + directionVector.y * directionVector.y);

  return {
    x: point.x + scalarProjection * directionVector.x,
    y: point.y + scalarProjection * directionVector.y,
  };
};

export function removeMiddle(a: any, b: any, c: any) {
  const cross = (a.x - b.x) * (c.y - b.y) - (a.y - b.y) * (c.x - b.x);
  const dot = (a.x - b.x) * (c.x - b.x) + (a.y - b.y) * (c.y - b.y);
  return cross < 0 || (cross == 0 && dot <= 0);
}

export function convexHull(points: any[]) {
  points.sort(function (a, b) {
    return a.x != b.x ? a.x - b.x : a.y - b.y;
  });

  const n = points.length;
  const hull: any = [];

  for (let i = 0; i < 2 * n; i++) {
    const j = i < n ? i : 2 * n - 1 - i;
    while (hull.length >= 2 && removeMiddle(hull[hull.length - 2], hull[hull.length - 1], points[j])) hull.pop();
    hull.push(points[j]);
  }

  hull.pop();
  return hull;
}

export const calculateDirectionVector = (point1: Point, angle: number) => {
  const dir_X = Math.cos(angle);
  const dir_Y = Math.sin(angle);

  return {
    x: dir_X,
    y: dir_Y,
  };
};

export function flyToArea(points: Point[], controls: typeof CameraControls | undefined) {
  if (!controls) return;
  const minReferenceBox = {
    width: 100,
    height: 200,
  };
  const { xPoints, yPoints } = points.reduce(
    (acc: { xPoints: number[]; yPoints: number[] }, point) => {
      acc.xPoints.push(point.x);
      acc.yPoints.push(point.y);
      return acc;
    },
    { xPoints: [], yPoints: [] }
  );
  const minX = Math.min(...xPoints) - minReferenceBox.width / 2;
  const minY = Math.min(...yPoints) - minReferenceBox.height / 2;
  const maxX = Math.max(...xPoints) + minReferenceBox.width / 2;
  const maxY = Math.max(...yPoints) + minReferenceBox.height / 2;
  const minVector = new THREE.Vector3(minX, 0, -minY);
  const maxVector = new THREE.Vector3(maxX, 0, -maxY);
  const bbox = new THREE.Box3(minVector, maxVector);
  controls.fitToBox(bbox, true);
}

export const centerAroundBbox = (newBbox: { min: Point; max: Point }, controls: typeof CameraControls | undefined) => {
  if (controls) {
    const currentAzimuthAngle = controls.azimuthAngle;
    const minVector = new THREE.Vector3(newBbox.min.x, 0, -newBbox.min.y);
    const maxVector = new THREE.Vector3(newBbox.max.x, 0, -newBbox.max.y);
    const box3 = new THREE.Box3(minVector, maxVector);
    controls.setTarget(0, 3, 0, false);
    controls.setPosition(0, 3, 0, false);
    controls.fitToBox(box3, false);
    controls.azimuthAngle = currentAzimuthAngle;
    controls.update();
  }
};

export function sortPowerStationsByName(powerStations: PowerStationKPIData[]) {
  return [...powerStations].sort((a, b) => {
    const regex = /^([A-Za-z]+)(\d+)-(\d+)$/;
    const matchA = a.psName.match(regex);
    const matchB = b.psName.match(regex);

    if (!matchA || !matchB) {
      return 0;
    }

    const [, lettersA, prefixNumA, suffixNumA] = matchA;
    const [, lettersB, prefixNumB, suffixNumB] = matchB;

    const lettersComparison = lettersA.localeCompare(lettersB);
    if (lettersComparison !== 0) return lettersComparison;

    const prefixComparison = parseInt(prefixNumA, 10) - parseInt(prefixNumB, 10);
    if (prefixComparison !== 0) return prefixComparison;

    return parseInt(suffixNumA, 10) - parseInt(suffixNumB, 10);
  });
}

export function sortPowerStationsByKPIValue(
  kpiValue: KPISortByValue,
  powerStations: PowerStationKPIData[],
  sortDirection: SortDirection
) {
  return [...powerStations].sort((a, b) => {
    const comparison = a.powerKpis[kpiValue] - b.powerKpis[kpiValue];
    return sortDirection === 'asc' ? comparison : -comparison;
  });
}

export function sortAreas(arr: Area[]): Area[] {
  return [...arr].sort((a, b) => {
    const numA = parseInt(a.label.replace(/[^\d]/g, ''), 10);
    const numB = parseInt(b.label.replace(/[^\d]/g, ''), 10);
    return numA - numB;
  });
}

export function disableEdge(edge: Edge): boolean {
  const disableMovementTypes: Edge['type'][] = ['ACCESS_ROAD', 'ACCESS_BRIDGE', 'CUSTOM_BRIDGE', 'BRIDGE_OUTSIDE_AA'];
  return disableMovementTypes.includes(edge.type);
}

export function getPointerFromEvent(
  event: { clientX: number; clientY: number },
  size: { width: number; height: number }
): Point {
  return { x: (event.clientX / size.width) * 2 - 1, y: -(event.clientY / size.height) * 2 + 1 };
}
