import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSidebarItem, RadioGroup, FilterSidebar } from '@ratedpower/components';
import {
  useKpisPowerStationsFilterState,
  useUpdateKpisPowerStationsFilterState,
} from 'pages/interactive-layout-creator/ilc-store';
import { KPISortByValue, SortDirection } from 'pages/interactive-layout-creator/ilc-types';

const PowerStationFilterPanel: React.FC = () => {
  const { t } = useTranslation();
  const { open, sortBy, sortDirection } = useKpisPowerStationsFilterState();
  const updateKpisPowerStationsFilterState = useUpdateKpisPowerStationsFilterState();
  const [filterValueSelection, setFilterValueSelection] = useState<KPISortByValue>(sortBy ?? 'name');
  const [sortingDirectionSelection, setSortingDirectionSelection] = useState<SortDirection>(sortDirection ?? 'asc');

  const handleClose = () => {
    updateKpisPowerStationsFilterState(false);
  };

  const kpisPowerStationFilterValueToTranslation = {
    ratedPower: 'ilc:results.power-kpis.rated-power',
    peakDcPower: 'ilc:results.power-kpis.peak-power',
    dcAcRatio: 'ilc:results.power-kpis.dc-ac-ratio',
    strings: 'ilc:results.power-kpis.strings',
  };

  const kpisPowerStationFilterValues: KPISortByValue[] = ['ratedPower', 'peakDcPower', 'dcAcRatio'];
  const filterOptions = kpisPowerStationFilterValues.map((value) => ({
    label: t(kpisPowerStationFilterValueToTranslation[value]),
    value: value,
  }));

  const handleSelectSorting = () => {
    updateKpisPowerStationsFilterState(false, {
      sortBy: filterValueSelection,
      sortDirection: sortingDirectionSelection,
    });
  };

  return (
    <FilterSidebar
      onApply={handleSelectSorting}
      onClear={handleClose}
      isOpen={open}
      onClose={handleClose}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem title={t('ilc:results.filters.variable')} hasBorderBottom>
        <RadioGroup
          id="power-station-sort-by"
          direction="column"
          selectedValue={filterValueSelection}
          options={filterOptions}
          onChange={(newOption) => {
            setFilterValueSelection(newOption.value as KPISortByValue);
          }}
        />
      </FilterSidebarItem>
      <FilterSidebarItem title={t('ilc:results.filters.sorting-order')} hasBorderBottom>
        <RadioGroup
          id="power-station-sort-direction"
          direction="column"
          selectedValue={sortingDirectionSelection}
          options={[
            { label: t('ilc:results.filters.ascending'), value: 'asc' },
            { label: t('ilc:results.filters.descending'), value: 'desc' },
          ]}
          onChange={(newOption) => {
            setSortingDirectionSelection(newOption.value as SortDirection);
          }}
        />
      </FilterSidebarItem>
    </FilterSidebar>
  );
};

export { PowerStationFilterPanel };
