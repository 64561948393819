import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { IOption } from '@ratedpower/components';
import { companyActivityTabsNames } from 'utils/constants/support';
import { teamActions } from 'utils/teams';

export const useTeamsPermissions = () => {
  const location = useLocation();
  const { id: companyId } = useParams<{ id: string }>();
  const profile = useSelector((state: RootState) => state.currentUser.profile);
  const { isAuthorized } = useAuthorization();

  const isCompaniesActivity = location.pathname.includes(companyActivityTabsNames.teams);
  const isSupportRatedPowerCompany = isCompaniesActivity && companyId === profile?.companyId;

  const hasWritePermissions =
    (isCompaniesActivity && isAuthorized(Permission.TEAM_SUPPORT_WRITE)) ||
    (!isCompaniesActivity &&
      (isAuthorized(Permission.TEAM_WRITE_IN_USER_COMPANY) || isAuthorized(Permission.TEAM_WRITE_IN_USER_TEAM)));

  const canCreateAndDeleteTeams =
    (isCompaniesActivity && isAuthorized(Permission.TEAM_SUPPORT_WRITE)) ||
    (!isCompaniesActivity && isAuthorized(Permission.TEAM_WRITE_IN_USER_COMPANY));

  const teamsAllowedDropdownActions = (): IOption[] | undefined => {
    if (hasWritePermissions) {
      return teamActions;
    }
    if (!isSupportRatedPowerCompany) {
      return [teamActions[2]];
    }
    return teamActions.slice(0, -1);
  };

  return {
    teamsAllowedDropdownActions,
    canCreateAndDeleteTeams,
    hasWritePermissions,
    isCompaniesActivity,
    isSupportRatedPowerCompany,
  };
};
