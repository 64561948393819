import styled from 'styled-components';

export const PowerStationKpisDisplayWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  border-radius: ${({ theme }) => theme.v2.spacing.xxs};
  max-height: 350px;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
  width: 100%;

  p {
    flex-grow: 1;
  }
`;

export const PowerStationItem = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.v2.spacing.xxs};
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-items: center;

  p {
    flex-grow: 1;
  }
`;

export const CollapseContent = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xxs} 0;
`;
