import { useLazyQuery } from '@apollo/client';
import { GET_CAPEX_ENTRIES } from 'services/capex-gql';
import { ICapexCreateTemplateEntry, ICapexUpdateTemplateEntry } from 'types/capex';

export const useCapexEntries = () => {
  const [getCapexEntries, { data: capexEntries }] = useLazyQuery(GET_CAPEX_ENTRIES, { fetchPolicy: 'network-only' });

  const formatCreateCapexDataToSend = (data: any): ICapexCreateTemplateEntry[] => {
    if (data) {
      return data.map((item: any) => {
        return {
          name: item.name,
          visible: item.visible,
          parentId: item?.parentId,
          templateId: item?.templateId,
          simulationId: item?.simulationId,
          priceDefinition: item?.priceDefinition && {
            name: item.priceDefinition.name,
            baseParam: item.priceDefinition.baseParam.name,
            paramMultiplier: item.priceDefinition.paramMultiplier,
            tax: item.priceDefinition.tax,
            price: item.priceDefinition.price,
            templateEntryId: item.priceDefinition.templateEntryId,
          },
          children: item.children ? formatCreateCapexDataToSend(item.children) : [],
        };
      });
    }
    return [];
  };

  const formatUpdateCapexDataToSend = (data: any): ICapexUpdateTemplateEntry[] => {
    if (data) {
      return data.map((item: any) => {
        return {
          name: item.name,
          visible: item.visible,
          id: item.id,
          priceDefinition: item?.priceDefinition && {
            name: item.priceDefinition.name,
            baseParam: item.priceDefinition.baseParam.name,
            paramMultiplier: item.priceDefinition.paramMultiplier,
            tax: item.priceDefinition.tax,
            price: item.priceDefinition.price,
            id: !item.provisionalId ? item.priceDefinition.id : undefined,
          },
          children: item.children ? formatUpdateCapexDataToSend(item.children) : [],
        };
      });
    }
    return [];
  };

  return {
    formatCreateCapexDataToSend,
    formatUpdateCapexDataToSend,
    getCapexEntries,
    capexEntries,
  };
};
