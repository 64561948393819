import React, { useEffect, useState } from 'react';
import { Modal, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import { LAYOUT_EDITOR_TOUR_ID, LAYOUT_EDITOR_TOUR_TIMES } from './tour-popover';

const LayoutEditorTour: React.FC = () => {
  const { t } = useTranslation('ilc');
  const { setIsOpen } = useTour();
  const [openModal, setOpenModal] = useState(false);

  const onCloseModal = () => {
    const layoutEditorTourTimes = localStorage.getItem(LAYOUT_EDITOR_TOUR_ID);
    if (!layoutEditorTourTimes) {
      localStorage.setItem(LAYOUT_EDITOR_TOUR_ID, '1');
    } else {
      localStorage.setItem(LAYOUT_EDITOR_TOUR_ID, String(Number(layoutEditorTourTimes) + 1));
    }
    setOpenModal(false);
  };

  useEffect(() => {
    const layoutEditorTourTimes = localStorage.getItem(LAYOUT_EDITOR_TOUR_ID);
    if (!layoutEditorTourTimes) {
      setOpenModal(true);
      return;
    }
    const shouldShowTour = Number(layoutEditorTourTimes) < LAYOUT_EDITOR_TOUR_TIMES;
    if (shouldShowTour) {
      setOpenModal(true);
    }
  }, []);

  return (
    <Modal
      isActive={openModal}
      onClose={onCloseModal}
      title={t('tour.modal.ilc-title')}
      disableClickOutside
      buttons={{
        primary: {
          text: t('tour.modal.start'),
          onClick: () => {
            setIsOpen(true);
            setOpenModal(false);
          },
        },
        secondary: {
          text: t('action:skip'),
          onClick: onCloseModal,
        },
      }}
    >
      <Typography>{t('tour.modal.ilc-description')}</Typography>
    </Modal>
  );
};

export default LayoutEditorTour;
