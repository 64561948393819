import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ISubscriptionPlanInputs } from 'types/subscription-plan';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useNotificationToast } from './use-notification-toast';
import { CREATE_SUBSCRIPTION_PLAN, GET_SUPPORT_PLANS } from 'services/subscription-plan-gql';
import { GET_SUPPORT_COMPANY } from 'services/support-gql';
import { useRef } from 'react';

export interface IUseAddPlan {
  newPlanModal: IUseModal;
  handleCreateNewPlan: (newPlan: ISubscriptionPlanInputs) => void;
}

export const useAddPlan = (): IUseAddPlan => {
  const { id: supportPagesCompanyId } = useParams<{ id: string }>();
  const { errorNotification, successNotification } = useNotificationToast();
  const newPlanModal = useModal();
  const currentInputs = useRef<ISubscriptionPlanInputs | null>(null);

  const [createPlan] = useMutation(CREATE_SUBSCRIPTION_PLAN, {
    onCompleted: () => {
      successNotification('company:new-plan-created');
      newPlanModal.setModalOpen(false);
    },
    onError: (graphqlError: any) => errorNotification(graphqlError),
    refetchQueries: [GET_SUPPORT_PLANS, GET_SUPPORT_COMPANY],
  });

  const handleCreateNewPlan = (newPlan: ISubscriptionPlanInputs) => {
    currentInputs.current = newPlan;
    createPlan({
      variables: {
        companyId: supportPagesCompanyId,
        subscriptionPlanCreateDTO: {
          plan: newPlan.plan,
          startDate: newPlan.startDate,
          expirationDate: newPlan.expirationDate,
          projectsPurchased: Number(newPlan.projectLimit.projectsPurchased) || 0,
          unlimitedProjects: newPlan.projectLimit.unlimited,
          unlimitedUsers: newPlan.userLimit.unlimited,
          userLimit: Number(newPlan.userLimit.numberOfUsers) || 0,
          stockKeepingUnits: newPlan.stockKeepingUnits || [],
        },
        sendWelcomeEmail: newPlan.sendWelcomeEmail,
      },
    });
  };

  return {
    newPlanModal,
    handleCreateNewPlan,
  };
};
