export enum RudderstackEvent {
  AA_ADDED = 'available_area_added',
  APPLY_CAPEX_FILTERS = 'capex_filters_applied',
  APPLY_DESIGNS_FILTERS = 'designs_filters_applied',
  APPLY_EQUIPMENT_FILTERS = 'equipment_filters_applied',
  APPLY_PROJECTS_FILTERS = 'projects_filters_applied',
  APPLY_STATISTICS_AND_KPIS_FILTERS = 'statistics_and_kpis_filters_applied',
  APPLY_USERS_FILTERS = 'users_filters_applied',
  BESS_DESIGN_RUN = 'bess_design_run',
  BESS_DESIGN_SAVED = 'bess_design_saved',
  BESS_DOCUMENTS_DOWNLOADED = 'bess_documents_downloaded',
  BESS_DP_FILTERS_APPLIED = 'bess_dp_filters_applied',
  COMPARISON_EXPORTED = 'comparison_exported',
  CREATE_CAPEX_TEMPLATE = 'capex_created',
  CREATE_PROJECT = 'project_created',
  CREATE_STRUCTURE_START = 'structure_saved',
  DESIGN_ARCHIVED = 'design_archived',
  DESIGN_CLONE = 'design_cloned',
  DESIGN_MARKED_FAVORITE = 'design_marked_favorite',
  DESIGN_RESTORED = 'design_restored',
  EXPORT_STATISTICS_AND_KPIS = 'statistics_and_kpis_exported',
  IMPORT_SITE_ELEMENTS = 'site_elements_imported',
  LOGIN = 'user_logged_in',
  PARCELS_ACTIVATED = 'parcels_activated',
  PRIVATE_CAPEX_CLONED = 'private_capex_cloned',
  PROJECT_ARCHIVED = 'project_archived',
  PROJECT_RESTORED = 'project_restored',
  PROJECT_MARKED_FAVORITE = 'project_marked_favorite',
  PUBLIC_CAPEX_CLONED = 'public_capex_cloned',
  PV_DESIGN_RUN = 'pv_design_run',
  PV_DESIGN_SAVED = 'pv_design_saved',
  PV_DOCUMENTS_DOWNLOADED = 'pv_documents_downloaded',
  PV_DP_FILTERS_APPLIED = 'pv_dp_filters_applied',
  PV_BATCH_RUN = 'pv_batch_run',
  PV_EDITED_GENERATE = 'pv_edited_generate',
  PV_EDITED_VALIDATE = 'pv_edited_validate',
  EDIT_LAYOUT_CLICK_BUTTON = 'edit_layout_click_button',
  RUN_COMPARISON = 'comparison_run',
  SHAPE_TOOL_CLICKED = 'shape_tool_clicked',
}

export enum ComparisonExportType {
  CHART = 'chart',
  TABLE = 'table',
}

export enum LayoutEditorButtonSource {
  DP_RESULTS_MAIN_BUTTON = 'dp/results_main_button',
  DESIGN_HISTORY = 'design_history',
  DESIGN_CARD = 'design_card',
}

export enum DPFilterType {
  MY_DESIGNS = 'my designs',
  ALL_DESIGNS = 'all designs',
}

export enum BatchType {
  PITCH = 'pitch',
  DC_AC_RATIO = 'dc_ac_ratio',
  TILT = 'tilt',
}

export enum ShapeType {
  POLYGON = 'polygon',
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
}

export enum SourceArea {
  US_PARCELS = 'us_parcels',
}

export enum SavePvDesignSource {
  MAIN_BUTTON = 'main_button',
  HISTORY = 'history',
}

export enum SiteSource {
  CAD = 'cad',
  KML = 'kml',
  SHP = 'shp',
  SITE = 'site',
  PRISM_ID = 'prismid',
}

export enum DesignFilters {
  METEO_SOURCE = 'meteosource',
  TYPE_OF_DESIGN = 'type of design',
  CATEGORY_OF_DESIGNS = 'category of designs',
  CREATOR = 'creator',
}

export enum EquipmentFilters {
  DATABASE = 'database',
}

export enum UsersFilters {
  JOB_TITLE = 'job title',
  STATUS = 'status',
}

export enum CapexFilters {
  CURRENCY = 'currency',
  CREATOR = 'creator',
  CREATION_DATE_RANGE = 'creationdaterange',
}

export enum DesignType {
  BESS = 'bess',
  PV = 'pv',
  PV_EDITED = 'pv_edited',
}

export enum CapexCreateSource {
  EXISTING_SHEET = 'existing_sheet',
  SCRATCH = 'scratch',
}

export enum PageLocations {
  PROJECTS = 'projects',
  HOMEPAGE = 'homepage',
  TEAMS = 'teams',
}
