import { IUserMinInfoDTO } from './users';

export enum Plan {
  DEMO = 'DEMO',
  FREE_TRIAL = 'FREE_TRIAL',
  /** @deprecated legacy plan */
  CUSTOM = 'CUSTOM',
  /** @deprecated legacy plan */
  ESSENTIALS = 'ESSENTIALS',
  /** @deprecated legacy plan */
  PROFESSIONAL = 'PROFESSIONAL',
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  ENTERPRISE = 'ENTERPRISE',
}

export enum NewPlan {
  DEMO = 'DEMO',
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  ENTERPRISE = 'ENTERPRISE',
}

export enum PlanStage {
  PLAN_VALID = 'PLAN_VALID',
  PROJECTS_LIMIT = 'PROJECTS_LIMIT',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  OVERDUE_PAYMENT = 'OVERDUE_PAYMENT',
}

export interface ISubscriptionPlanExtensionDTO {
  id: string;
  createdBy?: IUserMinInfoDTO;
  expirationDate: string;
  publishedDate: string;
  price?: number;
  projectsPurchased?: number;
  projectsDone?: number;
  designsDone?: number;
  planType?: string;
  isLegacy?: boolean;
  unlimitedProjects?: boolean;
}

export interface ICurrentSubscriptionPlan {
  hasFuturePlan?: boolean;
  publishedDate: string;
  projectsDone: number;
  projectsPurchased: number;
  startDate: string;
  plan: string;
  expirationDate: string;
  unlimitedProjects: boolean;
  usersCreated: number;
  stockKeepingUnits: IStockKeepingUnitDTO[];
  isLegacy: boolean;
  userLimit: number;
  unlimitedUsers: boolean;
}

export interface ISubscriptionPlanDTO {
  id: string;
  plan: Plan;
  extensions: ISubscriptionPlanExtensionDTO[];
  projectsPurchased: number;
  projectsDone: number;
  designsDone: number;
  startDate: string;
  expirationDate: string;
  publishedDate: string;
  usersCreated: number;
  unlimitedProjects: boolean;
  activeUsers: number;
  stockKeepingUnits: IStockKeepingUnitDTO[];
  createdBy?: IUserMinInfoDTO;
  originalExpirationDate: string;
  title?: string;
  isLegacy?: boolean;
  userLimit: number;
  unlimitedUsers: boolean;
}

export interface ISubscriptionPlanCard {
  plan: Plan;
  startDate: string;
  expirationDate: string;
  projectsPurchased: number;
  unlimitedProjects: boolean;
  userLimit: number;
  unlimitedUsers: boolean;
  isLegacy: boolean;
  stockKeepingUnits: IStockKeepingUnitDTO[];
}

export interface ISubscriptionPlanInputs {
  plan: Plan;
  startDate: string;
  expirationDate: string;
  stockKeepingUnits: IStockKeepingUnitDTO[];
  userLimit: { numberOfUsers: number; unlimited: boolean };
  projectLimit: { projectsPurchased: number; unlimited: boolean };
  sendWelcomeEmail?: boolean;
  isLegacy?: boolean;
}

export type IPvDesignPlan = {
  id: Plan;
  title: string;
  planDescription: string;
  color: 'primary' | 'secondary' | 'yellow_dark' | 'purple' | 'red';
  includedItems: string[];
};

export interface IDefaultSkusPerPlan {
  plan: Plan;
  defaultStockKeepingUnits: string[];
}

export interface IStockKeepingUnitDTO {
  id: string;
  name: string;
}
