import React from 'react';
import * as S from './rated-power-plan.styled';
import { useTranslation } from 'react-i18next';
import { Button, SVGWrapper, Tag, Typography } from '@ratedpower/components';
import SkeletonList from 'utils/skeleton-list';
import { RoundedCheckSvg } from 'assets/icons';
import { useTheme } from 'styled-components';
import { BUSINESS_EMAIL } from 'utils/constants/contact';
import { openExternalLink } from 'utils/navigation';
import { IndexIds } from 'index.ids';
import { IPlanCardOption } from '../hooks/use-pv-design-plans';

interface IProps {
  isCurrentPlan: boolean;
  planTitle: string;
  entries?: IPlanCardOption[];
  loading: boolean;
}

const RatedPowerPlan: React.FC<IProps> = ({ isCurrentPlan, planTitle, entries, loading }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const printEntryValue = (value: string | number | boolean) => {
    if (typeof value === 'boolean') {
      return (
        <SVGWrapper
          icon={RoundedCheckSvg}
          color={value ? theme.v2.success.bg.contrast : theme.v2.icon.primary.disabled}
          size="s"
        />
      );
    }
    if (typeof value === 'number') {
      return value;
    }
    return t(`company:${value}`);
  };

  return (
    <S.PvPlanCard $current>
      <S.PvPlanTitle>
        <Typography size="l" weight="bold" dataTest={IndexIds.MyCompany.CurrentPlan}>
          {planTitle}
        </Typography>
        {isCurrentPlan && <Tag color="brand">{t('company:current-plan')}</Tag>}
      </S.PvPlanTitle>
      <S.PvPlanContent>
        {loading ? (
          <S.CurrentPlanLoading>
            <SkeletonList amount={7} height={16} width={430} />
          </S.CurrentPlanLoading>
        ) : (
          entries?.map((entry) => (
            <S.PlanRowWrapper key={entry.label}>
              <Typography weight={entry.bold ? 'bold' : 'regular'} as="span" size="s">
                {t(entry.label)}
              </Typography>
              <Typography as="span" size="s">
                {printEntryValue(entry.value)}
              </Typography>
            </S.PlanRowWrapper>
          ))
        )}
      </S.PvPlanContent>
      {!isCurrentPlan && (
        <Button
          className="request-more-info-btn"
          text={t('company:request-more-info')}
          variant="accent"
          onClick={() => openExternalLink(`mailto:${BUSINESS_EMAIL}`)}
        />
      )}
    </S.PvPlanCard>
  );
};

export default RatedPowerPlan;
