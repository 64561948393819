import React from 'react';
import * as S from './rated-power-plans.styled';
import { usePvDesignPlans } from './hooks/use-pv-design-plans';
import { pvDesignPlanTitles } from './rated-power-plans-constants';
import { Plan } from 'types/subscription-plan';
import { useTranslation } from 'react-i18next';
import RatedPowerPlan from './rated-power-plan/rated-power-plan';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';

const PvDesignPlans: React.FC = () => {
  const { t } = useTranslation();
  const { currentPlanEntries, currentPlan, oferredPlans, loading, error, fromSkusToOptions } = usePvDesignPlans();

  const parsePlanTitle = (plan?: string) => {
    if (currentPlan?.isLegacy && plan === Plan.DEMO) return pvDesignPlanTitles[Plan.FREE_TRIAL];
    return pvDesignPlanTitles[plan ?? Plan.DEMO];
  };
  const parsedCurrentPlan = parsePlanTitle(currentPlan?.plan);
  const currentPlanTitle = currentPlan?.isLegacy
    ? t('support:' + parsedCurrentPlan) + ' (' + t('company:legacy') + ')'
    : t(`support:${parsedCurrentPlan}`);

  if (error) return <ErrorRetrievingData />;

  return (
    <S.CompanyLicenseContainer>
      <RatedPowerPlan isCurrentPlan loading={loading} entries={currentPlanEntries} planTitle={currentPlanTitle} />
      {oferredPlans?.map((item) => (
        <RatedPowerPlan
          isCurrentPlan={false}
          loading={loading}
          key={item.plan}
          planTitle={t(`support:${parsePlanTitle(item.plan)}`)}
          entries={fromSkusToOptions(true, item.defaultStockKeepingUnits)}
        />
      ))}
    </S.CompanyLicenseContainer>
  );
};

export default PvDesignPlans;
