type Positions = 'top' | 'bottom' | 'right' | 'left';

const oppositeSide: Record<Positions, Positions> = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

/** @description this function adds to the div popover a triangle that will work as an arrow, pointing the highlighted area */
/** @description if the popOverPosition is top or bottom, the arrowAlign should be left or right, and vice versa*/
export const doArrow = (popOverPosition: Positions, arrowAlign: Positions) => {
  const width = 34;
  const height = 12;
  const color = 'white';
  const isVertical = popOverPosition === 'top' || popOverPosition === 'bottom';
  const spaceFromSide = 10;

  return {
    [`--rtp-arrow-${isVertical ? oppositeSide[arrowAlign] : arrowAlign}`]: height + spaceFromSide + 'px',
    [`--rtp-arrow-${oppositeSide[popOverPosition]}`]: -height + 2 + 'px',
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${popOverPosition}`]: `${height}px solid ${color}`,
  };
};

export const styles = (popOverPosition: Positions, arrowAlign: Positions, popoverX: number, popoverY: number) => ({
  popover: (base) => ({
    ...base,
    padding: 0,
    width: '312px',
    transition: 'transform 0.3s ease-out',
    // This line below sets a initial position for the popover and avoids a flickering animation between steps
    transform: `translate(${popoverX}px, ${popoverY}px)`,
    ...doArrow(popOverPosition, arrowAlign),
  }),
});
