export const pvDesignPlanTitles = {
  DEMO: 'demo',
  FREE_TRIAL: 'free-trial',
  ESSENTIALS: 'essentials',
  ADVANCED: 'advanced',
  PROFESSIONAL: 'profesional',
  ENTERPRISE: 'enterprise',
  CUSTOM: 'custom',
  BASIC: 'basic',
};
