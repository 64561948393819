import { useQuery } from '@apollo/client';
import { GET_ALL_SKUS } from 'services/subscription-plan-gql';
import { IStockKeepingUnitDTO } from 'types/subscription-plan';
import { SKUS } from 'types/support.types';

export const useGetAllSkus = (forUsersPreview: boolean) => {
  const { data: allSkusData } = useQuery(GET_ALL_SKUS);

  const excludedSkusForUsersPreview = [SKUS.PRISM_INTEGRATION];

  const allStockKeepingUnits = allSkusData?.getStockKeepingUnits as IStockKeepingUnitDTO[];

  return allStockKeepingUnits?.filter(
    (sku) => !forUsersPreview || !excludedSkusForUsersPreview.includes(sku.name as SKUS)
  );
};
