import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'redux/root-reducer';
import {
  setSubscriptionPlanExpired,
  setSubscriptionPlanBanner,
  ISubscriptionPlanBanner,
} from 'redux/current-user-slice';
import { ACCOUNTS_RECEIVABLE_EMAIL, BUSINESS_EMAIL, LICENSE_ENDING_WARNING_DAYS } from '../constants/contact';
import { objectEquals } from '../objects';
import { getDaysDiff, subtractDays, toDayEnd, toMidnight } from 'utils/date';
import { usePlanIsExpired } from './use-plan-is-expired';

const initialPlanStatus: ISubscriptionPlanBanner = { general: null, projects: null };

const useSubscriptionPlanBanner = (): boolean => {
  const { t } = useTranslation();
  const planIsExpired = usePlanIsExpired();
  const subscriptionPlan = useSelector((state: RootState) => state.currentUser.subscriptionPlan);
  const dispatch = useDispatch();

  const checkLicenseGlobalState = useCallback(() => {
    const status = { ...initialPlanStatus };

    if (!!subscriptionPlan) {
      const { expirationDate, paymentOverdue, projectsPurchased, projectsDone } = subscriptionPlan;

      // Calculate subscription plan days left
      const nowDate = new Date();
      const expDate = toDayEnd(new Date(expirationDate));
      const startWarningDate = toMidnight(subtractDays(expDate, LICENSE_ENDING_WARNING_DAYS));
      const currentDaysLeft = getDaysDiff(nowDate, expDate);

      if (planIsExpired) {
        status.general = {
          type: 'error',
          cause: t('errors:license.your-license-has-expired'),
          link: BUSINESS_EMAIL,
        };
      } else {
        if (paymentOverdue) {
          status.general = {
            type: 'warning',
            cause: t('errors:license.payment-overdue'),
            contactUs: 'contact-us-in-order-to-keep-using-your-subscription',
            link: ACCOUNTS_RECEIVABLE_EMAIL,
          };
        } else if (nowDate > startWarningDate && !subscriptionPlan.hasFuturePlan) {
          status.general = {
            type: 'warning',
            cause: currentDaysLeft
              ? t('errors:license.your-subscription-ends-in-n-days', { amount: currentDaysLeft })
              : t('errors:license.your-subscription-ends-today'),
            link: BUSINESS_EMAIL,
          };
        }
        if (projectsPurchased - projectsDone <= 0) {
          status.projects = {
            type: 'error',
            cause: t('errors:license.maximum-number-of-projects-reached'),
            link: BUSINESS_EMAIL,
          };
        }
      }

      if (!!status.general && status.general.type === 'error' && !subscriptionPlan.subscriptionPlanExpired) {
        dispatch(setSubscriptionPlanExpired());
      }
    }

    if (!subscriptionPlan?.banner || !objectEquals(status, subscriptionPlan.banner)) {
      dispatch(setSubscriptionPlanBanner(status));
    }
  }, [subscriptionPlan, t]);

  useEffect(() => {
    checkLicenseGlobalState();
  }, [subscriptionPlan]);

  const hasLicenseBanner: boolean =
    !!subscriptionPlan &&
    !!subscriptionPlan.banner.general &&
    (subscriptionPlan.banner.general.type !== 'warning' || subscriptionPlan.showGeneralWarning);

  return hasLicenseBanner;
};

export default useSubscriptionPlanBanner;
