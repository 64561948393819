import store from 'redux/store';
import { clearCurrentUser } from 'redux/current-user-slice';

export const clearLocalStorage = () => {
  const tableFilters = localStorage.getItem('tableFilters');
  const chartFilters = localStorage.getItem('chartFilters');
  const iscTourTimes = localStorage.getItem('isc-times');
  const newDPTourTimes = localStorage.getItem('new-dp-tour-times');
  const layoutEditorTourTimes = localStorage.getItem('layout-editor-tour-times');
  const version = localStorage.getItem('popup-modified-date');
  const warningPopup = localStorage.getItem('warning-popup');
  const dontShowAgainItems = localStorage.getItem('dontShowAgain');
  localStorage.clear();
  if (tableFilters) {
    localStorage.setItem('tableFilters', tableFilters);
  }
  if (chartFilters) {
    localStorage.setItem('chartFilters', chartFilters);
  }
  if (version) {
    localStorage.setItem('popup-modified-date', version);
  }
  if (warningPopup) {
    localStorage.setItem('warning-popup', warningPopup);
  }
  if (iscTourTimes) {
    localStorage.setItem('isc-times', iscTourTimes);
  }
  if (newDPTourTimes) {
    localStorage.setItem('new-dp-tour-times', newDPTourTimes);
  }
  if (layoutEditorTourTimes) {
    localStorage.setItem('layout-editor-tour-times', layoutEditorTourTimes);
  }
  if (dontShowAgainItems) {
    localStorage.setItem('dontShowAgain', dontShowAgainItems);
  }
  store.dispatch(clearCurrentUser());
};
