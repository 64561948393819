import React from 'react';
import { SVGWrapper, Typography, Banner } from '@ratedpower/components';
import { BannerDescriptionWrapper, HomeContainer, HomeTitleContainer } from 'pages/home/home.styled';
import { Trans, useTranslation } from 'react-i18next';
import { WavingHandSvg } from 'assets/icons';
import HomeProjectsMap from 'pages/home/home-projects-map/home-projects-map';
import HomeDesigns from 'pages/home/home-designs/home-designs';
import { IndexIds } from 'index.ids';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { openExternalLink } from 'utils/navigation';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const flags = useFlags();

  return (
    <HomeContainer>
      <HomeTitleContainer>
        <div className="title">
          <SVGWrapper icon={WavingHandSvg} size={'m'} svgDimensions={{ width: '24px', height: '24px' }} />
          <Typography size={'xl'} dataTest={IndexIds.Homepage.PageTitle}>
            {t('home:page-title')}
          </Typography>
        </div>
        <Typography size={'m'} as={'div'}>
          {t('home:subtitle')}
        </Typography>
      </HomeTitleContainer>
      {flags?.showBannerIlc && (
        <Banner
          status="informative"
          header={t('home:banners.ilc.header')}
          rightLinkText={t('home:banners.ilc.right-link-text')}
          onClickRightLink={() => {
            openExternalLink('https://ratedpower.com/contact/');
          }}
          description={
            <BannerDescriptionWrapper>
              <Trans
                i18nKey={'home:banners.ilc.description'}
                components={{
                  link1: <a href={'https://ratedpower.com/platform/editor'} target="_blank" rel="noreferrer" />,
                  link2: <a href={'https://ratedpower.com/platform/site-analysis'} target="_blank" rel="noreferrer" />,
                }}
              />
            </BannerDescriptionWrapper>
          }
        />
      )}
      <HomeProjectsMap />
      <HomeDesigns />
    </HomeContainer>
  );
};

export default Home;
