import React from 'react';
import TourPopoverTemplate from 'components/tour-popover/tour-popover';

export const LAYOUT_EDITOR_TOUR_ID = 'layout-editor-tour-times';
export const LAYOUT_EDITOR_TOUR_TIMES = 1;

const TourPopover: React.FC<any> = (props) => {
  return (
    <TourPopoverTemplate
      {...props}
      tourLocalStoreId={LAYOUT_EDITOR_TOUR_ID}
      timesToShowTour={LAYOUT_EDITOR_TOUR_TIMES}
    />
  );
};

export { TourPopover };
