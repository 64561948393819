import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'types/users';
import { IUnitSystemPreferences } from 'types/preferences.types';
import { ICurrentSubscriptionPlan } from 'types/subscription-plan';

export interface ICurrentUser extends IUser {
  logo: string;
  companyId: string;
  organizationId?: string;
  companyName?: string;
}

export interface ICurrentLanguage {
  language: string;
}

interface ISubscriptionPlanBannerProps {
  type: 'warning' | 'error';
  cause: string;
  contactUs?: string;
  link?: string;
}

export interface ISubscriptionPlanBanner {
  general: ISubscriptionPlanBannerProps | null;
  projects: ISubscriptionPlanBannerProps | null;
}
interface ISubscriptionPlanLimits {
  paymentOverdue: boolean;
  showGeneralWarning: boolean;
  subscriptionPlanExpired: boolean;
  banner: ISubscriptionPlanBanner;
}

interface ILoggedUser {
  profile: ICurrentUser | null;
  preferences: ICurrentLanguage & IUnitSystemPreferences & { isGoogleMapsAPIRestricted?: boolean };
  subscriptionPlan: ICurrentSubscriptionPlan | null;
}

interface ICurrentUserState extends ILoggedUser {
  subscriptionPlan: (ICurrentSubscriptionPlan & ISubscriptionPlanLimits) | null;
}

const initialState: ICurrentUserState = {
  profile: null,
  preferences: {
    language: navigator.language,
    isGoogleMapsAPIRestricted: false,
    unitsId: '',
    temperature: 'INTERNATIONAL',
    length: 'INTERNATIONAL',
    surface: 'INTERNATIONAL',
  },
  subscriptionPlan: null,
};

const initialPlanLimits: ISubscriptionPlanLimits = {
  paymentOverdue: false,
  showGeneralWarning: true,
  subscriptionPlanExpired: false,
  banner: { general: null, projects: null },
};

const currentUserSlice = createSlice({
  initialState,
  name: 'currentUser',
  reducers: {
    setCompanyLogo(state, action: PayloadAction<string>) {
      if (state.profile) state.profile.logo = action.payload;
    },
    setCurrentUser(state, action: PayloadAction<ILoggedUser>) {
      const { profile, preferences, subscriptionPlan } = action.payload;
      state.profile = profile;
      state.subscriptionPlan = subscriptionPlan ? { ...initialPlanLimits, ...subscriptionPlan } : null;
      state.preferences = { ...state.preferences, ...preferences };
    },
    setProfile(state, action: PayloadAction<ICurrentUser>) {
      const { id, email, name, lastname, roleType, roleName, permissions, jobTitle, logo, companyId } = action.payload;
      state.profile = {
        id,
        email,
        name,
        lastname,
        roleName,
        roleType,
        permissions,
        jobTitle,
        logo,
        companyId,
      };
    },
    setUnitSystem(state, action: PayloadAction<IUnitSystemPreferences>) {
      const { temperature, length, surface } = action.payload;
      state.preferences = { ...state.preferences, ...{ temperature, length, surface } };
    },
    setLanguage(state, action: PayloadAction<ICurrentLanguage>) {
      const { language } = action.payload;
      state.preferences.language = language;
    },
    restrictGoogleMapsAPI(state) {
      state.preferences.isGoogleMapsAPIRestricted = true;
    },
    restoreExpirationDate(state) {
      if (state.subscriptionPlan) {
        state.subscriptionPlan = {
          ...state.subscriptionPlan,
          ...{ expirationDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString() },
        };
      }
    },
    restorePaymentOverdue(state) {
      if (state.subscriptionPlan) {
        state.subscriptionPlan = { ...state.subscriptionPlan, ...{ paymentOverdue: true } };
      }
    },
    restoreRemainingProjects(state) {
      if (state.subscriptionPlan) {
        state.subscriptionPlan = { ...state.subscriptionPlan, ...{ remainingProjects: 0 } };
      }
    },
    setSubscriptionPlanExpired(state) {
      if (state.subscriptionPlan) {
        state.subscriptionPlan = { ...state.subscriptionPlan, ...{ subscriptionPlanExpired: true } };
      }
    },
    setNewProjectDone(state) {
      if (state.subscriptionPlan) {
        state.subscriptionPlan = {
          ...state.subscriptionPlan,
          ...{ projectsDone: state.subscriptionPlan.projectsDone + 1 },
        };
      }
    },
    setSubscriptionPlanBanner(state, action: PayloadAction<ISubscriptionPlanBanner>) {
      if (state.subscriptionPlan) {
        state.subscriptionPlan = { ...state.subscriptionPlan, ...{ banner: action.payload } };
      }
    },
    clearCurrentUser(state) {
      state.profile = initialState.profile;
      state.preferences = initialState.preferences;
      state.subscriptionPlan = initialState.subscriptionPlan;
    },
  },
});

export const {
  setCompanyLogo,
  setCurrentUser,
  setProfile,
  setUnitSystem,
  setLanguage,
  restrictGoogleMapsAPI,
  setSubscriptionPlanExpired,
  setSubscriptionPlanBanner,
  setNewProjectDone,
  restoreExpirationDate,
  restoreRemainingProjects,
  restorePaymentOverdue,
  clearCurrentUser,
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
