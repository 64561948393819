import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import AppContainer from '../app.styled';
import { ScreenLoader, ThemeProvider } from '@ratedpower/components';
import { Outlet } from 'react-router-dom';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import { useErrorNotifier } from 'components/error-components/error-notifier';
import { Helmet } from 'react-helmet';
import GlobalStyle from 'globalStyles';
import { Auth0Provider } from '@auth0/auth0-react';
import ApolloAuthProvider from './apollo-auth-provider';
import { getRedirectUri } from 'utils/redirect_uri';
import Maintenance from 'pages/maintenance/maintenance';

const AppLayout = () => {
  const showError = useErrorNotifier();
  const { maintenanceWindow, ...flags } = useFlags();
  const flagsReady = Object.keys(flags).length > 0;

  if (!flagsReady) return <ScreenLoader />;
  if (maintenanceWindow) return <Maintenance />;

  return (
    <I18nextProvider i18n={i18n}>
      <Auth0Provider
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
        authorizationParams={{
          redirect_uri: getRedirectUri(),
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
        useRefreshTokens
        useRefreshTokensFallback
      >
        <React.Suspense fallback={<ScreenLoader />}>
          <ThemeProvider>
            <GlobalStyle />
            <AppContainer>
              <Helmet titleTemplate="%s - RatedPower" defaultTitle="RatedPower — Smart energy flow" />
              <ApolloAuthProvider showError={showError}>
                <Outlet />
              </ApolloAuthProvider>
            </AppContainer>
          </ThemeProvider>
        </React.Suspense>
      </Auth0Provider>
    </I18nextProvider>
  );
};

const AppLayoutWithLD = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
  options: {
    streaming: true, // Enable streaming for real-time updates
    sendEvents: true, // Ensure events are sent to LaunchDarkly
    bootstrap: {
      skuIlc: false,
      maintenanceWindow: false,
      skuStandaloneBess: false,
      skuPrismIntegration: false,
      skuPrismPreview: false,
      skuTeamManagement: false,
      skuHybridBess: false,
    },
  },
})(AppLayout);

export default AppLayoutWithLD;
