import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { LDContextKeys } from 'utils/constants/launch-darkly';

export const useLaunchDarklySetContext = () => {
  const { subscriptionPlan, profile } = useSelector((state: RootState) => state.currentUser);
  const ldClient = useLDClient();

  const setInitialLaunchDarklyContext = () => {
    if (!ldClient) return;
    const contexts = {
      kind: 'multi',
      user: {
        'key': LDContextKeys.userContext,
        'auth-id': profile?.authId,
      },
      company: {
        'key': LDContextKeys.companyContext,
        'organization-id': profile?.organizationId,
        'sku': subscriptionPlan?.stockKeepingUnits.map((sku) => sku.name),
      },
    };
    ldClient.identify(contexts);
  };

  return { setInitialLaunchDarklyContext };
};
