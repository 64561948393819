import styled from 'styled-components';
import { Tag } from '@ratedpower/components';

export const PositionedTag = styled(Tag)<{
  $position: { type?: string; top: number; right: number | 'auto' };
  $static?: boolean;
}>`
  top: ${({ $position }) => $position.top}px;
  right: ${({ $position }) => ($position.right === 'auto' ? 'auto' : `${$position.right}px`)};
  position: ${({ $static }) => ($static ? 'static' : 'absolute')};
`;
