import gql from 'graphql-tag';

const fragments = {
  supportPlanCommon: gql`
    fragment SupportPlanCommon on SubscriptionPlanDTO {
      id
      plan
      projectsPurchased
      activeUsers
      usersCreated
      userLimit
      projectsDone
      designsDone
      startDate
      expirationDate
      originalExpirationDate
      publishedDate
      unlimitedProjects
      unlimitedUsers
      isLegacy
      stockKeepingUnits {
        __typename @skip(if: true)
        id
        name
      }
    }
  `,
};

export const GET_SUPPORT_PLANS = gql`
  query SubscriptionPlansSupport($companyId: ID!, $pagination: PaginationForm!, $sorting: [SortOrder]) {
    result: subscriptionPlansSupport(companyId: $companyId, pagination: $pagination, sorting: $sorting) {
      content {
        ...SupportPlanCommon
        extensions {
          id
          expirationDate
          publishedDate
          price
          projectsPurchased
          projectsDone
          designsDone
          isLegacy
          planType
          unlimitedProjects
        }
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.supportPlanCommon}
`;

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CreateSubscriptionPlan(
    $companyId: ID!
    $subscriptionPlanCreateDTO: SubscriptionPlanCreateDTO!
    $sendWelcomeEmail: Boolean!
  ) {
    createSubscriptionPlan(
      companyId: $companyId
      subscriptionPlanCreateDTO: $subscriptionPlanCreateDTO
      sendWelcomeEmail: $sendWelcomeEmail
    ) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation UpdateSubscriptionPlan($subscriptionPlanUpdateDTO: SubscriptionPlanUpdateDTO!, $sendWelcomeEmail: Boolean!) {
    updateSubscriptionPlan(subscriptionPlanUpdateDTO: $subscriptionPlanUpdateDTO, sendWelcomeEmail: $sendWelcomeEmail) {
      id
    }
  }
`;

export const UPGRADE_CURRENT_PLAN = gql`
  mutation UpgradeCurrentPlan(
    $companyId: ID!
    $subscriptionPlanUpdateDTO: SubscriptionPlanUpdateDTO!
    $sendWelcomeEmail: Boolean!
  ) {
    upgradeCurrentPlan(
      companyId: $companyId
      subscriptionPlanUpdateDTO: $subscriptionPlanUpdateDTO
      sendWelcomeEmail: $sendWelcomeEmail
    ) {
      id
    }
  }
`;

export const DELETE_SUBSCRIPTION_PLAN = gql`
  mutation DeleteSubscriptionPlan($planId: ID!) {
    deleteSubscriptionPlan(planId: $planId) {
      id
    }
  }
`;

export const GET_DEFAULT_SKUS_FOR_PLANS = gql`
  query DefaultSkusForPlans {
    getPlansWithDefaultStockKeepingUnits {
      plan
      defaultStockKeepingUnits
    }
  }
`;

export const GET_ALL_SKUS = gql`
  query GetStockKeepingUnits {
    getStockKeepingUnits {
      __typename @skip(if: true)
      id
      name
    }
  }
`;
