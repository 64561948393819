/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import * as THREE from 'three';
import React, { createContext, forwardRef, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import FatLine from './fat-line';
import {
  PrimaryActionTypes,
  useAddEdgeBetweenNodes,
  useAddLineBetweenNodesMode,
  useAddNodeBetweenTwoNodes,
  useAddNodeToOtherNodeMode,
  useDesignInputs,
  useModes,
  useMovementType,
  usePointerType,
  useResetMode,
  useRoads,
  useUpdateWithPreviousRoads,
} from '../ilc-store';
import { useWorldCoords } from '../hooks/use-world-coords';

import {
  calculateSelectedReferenceRectangleForRoads,
  movePointAlongLineUsingDirectionVector,
  disableEdge,
  getMidpoints,
  getPointerFromEvent,
} from '../ilc-utils';
import { DrawableNode, Edge, Point } from '../ilc-types';
import { useToasts } from '../../../utils/hooks/use-toasts';
import { useTranslation } from 'react-i18next';
import { useThree } from '@react-three/fiber';

function distance(point1: Point, point2: Point): number {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

const calculateDirectionVector = (point1: Point, point2: Point) => {
  return { x: point2.x - point1.x, y: point2.y - point1.y };
};

function moveNodeAlongLine(
  mousePosition: Point,
  initialNodePosition: Point,
  connectionNodes: Point[]
): Point | undefined {
  let closestPoint: Point | null = null;
  let closestDistance = Infinity;
  for (const connectionNode of connectionNodes) {
    // const closest = closestPointOnLine(mousePosition, initialNodePosition, connectionNode);
    const dirVector = calculateDirectionVector(initialNodePosition, connectionNode);
    // const closest = closestPointOnDirectionVector(mousePosition, dirVector);
    const closest = movePointAlongLineUsingDirectionVector(initialNodePosition, dirVector, mousePosition);
    const dist = distance(mousePosition, closest);
    if (dist < closestDistance) {
      closestDistance = dist;
      closestPoint = closest;
    }
  }

  if (closestPoint) {
    // Move the node to the closest point
    return closestPoint;
  }
}

const context = createContext<any>(null);
export const Circle: any = forwardRef<any, any>(
  ({ children, opacity = 1, radius = 0.05, segments = 32, color = '#ff1050', ...props }, ref) => (
    <mesh rotation={[-Math.PI / 2, 0, 0]} ref={ref} {...props}>
      <circleGeometry args={[radius, segments]} />
      <meshBasicMaterial transparent={opacity < 1} opacity={opacity} color={color} />
      {children}
    </mesh>
  )
);

export function Nodes({ children, roadId }: { children: any; roadId: string }) {
  const [existingNodes, set] = useState<DrawableNode[]>([]);

  const lines = useMemo(() => {
    const lines: {
      start: { x: number; y: number; z: number };
      end: { x: number; y: number; z: number };
      userId: string;
      disabled: boolean;
      node1: string;
      node2: string;
      midPoint: Point;
      width: number;
    }[] = [];
    for (const node of existingNodes) {
      for (const edge of node.connectedToEdges) {
        const connectedTargetNode = existingNodes.find((existingNode) => existingNode.userId === edge.target);
        const connectedNodePosition = connectedTargetNode?.position;

        if (connectedNodePosition) {
          const disabledEdge = disableEdge(edge);
          lines.push({
            start: { x: node.position.x, z: node.position.z, y: 4.5 },
            end: { x: connectedNodePosition.x, z: connectedNodePosition.z, y: 4.5 },
            userId: `${node.userId}-${edge.target}`,
            node1: node.userId,
            node2: edge.target,
            disabled: disabledEdge,
            midPoint: {
              x: (node.position.x + connectedNodePosition.x) / 2,
              y: (node.position.z + connectedNodePosition.z) / 2,
            },
            width: node.roadWidth,
          });
        }
      }
    }

    return lines;
  }, [existingNodes]);

  const addNodeBetweenTwoNodes = useAddNodeBetweenTwoNodes();

  return (
    <context.Provider value={set}>
      {lines.map((line: any) => (
        <FatLine
          key={line.userId}
          color={line.disabled ? '#989E95' : 'black'}
          coordinates={[line.start, line.end]}
          width={line.width}
          userData={{ node1: line.node1, node2: line.node2 }}
        />
      ))}
      {lines.map((line: any) =>
        line.disabled ? null : (
          <Circle
            key={`${line.userId}-midpoint`}
            radius={2}
            position={[line.midPoint.x, 5, line.midPoint.y]}
            onPointerDown={() => {
              addNodeBetweenTwoNodes({ x: line.midPoint.x, y: -line.midPoint.y }, roadId, line.node1, line.node2);
            }}
            color={'yellow'}
          ></Circle>
        )
      )}
      {children}
    </context.Provider>
  );
}

interface INodesThreeJs {
  key: string;
  userId: string;
  name: string;
  nodesGraph: {
    [nodeId: string]: Point;
  };
  roadId: string;
  areaId: string;
  position: [number, number, number];
  color: string;
  connectedToBidirectional: Edge[];
  connectedToEdges: Edge[];
  onUpdate: (nodeId: string, coordinate: Point) => void;
  isSelectingInitialNode: boolean;
  onDeleteNodeAndEdges: (nodeId: string, roadId: string) => void;
  roadWidth: number;
  isDeletingNode: boolean;
  isAddingEdgeBetweenNodes: boolean;
}

export const NodeThreeJS: React.FC<INodesThreeJs> = ({
  key,
  name,
  userId,
  nodesGraph,
  color = 'black',
  connectedToBidirectional = [],
  connectedToEdges = [],
  position = [0, 0, 0],
  onUpdate,
  roadId,
  areaId,
  onDeleteNodeAndEdges,
  isDeletingNode,
  isAddingEdgeBetweenNodes,
  isSelectingInitialNode,
  roadWidth,
}) => {
  const { t } = useTranslation('ilc');
  const { addErrorToast } = useToasts();
  const set = useContext(context);
  const action = useModes();
  const addEdgeBetweenNodes = useAddEdgeBetweenNodes();
  const addLineBetweenNodesMode = useAddLineBetweenNodesMode();
  const resetMode = useResetMode();
  const pointerType = usePointerType();
  const movementType = useMovementType();

  const roads = useRoads();
  const updateWithPreviousRoads = useUpdateWithPreviousRoads();
  const getWorldCoords = useWorldCoords();
  const { size } = useThree();
  const selectedReferenceElement =
    action.mode.type === PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS && action.mode.payload
      ? action.mode.payload
      : null;
  const designInputs = useDesignInputs();
  const selectedReferenceRectangle = calculateSelectedReferenceRectangleForRoads(
    selectedReferenceElement,
    designInputs,
    roadWidth
  );
  const addNodeToOtherNodeMode = useAddNodeToOtherNodeMode();
  const selectedInitialNodeForAdding =
    action.mode.type === PrimaryActionTypes.ADD_NODE_TO_OTHER_NODE
      ? action.mode.payload?.connectedNode.initialNodeId
      : null;
  const selectedInitialNodeForConnecting =
    action.mode.type === PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES ? action.mode.payload?.initialNodeId : null;
  const highlightSelectedInitialNode =
    selectedInitialNodeForAdding === userId || selectedInitialNodeForConnecting === userId;

  const preventNodeEditing = [...connectedToEdges, ...connectedToBidirectional].some((edge) => disableEdge(edge));

  const midpoints = selectedReferenceRectangle ? getMidpoints(selectedReferenceRectangle) : null;

  const fullRectangleWithMidPoints =
    selectedReferenceRectangle && midpoints ? [...selectedReferenceRectangle, ...midpoints] : null;

  const [initialPosition, setInitialPosition] = useState(position);

  const positionVector = new THREE.Vector3(...position);
  const state = useMemo(() => {
    const drawableNode: DrawableNode = { position: positionVector, userId, connectedToEdges, roadId, roadWidth };
    return drawableNode;
  }, [positionVector, userId, connectedToEdges, roadWidth, roadId]);

  // Register this node on mount, unregister on unmount
  useLayoutEffect(() => {
    set((nodes: any) => [...nodes, state]);
    return () => set((nodes: any) => nodes.filter((n: any) => n !== state));
  }, [state, positionVector]);

  // Drag n drop, hover
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'grab' : 'auto';
  }, [hovered]);

  const [initialRoads, setInitialRoads] = useState<any>(null);
  const bind: any = useGesture({
    onDragStart: () => {
      if (isSelectingInitialNode) return;
      if (isAddingEdgeBetweenNodes) return;
      if (isDeletingNode) return;
      if (preventNodeEditing) return;
      if (roads) setInitialRoads(roads);
    },
    onDrag: ({ down, xy: [x, y] }) => {
      if (
        isSelectingInitialNode ||
        isAddingEdgeBetweenNodes ||
        isDeletingNode ||
        pointerType === 'MOVE' ||
        preventNodeEditing
      )
        return;
      document.body.style.cursor = down ? 'grabbing' : 'grab';

      const newPosition = getWorldCoords(getPointerFromEvent({ clientX: x, clientY: y }, size));

      if (selectedReferenceElement && fullRectangleWithMidPoints) {
        const distanceToRespect = 10;
        let closestDistance = Infinity;
        let closestPoint: Point | null = null;
        for (let i = 0; i < fullRectangleWithMidPoints.length; i++) {
          const dist = distance(newPosition, fullRectangleWithMidPoints[i]);
          if (dist < closestDistance) {
            closestDistance = dist;
            closestPoint = fullRectangleWithMidPoints[i];
          }
        }
        if (closestDistance < distanceToRespect && closestPoint) {
          onUpdate(userId, { x: closestPoint.x, y: closestPoint.y });
          return;
        } else {
          onUpdate(userId, { x: newPosition.x, y: newPosition.y });
        }
      } else if (movementType === 'ORTHOGONAL') {
        const newPositionAlongLine = moveNodeAlongLine(
          { x: newPosition.x, y: newPosition.y },
          { x: initialPosition[0], y: -initialPosition[2] },
          connectedToBidirectional
            .map((edge) => edge.target)
            .map((connectedNode: string) => ({
              x: nodesGraph[connectedNode].x,
              y: nodesGraph[connectedNode].y,
            }))
        );
        if (newPositionAlongLine) onUpdate(userId, { x: newPositionAlongLine.x, y: newPositionAlongLine.y });
      } else {
        onUpdate(userId, { x: newPosition.x, y: newPosition.y });
      }
    },
    onDragEnd: () => {
      if (isSelectingInitialNode) return;
      if (isAddingEdgeBetweenNodes) return;
      if (isDeletingNode) return;
      document.body.style.cursor = 'auto';
      setInitialPosition([nodesGraph[userId].x, 5, -nodesGraph[userId].y]);
      updateWithPreviousRoads(initialRoads);
    },
  });

  const handleSelectNodeForAddingEdge = (nodeId: string) => {
    if (action.mode.type === PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES) {
      if (!action.mode.payload) {
        addLineBetweenNodesMode({ initialNodeId: nodeId, initialNodeAreaId: areaId });
        return;
      }
      if (action.mode.payload.initialNodeAreaId !== areaId) {
        addErrorToast(t('errors.connect-roads-different-areas'), 'top-center');
        return;
      }
      addEdgeBetweenNodes(action.mode.payload.initialNodeId, nodeId, roadId);
      resetMode();
    }
  };

  const nodeColor = hovered || highlightSelectedInitialNode ? '#ff1050' : color;

  if (preventNodeEditing) {
    return null;
  }

  return (
    <Circle
      {...bind()}
      opacity={0.5}
      radius={4}
      onClick={() => {
        if (isSelectingInitialNode) {
          addNodeToOtherNodeMode({ connectedNode: { roadId, initialNodeId: userId, initialNodeAreaId: areaId } });
        }
        if (isAddingEdgeBetweenNodes) {
          handleSelectNodeForAddingEdge(userId);
        }
        if (!isDeletingNode) return;
        onDeleteNodeAndEdges(userId, roadId);
      }}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      color={nodeColor}
      position={positionVector}
      roadId={roadId}
      key={key}
      name={name}
    />
  );
};
