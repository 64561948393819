import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import tunnel from 'tunnel-rat';
import { useOffset, useUtmZone } from '../ilc-store';
import { useWorldCoords } from '../hooks/use-world-coords';

const round = (n: number) => Math.round(n * 100) / 100;

const utmBoxTunnel = tunnel();

export function UtmBoxIn() {
  const mouseRef = useRef<any>();
  const getWorldCoords = useWorldCoords();
  const offset = useOffset();
  const utmZone = useUtmZone();

  useFrame(({ pointer }) => {
    if (!mouseRef.current && !offset) return;
    const vector = getWorldCoords(pointer);
    if (offset && utmZone) {
      if (!mouseRef.current) return;
      mouseRef.current.innerHTML = `UTM Zone: ${utmZone} <br> X: ${round(vector.x + offset?.x)} <br> Y: ${round(
        vector.y + offset.y
      )}`;
    }
  });

  return (
    <utmBoxTunnel.In>
      <div style={{ position: 'fixed', bottom: 0, left: 0 }}>
        <div style={{ fontSize: '20px', backgroundColor: '#F5F5F5' }} ref={mouseRef}></div>
      </div>
    </utmBoxTunnel.In>
  );
}

export function UtmBoxOut() {
  return <utmBoxTunnel.Out />;
}
