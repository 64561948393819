import React, { useMemo } from 'react';
import * as Activitytab from 'pages/support/company-activity/activity-tab/activity-tab.styled';
import AlertsComboBox from 'pages/support/alerts-combo-box/alerts-combo-box';
import DownloadFiles from 'pages/support/company-activity/activity-tab/download-files/download-files';
import ErrorInfoLink from 'pages/support/company-activity/activity-tab/error-info-link/error-info-link';
import { DefaultTheme } from 'styled-components';
import { IOption } from 'types/common';
import { ISort } from 'types/filter';
import { t, TFunction } from 'i18next';
import {
  BriefcaseSvg,
  DownloadSvg,
  FolderSvg,
  LayersSvg,
  LegendSvg,
  PeopleSvg,
  SheetSvg,
  TeamsSvg,
  UploadSvg,
} from 'assets/icons';
import { getDateMonthDDYYYY, getHourAndMinutesHHmm, getOnCurrentTimezoneDate, toDayEnd } from '../date';
import {
  AlertSeverity,
  IActivityOptions,
  IActivityTabFilters,
  IDesignsTabFilters,
  IOptionsAttributes,
  ISupportAlert,
  ISupportedCompany,
  ISupportUserFilters,
  LinkedEntityType,
  SKUS,
} from 'types/support.types';
import { ROLE_TYPE } from 'types/role';
import {
  Button,
  Checkbox,
  ICheckboxOption,
  IRadioOption,
  ITab,
  LinkText,
  SortableHeader,
  Tooltip,
  Typography,
} from '@ratedpower/components';
import { Plan } from 'types/subscription-plan';
import { dateFilters } from './filters';
import { createColumnHelper } from '@tanstack/react-table';
import { GenericStatus } from './status';
import { useNavigate } from 'react-router-dom';
import { IndexIds } from 'index.ids';
import { openExternalLink } from 'utils/navigation';
import { buildDesignProcessUrl, buildProjectUrl } from 'utils/navigation/navigation';
import { SIMULATION_CATEGORY } from 'types/design';
import { USER_TYPE } from 'types/users';
import { pvDesignPlanTitles } from 'pages/company/plan/rated-power-plans-constants';

export const plansOptions: IOption[] = [
  {
    value: Plan.BASIC,
    label: 'basic',
  },
  {
    value: Plan.ADVANCED,
    label: 'advanced',
  },
  {
    value: Plan.ENTERPRISE,
    label: 'enterprise',
  },
  {
    value: Plan.DEMO,
    label: 'demo',
  },
];

export const expirationDate = [
  {
    code: 'THIS_WEEK',
    label: 'this-week',
    checked: false,
  },
  {
    code: 'NEXT_WEEK',
    label: 'next-week',
    checked: false,
  },
  {
    code: 'NEXT_MONTH',
    label: 'next-month',
    checked: false,
  },
];

export const activityDateRange = [
  {
    code: 'LAST_WEEK',
    label: 'last-week',
    checked: false,
  },
  {
    code: 'LAST_MONTH',
    label: 'last-month',
    checked: false,
  },
  {
    code: 'CUSTOM',
    label: 'custom',
    checked: false,
  },
];

export const companiesActivityFilters = {
  activity: 'ACTIVITY',
  businessManager: 'BUSINESS_MANAGER',
  customerSupport: 'CUSTOMER_SUPPORT',
  pseManager: 'PRODUCT_SUPPORT_ENGINEER',
  sdrManager: 'SALES_DEV_REPRESENTATIVE',
  expiration: 'EXPIRATION',
  plan: 'CURRENT_PLAN',
  country: 'COUNTRY',
};

export const companyActivityTabsNames = {
  activity: 'activity',
  projectsAndfiles: 'projects-and-files',
  designs: 'designs',
  company: 'company',
  teams: 'support-teams',
  users: 'support-users',
};

export const CompanyActivityTabs: ITab[] = [
  {
    id: companyActivityTabsNames.activity,
    label: `support:${companyActivityTabsNames.activity}`,
    Icon: LegendSvg,
    dataTest: IndexIds.CompaniesActivity.ActivityTab,
  },
  {
    id: companyActivityTabsNames.projectsAndfiles,
    label: `support:${companyActivityTabsNames.projectsAndfiles}`,
    Icon: FolderSvg,
    dataTest: IndexIds.CompaniesActivity.ProjectsTab,
  },
  {
    id: companyActivityTabsNames.designs,
    label: `${companyActivityTabsNames.designs}`,
    Icon: LayersSvg,
    dataTest: IndexIds.CompaniesActivity.DesignsTab,
  },
  {
    id: companyActivityTabsNames.company,
    label: `${companyActivityTabsNames.company}`,
    Icon: BriefcaseSvg,
    dataTest: IndexIds.CompaniesActivity.CompanyTab,
  },
  {
    id: companyActivityTabsNames.users,
    label: `support:${companyActivityTabsNames.users}`,
    Icon: PeopleSvg,
    dataTest: IndexIds.CompaniesActivity.UsersTab,
  },
  {
    id: companyActivityTabsNames.teams,
    label: `support:${companyActivityTabsNames.teams}`,
    Icon: TeamsSvg,
    dataTest: IndexIds.CompaniesActivity.TeamsTab,
  },
];

export const activityOptions = [
  {
    label: 'all-activity',
    value: 'all-activity',
  },
  {
    label: 'archived',
    value: 'archived',
  },
];

export const alertSeverityTitles: {
  [key in AlertSeverity]: string;
} = {
  ERROR: 'errors:error',
  WARNING: 'support:filters.warning',
  CHECK: 'support:correct',
};

export const entityTypeTitles: {
  [key in LinkedEntityType]: string;
} = {
  [LinkedEntityType.COMPANY]: 'common:company',
  [LinkedEntityType.SITE]: 'design-process:location-tab.site',
  [LinkedEntityType.SIMULATION]: 'design-process:energy-tab.simulation',
  [LinkedEntityType.SIMULATION_STANDALONE_BESS]: 'design-process:energy-tab.simulation-standalone-bess',
  [LinkedEntityType.METEO_DATA]: 'design-process:location-tab.meteo-data',
  [LinkedEntityType.ELEVATION]: 'design:elevation',
  [LinkedEntityType.HORIZON]: 'design:horizon',
  [LinkedEntityType.MODULE]: 'equipment:module',
  [LinkedEntityType.INVERTER]: 'electrical:inverter',
};

export const ALL_USERS = 'ALL_USERS';
export type UsersFilterOption = ROLE_TYPE.INTERNAL | ROLE_TYPE.EXTERNAL | typeof ALL_USERS;

export const usersOptions: IRadioOption[] = [
  {
    label: 'user:roles.internal - RatedPower',
    value: ROLE_TYPE.INTERNAL,
  },
  {
    label: 'user:roles.external - support:filters.company-users',
    value: ROLE_TYPE.EXTERNAL,
  },
  {
    label: 'support:filters.all-users',
    value: ALL_USERS,
  },
];

export const activityTabInitialFilters: IActivityTabFilters = {
  teamIds: [],
  alertTypes: [],
  dateRange: { from: dateFilters[1].value, to: getOnCurrentTimezoneDate(toDayEnd(new Date())) },
  entityTypes: [],
  users: [],
};

// Selector empty
export const defaultSelector: ISort = {
  ascendent: false,
  selector: {
    code: '',
    label: '',
  },
};

export const CUSTOM_DATE = 'filters.custom';
export const COMPANIES_PER_PAGE = 10;
export const PROJECTS_PER_PAGE = 20;
export const DESIGNS_PER_PAGE = 20;
export const USERS_PER_PAGE = 19;

export const COMPANIES_PAGE_SIZE_OPTIONS: IOption[] = [
  {
    label: '10 rows',
    value: 10,
  },
  {
    label: '20 rows',
    value: 20,
  },
  {
    label: '30 rows',
    value: 30,
  },
  {
    label: 'Unlimited',
    value: 'unlimited',
  },
];

export const ACTIVITY_PER_PAGE_OPTIONS: IOption[] = [
  {
    label: '20 rows',
    value: 20,
  },
  {
    label: '50 rows',
    value: 50,
  },
  {
    label: 'Unlimited',
    value: 'unlimited',
  },
];

export const supportFiltersTypes = {
  plan: 'CURRENT_PLAN',
  activityDateRange: 'ACTIVITY',
  expirationDate: 'EXPIRATION',
  cusManager: 'CUSTOMER_SUPPORT',
  bizManager: 'BUSINESS_MANAGER',
  sdrManager: 'SALES_DEV_REPRESENTATIVE',
  pseManager: 'PRODUCT_SUPPORT_ENGINEER',
};

const designActions = ['RECOVER', 'SAVE', 'DELETE'] as const;
export type DesignActions = (typeof designActions)[number];

export const getDesignActions = (t: TFunction): IOption[] => {
  return designActions.map((designAction) => ({
    label: t(`action:${designAction.toLowerCase()}`),
    value: designAction,
  }));
};

export const DESIGNS_STATUS_OPTIONS: ICheckboxOption[] = [
  {
    label: 'Not saved (< 1 day)',
    value: 'NOT_SAVED_LESS_THAN_ONE_DAY',
    checked: false,
  },
  {
    label: 'Not saved (> 1 day)',
    value: 'NOT_SAVED_MORE_THAN_ONE_DAY',
    checked: false,
  },
  {
    label: 'Saved by support',
    value: 'SAVED_SUPPORT',
    checked: false,
  },
  {
    label: 'Saved by user',
    value: 'SAVED_USER',
    checked: false,
  },
  {
    label: 'Deleted by support',
    value: 'DELETED_SUPPORT',
    checked: false,
  },
  {
    label: 'Deleted by user',
    value: 'DELETED_USER',
    checked: false,
  },
  {
    label: 'Expired',
    value: 'EXPIRED',
    checked: false,
  },
];

export const SIMULATION_RESULTS_OPTIONS: ICheckboxOption[] = [
  {
    label: 'support:finished',
    value: GenericStatus.FINISHED,
    checked: false,
  },
  {
    label: 'errors:error',
    value: GenericStatus.ERROR,
    checked: false,
  },
  {
    label: 'support:in-progress',
    value: GenericStatus.IN_PROGRESS,
    checked: false,
  },
  {
    label: 'support:queued',
    value: GenericStatus.QUEUED,
    checked: false,
  },
];

export const designsTabInitialFilters: IDesignsTabFilters = {
  status: [],
  dateRange: { from: dateFilters[1].value, to: getOnCurrentTimezoneDate(toDayEnd(new Date())) },
  simulationResults: [],
  teamIds: [],
  users: [],
};

const allCompaniesColumnHelper = createColumnHelper<ISupportedCompany>();

export const useAllCompaniesColumns = (
  isToggled?: boolean,
  companiesOptions?: IOptionsAttributes,
  allCompanies?: ISupportedCompany[]
) => {
  const navigate = useNavigate();
  const handleOnToggle = (id: string, active: boolean) => () => {
    if (companiesOptions) companiesOptions.onToggle(id, active);
  };

  const handleSorting = (filter: ISort) => {
    if (companiesOptions && filter) companiesOptions.setSortedBy(filter);
  };

  return useMemo(
    () => [
      allCompaniesColumnHelper.accessor('id', {
        header: () => (
          <Checkbox id={'input-toggle-all'} checked={!!isToggled} onChange={handleOnToggle('', !isToggled)} />
        ),
        cell: (info) => (
          <Checkbox
            id={info.row.original.id}
            checked={info.row.original.active}
            onChange={handleOnToggle(info.row.original.id, !info.row.original.active)}
          />
        ),
      }),
      allCompaniesColumnHelper.accessor('name', {
        header: () => (
          <SortableHeader
            onSort={handleSorting}
            selector={{
              label: t('support:company-name'),
              code: 'name',
            }}
            sortedBy={companiesOptions ? companiesOptions.sortedBy : defaultSelector}
          />
        ),
        cell: (info) => (
          <Button
            dataTest={IndexIds.CompaniesActivity.CompanyNameCell}
            onClick={() => navigate(`/companies-activity/${info.row.original.id}`)}
            variant="link"
            text={info.row.original.name}
          />
        ),
      }),
      allCompaniesColumnHelper.accessor('supportedBy', {
        header: () => (
          <SortableHeader
            onSort={handleSorting}
            selector={{
              label: t('support:supported-by'),
              code: 'supportedBy',
            }}
            sortedBy={companiesOptions ? companiesOptions.sortedBy : defaultSelector}
          />
        ),
        cell: (info) => <Typography>{info.getValue()?.name ?? '-'}</Typography>,
      }),
      allCompaniesColumnHelper.accessor('currentPlan', {
        header: () => (
          <SortableHeader
            onSort={handleSorting}
            selector={{
              label: t('support:filters.type-of-license'),
              code: 'currentPlan.plan',
            }}
            sortedBy={companiesOptions ? companiesOptions.sortedBy : defaultSelector}
          />
        ),
        cell: (info) => {
          const { plan, isLegacy } = info.getValue();
          if (isLegacy) {
            return <Typography>{t(`support:${pvDesignPlanTitles[plan]}`) + ` (${t('company:legacy')})`}</Typography>;
          }
          return <Typography>{t(`support:${pvDesignPlanTitles[plan]}`)}</Typography>;
        },
      }),
      allCompaniesColumnHelper.accessor('publishedDate', {
        header: () => (
          <SortableHeader
            onSort={handleSorting}
            selector={{
              label: t('support:published-date'),
              code: 'publishedDate',
            }}
            sortedBy={companiesOptions ? companiesOptions.sortedBy : defaultSelector}
          />
        ),
        cell: (info) => <Typography>{getDateMonthDDYYYY(String(info.getValue()))}</Typography>,
      }),
      allCompaniesColumnHelper.accessor('currentPlan.expirationDate', {
        header: () => (
          <SortableHeader
            onSort={handleSorting}
            selector={{
              label: t('expiration-date'),
              code: 'currentPlan.expirationDate',
            }}
            sortedBy={companiesOptions ? companiesOptions.sortedBy : defaultSelector}
          />
        ),
        cell: (info) => <Typography>{getDateMonthDDYYYY(info.getValue())}</Typography>,
      }),
      allCompaniesColumnHelper.accessor('country', {
        header: () => <Typography>{t('company:country')}</Typography>,
        cell: (info) => <Typography>{info.getValue()?.translation || '-'}</Typography>,
      }),
      allCompaniesColumnHelper.accessor('currentPlan', {
        header: () => <Typography>{t('support:days-left')}</Typography>,
        cell: (info) => {
          const dateDifference = new Date(info.getValue().expirationDate).getTime() - new Date().getTime();
          if (dateDifference <= 0) return <Typography>0</Typography>;
          return <Typography>{(dateDifference / (1000 * 3600 * 24)).toFixed()}</Typography>;
        },
      }),
      allCompaniesColumnHelper.accessor('suppAlert', {
        header: () => <Typography>{t('support:alerts')}</Typography>,
        cell: (info) => (
          <AlertsComboBox
            alerts={{
              suppAlert: info.getValue() ?? 0,
              warnings: info.row.original.warnings ?? 0,
              errors: info.row.original.errors ?? 0,
            }}
          />
        ),
      }),
    ],
    [allCompanies]
  );
};

const columnHelper = createColumnHelper<ISupportAlert>();

export const companyActivityTabColumns = (t: TFunction, theme: DefaultTheme, actOptions: IActivityOptions) => {
  const isAllActivitySelected = actOptions.activityView === 'all-activity';

  function renderDate(publishedDate: Date) {
    return (
      <Activitytab.DateContainer>
        <Typography>{getDateMonthDDYYYY(publishedDate)}</Typography>
        <Typography>{getHourAndMinutesHHmm(publishedDate)}</Typography>
      </Activitytab.DateContainer>
    );
  }

  function getAction(value: {
    entityName: string;
    entityId: string;
    linkedEntityType: string;
    projectId: string;
    alertSeverity: string;
  }) {
    const isSimulationAlert = value.linkedEntityType === LinkedEntityType.SIMULATION;
    const isStandaloneBessSimulationAlert = value.linkedEntityType === LinkedEntityType.SIMULATION_STANDALONE_BESS;

    return (
      <Activitytab.ActionsContainer>
        {value.alertSeverity === AlertSeverity.ERROR && value.linkedEntityType !== 'COMPANY' && (
          <ErrorInfoLink entityType={value.linkedEntityType} entityId={value.entityId} />
        )}
        {isSimulationAlert || isStandaloneBessSimulationAlert ? (
          <>
            {value.alertSeverity !== 'ERROR' && (
              <Button
                onClick={() =>
                  openExternalLink(
                    buildDesignProcessUrl(
                      value.projectId,
                      value.entityId,
                      isSimulationAlert ? SIMULATION_CATEGORY.PV : SIMULATION_CATEGORY.STANDALONE_BATTERY,
                      true
                    )
                  )
                }
                textUnderlined
                text={t('support:view-result')}
                variant="link"
                noPadding
              />
            )}
            <Button
              onClick={() =>
                openExternalLink(
                  buildDesignProcessUrl(
                    value.projectId,
                    value.entityId,
                    isSimulationAlert ? SIMULATION_CATEGORY.PV : SIMULATION_CATEGORY.STANDALONE_BATTERY,
                    false
                  )
                )
              }
              text={t('action:clone')}
              textUnderlined
              variant="link"
              noPadding
            />
          </>
        ) : (
          <DownloadFiles entityType={value.linkedEntityType} entityId={value.entityId} />
        )}
      </Activitytab.ActionsContainer>
    );
  }

  const renderCapitalizedValue = (value?: string) => {
    return value ? <Activitytab.CapitalizedContainer>{value.toLowerCase()}</Activitytab.CapitalizedContainer> : '';
  };
  return [
    columnHelper.accessor('publishedDate', {
      header: () => (
        <SortableHeader
          onSort={actOptions.onSort}
          selector={{
            label: t('support:date-time'),
            code: 'publishedDate',
          }}
          sortedBy={actOptions ? actOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => renderDate(info.getValue()),
    }),
    columnHelper.accessor('entityName', {
      header: () => t('support:action'),
      cell: (info) => getAction({ ...info.row.original }),
    }),
    columnHelper.accessor('linkedEntityType', {
      header: () => (
        <SortableHeader
          onSort={actOptions.onSort}
          selector={{
            label: t('support:entity'),
            code: 'linkedEntityType',
          }}
          sortedBy={actOptions ? actOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{t(entityTypeTitles[info.getValue()])}</Typography>,
    }),
    columnHelper.accessor('alertType', {
      header: () => (
        <SortableHeader
          onSort={actOptions.onSort}
          selector={{
            label: t('type'),
            code: 'alertType',
          }}
          sortedBy={actOptions ? actOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => renderCapitalizedValue(info.getValue()),
    }),
    columnHelper.accessor('projectName', {
      header: () => (
        <SortableHeader
          onSort={actOptions.onSort}
          selector={{
            label: t('support:activity-tab.project-design'),
            code: 'projectName',
          }}
          sortedBy={actOptions ? actOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => (
        <Activitytab.ProjectDesignTeam>
          <Activitytab.ProjectDesign>
            <Button
              onClick={() => openExternalLink(buildProjectUrl(info.row.original.projectId))}
              text={info.row.original.projectName}
              variant="link"
              noPadding
            />
            /
            <Button
              onClick={() =>
                openExternalLink(
                  buildDesignProcessUrl(
                    info.row.original.projectId,
                    info.row.original.entityId,
                    info.row.original.linkedEntityType === LinkedEntityType.SIMULATION
                      ? SIMULATION_CATEGORY.PV
                      : SIMULATION_CATEGORY.STANDALONE_BATTERY,
                    true
                  )
                )
              }
              text={info.row.original.entityName}
              variant="link"
              noPadding
              disabled={info.row.original.alertSeverity === AlertSeverity.ERROR}
            />
          </Activitytab.ProjectDesign>
          <Typography color={theme.gray_dark}>{info.row.original.teamName ?? t('user:teams.public-teams')}</Typography>
        </Activitytab.ProjectDesignTeam>
      ),
    }),
    columnHelper.accessor('linkedEntityOwner', {
      header: () => t('support:action'),
      cell: (info) => {
        const linkedEntityOwner = info.getValue();
        if (linkedEntityOwner) {
          return (
            <Activitytab.UserInfoContainer>
              <LinkText href={`mailto:${linkedEntityOwner.email}`} data-tooltip={t('action:mail-to')}>
                {linkedEntityOwner.email}
              </LinkText>
              <Typography color={theme.gray_dark}>
                {linkedEntityOwner.name} {linkedEntityOwner.surname} - {linkedEntityOwner.codeLanguage}
              </Typography>
            </Activitytab.UserInfoContainer>
          );
        }
        return '-';
      },
    }),
    ...(actOptions.hasWritePermissions
      ? [
          columnHelper.accessor('id', {
            id: 'options',
            header: () => t('support:options'),
            cell: (info) => {
              const { linkedEntityOwner, id, description } = info.row.original;
              return (
                <Activitytab.OptionsContainer>
                  <Tooltip description={t('support:info-and-notes')}>
                    <Button
                      onClick={() => {
                        actOptions.notesModal.setData({
                          description: description,
                          createdBy: linkedEntityOwner,
                          id: id,
                        });
                        actOptions.notesModal.setModalOpen(true);
                      }}
                      Icon={SheetSvg}
                      variant="link"
                    />
                  </Tooltip>
                  <Tooltip description={t(`action:${isAllActivitySelected ? 'archive' : 'unarchive'}`)}>
                    <Button
                      onClick={() => actOptions.onArchive(id)}
                      Icon={isAllActivitySelected ? DownloadSvg : UploadSvg}
                      variant="link"
                    />
                  </Tooltip>
                </Activitytab.OptionsContainer>
              );
            },
          }),
        ]
      : []),
  ];
};

export const initialUserFilters: ISupportUserFilters = {
  status: '',
  teamIds: [],
  roleIds: [],
  userIds: [],
};

export enum OrganizationSearchSource {
  SALESFORCE_ID,
  DOMAIN,
}

export const devSecOpsForm =
  'https://forms.office.com/pages/responsepage.aspx?id=EQ_5YXaNBUubCZCvfQcyim-eoWocW2RCqCNRStuOAkBURU5NRElHVUE1UFVINEwwTjE3WERVRDhPNy4u';

export const getMainSkus = (plan: Plan): SKUS[] => {
  const mapMainSkusPerPlan = {
    [Plan.ADVANCED]: [
      SKUS.CORE_PV,
      SKUS.STORAGE_HYBRID,
      SKUS.STORAGE_STANDALONE,
      SKUS.PRISM_US_PARCELS_PREVIEW,
      SKUS.LAYOUT_EDITOR,
    ],
    [Plan.BASIC]: [SKUS.CORE_PV, SKUS.STORAGE_HYBRID, SKUS.STORAGE_STANDALONE, SKUS.PRISM_US_PARCELS_PREVIEW],
  };
  return mapMainSkusPerPlan[plan];
};

export const mainDisabledSkus: SKUS[] = [SKUS.CORE_PV];

export const getAdditionalSkus = (plan: Plan): SKUS[] => {
  const mapAdditionalSkusPerPlan = {
    [Plan.ADVANCED]: [SKUS.PRISM_INTEGRATION, SKUS.TEAM_MANAGEMENT, SKUS.SSO],
    [Plan.BASIC]: [SKUS.LAYOUT_EDITOR, SKUS.PRISM_INTEGRATION, SKUS.TEAM_MANAGEMENT, SKUS.SSO],
  };
  return mapAdditionalSkusPerPlan[plan];
};

export const additionalDisabledSkus: SKUS[] = [SKUS.SSO];
export const csUserTypes: Set<string> = new Set([
  USER_TYPE.CS_MANAGER,
  USER_TYPE.PRINCIPAL_CONSULTANT,
  USER_TYPE.TECHNICAL_ADVISOR,
]);
