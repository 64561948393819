import React, { useState } from 'react';
import { GET_SUPPORT_COMPANY, UPDATE_SUPPORT_COMPANY } from 'services/support-gql';
import { ISelectorOptions } from 'types/selector.types';
import { useToasts } from 'utils/hooks/use-toasts';
import { useCompanyActivityState } from '../company-activity-state';
import { useCompanyFormData } from 'pages/support/company-form/use-company-form-data';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ISupportCompanyForm, ISupportUpdateCompany } from 'types/support.types';
import { ICompanyDTO } from 'types/company.types';

export interface IUseCompanyInfoTab {
  formError: any;
  setNewCompanyLogo: (newLogo?: File) => void;
  handleSubmit: (e: any) => void;
  businessUsers: ISelectorOptions[];
  customerSupportUsers: ISelectorOptions[];
  edition: boolean;
  companyUpdateLoading: boolean;
  setEdition: React.Dispatch<React.SetStateAction<boolean>>;
  loadingOptionsData: boolean;
  inputs: Record<string, any>;
  handleInputChangeManual: (name: string, value: any) => void;
  loadingCompanyData: boolean;
  contextCompany: ICompanyDTO;
}

export const useCompanyInfoTab = (): IUseCompanyInfoTab => {
  const { t } = useTranslation();
  const { addErrorToast, addSuccessToast } = useToasts();

  const {
    companyId,
    contextCompany,
    loading: loadingCompanyData,
    inputs,
    handleInputChangeManual,
    formError,
    setFormError,
  } = useCompanyActivityState();

  const { customerSupportUsers, businessUsers, loadingOptionsData, setNewCompanyLogo, newCompanyLogo } =
    useCompanyFormData();

  const [edition, setEdition] = useState(false);

  const [updateSupportCompany, { loading: companyUpdateLoading }] = useMutation(UPDATE_SUPPORT_COMPANY, {
    refetchQueries: [GET_SUPPORT_COMPANY],
    onError: (error) => {
      if (error.message === 'BadRequest') {
        addErrorToast(t('errors:duplicated-organization-name'));
        return;
      }
      addErrorToast(error.message || t('support:update-company-failed'));
    },
    onCompleted: (data) => {
      if (data) {
        setEdition(false);
        addSuccessToast(t('support:update-company-success'));
      }
    },
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
  });

  const handleSubmit = () => {
    const { name, email, maxSiteToProjectDistance } = inputs;
    if (!name || !email || !maxSiteToProjectDistance) {
      setFormError('field-empty-plural');
      return;
    }
    const companyForm: ISupportUpdateCompany = {
      id: companyId,
      name: inputs.name,
      email: inputs.email,
      countryId: inputs.countryId,
      salesforceId: inputs.salesforceId,
      businessManagerId: inputs.businessManagerId,
      supportedById: inputs.supportedById,
      maxSiteToProjectDistance: inputs.maxSiteToProjectDistance,
      showLogoInDocuments: inputs.showLogoInDocuments,
    };

    const newCompany: ISupportCompanyForm = {
      companyForm: companyForm,
      ...(newCompanyLogo && { image: newCompanyLogo }),
    };

    updateSupportCompany({ variables: { ...newCompany } });
  };

  return {
    customerSupportUsers,
    businessUsers,
    formError,
    loadingOptionsData,
    edition,
    companyUpdateLoading,
    setEdition,
    setNewCompanyLogo,
    handleSubmit,
    inputs,
    handleInputChangeManual,
    loadingCompanyData,
    contextCompany,
  };
};
