import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { Point } from '../ilc-types';

export const getWorldCoords = (pointer: Point, camera, plane = 'XY') => {
  // Get x,y coords where click occurred
  const mouse = new THREE.Vector2();
  mouse.x = pointer.x;
  mouse.y = pointer.y;

  // Setup the raycaster
  const raycaster = new THREE.Raycaster();
  if (camera.isPerspectiveCamera) {
    raycaster.near = camera.near;
    raycaster.far = camera.far;
  }
  raycaster.setFromCamera(mouse, camera);

  // Create a plane to intersect with
  const planeNormal = plane === 'XY' ? new THREE.Vector3(0, 0, 1) : new THREE.Vector3(0, 1, 0);
  const planeConstant = 0; // Distance from origin
  const intersectionPlane = new THREE.Plane(planeNormal, planeConstant);

  // Calculate the intersection point
  const intersectionPoint = new THREE.Vector3();
  raycaster.ray.intersectPlane(intersectionPlane, intersectionPoint);

  return { x: intersectionPoint.x, y: -intersectionPoint.z };
};

export const useWorldCoords = () => {
  const { camera } = useThree();

  return (pointer: Point) => getWorldCoords(pointer, camera, 'XZ');
};
