import { useMutation } from '@apollo/client';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_SUBSCRIPTION_PLAN, GET_SUPPORT_PLANS } from 'services/subscription-plan-gql';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useToasts } from 'utils/hooks/use-toasts';

export interface IUseDeletePlan {
  confirmDeleteModal: IUseModal;
  handleDeletePlan: () => void;
  handleOpenConfirmDeleteModal: (id: string) => void;
}

export const useDeletePlan = (): IUseDeletePlan => {
  const { t } = useTranslation();

  const confirmDeleteModal = useModal();
  const { addSuccessToast, addErrorToast } = useToasts();
  const rowId = useRef('');

  const handleOpenConfirmDeleteModal = (id: string) => {
    rowId.current = id;
    confirmDeleteModal.setModalOpen(true);
  };

  const [deletePlan] = useMutation(DELETE_SUBSCRIPTION_PLAN, {
    onCompleted: (deletedPlan) => {
      if (deletedPlan) {
        addSuccessToast(t('company:plan-deleted-successfully'));
        confirmDeleteModal.setModalOpen(false);
      }
    },
    onError: (graphqlError: any) => {
      const hasDebugInfo: any =
        'debugInfo' in graphqlError.graphQLErrors[0].extensions
          ? graphqlError.graphQLErrors[0].extensions.debugInfo
          : null;
      const errorMessage: string | null =
        hasDebugInfo && 'messageError' in hasDebugInfo ? hasDebugInfo.messageError : null;
      addErrorToast(errorMessage ?? t('company:delete-company-error'));
    },
    refetchQueries: [GET_SUPPORT_PLANS],
  });

  const handleDeletePlan = () => {
    deletePlan({
      variables: {
        planId: rowId.current,
      },
    });
  };

  return {
    confirmDeleteModal,
    handleDeletePlan,
    handleOpenConfirmDeleteModal,
  };
};
