import styled from 'styled-components';

export const UsersManagementTeamsWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.v2.spacing.l};
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
`;
