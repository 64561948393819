import React from 'react';
import { IBessDesign, IDesign, SimulationSecondaryCharacteristicsTag } from 'types/design';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { GenericStatus } from 'utils/constants/status';
import Skeleton from 'react-loading-skeleton';
import * as S from './home-design-item-content.styled';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DesignTag } from 'components/design-components/design-tag/design-tag';
import {
  getPrimaryTagText,
  getSecondaryTagText,
  selectedPrimaryTagColor,
  selectedSecondaryTagColor,
} from 'utils/design-tags';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';
import { useStandaloneBessSku } from 'utils/hooks/sku/use-standalone-bess-sku';
import { useIlcSku } from 'utils/hooks/sku/use-ilc-sku';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';

interface IHomeDesignItemContent {
  design: IDesign | IBessDesign;
}

export const designCardDimensions = {
  width: 276,
  height: 120,
};

const HomeDesignItemContent = ({ design }: IHomeDesignItemContent) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const { restrictStandaloneBessSku } = useStandaloneBessSku();
  const { restrictHybridBessSku } = useHybridBessSku();
  const { restrictIlcSku } = useIlcSku();
  const canCloneDesign = useCanCloneDesigns();

  const isSimulationInProgress: boolean =
    design.simulationResult === GenericStatus.IN_PROGRESS || design.simulationResult === GenericStatus.QUEUED;

  const isDisabledColor =
    restrictIlcSku(design) || restrictStandaloneBessSku(design) || restrictHybridBessSku(design) || !canCloneDesign;

  const tagVariant: string = selectedPrimaryTagColor(design, isDisabledColor);
  const tagText: string = t(getPrimaryTagText(design));

  return (
    <>
      <S.ThumbnailContainer>
        <DesignTag color={tagVariant} position={{ top: 8, right: 8 }}>
          {tagText}
        </DesignTag>
        {design.secondaryTag === SimulationSecondaryCharacteristicsTag.EDITED && (
          <DesignTag
            color={selectedSecondaryTagColor(design, !flags?.skuIlc || !canCloneDesign)}
            position={{ top: 38, right: 8 }}
          >
            {t(getSecondaryTagText(design))}
          </DesignTag>
        )}
        {isSimulationInProgress ? (
          <Skeleton width={designCardDimensions.width} height={designCardDimensions.height} />
        ) : (
          <RemoteImage
            width={designCardDimensions.width}
            height={designCardDimensions.height}
            url={design.satellitePicture?.thumbnail}
            alt="Layout view"
          />
        )}
      </S.ThumbnailContainer>
      {isSimulationInProgress && <Skeleton />}
    </>
  );
};

export default HomeDesignItemContent;
