import styled from 'styled-components';
import { Button } from '@ratedpower/components';

export const FormActions = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  width: 100%;
`;

export const CompanyLogoImg = styled.div`
  img {
    border-radius: ${({ theme }) => theme.v2.radius.s};
    border: 1px solid ${({ theme }) => theme.v2.bg.secondary.default};
    max-width: 160px;
  }
`;

export const PlansHistorySection = styled.div`
  flex-grow: 1;
  overflow-x: auto;
  max-width: 2200px;
`;

export const CompanyInfoContainer = styled.div`
  display: flex;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 2rem 2rem;
  column-gap: ${({ theme }) => theme.v2.spacing.m};
`;

export const CompanyInfoColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  margin-top: 40px;

  @media screen and (min-width: 1440px) {
    max-width: 435px;
  }
`;

export const Block = styled.div`
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
`;

export const CompanyLogoHeadline = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v2.spacing.m};
`;

export const FormSectionHeadline = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v2.spacing.xs};
`;

export const EditButton = styled(Button)`
  padding: 0;
`;

export const InputWithUnlimitedOption = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const CompanyFormSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  padding: ${({ theme }) => theme.v2.spacing.m};
  @media screen and (max-width: 1440px) {
    width: 320px;
  }
`;

export const BannerDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.v2.spacing.xxs};
`;
