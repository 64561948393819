import ImgComingSoon from './img-coming-soon.png';
import ImgUnderConstruction from './img-under-construction.webp';

import ImgDesignComparison from './design-comparison.png';

import LogoRatedPowerSvg from './logo-ratedpower.svg';

import SiteCardBackgroundPng from './site-card-background.png';
import TopographyCardBackgroundPng from './topography-card-background.png';

import ColorWheelSvg from './color-wheel.svg';

import DistancesEastWestStructureLayout from './distances-east-west-structure-layout.png';
import DistancesStructureMountingSystemPng from './distances-structure-mounting-system.png';

import EnergyProductionChartPng from './energy-production-chart.png';
import EnergyYieldLineChartPng from './energy-yield-line-chart.png';
import EnergyYieldTableChartPng from './energy-yield-table-chart.png';
import HorizonChartPng from './horizon-chart.png';
import AlbedoChartPng from './albedo-chart.png';
import MeteoChartPng from './meteo-chart.png';

import AreaComplex from './area-complex.png';
import AreasIntersecting from './areas-intersecting.png';
import CombinedSurfaceAreaTooBig from './combined-surface-area-too-big.png';
import ExceededAvailableAreas from './exceeded-available-areas.png';
import ExceededRestrictedAreas from './exceeded-restricted-areas.png';
import ExceededSubstations from './exceeded-substations.png';
import ExceededBatteryAreas from './exceeded-battery-areas.png';
import InvalidFeatureType from './invalid-feature-type.png';
import MissingPoint from './missing-point.png';
import PointOutside from './point-outside.png';
import PolygonNamedIncorrectly from './polygon-named-incorrectly.png';
import RequiredAreaMissing from './required-area-missing.png';
import RequiredSTMissing from './required-st-missing.png';
import SiteTooFar from './site-too-far.png';
import TooManyLevels from './too-many-levels.png';
import MultipleNonRedundantConnections from './multiple-non-redundant-connections.png';
import FirstLevelMissing from './first-level-missing.png';

import NoDataAvailable from './no-data-available.png';
import FixedBiPole from './fixed-bipole.png';
import FixedMonoPole from './fixed-monopole.png';
import FixedBiPoleEW from './fixed-ew-bipole.png';
import FixedMonoPoleEW from './fixed-ew-monopole.png';
import FixedTriPoleEW from './fixed-ew-tripole.png';
import EWStructure from './e-w-structures.png';
import SingleMPPT from './single-mppt.png';
import MultipleMPPT from './multiple-mppt.png';
import ImgDefault from './default-img.png';

import EnglishTracker from './structures/tracker/english.png';
import ChineseTracker from './structures/tracker/chinese.png';
import FrenchTracker from './structures/tracker/french.png';
import ItalianTracker from './structures/tracker/italian.png';
import PortugueseTracker from './structures/tracker/portuguese.png';
import SpanishTracker from './structures/tracker/spanish.png';
import GermanTracker from './structures/tracker/german.png';
import JapaneseTracker from './structures/tracker/japanese.png';

import EnglishFixedBipole from './structures/fixed/bipole/english.png';
import ChineseFixedBipole from './structures/fixed/bipole/chinese.png';
import FrenchFixedBipole from './structures/fixed/bipole/french.png';
import ItalianFixedBipole from './structures/fixed/bipole/italian.png';
import PortugueseFixedBipole from './structures/fixed/bipole/portuguese.png';
import SpanishFixedBipole from './structures/fixed/bipole/spanish.png';
import GermanFixedBipole from './structures/fixed/bipole/german.png';
import JapaneseFixedBipole from './structures/fixed/bipole/japanese.png';

import EnglishFixedMonopole from './structures/fixed/monopole/english.png';
import ChineseFixedMonopole from './structures/fixed/monopole/chinese.png';
import FrenchFixedMonopole from './structures/fixed/monopole/french.png';
import ItalianFixedMonopole from './structures/fixed/monopole/italian.png';
import PortugueseFixedMonopole from './structures/fixed/monopole/portuguese.png';
import SpanishFixedMonopole from './structures/fixed/monopole/spanish.png';
import GermanFixedMonopole from './structures/fixed/monopole/german.png';
import JapaneseFixedMonopole from './structures/fixed/monopole/japanese.png';

import EnglishEWMonopole from './structures/east-west/monopole/english.png';
import ChineseEWMonopole from './structures/east-west/monopole/chinese.png';
import FrenchEWMonopole from './structures/east-west/monopole/french.png';
import ItalianEWMonopole from './structures/east-west/monopole/italian.png';
import PortugueseEWMonopole from './structures/east-west/monopole/portuguese.png';
import SpanishEWMonopole from './structures/east-west/monopole/spanish.png';
import GermanEWMonopole from './structures/east-west/monopole/german.png';
import JapaneseEWMonopole from './structures/east-west/monopole/japanese.png';

import EnglishEWBipole from './structures/east-west/bipole/english.png';
import ChineseEWBipole from './structures/east-west/bipole/chinese.png';
import FrenchEWBipole from './structures/east-west/bipole/french.png';
import ItalianEWBipole from './structures/east-west/bipole/italian.png';
import PortugueseEWBipole from './structures/east-west/bipole/portuguese.png';
import SpanishEWBipole from './structures/east-west/bipole/spanish.png';
import GermanEWBipole from './structures/east-west/bipole/german.png';
import JapaneseEWBipole from './structures/east-west/bipole/japanese.png';

import EnglishEWTripole from './structures/east-west/tripole/english.png';
import ChineseEWTripole from './structures/east-west/tripole/chinese.png';
import FrenchEWTripole from './structures/east-west/tripole/french.png';
import ItalianEWTripole from './structures/east-west/tripole/italian.png';
import PortugueseEWTripole from './structures/east-west/tripole/portuguese.png';
import SpanishEWTripole from './structures/east-west/tripole/spanish.png';
import GermanEWTripole from './structures/east-west/tripole/german.png';
import JapaneseEWTripole from './structures/east-west/tripole/japanese.png';

import AdaptativeDesignImg from './design-process/type-of-layouts/adaptative-design.png';
import RegularBlockDesignImg from './design-process/type-of-layouts/regular-block-design.png';

import StandardRotationImg from './design-process/structure-positions/standard.png';
import RotatedRotationImg from './design-process/structure-positions/rotated.png';
import OffsetRotationImg from './design-process/structure-positions/offset.png';
import TurningAxisRotationImg from './design-process/structure-positions/turning-axis.png';

import HorizontalRoadsImg from './design-process/road-layouts/horizontal-roads.png';
import HorizontalPerimeterRoadsImg from './design-process/road-layouts/horizontal-perimeter-roads.png';
import VerticalRoadsImg from './design-process/road-layouts/vertical-roads.png';
import VerticalPerimeterRoadsImg from './design-process/road-layouts/vertical-perimeter-roads.png';
import FullPerimeterRoadsImg from './design-process/road-layouts/full-perimeter-roads.png';
import MinimumPerimeterRoadsImg from './design-process/road-layouts/minimum-perimeter-roads.png';
import NoRoadsImg from './design-process/road-layouts/no-roads.png';

import UniformArrangementImg from './design-process/structure-alignment/uniform-arrangement.png';
import BorderAdaptationImg from './design-process/structure-alignment/border-adaptation.png';

import OutsideDCFieldImg from './design-process/power-station-locations/outside-dc-field.png';
import InsideDCFieldImg from './design-process/power-station-locations/inside-dc-field.png';

import DCBESS from './design-process/bess/type/dc-bess.png';
import ACBESS from './design-process/bess/type/ac-bess.png';

import FollowAAPerimeter from './design-process/setbacks/available-area/cards/follow-aa-perimeter.png';
import MinimizeFenceLength from './design-process/setbacks/available-area/cards/minimize-fence-length.png';
import PreventFenceCrossRA from './design-process/setbacks/restricted-area/cards/prevent-fence-cross-ra.png';
import KeepRAInsidePVPlant from './design-process/setbacks/restricted-area/cards/keep-ra-inside-pv-plant.png';
import LetFenceCrossRA from './design-process/setbacks/restricted-area/cards/let-fence-cross-ra.png';
import MinimiseCrossingRA from './design-process/setbacks/restricted-area/cards/minimise-crossing-ra.png';
import CrossDirectlyRA from './design-process/setbacks/restricted-area/cards/cross-directly-ra.png';

import AxialGroupingImg from './design-process/string-positioning/axial-grouping.png';
import PitchGroupingImg from './design-process/string-positioning/pitch-grouping.png';

import StringBoxImg from './design-process/connection-type/string-box.png';
import DCBusImg from './design-process/connection-type/dc-bus-system.png';
import StringInverterFieldImg from './design-process/connection-type/string-inverter-field.png';
import StringInverterStationImg from './design-process/connection-type/string-inverter-station.png';

import SwitchingBreakingStationImg from './design-process/interconnection-facility/switching-breaking-station.png';
import SubstationImg from './design-process/interconnection-facility/substation.png';

import TwoWindingImg from './design-process/power-transformer-type/two-winding.png';
import ThreeWindingImg from './design-process/power-transformer-type/three-winding.png';
import ThreeWindingEquipmentImg from './design-process/power-transformer-type/three-winding-equipment.png';

import GoogleEarthImg from './design-process/cards/google-earth.png';

import CustomHourlyImg from './design-process/cards/custom-hourly.png';
import CustomMonthlyImg from './design-process/cards/custom-monthly.png';
import NasaPowerImg from './design-process/cards/nasa-power.png';
import NasaSseImg from './design-process/cards/nasa-sse.png';
import PvgisImg from './design-process/cards/pvgis.png';

import IntersectingCircles from './design-process/grid-requirements/intesecting-circles.png';

import SatelliteView from './design-process/satellite-views/satellite-view.png';
import StreetsView from './design-process/satellite-views/streets-view.png';
import SatelliteStreets from './design-process/satellite-views/satellite-streets.png';
import OutdoorsView from './design-process/satellite-views/outdoors-view.png';

import FlatHorizonImg from './design-process/cards/flat-horizon.png';
import SeparateInverters from '../images/separate-inverters.png';

import NewUpdatesImg from './new-update-image.png';

import SIUploadFile from './design-process/import-site-cards/upload-file.svg';
import SIRatedPower from './design-process/import-site-cards/rated-power-logo.svg';
import SIPrism from './design-process/import-site-cards/prism-logo.svg';
import SICadFile from './design-process/import-site-cards/cad-file.svg';

const images: any = {
  ImgComingSoon,
  ImgUnderConstruction,

  ImgDesignComparison,
  LogoRatedPowerSvg,
  SiteCardBackgroundPng,
  TopographyCardBackgroundPng,
  ColorWheelSvg,
  DistancesEastWestStructureLayout,
  DistancesStructureMountingSystemPng,

  EnergyProductionChartPng,
  EnergyYieldLineChartPng,
  EnergyYieldTableChartPng,
  HorizonChartPng,
  AlbedoChartPng,
  MeteoChartPng,
  AreaComplex,
  AreasIntersecting,
  CombinedSurfaceAreaTooBig,
  ExceededAvailableAreas,
  ExceededRestrictedAreas,
  ExceededSubstations,
  ExceededBatteryAreas,
  InvalidFeatureType,
  MissingPoint,
  PointOutside,
  PolygonNamedIncorrectly,
  RequiredAreaMissing,
  RequiredSTMissing,
  SiteTooFar,
  TooManyLevels,
  MultipleNonRedundantConnections,
  FirstLevelMissing,
  NoDataAvailable,

  IntersectingCircles,
  FixedBiPole,
  FixedMonoPole,
  FixedBiPoleEW,
  FixedMonoPoleEW,
  FixedTriPoleEW,
  EWStructure,
  SingleMPPT,
  MultipleMPPT,
  ImgDefault,

  AAFenceStructures: {
    FollowAAPerimeter,
    MinimizeFenceLength,
  },

  RAFenceStructures: {
    PreventFenceCrossRA,
    KeepRAInsidePVPlant,
    LetFenceCrossRA,
  },

  RARoadsStrategy: {
    MinimiseCrossingRA,
    CrossDirectlyRA,
  },

  trackerImages: {
    en: EnglishTracker,
    zh: ChineseTracker,
    fr: FrenchTracker,
    it: ItalianTracker,
    pt: PortugueseTracker,
    es: SpanishTracker,
    de: GermanTracker,
    jp: JapaneseTracker,
  },

  fixedBipoleImages: {
    en: EnglishFixedBipole,
    zh: ChineseFixedBipole,
    fr: FrenchFixedBipole,
    it: ItalianFixedBipole,
    pt: PortugueseFixedBipole,
    es: SpanishFixedBipole,
    de: GermanFixedBipole,
    jp: JapaneseFixedBipole,
  },

  fixedMonopoleImages: {
    en: EnglishFixedMonopole,
    zh: ChineseFixedMonopole,
    fr: FrenchFixedMonopole,
    it: ItalianFixedMonopole,
    pt: PortugueseFixedMonopole,
    es: SpanishFixedMonopole,
    de: GermanFixedMonopole,
    jp: JapaneseFixedMonopole,
  },

  monopoleImages: {
    en: EnglishEWMonopole,
    zh: ChineseEWMonopole,
    fr: FrenchEWMonopole,
    it: ItalianEWMonopole,
    pt: PortugueseEWMonopole,
    es: SpanishEWMonopole,
    de: GermanEWMonopole,
    jp: JapaneseEWMonopole,
  },

  SeparateInverters,

  SatelliteView,
  StreetsView,
  SatelliteStreets,
  OutdoorsView,

  bipoleImages: {
    en: EnglishEWBipole,
    zh: ChineseEWBipole,
    fr: FrenchEWBipole,
    it: ItalianEWBipole,
    pt: PortugueseEWBipole,
    es: SpanishEWBipole,
    de: GermanEWBipole,
    jp: JapaneseEWBipole,
  },

  tripoleImages: {
    en: EnglishEWTripole,
    zh: ChineseEWTripole,
    fr: FrenchEWTripole,
    it: ItalianEWTripole,
    pt: PortugueseEWTripole,
    es: SpanishEWTripole,
    de: GermanEWTripole,
    jp: JapaneseEWTripole,
  },

  typeOfLayouts: {
    AdaptativeDesignImg,
    RegularBlockDesignImg,
  },

  structurePositions: {
    StandardRotationImg,
    RotatedRotationImg,
    OffsetRotationImg,
    TurningAxisRotationImg,
  },

  roadsLayouts: {
    HorizontalRoadsImg,
    HorizontalPerimeterRoadsImg,
    VerticalRoadsImg,
    VerticalPerimeterRoadsImg,
    FullPerimeterRoadsImg,
    MinimumPerimeterRoadsImg,
    NoRoadsImg,
  },

  perimeterAdaptation: {
    UniformArrangementImg,
    BorderAdaptationImg,
  },

  powerStationLocations: {
    OutsideDCFieldImg,
    InsideDCFieldImg,
  },

  bessTypes: {
    DCBESS,
    ACBESS,
  },

  stringPositioning: {
    AxialGroupingImg,
    PitchGroupingImg,
  },

  connectionType: {
    StringBoxImg,
    DCBusImg,
    StringInverterFieldImg,
    StringInverterStationImg,
  },

  inteerconnectionFacility: {
    SwitchingBreakingStationImg,
    SubstationImg,
  },

  powerTransformerType: {
    TwoWindingImg,
    ThreeWindingImg,
    ThreeWindingEquipmentImg,
  },

  GoogleEarthImg,
  FlatHorizonImg,

  meteo: {
    CustomHourlyImg,
    CustomMonthlyImg,
    NasaPowerImg,
    NasaSseImg,
    PvgisImg,
  },

  NewUpdatesImg,

  importSiteCards: {
    SIUploadFile,
    SIRatedPower,
    SIPrism,
    SICadFile,
  },
};

export default images;
