import { DesignType, PageLocations } from 'rudderstack/types';
import { RudderstackScreenResolution } from 'rudderstack/utils';
import { SimulationPrimaryCharacteristicsTag, SimulationSecondaryCharacteristicsTag } from 'types/design';

export const getRudderstackScreenResolution = (): RudderstackScreenResolution => {
  const width = window.screen.availWidth;
  if (width >= 1920) return '1920+';
  if (width >= 1440 && width <= 1919) return '1440-1919';
  if (width >= 1280 && width <= 1439) return '1280-1439';
  if (width >= 1024 && width <= 1279) return '1024-1279';
  return '-1023';
};

export const getRudderstackCreateProjectEventLocation = (pathname: string) => {
  if (pathname === '/') {
    return PageLocations.HOMEPAGE;
  } else if (pathname.startsWith('/projects')) {
    return PageLocations.PROJECTS;
  } else if (pathname.startsWith('/users-management/teams')) {
    return PageLocations.TEAMS;
  }
  console.warn(`Not covered create project rudderstack location: ${pathname}`);
  return null;
};

export const getRudderstackFavoriteDesignEventLocation = (pathname: string) => {
  if (pathname === '/') {
    return PageLocations.HOMEPAGE;
  } else if (pathname.startsWith('/projects')) {
    return PageLocations.PROJECTS;
  }
  console.warn(`Not covered mark as favorite design rudderstack location: ${pathname}`);
  return null;
};

export const getRudderstackCloneDesignEventLocation = (pathname: string) => {
  if (pathname === '/') {
    return PageLocations.HOMEPAGE;
  } else if (pathname.startsWith('/projects')) {
    return PageLocations.PROJECTS;
  }
  console.warn(`Not covered clone design design rudderstack location: ${pathname}`);
  return null;
};

export const getRudderstackDesignCategory = (
  primaryTag: SimulationPrimaryCharacteristicsTag,
  secondaryTag: SimulationSecondaryCharacteristicsTag
): string => {
  if (secondaryTag === SimulationSecondaryCharacteristicsTag.EDITED) return DesignType.PV_EDITED;
  if (primaryTag === SimulationPrimaryCharacteristicsTag.PV) return DesignType.PV;
  return DesignType.BESS;
};

export const projectsNumOfDesignFilter = 'numberofdesigns';
