import styled from 'styled-components';
import { Typography } from '@ratedpower/components';

export const ModalPlanForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  padding: ${({ theme }) => theme.v2.spacing.m} 0;
`;

export const FormError = styled(Typography)`
  text-align: right;
  color: ${({ theme }) => theme.v2.error.text.default};
`;

export const Unlimited = styled(Typography)`
  width: 100%;
`;

export const SendWelcomeEmailCheckbox = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.m};
`;
