import React, { useState } from 'react';
import * as S from './tour-popover.styled';
import { Button, Checkbox, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { PopoverContentProps } from '@reactour/tour';

type TourPopoverTemplateProps = {
  tourLocalStoreId: string;
  timesToShowTour: number;
} & PopoverContentProps;

const TourPopoverTemplate: React.FC<TourPopoverTemplateProps> = ({
  currentStep,
  tourLocalStoreId,
  setIsOpen,
  setCurrentStep,
  steps,
  timesToShowTour,
}) => {
  const theme = useTheme();
  const [tourOff, setTourOff] = useState(false);

  const onToggle = () => {
    if (!tourOff) {
      localStorage.setItem(tourLocalStoreId, timesToShowTour.toString());
      setTourOff(true);
    }
    if (tourOff) {
      localStorage.setItem(tourLocalStoreId, '0');
      setTourOff(false);
    }
  };

  const onSkip = () => {
    setIsOpen(false);
    const currentTime = localStorage.getItem(tourLocalStoreId);
    if (!currentTime) {
      localStorage.setItem(tourLocalStoreId, '1');
    }
    if (currentTime && Number(currentTime) < timesToShowTour) {
      const newTime = Number(currentTime) + 1;
      localStorage.setItem(tourLocalStoreId, `${newTime}`);
    }
  };

  const isLastStep = currentStep === steps.length - 1;
  const content = steps[currentStep].content;

  return (
    <S.PopoverContainer>
      <>
        {content}
        {timesToShowTour > 1 && (
          <Checkbox
            id="tour-toggle"
            checked={tourOff}
            onChange={() => onToggle()}
            label="Don't show again"
            color={theme.primary}
          />
        )}
        <S.PopoverFooter>
          <Typography color={theme.v2.text.secondary.default}>
            {currentStep + 1} of {steps.length}
          </Typography>
          <S.ButtonsContainer>
            {isLastStep && <Button type="submit" variant="accent" onClick={onSkip} text="Got it!" />}
            {!isLastStep && currentStep > 0 && (
              <>
                <Button variant="ghost" onClick={() => setCurrentStep(currentStep - 1)} text="Back" />
                <Button variant="accent" onClick={() => setCurrentStep(currentStep + 1)} text="Next" />
              </>
            )}
            {currentStep === 0 && (
              <>
                <Button type="submit" variant="ghost" text="Skip" onClick={onSkip} />
                <Button variant="accent" onClick={() => setCurrentStep(currentStep + 1)} text="Next" />
              </>
            )}
          </S.ButtonsContainer>
        </S.PopoverFooter>
      </>
    </S.PopoverContainer>
  );
};

export default TourPopoverTemplate;
