import { useQuery } from '@apollo/client';
import { GET_DEFAULT_SKUS_FOR_PLANS } from 'services/subscription-plan-gql';
import { IDefaultSkusPerPlan, Plan } from 'types/subscription-plan';
import { SKUS } from 'types/support.types';
import { useMemo } from 'react';
import { useGetCompanyCurrentPlan } from './use-get-company-skus';
import { useGetAllSkus } from './use-get-all-skus';

export const useDefaultSkus = (forUsersPreview: boolean) => {
  const currentCompanyPlan = useGetCompanyCurrentPlan();
  const excludedSkusForUsersPreview = [SKUS.PRISM_INTEGRATION];
  const excludedPlansForUsersPreview = [Plan.DEMO];
  const allSkus = useGetAllSkus(forUsersPreview);

  const { data, error, loading } = useQuery(GET_DEFAULT_SKUS_FOR_PLANS, { fetchPolicy: 'cache-and-network' });

  const otherPlansSkus: IDefaultSkusPerPlan[] = useMemo(() => {
    return data?.getPlansWithDefaultStockKeepingUnits
      .filter((defaultSku) => !forUsersPreview || !excludedPlansForUsersPreview.includes(defaultSku.plan))
      .map((plan: IDefaultSkusPerPlan) => ({
        ...plan,
        defaultStockKeepingUnits: plan.defaultStockKeepingUnits.filter(
          (sku) => !forUsersPreview || !excludedSkusForUsersPreview.includes(sku as SKUS)
        ),
      }));
  }, [forUsersPreview, data]);

  return {
    currentPlan: currentCompanyPlan,
    otherPlansSkus,
    allSkus,
    loading,
    error,
  };
};
