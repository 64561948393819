import styled, { css } from 'styled-components';
import { BodyTr } from '@ratedpower/components';

const stickyColumn = css`
  position: sticky;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.v2.bg.primary.default};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  }
`;

export const PlanHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.m};

  .renew-plan-btn {
    width: max-content;
    margin-left: auto;
  }
`;

export const PlanHistoryTable = styled.div`
  th:last-child {
    ${stickyColumn}
    background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  }

  ${BodyTr} {
    td {
      white-space: nowrap;

      &.top-aligned {
        vertical-align: top;
        padding-top: ${({ theme }) => theme.v2.spacing.s};
      }
    }

    .sticky-column {
      ${stickyColumn}
    }

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.v2.bg.secondary.default};

      .sticky-column {
        background-color: ${({ theme }) => theme.v2.bg.secondary.default};
      }
    }
  }
`;

export const TimelapseCell = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.m};
`;

export const ThreeDotsCell = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.xs};
`;
