import styled from 'styled-components';

export const TeamsTabContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;

  .no-teams-description {
    margin-bottom: ${({ theme }) => theme.v2.spacing.s};
  }
`;

export const TeamsTabContent = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.v2.spacing.m};
`;

export const ErrorContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.v2.spacing.m};
`;

export const ModalButtons = styled.div`
  margin-top: ${({ theme }) => theme.v2.spacing.m};
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  justify-content: right;
`;

export const ContentTopbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
