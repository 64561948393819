import React from 'react';
import * as S from './header-row.styled';
import { Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { IUserRoleSummary } from 'types/users';
import {
  getReadOnlyRolesColors,
  ReadOnlyRoles,
} from 'pages/users-management/read-only-roles-tab/config/use-read-only-roles-config';
import { BaseCell } from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/read-only-roles-custom.styled';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IProps {
  userRoleSummary: IUserRoleSummary[];
}

const HeaderRow: React.FC<IProps> = ({ userRoleSummary }) => {
  const theme = useTheme();
  const { skuTeamManagement } = useFlags();
  const roleColors = getReadOnlyRolesColors(theme);

  const roleHeaders = skuTeamManagement
    ? Object.values(ReadOnlyRoles)
    : Object.values(ReadOnlyRoles).filter((role) => role !== ReadOnlyRoles.teamManager);

  const userCounterForRole = (roleToFind: ReadOnlyRoles): number => {
    const foundRole = userRoleSummary.find(({ role }) => role.name === roleToFind);

    return foundRole ? foundRole.userCounter : 0;
  };

  return (
    <>
      <BaseCell />
      {userRoleSummary &&
        roleHeaders.map((role: ReadOnlyRoles) => (
          <S.HeaderCell key={role} $bgColor={roleColors[role]} $hide={false}>
            <Typography weight="bold" color={theme.v2.text.secondary.default}>
              {role} ({userCounterForRole(role)})
            </Typography>
          </S.HeaderCell>
        ))}
    </>
  );
};
export default HeaderRow;
