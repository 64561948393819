import React from 'react';
import * as S from './topbar.styled';
import { Breadcrumb, Button, SVGWrapper } from '@ratedpower/components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCallValidation,
  useDesignName,
  useIsGeneratingDesign,
  useLoadingInputsData,
  useLoadingUpdateOperation,
  useLoadingValidateOperation,
  useProjectName,
  useSetRightSideBarSelection,
  useUpdateDesignName,
} from '../../ilc-store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { setSidebar } from 'redux/sidebar-slice';
import { BurgerMenuSvg } from 'assets/icons';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ITopBar {
  handleGenerateDesign: () => void;
}

function TopBar({ handleGenerateDesign }: Readonly<ITopBar>) {
  const navigate = useNavigate();
  const projectName = useProjectName();
  const designName = useDesignName();
  const { projectId } = useParams<{ projectId: string }>();
  const loadingInputsData = useLoadingInputsData();
  const loadingUpdateOperation = useLoadingUpdateOperation();
  const { collapsed } = useSelector((state: RootState) => state.sidebar);
  const dispatch = useDispatch();
  const theme = useTheme();
  const callValidation = useCallValidation();
  const loadingValidateOperation = useLoadingValidateOperation();
  const setRightSideBar = useSetRightSideBarSelection();
  const isGeneratingDesign = useIsGeneratingDesign();
  const { t } = useTranslation('ilc');

  const handleUpdateDesignName = useUpdateDesignName();

  const handleValidateAndGenerateDesign = async () => {
    const validationErrors = await callValidation();
    if (!validationErrors) return;
    if (validationErrors.length > 0 && validationErrors.find((error) => error.result === 'BLOCKER')) {
      setRightSideBar('validations');
      return;
    }

    handleGenerateDesign();
  };

  const handleCallValidation = async () => {
    await callValidation();
    setRightSideBar('validations');
  };

  return (
    <S.TopBarWrapper>
      <S.Flex>
        <SVGWrapper
          icon={BurgerMenuSvg}
          size="m"
          onClick={() => {
            dispatch(setSidebar({ collapsed: !collapsed }));
          }}
          color={theme.v2.icon.primary.default}
        />
        <Breadcrumb
          prevPages={[
            { name: projectName ?? '', url: `/projects/${projectId}`, onClick: (url) => url && navigate(url) },
          ]}
          currentPage={{ name: designName ?? '' }}
          size="m"
          onEdit={(text: string) => handleUpdateDesignName(text)}
          loading={loadingInputsData}
        />
      </S.Flex>

      <S.Flex id="layout-editor-validate-and-generate">
        <Button
          text={t('actions.validate-design')}
          onClick={handleCallValidation}
          variant="accent"
          disabled={loadingUpdateOperation}
          loading={loadingValidateOperation}
        />
        <Button
          text={t('actions.generate-design')}
          onClick={handleValidateAndGenerateDesign}
          disabled={loadingUpdateOperation}
          variant="brand"
          loading={isGeneratingDesign}
        />
      </S.Flex>
    </S.TopBarWrapper>
  );
}

export { TopBar };
