import styled from 'styled-components';

export const CompanyLicenseContainer = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.l};
  background: ${({ theme }) => theme.v2.bg.secondary.default};
  margin: ${({ theme }) => `${theme.v2.spacing.m} ${theme.v2.spacing.xl}`};
  padding: ${({ theme }) => theme.v2.spacing.xl};
  width: max-content;
`;
