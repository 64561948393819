import React from 'react';
import { SVGWrapper, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { CrossSvg, RoundedCheckSvg } from 'assets/icons/index';
import { useTheme } from 'styled-components';
import * as S from './action-row.styled';
import {
  getReadOnlyRolesColors,
  ReadOnlyRoles,
} from 'pages/users-management/read-only-roles-tab/config/use-read-only-roles-config';
import { ReadOnlyRolesRow } from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/read-only-roles-custom.styled';
import { ReadOnlyRolesPermissionMap } from 'utils/hooks/use-get-external-roles-with-permissions';
import { Permission } from 'access-control/permissions';

interface IProps {
  featureKey: string;
  action: any;
  readOnlyRolesPermissionsMap: ReadOnlyRolesPermissionMap;
  withTitle: boolean;
  featureTitle: string;
}

const ActionRow: React.FC<IProps> = ({ featureKey, action, readOnlyRolesPermissionsMap, withTitle, featureTitle }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const iconConfig = {
    success: {
      color: theme.v2.success.bg.contrast,
      icon: RoundedCheckSvg,
    },
    error: {
      color: theme.v2.error.bg.contrast,
      icon: CrossSvg,
    },
  };
  const roleColors = getReadOnlyRolesColors(theme);

  const roleHasPermission = (role: ReadOnlyRoles, permissions: Permission[]): boolean => {
    if (!permissions) return false;

    const rolePermissions = readOnlyRolesPermissionsMap.get(role);

    return (
      permissions.length === 0 ||
      rolePermissions?.size === 0 ||
      permissions.some((permission) => rolePermissions?.has(permission) ?? false)
    );
  };

  return (
    <ReadOnlyRolesRow key={featureKey}>
      <S.Cell $textAlign="left">
        {withTitle && <Typography weight="bold">{t(featureTitle)}</Typography>}
        <Typography>{t(action.title)}</Typography>
      </S.Cell>
      {Object.keys(action.roles).map((role) => {
        const roleTexts = action.roles[role].texts;
        const permissionIsValid = roleHasPermission(role as ReadOnlyRoles, action.roles[role].permissions);
        const svgConfig = permissionIsValid ? iconConfig.success : iconConfig.error;
        const textsAreHidden = action.roles[role].hiddenTexts;

        return (
          <S.Cell key={`${role}-${featureKey}`} $bgColor={roleColors[role]} $withTitle={withTitle}>
            <S.SVGWrapper>
              <SVGWrapper icon={svgConfig.icon} size="m" color={svgConfig.color} />
            </S.SVGWrapper>
            {permissionIsValid &&
              !textsAreHidden &&
              roleTexts?.map((text: string) => <Typography key={text}>{t(`user:read-only-roles.${text}`)}</Typography>)}
          </S.Cell>
        );
      })}
    </ReadOnlyRolesRow>
  );
};

export default ActionRow;
