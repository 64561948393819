import { FinancialResultsDTO } from 'pages/design-process/outputs/financial/types';
import { GenericStatus } from 'utils/constants/status';
import { CosPhiDeliveryPoint, CosPhiSizingMode } from './design-process';

interface IMainValues {
  ratedPower: number;
  peakPower: number;
  ratioDcAc: number;
  specificProduction: number;
  plantPR: number;
  gcr: number;
  energyYield: number;
  financialResultsDTO: FinancialResultsDTO;
  capexResult?: {
    currency: string;
  };
}

interface IMeteo {
  summary: {
    source: string;
    ghi: number;
    bhi: number;
    dhi: number;
    tempAvg: number;
  };
}
export type IMonthlyEnergyProduction = {
  month: number;
  ghi: number;
  bhi: number;
  dhi: number;
  temp: number;
};

export interface IMeteoFull {
  summary: {
    source: string;
    resolution: string;
    ghi: number;
    bhi: number;
    dhi: number;
    tempAvg: number;
    tempMax: number;
    tempMin: number;
  };
  monthlyProduction: IMonthlyEnergyProduction[];
}

export interface IMonthlyAlbedo {
  month: number;
  albedoValue: number;
}

export interface IAlbedo {
  definition: {
    source: string;
    resolution: string;
  };
  yearlyResults: {
    mean: number;
    totalSamples: number;
    maxSamplesPerMonth: number;
    minSamplesPerMonth: number;
  };
  monthlyData: IMonthlyAlbedo[];
}
export interface IHorizon {
  horizonSummary: {
    source: string;
    horizonAvg: number;
    horizonMax: number;
  };
  profilePicture: string;
}

interface IEquipment {
  module: {
    model: string;
    manufacturer: string;
    power: number;
  };
  inverter1: {
    manufacturer: string;
    model: string;
    type: string;
    power: number;
  };
  inverter2: {
    manufacturer: string;
    model: string;
    type: string;
    power: number;
  } | null;
  structure: {
    id?: string;
    manufacturer: string;
    model: string;
    positionLetter: string;
    positionNumber: string;
    isTracker?: boolean;
  };
}

interface ILayoutParams {
  tiltAngle: number;
  pitchDistance: number;
  electricalConfig: {
    modulesPerString: number;
  };
  lvConfig: string;
}
export interface IDesign {
  id: string;
  name: string;
  projectTeamId?: string;
  notes: string;
  status: string;
  number: number;
  simulationType: SIMULATION_TYPE;
  simulationCategory: SIMULATION_CATEGORY;
  primaryTag: SimulationPrimaryCharacteristicsTag;
  secondaryTag: SimulationSecondaryCharacteristicsTag;
  layoutEditorTag: LayoutEditorTag;
  isFavorite: boolean;
  fillColor?: string;
  createdBy: {
    id: string;
    name: string;
    lastname: string;
  };
  inputsSimulation: {
    inputsPerAreaDefinedGlobally: boolean;
  };
  publishedDate: string;
  satellitePicture: {
    high: string;
    thumbnail: string;
    low: string;
  };
  inputsPlotAreas: any;
  pitchDistanceMinMax: any;
  meteoData: IMeteo;
  inputs: ILayoutParams & IEquipment;
  outputs: IMainValues | null;
  simulationResult: string;
  docsSaved: boolean;
}

export enum LVCONFIG {
  CONFIG_SBOX_L1,
  CONFIG_DCBUS,
  CONFIG_SBOX_L2_FIELD,
  CONFIG_SBOX_L2_PS,
}

export enum SIMULATION_TYPE {
  SINGLE = 'SINGLE',
  BATCH_CONTAINER = 'BATCH_CONTAINER',
  BATCH_SIMULATION = 'BATCH_SIMULATION',
}

export enum SIMULATION_CATEGORY {
  PV = 'PV',
  ILC = 'ILC',
  STANDALONE_BATTERY = 'STANDALONE_BATTERY',
}

export enum SimulationPrimaryCharacteristicsTag {
  PV = 'PV',
  PV_BATCH = 'PV_BATCH',
  BESS = 'BESS',
  PV_BESS = 'PV_BESS',
  PV_BESS_BATCH = 'PV_BESS_BATCH',
}

export enum SimulationSecondaryCharacteristicsTag {
  EDITED = 'EDITED',
  NO_EDITED = 'NO_EDITED',
}

export enum LayoutEditorTag {
  AVAILABLE = 'AVAILABLE',
  REQUIRED_FILES = 'REQUIRED_FILES',
  STAND_ALONE_BESS_DESIGN = 'STAND_ALONE_BESS_DESIGN',
  AAS_SHARING_LV_DEFINED = 'AAS_SHARING_LV_DEFINED',
}

export const DESIGN_STATUS = {
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  REVIEWED: 'REVIEWED',
  ARCHIVED: 'ARCHIVED',
};

export type DesignProcessLoadingFileType = 'site' | 'topography' | 'meteorology' | 'horizons' | 'energyPrices';
export interface IHelpLink {
  link: string;
  text: string;
}

export interface IBessDesign {
  id: string;
  name: string;
  projectTeamId?: string;
  number: number;
  site: SiteDTO;
  meteoData: MeteoDataDTO;
  elevation: ElevationDTO;
  simulationType: SIMULATION_TYPE;
  simulationCategory: SIMULATION_CATEGORY;
  primaryTag: SimulationPrimaryCharacteristicsTag;
  secondaryTag: SimulationSecondaryCharacteristicsTag;
  layoutEditorTag: LayoutEditorTag;
  simulationResult: string;
  publishedDate: string;
  standaloneBatterySimulationInputs: SimulationStandAloneBatteryInputsDTO;
  standaloneBatterySimulationOutputs: SimulationStandAloneBatteryOutputsDTO;
  satellitePicture: {
    high: string;
    thumbnail: string;
    low: string;
  };
  isFavorite: boolean;
  notes: string;
  status: string;
  createdBy: {
    id: string;
    name: string;
    lastname: string;
  };
}

interface SimulationStandAloneBatteryOutputsDTO {
  id: string;
  activePowerAc: number;
  energyCapacity: number;
  operationHours: number;
}

interface SimulationStandAloneBatteryInputsDTO {
  id: string;
  documentsSystemOfUnits: string;
  cosinePhiSizingMode: CosPhiSizingMode;
  cosPhiDelivery: number;
  cosinePhiDeliveryPoint: CosPhiDeliveryPoint;
  utmConvergence: number;
  cableSizingStandardName: string;
  electricalVoltageDropMv: number;
  inverter: BasicInverterDTO;
}

interface BasicInverterDTO {
  id: string;
  manufacturer: string;
  model: string;
  ratedPower: number;
}

interface SiteDTO {
  id: string;
  name: string;
  errorComponents: string;
  duration: number;
  publishedDate: Date;
  executionDate: Date;
  originalFileFormat: string;
  status: GenericStatus;
  errorType: string;
  createdBy: {
    id: string;
    name: string;
    lastname: string;
  };
  sitePictureUrl: string;
}

interface MeteoDataDTO {
  meteoData: {
    id: string;
    name: string;
    errorMessage: string;
    duration: number;
    originalAvailable: boolean;
    status: string;
    errorType: string;
    meteoDataSummary: MeteoDataSummaryDTO;
    meteoDataValues: MeteoDataValueDTO[];
  };
}

interface MeteoDataSummaryDTO {
  id: number;
  uncertainty: number;
  latitudeMeteoData: number;
  longitudeMeteoData: number;
  designExtremeTempCoefficient: number;
  timeShift: number;
  tempMinHistoric: number;
  tempMaxHistoric: number;
  tempAvgHistoric: number;
  yearGhiWh: number;
  yearDhiWh: number;
  yearBhiWh: number;
  timeResolution: string;
  source: string;
  sourceComment: string;
  tempMinHistoricAt10Wm2: number;
  tempMinHistoricAt50Wm2: number;
  tempMinHistoricAt100Wm2: number;
  tempMinHistoricAt200Wm2: number;
}

interface MeteoDataValueDTO {
  id: number;
  month: number;
  ghi: number;
  bhi: number;
  dhi: number;
  temp: number;
}

interface ElevationDTO {
  id: string;
  name: string;
  errorMessage: string;
  duration: number;
  publishedDate: Date;
  executionDate: Date;
  status: GenericStatus;
  errorType: string;
  createdBy: {
    id: string;
    name: string;
    lastname: string;
  };
  elevationSummary: ElevationSummaryDTO;
  elevationPoints: ElevationPoints[];
  elevationSlopePoints: ElevationSlopePoints[];
  availableDocuments: any[];
}

interface ElevationSummaryDTO {
  id: number;
  elevationSite: number;
  elevationMaxSite: number;
  elevationMinSite: number;
  slopeXAvgSite: number;
  slopeXMaxSite: number;
  slopeYAvgSite: number;
  slopeYMaxSite: number;
  maxEastingUTM: number;
  minEastingUTM: number;
  maxNorthingUTM: number;
  minNorthingUTM: number;
  latitudeElevation: number;
  longitudeElevation: number;
  source: string;
}

type ElevationPoints = {
  latitude: number;
  longitude: number;
  elevation: number;
};

type ElevationSlopePoints = {
  type: string;
  latitude: number;
  longitude: number;
  slope: number;
  arrowLatitude1: number;
  arrowLongitude1: number;
  arrowLatitude2: number;
  arrowLongitude2: number;
  arrowEasting1: number;
  arrowNorthing1: number;
  arrowEasting2: number;
  arrowNorthing2: number;
};

export interface PageInfo {
  totalPages: number;
  pageNumber: number;
}

interface ProjectSimulationsResult {
  pvResult: Array<IDesign>;
  saBessResult: Array<IBessDesign>;
  pageInfo: PageInfo;
}

export interface GetCombinedProjectDesignsResponse {
  combinedProjectSimulations: ProjectSimulationsResult;
  project: {
    name: string;
    teamId: string;
    publishedDate: Date;
  };
}

export interface GetCombinedProjectDesignsByUserResponse {
  combinedProjectSimulationsByUser: ProjectSimulationsResult;
  project: {
    name: string;
    teamId: string;
    publishedDate: Date;
  };
}
