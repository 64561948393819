import { useEffect, useRef, useState } from 'react';
import { useFilters, IOverlayFilter } from 'utils/hooks/use-filters';
import { GET_PROJECT_COUNTRIES } from 'services/projects-gql';
import { useTranslation } from 'react-i18next';
import { getFilter, getRangeFilters, getSelectedFilters } from 'utils/filters';
import { useQuery } from '@apollo/client';
import { useGetProjectCreatos } from './use-get-project-creators';
import { IUseProjectFilters } from 'types/projects';
import { useListTeams } from 'pages/teams/hooks/use-list-teams';
import { track } from 'rudderstack/utils';
import { stringArraysSameElements } from 'utils/array';
import { projectFiltersTypes } from 'utils/constants/projects';
import { useTheme } from 'styled-components';
import { projectStatusList } from 'utils/constants/status';
import { RudderstackEvent } from 'rudderstack/types';
import { NULL_FILTER_VALUE } from 'utils/constants/filters';
import { projectsNumOfDesignFilter } from 'utils/constants/rudderstack';

const noDesignsOptions = [
  { code: 'All', label: 'all', checked: true },
  { code: '< 5', label: '< 5', checked: false },
  { code: '5 - 10', label: '5 - 10', checked: false },
  { code: '> 10', label: '> 10', checked: false },
];

interface IServerFilter {
  field: string;
  operator: string;
  values: string[];
}

export const useProjectFilters = (
  showTeamsFilter?: boolean,
  showStatusFilter?: boolean,
  selectedTab?: string
): IUseProjectFilters => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [serverFilters, setServerFilters] = useState<IServerFilter[]>([]);
  const { filters, setFilters, overlayOpen, setOverlayOpen, onClickFilterOption, handleSingleSelection } = useFilters();

  const selectedFilterCodesRef = useRef<string[]>([]);

  const { data: fetchedCountries } = useQuery(GET_PROJECT_COUNTRIES, {
    fetchPolicy: 'network-only',
  });

  const { teams: fetchedTeams } = useListTeams({});

  const teamsFilter = {
    title: t('user:teams.team'),
    code: projectFiltersTypes.team,
    options: [
      ...(fetchedTeams?.reduce(
        (result: [{ label: string; code: string; checkbox: boolean }], next: { name: string; id: string }) => {
          if (next.name !== '-') {
            result.push({
              label: next.name,
              code: next.id,
              checkbox: false,
            });
          }
          return result;
        },
        []
      ) || []),
      {
        label: t('user:teams.public-teams'),
        code: NULL_FILTER_VALUE,
        checked: false,
      },
    ],
  };

  const { fetchedCreators, unknownCreators, loadingCreators } = useGetProjectCreatos();

  // If teams selector changes we should stop using sidebar's teams filter
  useEffect(() => {
    if (!showTeamsFilter) {
      setFilters((currentFilters) => currentFilters.slice(0, -1));
      setServerFilters((currentServerFilters) =>
        currentServerFilters.filter((serverFilter) => serverFilter.field !== projectFiltersTypes.team)
      );
    } else {
      setFilters((currentFilters) => [...currentFilters, teamsFilter]);
    }
  }, [showTeamsFilter]);

  useEffect(() => {
    resetFilters();
  }, [fetchedCountries, fetchedCreators, fetchedTeams]);

  const resetFilters = () => {
    const newFilters: IOverlayFilter[] = [
      {
        title: t('project:num-designs'),
        code: projectFiltersTypes.numDesigns,
        options: noDesignsOptions,
      },
    ];

    if (fetchedCountries?.countries) {
      newFilters.push({
        title: t('company:country'),
        code: projectFiltersTypes.country, // strings
        options: [...fetchedCountries.countries]
          .sort((a: string, b: string) => a.localeCompare(b))
          .map((c: string) => ({ code: c, label: c, checked: false })),
      });
    }

    if (showStatusFilter) {
      newFilters.push({
        title: t('support:status'),
        code: projectFiltersTypes.status,
        options: projectStatusList(theme).map((status) => ({
          code: status.value,
          label: t(status.label),
          checked: false,
        })),
      });
    }

    if (fetchedCreators?.result?.content) {
      newFilters.push({
        title: t('filters.creator'),
        code: projectFiltersTypes.creator, // authors IDs
        options: fetchedCreators.result.content.reduce(
          (
            result: [{ label: string; code: string; checkbox: boolean }],
            next: { name: string; lastname: string; id: string }
          ) => {
            if (next.name !== '-' && next.lastname !== '-') {
              result.push({
                label: `${next.name} ${next.lastname}`,
                code: next.id,
                checkbox: false,
              });
            }
            return result;
          },
          []
        ),
      });
    }

    if (fetchedTeams?.length && showTeamsFilter) {
      newFilters.push(teamsFilter);
    }

    setFilters(newFilters);
  };

  function applyFilters() {
    let updatedServerFilters: IServerFilter[] = [];
    for (const filter of filters) {
      let serverFilter: IServerFilter[] = [];
      switch (filter.code) {
        case projectFiltersTypes.country: {
          const countryFilter = getFilter(filter);
          serverFilter = !!countryFilter ? [countryFilter] : [];
          break;
        }
        case projectFiltersTypes.status: {
          const statusFilter = getFilter(filter);
          serverFilter = !!statusFilter ? [statusFilter] : [];
          break;
        }
        case projectFiltersTypes.creator: {
          const creatorFilter = JSON.parse(JSON.stringify(getFilter(filter)));
          if (creatorFilter && creatorFilter.values.find((e: string) => e === 'unknown')) {
            creatorFilter.values = creatorFilter.values.filter((e: string) => e !== 'unknown');
            creatorFilter.values.push(...unknownCreators);
          }
          serverFilter = !!creatorFilter ? [creatorFilter] : [];
          break;
        }
        case projectFiltersTypes.numDesigns:
          serverFilter = getRangeFilters(filter);
          break;
        case projectFiltersTypes.team: {
          const teamFilter = getFilter(filter);
          serverFilter = !!teamFilter ? [teamFilter] : [];
          break;
        }
      }

      if (!!serverFilter && !!serverFilter.length) {
        updatedServerFilters = [...updatedServerFilters, ...serverFilter];
      }
    }
    const selectedFilters = getSelectedFilters(filters);
    if (
      !showStatusFilter &&
      (!selectedFilterCodesRef.current || !stringArraysSameElements(selectedFilters, selectedFilterCodesRef.current))
    ) {
      rudderstackTrackProjectFilters(selectedFilters);
    }
    setServerFilters(updatedServerFilters);
    setOverlayOpen(false);
  }

  const rudderstackTrackProjectFilters = (selectedFilterCodes: string[]) => {
    selectedFilterCodesRef.current = selectedFilterCodes;
    if (selectedFilterCodes.includes(projectFiltersTypes.country)) {
      track(RudderstackEvent.APPLY_PROJECTS_FILTERS, {
        filter_applied: projectFiltersTypes.country.toLowerCase(),
        tab_selected: selectedTab,
      });
    }
    if (selectedFilterCodes.includes(projectFiltersTypes.numDesigns)) {
      track(RudderstackEvent.APPLY_PROJECTS_FILTERS, {
        filter_applied: projectsNumOfDesignFilter,
        tab_selected: selectedTab,
      });
    }
    if (selectedFilterCodes.includes(projectFiltersTypes.creator)) {
      track(RudderstackEvent.APPLY_PROJECTS_FILTERS, {
        filter_applied: projectFiltersTypes.creator.toLowerCase(),
        tab_selected: selectedTab,
      });
    }
    if (selectedFilterCodes.includes(projectFiltersTypes.team)) {
      track(RudderstackEvent.APPLY_PROJECTS_FILTERS, {
        filter_applied: projectFiltersTypes.team.toLowerCase(),
        tab_selected: selectedTab,
      });
    }
  };

  function clearFilters() {
    setServerFilters([]);
    setOverlayOpen(false);
    resetFilters();
  }

  const handleSelect = (filter: string, value: any, checked: boolean) => {
    if (filter === projectFiltersTypes.numDesigns) {
      handleSingleSelection(filter, value, checked);
      return;
    }
    onClickFilterOption(filter, value, checked);
  };

  return {
    serverFilters,
    toggleOverlay: () => setOverlayOpen(!overlayOpen),
    overlayProps: {
      loadingCreators,
      filters,
      isOpen: overlayOpen,
      onClear: () => clearFilters(),
      onApply: () => applyFilters(),
      onSelect: handleSelect,
    },
  };
};
