import styled from 'styled-components';

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.s};
  padding: ${({ theme }) => theme.v2.spacing.m};
  position: relative;
  border-radius: ${({ theme }) => theme.v2.spacing.xs};
`;

export const PopoverFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.s};
`;
