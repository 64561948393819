import React from 'react';
import { StatusSVGWrapper, Typography } from '@ratedpower/components';
import { CloneSvg, FavouriteOutlineSvg, FavouriteSvg, PenSquareSvg } from 'assets/icons';
import * as S from 'pages/home/home-designs/home-project-item/home-projects-designs/home-projects-designs.styled';
import { ButtonContainer } from 'pages/home/home-designs/home-project-item/home-projects-designs/home-projects-designs.styled';
import { useTheme } from 'styled-components';
import { IBessDesign, IDesign } from 'types/design';
import { useTranslation } from 'react-i18next';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { getAccessToIlcIsDisabled, getAccessToIlcMessage, getDesignIsILC } from 'utils/design-tags';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';
import { useStandaloneBessSku } from 'utils/hooks/sku/use-standalone-bess-sku';
import { useIlcSku } from 'utils/hooks/sku/use-ilc-sku';

interface IHomeDesignItemHeader {
  design: IDesign | IBessDesign;
  handleSwitchFavorite: (item: IDesign | IBessDesign) => Promise<void>;
  handleClone: (item: IDesign | IBessDesign) => Promise<void>;
  handleWebGL: (item: IDesign | IBessDesign) => Promise<void>;
}

const HomeDesignItemHeader = ({ design, handleSwitchFavorite, handleWebGL, handleClone }: IHomeDesignItemHeader) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const canCloneDesign = useCanCloneDesigns();
  const favoriteFontColor = design.isFavorite ? theme.v2.icon.primary.active : theme.v2.icon.primary.default;
  const { restrictStandaloneBessSku } = useStandaloneBessSku();
  const { restrictHybridBessSku } = useHybridBessSku();
  const { showIlcButtonByPrimaryTag } = useIlcSku();
  const designIsIlc = getDesignIsILC(design.secondaryTag);
  const disableAccessToIlc = getAccessToIlcIsDisabled(design) || !canCloneDesign;

  const handleClick =
    (handler: (item: IDesign | IBessDesign) => Promise<void>) =>
    (e: any): void => {
      e.preventDefault();
      handler(design);
    };

  const handleSwitchFavoriteClick = handleClick(handleSwitchFavorite);

  const handleCloneClick = handleClick(handleClone);

  const handleWebGLClick = handleClick(handleWebGL);

  const disableClone =
    !canCloneDesign || restrictStandaloneBessSku(design) || restrictHybridBessSku(design) || designIsIlc;

  const getIlcTooltip = () => {
    if (!canCloneDesign) {
      return t('action:readonly');
    }
    return t(getAccessToIlcMessage(design.layoutEditorTag));
  };

  const getCloneTooltip = () => {
    if (designIsIlc) {
      return t('design:ilc-cannot-clone');
    }
    return t('action:clone');
  };

  return (
    <S.HomeDesignItemHeader>
      <Typography size={'m'} weight={'bold'} className="title" nowrap>
        {design.number}. {design.name}
      </Typography>
      <ButtonContainer>
        <StatusSVGWrapper
          disabled={design.simulationResult === 'IN_PROGRESS'}
          onClick={handleSwitchFavoriteClick}
          icon={design.isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
          tooltip={{ text: t('action:favorite'), place: 'bottom' }}
          active={false}
          size="s"
          color={favoriteFontColor}
        />
        {showIlcButtonByPrimaryTag(design) && (
          <StatusSVGWrapper
            disabled={disableAccessToIlc}
            className="align-end"
            tooltip={{
              text: getIlcTooltip(),
              place: 'bottom',
            }}
            onClick={handleWebGLClick}
            icon={PenSquareSvg}
            dataTest="clone-btn"
            active={false}
            size="s"
          />
        )}

        <StatusSVGWrapper
          disabled={disableClone}
          tooltip={{ text: getCloneTooltip(), place: 'bottom' }}
          onClick={handleCloneClick}
          icon={CloneSvg}
          active={false}
          size="s"
        />
      </ButtonContainer>
    </S.HomeDesignItemHeader>
  );
};

export default HomeDesignItemHeader;
