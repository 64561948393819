import React from 'react';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import { CheckboxGroup, FilterSidebarItem, ICheckboxOption } from '@ratedpower/components';
import { ITeam } from 'types/teams';
import { useListTeams } from 'pages/teams/hooks/use-list-teams';
import { useTranslation } from 'react-i18next';
import { NULL_FILTER_VALUE } from 'utils/constants/filters';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';

interface IProps {
  teamsChecked: string[];
  onChangeTeams: (teamSelected: ICheckboxOption) => void;
}

const TeamsFilter: React.FC<IProps> = ({ onChangeTeams, teamsChecked }) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const { teams, error } = useListTeams({});

  if (error) {
    return <ErrorRetrievingData />;
  }

  const publicTeamCheckbox: ICheckboxOption = {
    label: t('user:teams.public-teams'),
    value: NULL_FILTER_VALUE,
    checked: teamsChecked.includes(NULL_FILTER_VALUE),
  };

  return (
    <>
      {!!teams?.length && isAuthorized(Permission.TEAM_SUPPORT_READ) && (
        <FilterSidebarItem title={t('user:teams.tab-title')} maxHeightContent="140px" hasBorderBottom>
          <CheckboxGroup
            id="teamFilter"
            direction="column"
            options={[
              ...teams.map((team: ITeam) => ({
                label: team.name,
                value: team.id,
                checked: teamsChecked.includes(team.id),
              })),
              publicTeamCheckbox,
            ]}
            onChange={onChangeTeams}
          />
        </FilterSidebarItem>
      )}
    </>
  );
};

export default TeamsFilter;
