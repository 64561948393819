import React, { useState, useEffect } from 'react';
import * as S from './validation-errors-display.styled';
import { useDesignInputs, useValidationErrorStatus } from '../../ilc-store';
import { Notification, SectionHeader, StatusSVGWrapper } from '@ratedpower/components';
import { ChevronDownSvg, ChevronUpSvg } from 'assets/icons';
import { Collapse } from 'react-collapse';
import { cleanAreaName } from './utils';
import { ValidationsDisplay } from './validations-display';
import { Area, IlcValidationError } from '../../ilc-types';
import { CameraControls } from '@react-three/drei';
import { useTranslation } from 'react-i18next';
import { sortAreas } from '../../ilc-utils';

interface IValidationErrorsDisplay {
  validationErrors: IlcValidationError[];
  controls: typeof CameraControls | undefined;
}

function ValidationErrorsDisplay({ validationErrors, controls }: Readonly<IValidationErrorsDisplay>) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('BLOCKER');
  const designInputs = useDesignInputs();
  const areaParametricInputs = designInputs ? designInputs.areaParametricInputs : {};
  const validationErrorStatus = useValidationErrorStatus();
  const { t } = useTranslation('ilc');

  const areasWithError: Area[] = validationErrors
    .filter((error) => error.result === selectedTab)
    .reduce((acc: string[], item) => {
      if (!acc.includes(item.areaId)) {
        acc.push(item.areaId);
      }
      return acc;
    }, [])
    .map((id, index) => {
      const areaName = cleanAreaName(areaParametricInputs[id]?.areaName ?? `area-${index + 1}`);
      return { id, label: areaName };
    });

  useEffect(() => {
    if (validationErrors.length > 0 && validationErrors.every((error) => error.result === 'WARNING')) {
      setSelectedTab('WARNING');
    }
  }, [validationErrors]);

  return (
    <S.ValidationErrorsDisplayWrapper>
      <SectionHeader
        title={t('validations-instructions.list-of-validations')}
        leftSideOptions={
          areasWithError.length > 0 ? (
            <StatusSVGWrapper
              icon={isCollapsed ? ChevronDownSvg : ChevronUpSvg}
              onClick={() => setIsCollapsed(!isCollapsed)}
              size="m"
              active={false}
            />
          ) : null
        }
      />
      {validationErrorStatus === 'error' ? (
        <S.ValidationStatusWrapper>
          <Notification
            title="Error"
            description="Could not process the validation of the design"
            status="error"
            heightFitContent
            hideCloseButton
          />
        </S.ValidationStatusWrapper>
      ) : validationErrorStatus === 'success' ? (
        <S.ValidationStatusWrapper>
          <Notification
            title="Validation complete!"
            description="Your design has been validated with no errors."
            status="success"
            heightFitContent
            hideCloseButton
          />
        </S.ValidationStatusWrapper>
      ) : null}
      {areasWithError.length > 0 ? (
        <Collapse isOpened={!isCollapsed}>
          <ValidationsDisplay
            areasWithError={sortAreas(areasWithError)}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            validationErrors={validationErrors}
            controls={controls}
          />
        </Collapse>
      ) : null}
    </S.ValidationErrorsDisplayWrapper>
  );
}

export { ValidationErrorsDisplay };
