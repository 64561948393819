import { useQuery } from '@apollo/client';
import { GET_BASE_PARAMS, GET_BESS_BASE_PARAMS } from 'services/capex-gql';
import { useEffect, useRef, useState } from 'react';
import { CapexEntryProperty, emptyMapSection, emptyMapEntry } from 'utils/constants/capex-constants';
import {
  IBaseParams,
  ICapexCreateTemplateEntry,
  ICapexTemplateEntry,
  ICapexUpdateTemplateEntry,
  IUseCapexFormObject,
  IUseCapexHashMapObject,
} from 'types/capex';
import { arrayDeepCopy } from 'utils/operations';
import { useCapexEntries } from './use-capex-entries';
import { t } from 'i18next';
import { SIMULATION_CATEGORY } from 'types/design';

export function useCapexForm(
  entries?: ICapexTemplateEntry[],
  simulationCategory?: SIMULATION_CATEGORY,
  skip?: boolean
): IUseCapexFormObject {
  const [lastId, setLastId] = useState(0);
  const { data: responseBaseParams } = useQuery(
    simulationCategory === SIMULATION_CATEGORY.STANDALONE_BATTERY ? GET_BESS_BASE_PARAMS : GET_BASE_PARAMS,
    { skip }
  );

  const baseParams: IBaseParams[] =
    simulationCategory === SIMULATION_CATEGORY.STANDALONE_BATTERY
      ? responseBaseParams?.capexBessBaseParams
      : responseBaseParams?.capexBaseParams;

  const { formatCreateCapexDataToSend, formatUpdateCapexDataToSend } = useCapexEntries();
  const mappedEntries = addId(entries);
  const capexForm = arrayDeepCopy(mappedEntries);

  function deleteItem(id: string, callback: (capexForm: any) => void) {
    function recursiveDelete(children: any) {
      if (!!children) {
        return children.reduce((accumulator: any, currentValue: any) => {
          if (!currentValue) return accumulator;
          if (
            (currentValue.provisionalId !== undefined && currentValue.provisionalId !== id) ||
            (currentValue.id !== undefined && currentValue.id !== id)
          ) {
            const newCurrentValue = { ...currentValue, children: recursiveDelete(currentValue.children) };
            accumulator.push(newCurrentValue);
          }
          return accumulator;
        }, []);
      }
      return [];
    }
    const newCapexData = recursiveDelete(capexForm);
    callback(newCapexData);
  }

  function addEmptyItem(tagIndex: string, callback: (capexForm: any) => void) {
    const array = tagIndex.replaceAll('.', ' ').split(' ');
    const defaultBaseParam = {
      ...emptyMapEntry,
      name: t(emptyMapEntry.name),
      priceDefinition: {
        baseParam: baseParams[0],
        name: baseParams[0].description,
        paramMultiplier: 1,
        price: 0,
        tax: 0,
      },
    };
    function recursiveAdd(items: any, index: string[]): any {
      if (index.length > 0) {
        const position = parseInt(index.shift() || '', 10) - 1;
        const newItems = [...items];
        newItems[position].children = recursiveAdd(newItems[position].children, index);
        return newItems;
      } else {
        const newItems = [...items];

        if (!!items[0] && !!items[0].priceDefinition) {
          newItems.push({ ...defaultBaseParam, provisionalId: lastId });
          setLastId(lastId + 1);
        } else {
          newItems.push({
            ...{
              ...emptyMapSection,
              name: t(emptyMapSection.name),
              provisionalId: lastId.toString(),
            },
            children: [{ ...defaultBaseParam, provisionalId: (lastId + 1).toString() }],
          });
          setLastId(lastId + 2);
        }
        return newItems;
        // añadimos el item al principio de los children y reordenamos los tag index
      }
    }
    let result = [...capexForm];
    if (!!tagIndex) {
      result = recursiveAdd(capexForm, array);
    } else {
      result.push({
        ...{
          ...emptyMapSection,
          name: t(emptyMapSection.name),
          provisionalId: lastId.toString(),
        },
        children: [
          {
            ...{
              ...emptyMapSection,
              name: t(emptyMapSection.name),
              provisionalId: (lastId + 1).toString(),
            },
            children: [{ ...defaultBaseParam, provisionalId: (lastId + 2).toString() }],
          },
        ],
      });
      setLastId(lastId + 3);
    }
    callback(result);
  }

  function getEntries(isCreating: boolean): ICapexCreateTemplateEntry[] | ICapexUpdateTemplateEntry[] {
    if (isCreating) {
      return formatCreateCapexDataToSend(capexForm);
    } else {
      return formatUpdateCapexDataToSend(capexForm);
    }
  }

  return {
    getEntries,
    deleteItem,
    addEmptyItem,
    capexForm,
    baseParams: baseParams || [],
  };
}

/**********************  HASH MAP ****************** */
export const useCapexHashMap = (capexForm: any): IUseCapexHashMapObject => {
  const hashMapEntries = useRef<Map<number, ICapexTemplateEntry>>(new Map());

  useEffect(() => {
    if (capexForm) {
      setMapEntries(capexForm);
    }
  }, [capexForm]);

  function setMapEntries(form: ICapexTemplateEntry[]) {
    if (form && form.length > 0) {
      form.forEach((templateEntry: ICapexTemplateEntry) => {
        if (!templateEntry?.mapKey) return;
        hashMapEntries.current.set(templateEntry.mapKey, templateEntry);
        const { entries, subtitles } = getEntriesAndSubtitlesFromCapexForm(templateEntry);
        if (subtitles?.length > 0) {
          subtitles.forEach((subtitle: any) => {
            hashMapEntries.current.set(subtitle.mapKey, subtitle);
          });
        }
        if (!!entries && entries.length > 0) {
          entries.forEach((entry: any) => {
            hashMapEntries.current.set(entry.mapKey, entry);
          });
        }
        setMapEntries(subtitles);
      });
    }
  }

  function setEntryProperty(
    mapKey: number,
    property: CapexEntryProperty,
    value: string | number | boolean | IBaseParams
  ) {
    if (mapKey !== null && !!property && value !== null) {
      const mapEntry = hashMapEntries.current.get(mapKey);
      if (mapEntry) {
        if (property === 'name') {
          mapEntry[property] = value as string;
        } else {
          const priceDefinition = mapEntry.priceDefinition;
          if (priceDefinition && priceDefinition[property] !== value) {
            priceDefinition[property] = value;
          }
        }
      }
    }
  }

  return {
    setEntryProperty,
    hashMapEntries,
  };
};

export function addId(capexData: any) {
  if (!capexData) return;

  let id = 0;
  function addTagIndexToCapexForm(children: any, index = '1', level = 0) {
    if (children && children.length > 0) {
      let counter = 1;
      return children.map((item: any) => {
        if (!item) return;
        const newLevel = level + 1;
        if (level === 0) {
          id = id + 1;
          const newItem = {
            ...item,
            mapKey: id,
            tagIndex: index,
            children: addTagIndexToCapexForm(item.children, index, newLevel),
          };
          // tslint:disable-next-line
          index = (parseInt(index, 10) + 1).toString();
          return newItem;
        } else {
          id = id + 1;
          // tslint:disable-next-line: prefer-template
          const newItem = {
            ...item,
            mapKey: id,
            tagIndex: index + '.' + counter.toString(),
            children: addTagIndexToCapexForm(item.children, index + '.' + counter.toString(), newLevel) || [],
          };
          counter = counter + 1;
          return newItem;
        }
      });
    }
  }
  return addTagIndexToCapexForm(capexData);
}

export function getEntriesAndSubtitlesFromCapexForm(item: ICapexTemplateEntry) {
  return item.children
    ? item.children.reduce(
        (
          result: { entries: ICapexTemplateEntry[]; subtitles: ICapexTemplateEntry[] },
          current: ICapexTemplateEntry
        ) => {
          if (!current) {
            return result;
          } else if (current?.priceDefinition) {
            result.entries.push(current);
          } else {
            result.subtitles.push(current);
          }
          return result;
        },
        { entries: [], subtitles: [] }
      )
    : { entries: [], subtitles: [] };
}
