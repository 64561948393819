import React from 'react';
import * as S from './read-only-roles-custom.styled';
import {
  useReadOnlyRolesConfig,
  ReadOnlyRolesConfigFeature,
} from 'pages/users-management/read-only-roles-tab/config/use-read-only-roles-config';
import { IRole } from 'types/role';
import { RatedPowerLoader } from '@ratedpower/components';
import ActionRow from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/action-row/action-row';
import { ReadOnlyRolesPermissionMap } from 'utils/hooks/use-get-external-roles-with-permissions';

interface IProps {
  feature: ReadOnlyRolesConfigFeature;
  readonlyRoles: IRole[];
  readOnlyRolesPermissionsMap: ReadOnlyRolesPermissionMap;
}

const ReadOnlyRolesCustom: React.FC<IProps> = ({ feature, readonlyRoles, readOnlyRolesPermissionsMap }: IProps) => {
  const readOnlyRolesConfig = useReadOnlyRolesConfig();
  const featureConfig = readOnlyRolesConfig[feature];

  return !readonlyRoles.length ? (
    <RatedPowerLoader />
  ) : (
    <S.ReadOnlyRolesCustomStyled>
      {Object.keys(featureConfig.actions).map((featureKey, index) => (
        <ActionRow
          featureTitle={featureConfig.title}
          withTitle={index === 0}
          key={featureKey}
          featureKey={featureKey}
          action={featureConfig.actions[featureKey]}
          readOnlyRolesPermissionsMap={readOnlyRolesPermissionsMap}
        />
      ))}
    </S.ReadOnlyRolesCustomStyled>
  );
};

export { ReadOnlyRolesCustom };
