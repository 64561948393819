import { useMutation } from '@apollo/client';
import { ISubscriptionPlanDTO, ISubscriptionPlanInputs } from 'types/subscription-plan';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useNotificationToast } from './use-notification-toast';
import { GET_SUPPORT_PLANS, UPDATE_SUBSCRIPTION_PLAN } from 'services/subscription-plan-gql';
import { GET_SUPPORT_COMPANY } from 'services/support-gql';
import { useRef } from 'react';

export interface IUseEditPlan {
  editPlanModal: IUseModal;
  handleEditPlan: (inputs: ISubscriptionPlanInputs, planId: string) => void;
  handleOpenEditModal: (plan: ISubscriptionPlanDTO) => void;
}

export const useEditPlan = (setInputs: (inputs: ISubscriptionPlanInputs) => void): IUseEditPlan => {
  const { errorNotification, successNotification } = useNotificationToast();
  const editPlanModal = useModal();
  const currentInputs = useRef<ISubscriptionPlanInputs | null>(null);

  const onError = (apolloError: any) => errorNotification(apolloError);

  const [updateSubscriptionPlan] = useMutation(UPDATE_SUBSCRIPTION_PLAN, {
    onCompleted: () => {
      editPlanModal.setModalOpen(false);
      successNotification('company:plan-updated-successfully');
    },
    onError,
    refetchQueries: [GET_SUPPORT_PLANS, GET_SUPPORT_COMPANY],
  });

  const handleEditPlan = (inputs: ISubscriptionPlanInputs, planId: string) => {
    currentInputs.current = inputs;
    updateSubscriptionPlan({
      variables: {
        subscriptionPlanUpdateDTO: {
          id: planId,
          startDate: inputs.startDate,
          plan: inputs.plan,
          projectsPurchased: Number(inputs.projectLimit.projectsPurchased) || 0,
          unlimitedProjects: inputs.projectLimit.unlimited,
          unlimitedUsers: inputs.userLimit.unlimited,
          userLimit: Number(inputs.userLimit.numberOfUsers) || 0,
          expirationDate: inputs.expirationDate,
          stockKeepingUnits: inputs.stockKeepingUnits,
        },
        sendWelcomeEmail: inputs.sendWelcomeEmail,
      },
    });
  };

  const handleOpenEditModal = (plan: ISubscriptionPlanDTO) => {
    setInputs({
      plan: plan.plan,
      startDate: plan.startDate,
      expirationDate: plan.expirationDate,
      isLegacy: plan.isLegacy,
      sendWelcomeEmail: false,
      stockKeepingUnits: plan.stockKeepingUnits || [],
      projectLimit: { projectsPurchased: plan.projectsPurchased, unlimited: plan.unlimitedProjects },
      userLimit: { numberOfUsers: plan.userLimit, unlimited: plan.unlimitedUsers },
    });
    editPlanModal.setData({ planId: plan.id });
    editPlanModal.setModalOpen(true);
  };

  return {
    editPlanModal,
    handleEditPlan,
    handleOpenEditModal,
  };
};
