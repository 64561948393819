import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import React, { ReactNode, useContext, useMemo } from 'react';
import { ITab } from '@ratedpower/components';
import { IUseRolesObject, IUseUsersObject } from 'types/users';
import { usersManagementTabs } from 'utils/constants/users';
import { useRoles } from './roles-tab/hooks/use-roles';
import { useUsers } from './users-tab/hooks/use-users';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface ContextState {
  tabs: ITab[];
  hasContractedTeams: boolean;
  hasAccessToTeamsTab: boolean;
  useUsersObject: IUseUsersObject;
  useRolesObject: IUseRolesObject;
}

export const Context = React.createContext<ContextState | null>(null);

export const useUsersManagementContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useUsersManagementContext must be used within a UsersManagementProvider tag');
  }
  return contextState;
};

export const UsersManagementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const useUsersObject: IUseUsersObject = useUsers();
  const { userRoleSummary } = useUsersObject;
  const useRolesObject: IUseRolesObject = useRoles(userRoleSummary);
  const {
    currentUser: { profile },
  } = useSelector((state: RootState) => state);

  const { skuTeamManagement: hasContractedTeams } = useFlags();
  const { isAuthorized, hasAtLeastOneValidAuthorization } = useAuthorization();
  const hasAccessToUsers = hasAtLeastOneValidAuthorization([
    Permission.USER_SUPPORT_READ,
    Permission.USER_READ_FROM_USER_COMPANY,
    Permission.USER_READ_FROM_USER_TEAM,
  ]);

  const canAccessReadOnlyRolesTab = ['Support limited', 'Product General', 'Admin'].includes(
    profile?.roleName as string
  );
  const hasAccessToRolesTab = isAuthorized(Permission.ROLE_SUPPORT_READ) || canAccessReadOnlyRolesTab;
  const hasAccessToTeamsTab =
    hasContractedTeams &&
    hasAtLeastOneValidAuthorization([
      Permission.TEAM_SUPPORT_READ,
      Permission.TEAM_READ_FROM_USER_COMPANY,
      Permission.TEAM_READ_FROM_USER_TEAM,
    ]);

  const checkTabAccess = (allTabs: ITab[]): ITab[] => {
    return allTabs.filter((tab) => {
      if (tab.id === usersManagementTabs[0].id) {
        return hasAccessToUsers && tab;
      }
      if (tab.id === usersManagementTabs[1].id) {
        return hasContractedTeams && tab;
      }
      if (hasAccessToRolesTab) {
        return tab;
      }
    });
  };

  const tabs = checkTabAccess(usersManagementTabs);

  const contextValue = useMemo(
    () => ({
      tabs,
      hasAccessToTeamsTab,
      hasContractedTeams,
      useUsersObject,
      useRolesObject,
    }),
    [tabs, hasAccessToTeamsTab, useUsersObject, useRolesObject]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
