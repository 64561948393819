import React from 'react';
import tunnel from 'tunnel-rat';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import * as S from './bottom-toolbar.styled';
import { Button, Tooltip } from '@ratedpower/components';
import {
  PointerSvg,
  HandSvg,
  PerpendicularSvg,
  ArrowCurvedRightSvg,
  ArrowCurvedLeftSvg,
  ArrowResetSvg,
  ArrowAxisSvg,
  TargetSvg,
} from 'assets/icons';
import {
  PrimaryActionTypes,
  useActionHistory,
  useBbox,
  useDesignInputs,
  useModes,
  useMovementType,
  usePointerType,
  useRedo,
  useSetMovementType,
  useSetPointerType,
  useUndo,
} from 'pages/interactive-layout-creator/ilc-store';
import { TypeStructure } from 'types/equipment.types';
import { useKeyboardShortcut } from 'utils/hooks/use-keyboard-shortcut';
import { useTranslation } from 'react-i18next';
import { centerAroundBbox } from '../../ilc-utils';

const gui = tunnel();

function BottomToolbarIn() {
  const bbox = useBbox();
  const { controls } = useThree();
  const action = useModes().mode;
  const { t } = useTranslation('ilc');

  const undo = useUndo();
  const redo = useRedo();

  const designInputs = useDesignInputs();

  const pointerType = usePointerType();
  const movementType = useMovementType();
  const setPointerType = useSetPointerType();
  const setMovementType = useSetMovementType();
  const actionHistory = useActionHistory();
  const disableUndo = actionHistory.past.length === 0;
  const disableRedo = actionHistory.future.length === 0;

  useKeyboardShortcut(['z'], undo, null, { ctrlKey: true });
  useKeyboardShortcut(['y'], redo, null, { ctrlKey: true });
  useKeyboardShortcut(['z'], redo, null, { ctrlKey: true, shiftKey: true });

  const rotateMapAccordingToAxis = () => {
    if (action.type === PrimaryActionTypes.SELECT_RECTANGLES) {
      const rectangles = action.payload;
      const structure = rectangles.find((element) => element.type === 'STRUCTURES');
      let objectiveAzimuth: number;
      if (structure) {
        const structureType = designInputs?.areaParametricInputs[structure.areaKey].structureType;
        if (structureType === TypeStructure.FIXED) {
          objectiveAzimuth = structure.angle + Math.PI / 2;
        } else {
          objectiveAzimuth = structure.angle;
        }
      } else {
        objectiveAzimuth = rectangles[0].angle;
      }

      const currentAzimuth = controls.azimuthAngle;
      const degrees90 = Math.PI / 2;
      const adjustedAzimuth = currentAzimuth + degrees90;

      const delta = objectiveAzimuth - adjustedAzimuth;
      if (delta === 0) return;
      controls.rotate(delta, 0, true);
    }
  };

  const resetRotation = () => {
    const defaultAzimuth = THREE.MathUtils.degToRad(0);

    const currentAzimuth = controls.azimuthAngle;

    const delta = defaultAzimuth - currentAzimuth;

    if (delta === 0) return;
    controls.rotate(delta, 0, true);
  };

  const handleCenterView = () => {
    if (controls && bbox) {
      centerAroundBbox(bbox, controls);
    }
  };

  return (
    <gui.In>
      <S.BottomToolbarWrapper>
        <S.BottomToolbarStyled id="layout-editor-bottom-toolbar">
          <Tooltip description={t('actions.select')} place="top">
            <Button
              variant="ghost"
              onClick={() => setPointerType('SELECT')}
              Icon={PointerSvg}
              size="l"
              selected={pointerType === 'SELECT'}
            />
          </Tooltip>
          <Tooltip description={t('actions.move')} place="top">
            <Button
              variant="ghost"
              onClick={() => setPointerType('MOVE')}
              Icon={HandSvg}
              size="l"
              selected={pointerType === 'MOVE'}
            />
          </Tooltip>
          <S.Divider />
          <Tooltip description={t('actions.orthogonal')} place="top">
            <Button
              variant="ghost"
              onClick={() => setMovementType(movementType === 'ORTHOGONAL' ? 'FREE' : 'ORTHOGONAL')}
              Icon={PerpendicularSvg}
              size="l"
              selected={movementType === 'ORTHOGONAL'}
            />
          </Tooltip>
          <S.Divider />
          <Tooltip description={t('actions.undo')} place="top">
            <Button variant="ghost" onClick={undo} Icon={ArrowCurvedLeftSvg} size="l" disabled={disableUndo} />
          </Tooltip>
          <Tooltip description={t('actions.redo')} place="top">
            <Button variant="ghost" onClick={redo} Icon={ArrowCurvedRightSvg} size="l" disabled={disableRedo} />
          </Tooltip>
          <S.Divider />
          <Tooltip description={t('actions.rotate-axis')} place="top">
            <Button
              variant="ghost"
              disabled={action.type !== PrimaryActionTypes.SELECT_RECTANGLES}
              onClick={() => rotateMapAccordingToAxis()}
              Icon={ArrowAxisSvg}
              size="l"
            />
          </Tooltip>
          <Tooltip description={t('actions.reset-rotation')} place="top">
            <Button variant="ghost" onClick={() => resetRotation()} Icon={ArrowResetSvg} size="l" />
          </Tooltip>
          <S.Divider />
          <Tooltip description={t('actions.center')} place="top">
            <Button variant="ghost" Icon={TargetSvg} onClick={handleCenterView} size="l" />
          </Tooltip>
        </S.BottomToolbarStyled>
      </S.BottomToolbarWrapper>
    </gui.In>
  );
}

export { BottomToolbarIn };

export function BottomToolbarOut() {
  return <gui.Out />;
}
