import React, { useState, useMemo } from 'react';
import * as S from './kpis-display.styled';
import { useKpis } from '../../ilc-store';
import { SectionHeader, Tabs, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { KpisData } from './kpis-data';
import { PowerStationsKpisDisplay } from './power-stations-kpis-display';
import { CameraControls } from '@react-three/drei';
import { sortAreas } from '../../ilc-utils';

interface IKpisDisplay {
  controls: typeof CameraControls | undefined;
}

export function KpisDisplay({ controls }: IKpisDisplay) {
  const { t } = useTranslation();
  const kpis = useKpis();
  const [selectedAreaTab, setSelectedAreaTab] = useState('all');

  const areaOptions = kpis
    ? Object.values(kpis.areaKpis).map((area) => {
        return {
          label: area.areaName,
          id: area.areaKey,
        };
      })
    : [];

  const kpisToDisplay = useMemo(() => {
    if (!kpis) return null;
    if (selectedAreaTab === 'all') return { main: kpis.powerKpis, powerStations: Object.values(kpis.powerStationKpis) };
    const filteredPowerStationKpis = Object.values(kpis.powerStationKpis).filter(
      (powerStationKPIData) => powerStationKPIData.areaKey === selectedAreaTab
    );
    return { main: kpis.areaKpis[selectedAreaTab].powerKpis, powerStations: filteredPowerStationKpis };
  }, [kpis, selectedAreaTab]);

  return (
    <S.KpisDisplayWrapper>
      <SectionHeader title={t('ilc:results.title')} />
      <S.KpisContent>
        <Tabs
          tabs={[{ label: t('common:all'), id: 'all' }, ...sortAreas(areaOptions)]}
          tabActive={selectedAreaTab}
          onClickTab={(tabId) => setSelectedAreaTab(tabId)}
          wrap
        />
        {kpisToDisplay && (
          <>
            <S.PowerDisplay>
              <Typography weight="bold">{t('ilc:results.ilc-power')}</Typography>
              <KpisData powerKpi={kpisToDisplay.main} />
            </S.PowerDisplay>
            <PowerStationsKpisDisplay powerStationsKpis={kpisToDisplay.powerStations} controls={controls} />
          </>
        )}
      </S.KpisContent>
    </S.KpisDisplayWrapper>
  );
}
