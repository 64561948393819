import React, { useRef, useMemo, useLayoutEffect } from 'react';
import * as THREE from 'three';
import {
  PrimaryActionTypes,
  SecondaryActionTypes,
  useDesignInputs,
  useModes,
  useOnSelectElement,
  useOnSelectMultipleRectanglesByIndexes,
  useSelectReferenceElementMode,
  useSelectReferenceElementForRoadsMode,
  useValidationErrors,
  usePointerType,
  useChangePsOfStructures,
  useResetMode,
  useAddStructuresMode,
} from '../ilc-store';
import InstancedMeshPrimitive from './primitives/instanced-mesh-primitive';
import { Selection } from './selection-box';
import { darkenHexColor } from '../ilc-utils';
import { PowerStationElement, StructureElement } from '../ilc-types';
import { useTheme } from 'styled-components';
import { elevation } from './config';
import { useToasts } from 'utils/hooks/use-toasts';
import { useTranslation } from 'react-i18next';

interface StructuresInstancedMeshProps {
  elements: { [x: string]: PowerStationElement | StructureElement };
}

const StructuresInstancedMesh: React.FC<StructuresInstancedMeshProps> = ({ elements }) => {
  const meshRef = useRef<any>(null);
  const onSelectElement = useOnSelectElement();
  const designInputs = useDesignInputs();
  const validationErrors = useValidationErrors();
  const pointerType = usePointerType();
  const changePsOfStructures = useChangePsOfStructures();
  const theme = useTheme();
  const { addErrorToast } = useToasts();
  const { t } = useTranslation('ilc');

  const modes = useModes();
  const secondaryAction = modes.secondaryMode;

  const dummyInstanceObject = useMemo(() => new THREE.Object3D(), []);
  const onSelectMultipleRectanglesByIndexes = useOnSelectMultipleRectanglesByIndexes();

  const modeState = useModes();
  const selectReferenceMode = useSelectReferenceElementMode();
  const selectReferenceForRoadsMode = useSelectReferenceElementForRoadsMode();
  const action = useModes().mode;
  const resetActions = useResetMode();
  const addStructuresMode = useAddStructuresMode();

  const isSelectingPowerStationsToAssignStructure =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES &&
    secondaryAction.type === SecondaryActionTypes.SELECT_PS_FOR_STRUCTURES;

  const onSelectNewPowerStationForStructure = (psId: string) => {
    if (isSelectingPowerStationsToAssignStructure) {
      changePsOfStructures(
        action.payload.map((element) => element.key),
        psId
      );
      resetActions();
    }
  };

  const isSelectingPowerStationIdToAddStructure =
    action.type === PrimaryActionTypes.ADD_STRUCTURES && !action.payload.selectedPs;

  const onSelectPowerStationToAddNewStructures = (psId: string) => {
    addStructuresMode({ selectedPs: psId });
  };

  const onSelectStructureAsReferenceToAddStructure = (structureId: string, powerStationId: string) => {
    const powerStation = elements[powerStationId];
    const structure = elements[structureId];
    if (structure?.areaKey !== powerStation?.areaKey) {
      addErrorToast(t('errors.cannot-assign-to-ps-different-area'), 'top-center');
      return;
    }
    addStructuresMode({
      referenceStructureId: structureId,
    });
  };

  const keys = Object.keys(elements);

  const defineStructureScale = (element: PowerStationElement | StructureElement) => {
    dummyInstanceObject.position.set(element.centroid.x, 0, -element.centroid.y);
    if (element.type === 'STRUCTURES') {
      let tableWidth = element.width;
      const elementAreaParametricInputs = designInputs?.areaParametricInputs[element.areaKey];
      if (elementAreaParametricInputs?.eastWest) {
        tableWidth = elementAreaParametricInputs.distanceBetweenTables + element.width * 2;
      }
      dummyInstanceObject.scale.set(element.length, tableWidth, 1);
    }
    if (element.type === 'POWER_STATIONS') {
      dummyInstanceObject.scale.set(element.length, element.width, 1);
    }
  };

  useLayoutEffect(() => {
    if (!meshRef.current) return;
    for (let i = 0; i < keys.length; i++) {
      const element = elements[keys[i]];
      const elementErrors = validationErrors[keys[i]];
      const elementHasWarning = elementErrors?.every((error) => error.result === 'WARNING');
      const elementHasError = elementErrors?.some((error) => error.result === 'BLOCKER');
      const elementIsSelected =
        action.type === PrimaryActionTypes.SELECT_RECTANGLES &&
        action.payload.find((element) => element.id === keys[i]);
      if (elementIsSelected) {
        dummyInstanceObject.scale.set(0, 0, 0);
      } else {
        defineStructureScale(element);
      }
      dummyInstanceObject.rotation.set(-Math.PI / 2, 0, element.angle);
      dummyInstanceObject.updateMatrix();
      let color = new THREE.Color(element.color);
      const isReferenceStructure =
        secondaryAction.type === SecondaryActionTypes.SELECT_REFERENCE &&
        secondaryAction.payload.element?.id === keys[i];
      const isReferencePs =
        action.type === PrimaryActionTypes.ADD_STRUCTURES &&
        action.payload?.selectedPs &&
        action.payload?.selectedPs === keys[i];
      const shouldDarkenColor = isReferenceStructure || isReferencePs;
      if (shouldDarkenColor) {
        color = new THREE.Color(darkenHexColor(element.color, 0.7));
      }
      if (elementHasWarning) {
        color = new THREE.Color(theme.v2.warning.icon.default);
      }
      if (elementHasError) {
        color = new THREE.Color(theme.v2.error.icon.default);
      }
      meshRef.current.setColorAt(i, color);
      meshRef.current.setMatrixAt(i, dummyInstanceObject.matrix);
    }
    meshRef.current.instanceMatrix.needsUpdate = true;
    meshRef.current.instanceColor.needsUpdate = true;
  }, [dummyInstanceObject, elements, action, secondaryAction, validationErrors]);

  const handleClick = (event: { instanceId: null | string }) => {
    // Early exit conditions
    if (!meshRef.current || event.instanceId === null || pointerType === 'MOVE') {
      return;
    }

    const selectedElementId = keys[event.instanceId];

    // Extract specialized selection logic into separate handlers
    if (handleSpecializedSelections(selectedElementId)) {
      return;
    }

    // Handle primary action selection
    handlePrimaryActionSelection(selectedElementId);
  };

  const handleSpecializedSelections = (selectedElementId: string): boolean => {
    if (isSelectingPowerStationIdToAddStructure) {
      const element = elements[selectedElementId];
      const psKey = element.type === 'POWER_STATIONS' ? element.key : element.psKey;
      onSelectPowerStationToAddNewStructures(psKey);
      return true;
    }

    if (
      action.type === PrimaryActionTypes.ADD_STRUCTURES &&
      action.payload.selectedPs &&
      !action.payload.referenceStructureId
    ) {
      onSelectStructureAsReferenceToAddStructure(selectedElementId, action.payload.selectedPs);
      return true;
    }

    if (isSelectingPowerStationsToAssignStructure) {
      const element = elements[selectedElementId];
      const areasAreEqual = action.payload.every((selectedElement) => selectedElement.areaKey === element.areaKey);
      if (!areasAreEqual) {
        addErrorToast(t('errors.cannot-assign-to-ps-different-area'), 'top-center');
        return true;
      }
      const psKey = element.type === 'POWER_STATIONS' ? element.key : element.psKey;
      onSelectNewPowerStationForStructure(psKey);
      return true;
    }

    return false;
  };

  const handlePrimaryActionSelection = (selectedElementId: string) => {
    if (action.type === PrimaryActionTypes.SELECT_RECTANGLES) {
      handleRectangleSelection(selectedElementId);
      return;
    }

    if (modeState.mode.type === PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS && !modeState.mode.payload) {
      selectReferenceForRoadsMode(selectedElementId);
      return;
    }

    if (modeState.mode.type === PrimaryActionTypes.NONE) {
      onSelectElement(selectedElementId);
    }
  };

  const handleRectangleSelection = (selectedElementId: string) => {
    if (modeState.secondaryMode.type === SecondaryActionTypes.SELECT_REFERENCE) {
      if (modeState.secondaryMode.payload.element) return;
      selectReferenceMode(selectedElementId, null);
      return;
    }

    onSelectElement(selectedElementId);
  };

  return (
    <>
      <Selection
        style={{
          border: '1px dashed #55aaff',
          backgroundColor: 'rgba(75, 160, 255, 0.3)',
          position: 'fixed',
        }}
        onSelectionChanged={(objs) => {
          if (!objs) return;
          const values = Object.values(objs);
          if (values && values.length > 0) {
            onSelectMultipleRectanglesByIndexes(values[values.length - 1]);
          }
        }}
      />
      <InstancedMeshPrimitive
        onPointerMove={() => {
          return;
        }}
        onClick={handleClick}
        ref={meshRef}
        position={[0, elevation.element, 0]}
        name="Structures"
        rotation={[0, 0, 0]}
        args={[undefined, undefined, keys.length]}
      >
        <planeGeometry />
        <meshBasicMaterial />
      </InstancedMeshPrimitive>
    </>
  );
};

export default StructuresInstancedMesh;
