import { useFlags } from 'launchdarkly-react-client-sdk';
import { getDesignIsILC, HasTagInfo } from 'utils/design-tags';
import { SimulationPrimaryCharacteristicsTag } from 'types/design';

export const useIlcSku = () => {
  const flags = useFlags();

  const restrictIlcSku = (item: HasTagInfo) => {
    return getDesignIsILC(item.secondaryTag) && !flags?.skuIlc;
  };

  const showIlcButtonByPrimaryTag = (item: HasTagInfo) => {
    switch (item.primaryTag) {
      case SimulationPrimaryCharacteristicsTag.PV:
      case SimulationPrimaryCharacteristicsTag.PV_BATCH: {
        return flags?.skuIlc;
      }
      case SimulationPrimaryCharacteristicsTag.PV_BESS:
      case SimulationPrimaryCharacteristicsTag.PV_BESS_BATCH: {
        return flags.skuHybridBess && flags?.skuIlc;
      }
      case SimulationPrimaryCharacteristicsTag.BESS: {
        return flags?.skuIlc;
      }
    }
  };

  return {
    restrictIlcSku,
    showIlcButtonByPrimaryTag
  };
};
