import { useMutation } from '@apollo/client';
import { IndexIds } from 'index.ids';
import { useTranslation } from 'react-i18next';
import { GET_USER_ROLE_SUMMARY } from 'services/roles-gql';
import { GET_TEAM_MANAGER_TEAMS_MEMBERS } from 'services/team-gql';
import { CREATE_USER_BY_SUPERUSER, GET_USERS } from 'services/user-gql';
import { errorMessages } from 'utils/constants/error-messages';
import { useToasts } from 'utils/hooks/use-toasts';

export const useCreateUserBySuperUser = () => {
  const { t } = useTranslation();
  const { addErrorToast, addSuccessToast } = useToasts();

  const [createUser, { loading: loadingCreate }] = useMutation(CREATE_USER_BY_SUPERUSER, {
    onCompleted: () => {
      addSuccessToast(t('user:user-updated-successfully'), 'bottom-center', IndexIds.Users.SuccessToast);
    },
    refetchQueries: [GET_USERS, GET_TEAM_MANAGER_TEAMS_MEMBERS, GET_USER_ROLE_SUMMARY],
    onError: (graphqlError) => {
      const debugInfo: any = graphqlError?.graphQLErrors[0]?.extensions?.debugInfo;
      const errorMessage: string | null = debugInfo?.messageError ?? null;
      const invalidOrGenericError = t(
        errorMessage && errorMessage === errorMessages.invalidEmail ? 'user:error-duplicated-email' : 'errors:GENERIC'
      );

      addErrorToast(
        errorMessage && errorMessage === errorMessages.noUsersAvailable
          ? t(`company:subscription-plans.${errorMessages.noUsersAvailable}`)
          : invalidOrGenericError
      );
    },
  });

  return {
    createUser,
    loadingCreate,
  };
};
