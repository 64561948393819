import { ICurrentSubscriptionPlan } from './subscription-plan';
import { IUserMinInfoDTO } from './users';

export interface ICompanyInfo {
  id: string;
  name: string;
  email: string;
  logo: string;
}

export interface ICountryTranslated {
  id: number;
  countryCode: string;
  translation: string;
}

export interface ICompanyLogoDTO {
  highResUrl: string;
  lowResUrl: string;
}

export enum CompanyWarningMessage {
  NO_WARNING = 'NO_WARNING',
  OVERDUE_PAYMENT = 'OVERDUE_PAYMENT',
}

export interface ICompanyDTO {
  id: string;
  name: string;
  email: string;
  country: ICountryTranslated;
  currentPlan: ICurrentSubscriptionPlan;
  legalId?: string;
  logo?: ICompanyLogoDTO;
  supportedBy?: IUserMinInfoDTO;
  businessManager?: IUserMinInfoDTO;
  publishedDate?: string;
  suppAlert?: number;
  warnings?: number;
  errors?: number;
  companyWarningMessage?: CompanyWarningMessage;
  salesforceId?: string;
  externalProviderName?: string;
  haveFederatedLogin?: boolean;
  maxSiteToProjectDistance: number;
  showLogoInDocuments?: boolean;
  stockKeepingUnits?: string[];
  organizationId?: string;
}

export type SecretFromAvailable = {
  secret: string;
  publicName: string;
};

export type SecretFromSaved = {
  secret: string;
  mode: 'MANUAL' | 'AUTOMATIC';
  status: 'ACTIVATED' | 'DISABLED';
};

export type Secret = SecretFromAvailable & SecretFromSaved;
