import React from 'react';
import * as S from './comparison-design-header.styled';
import { useTranslation } from 'react-i18next';
import { SVGWrapper, Typography } from '@ratedpower/components';
import { CrossSvg, FavouriteOutlineSvg, FavouriteSvg } from 'assets/icons';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { useTheme } from 'styled-components';
import { getDateYYYYMMDD } from 'utils/date';
import { IDesign, SimulationSecondaryCharacteristicsTag } from 'types/design';
import { Link } from 'react-router-dom';
import { useDesignComparisonData } from 'pages/design-comparison/design-comparison-state';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DesignTag } from 'components/design-components/design-tag/design-tag';
import {
  getDesignIsILC,
  getPrimaryTagText,
  getSecondaryTagText,
  selectedPrimaryTagColor,
  selectedSecondaryTagColor,
} from 'utils/design-tags';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';

interface IProps {
  design: IDesign;
  isLayoutShown: boolean;
  onFavorite: () => void;
  onRemove: () => void;
}

const ComparisonDesignHeader: React.FC<IProps> = ({ design, isLayoutShown, onFavorite, onRemove }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    project: { id: projectId },
  } = useDesignComparisonData();
  const { canEditDesign } = useCanEditDesigns();
  const canEditThisDesign = canEditDesign(design.createdBy.id, design.projectTeamId);
  const flags = useFlags();
  const { restrictHybridBessSku } = useHybridBessSku();

  const isDisabledColor = (getDesignIsILC(design.secondaryTag) && !flags?.skuIlc) || restrictHybridBessSku(design);

  const tagVariant: string = selectedPrimaryTagColor(design, isDisabledColor);
  const tagText: string = t(getPrimaryTagText(design));

  const handleFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onFavorite();
  };

  const handleRemove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onRemove();
  };

  return (
    <Link to={`/projects/${projectId}/design-process/${design.id}`}>
      <S.ComparisonDesignHeaderContainer>
        <S.ImageContainer>
          {isLayoutShown && (
            <>
              <DesignTag color={tagVariant} position={{ top: 8, right: 8 }}>
                {tagText}
              </DesignTag>
              {design.secondaryTag === SimulationSecondaryCharacteristicsTag.EDITED && (
                <DesignTag color={selectedSecondaryTagColor(design, !flags?.skuIlc)} position={{ top: 38, right: 8 }}>
                  {t(getSecondaryTagText(design))}
                </DesignTag>
              )}
              <RemoteImage url={design.satellitePicture.thumbnail} alt={design.name} height={120} width={278} />
            </>
          )}
        </S.ImageContainer>
        <S.DesignInfo>
          <S.DesignInfoTop>
            <Typography weight="bold" nowrap>
              {design.number}. {design.name}
            </Typography>
            <div className="buttons">
              <SVGWrapper
                size="s"
                icon={design.isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
                onClick={handleFavorite}
                disabled={!canEditThisDesign}
                color={theme.v2.icon.primary.default}
              />
              <SVGWrapper size="s" icon={CrossSvg} onClick={handleRemove} color={theme.v2.icon.primary.default} />
            </div>
          </S.DesignInfoTop>
          <S.DesignInfoBottom>
            <S.BottomData>
              <Typography
                size="s"
                color={theme.v2.text.secondary.default}
              >{`${design.createdBy.name} ${design.createdBy.lastname}`}</Typography>
              <Typography as="span" size="s" color={theme.v2.text.secondary.default}>
                {getDateYYYYMMDD(new Date(design.publishedDate))}
              </Typography>
            </S.BottomData>
          </S.DesignInfoBottom>
        </S.DesignInfo>
      </S.ComparisonDesignHeaderContainer>
    </Link>
  );
};

export default ComparisonDesignHeader;
