import styled from 'styled-components';

export const NoUsersContainer = styled.div`
  height: calc(100% - 120px);
  width: 100%;
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.v2.spacing.m};
  gap: ${({ theme }) => theme.v2.spacing.xl};

  span {
    width: 300px;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  user-select: text;
  overflow-y: scroll;
`;

export const TopContainer = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.l};
  margin-bottom: ${({ theme }) => theme.v2.spacing.l};
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.v2.spacing.l};
  height: calc(100% - 185px);
`;

export const RoleContainer = styled.div`
  position: relative;
`;

export const RoleSwithContainer = styled.div`
  padding-left: ${({ theme }) => theme.v2.spacing.l};
  margin-bottom: ${({ theme }) => theme.v2.spacing.l};
`;
