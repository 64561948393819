import React from 'react';
import { Nodes, NodeThreeJS } from './nodes-without-refs';
import {
  PrimaryActionTypes,
  useDeleteNodeAndConnectingEdges,
  useDeleteNodeAndEdges,
  useModes,
  useUpdateNodeCoordinate,
} from '../ilc-store';
import { Edge, Point, RoadsLayer } from '../ilc-types';

interface GraphLinesProps {
  nodesGraph: RoadsLayer;
  isDeletingNode: boolean;
  isSelectingInitialNode: boolean;
  isAddingEdgeBetweenNodes: boolean;
}

interface NodesRendererProps {
  nodes: {
    [nodeId: string]: Point;
  };
  outgoingEdges: Edge[];
  roadWidth: number;
  color: string;
  roadId: string;
  areaId: string;
  isDeletingNode: boolean;
  isSelectingInitialNode: boolean;
  isAddingEdgeBetweenNodes: boolean;
}

const NodesRenderer: React.FC<NodesRendererProps> = ({
  nodes,
  color,
  outgoingEdges,
  isDeletingNode,
  roadId,
  isSelectingInitialNode,
  isAddingEdgeBetweenNodes,
  roadWidth,
  areaId,
}) => {
  const nodeKeys = Object.keys(nodes);

  const updateNodeCoordinate = useUpdateNodeCoordinate();

  const deleteNodeAndEdges = useDeleteNodeAndEdges();
  const deleteNodeAndConnectingEdges = useDeleteNodeAndConnectingEdges();

  const updateWithNodeId = (nodeId: string, coordinate: Point) => {
    updateNodeCoordinate(roadId, nodeId, coordinate);
  };

  const turnUnidirectionalEdgesGraphInBidirectional = (outgoingEdges: Edge[]): Edge[] => {
    const bidirectionalEdges = outgoingEdges.map((edge) => {
      const reversedEdge: Edge = { source: edge.target, target: edge.source, type: edge.type };
      return [edge, reversedEdge];
    });

    return bidirectionalEdges.flat();
  };

  const getConnectedToBidirectional = (nodeKey: string): Edge[] => {
    return turnUnidirectionalEdgesGraphInBidirectional(outgoingEdges).filter((edge) => edge.source === nodeKey);
  };

  const getConnectedToEdges = (nodeKey: string): Edge[] => {
    return outgoingEdges.filter((edge) => edge.source === nodeKey);
  };

  const action = useModes();

  return (
    <Nodes roadId={roadId}>
      {nodeKeys.map((nodeKey) => (
        <NodeThreeJS
          key={nodeKey}
          userId={nodeKey}
          name={nodeKey}
          nodesGraph={nodes}
          roadId={roadId}
          areaId={areaId}
          color={color}
          position={[nodes[nodeKey].x, 5, -nodes[nodeKey].y]}
          connectedToBidirectional={getConnectedToBidirectional(nodeKey)}
          connectedToEdges={getConnectedToEdges(nodeKey)}
          onUpdate={updateWithNodeId}
          isSelectingInitialNode={isSelectingInitialNode}
          onDeleteNodeAndEdges={
            action.mode.type === PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES
              ? deleteNodeAndConnectingEdges
              : deleteNodeAndEdges
          }
          roadWidth={roadWidth}
          isDeletingNode={isDeletingNode}
          isAddingEdgeBetweenNodes={isAddingEdgeBetweenNodes}
        />
      ))}
    </Nodes>
  );
};

const GraphLines: React.FC<GraphLinesProps> = ({
  nodesGraph,
  isDeletingNode,
  isSelectingInitialNode,
  isAddingEdgeBetweenNodes,
}) => {
  const graphs = Object.keys(nodesGraph);
  return (
    <>
      {graphs.map((graphKey) => (
        <NodesRenderer
          key={graphKey}
          roadId={graphKey}
          areaId={nodesGraph[graphKey].areaKey}
          nodes={nodesGraph[graphKey].nodes}
          outgoingEdges={nodesGraph[graphKey].edges}
          color={nodesGraph[graphKey].color}
          roadWidth={nodesGraph[graphKey].roadWidth}
          isDeletingNode={isDeletingNode}
          isSelectingInitialNode={isSelectingInitialNode}
          isAddingEdgeBetweenNodes={isAddingEdgeBetweenNodes}
        />
      ))}
    </>
  );
};

export default GraphLines;
