import { TFunction } from 'i18next';
import React from 'react';
import { InterconnectionOutputData, ResultOutputs } from 'types/design-process';
import { ResultOutputs as ResultOutputsType } from 'types/bess-design-new';
import { IAttribute, renderCostAttribute, renderTotalCostAttribute } from './design-attributes';
import { CalculationResult } from 'pages/design-process/outputs/financial/types';

const DataDisplay = (value: any, unit: string, suffix = '') => {
  return (
    <>
      {value}
      <span className="unit">{` ${unit}${suffix}`}</span>
    </>
  );
};

const DataDisplayOrEmpty = (value: any, unit: string, suffix = '') => {
  const displayValue = !!value && !isNaN(value) ? value : '-';

  return (
    <>
      {displayValue}
      <span className="unit">{` ${unit}${suffix}`}</span>
    </>
  );
};

export interface IDesignResultAttributes {
  mainValues: {
    ratedPower: IAttribute;
    peakPower: IAttribute;
    ratioDcAc: IAttribute;
    specificProduction: IAttribute;
    energyYield: IAttribute;
    plantPR: IAttribute;
    totalPrice: IAttribute;
    specificPrice: IAttribute;
    lcoe: IAttribute;
    npv: IAttribute;
    irr: IAttribute;
    roi: IAttribute;
    payback: IAttribute;
    discountedPayback: IAttribute;
  };
  site: {
    areaAvailable: IAttribute;
    areaInstalled: IAttribute;
    gcr: IAttribute;
  };
  layout: {
    unusedPeakPower: IAttribute;
    maxPeakPower: IAttribute;
  };
  batteries: {
    batteryInstalledCapacity: IAttribute;
    batteryInstalledEnergy: IAttribute;
    batterySupplyDuration: IAttribute;
  };
}

export function designResultAttributes(t: TFunction): IDesignResultAttributes {
  return {
    mainValues: {
      ratedPower: {
        key: 'ratedPower',
        label: 'ratedPower',
        converter: {
          name: 'dimension',
          options: {
            decimals: 3,
          },
        },
        render: (data) => DataDisplay(data.value, data.unit, 'Wac'),
      },
      peakPower: {
        key: 'peakPower',
        label: 'peakPower',
        converter: {
          name: 'dimension',
          options: {
            decimals: 3,
          },
        },
        render: (data) => DataDisplay(data.value, data.unit, 'Wdc'),
      },
      ratioDcAc: {
        key: 'ratioDcAc',
        label: 'ratioDcAc',
        render: (data) => (data ? `${data.toFixed(3)}` : data),
      },
      specificProduction: {
        key: 'specificProduction',
        label: 'specificProduction',
        converter: {
          name: 'dimension',
          options: {
            dimension: 'default',
            decimals: 1,
          },
        },
        render: (data) => DataDisplayOrEmpty(data.value, 'kWh/kWp'),
      },
      energyYield: {
        key: 'energyYield',
        label: 'energyYield',
        converter: {
          name: 'dimension',
          options: {
            decimals: 3,
          },
        },
        render: (data) => DataDisplayOrEmpty(data.value, data.unit, 'Wh'),
      },
      plantPR: {
        key: 'plantPR',
        label: 'plantPR',
        converter: {
          name: 'percentage',
        },
        render: (data) => DataDisplayOrEmpty(data.value, data.unit),
      },
      totalPrice: {
        key: 'totalPrice',
        label: 'totalPrice',
        render: renderCostAttribute,
      },
      specificPrice: {
        key: 'specificPrice',
        label: 'specificPrice',
        render: (data, currency) => renderCostAttribute(data, currency, 'kWp'),
      },
      lcoe: {
        key: ['lcoe', 'lcoeCalculationResult'],
        label: 'lcoe',
        render: ([lcoe, lcoeCalculationResult], currency) =>
          !isNaN(lcoe) && lcoeCalculationResult === CalculationResult.CORRECT
            ? renderCostAttribute(lcoe, currency, 'MWh')
            : '-',
      },
      npv: {
        key: 'npv',
        label: 'npv',
        render: renderTotalCostAttribute,
      },
      irr: {
        key: 'irr',
        label: 'irr',
        converter: {
          name: 'percentage',
          options: {
            decimals: 2,
          },
        },
        render: (data) => DataDisplayOrEmpty(data.value, data.unit),
      },
      roi: {
        key: 'roi',
        label: 'roi',
        converter: {
          name: 'percentage',
          options: {
            decimals: 2,
          },
        },
        render: (data) => DataDisplayOrEmpty(data.value, data.unit),
      },
      payback: {
        key: 'payback',
        label: 'payback',
        converter: {
          name: 'decimal',
          options: {
            decimals: 2,
          },
        },
        render: (data) => <>{!isNaN(data.value) ? data.value : '-'}</>,
      },
      discountedPayback: {
        key: 'discountedPayback',
        label: 'discountedPayback',
        converter: {
          name: 'decimal',
          options: {
            decimals: 2,
          },
        },
        render: (data) => <>{!isNaN(data.value) ? data.value : '-'}</>,
      },
    },
    site: {
      areaAvailable: {
        key: 'areaAvailable',
        label: 'areaAvailable',
        converter: {
          name: 'surface',
        },
        render: (data) => DataDisplay(data.value, data.unit),
      },
      areaInstalled: {
        key: 'areaInstalled',
        label: 'areaInstalled',
        converter: {
          name: 'surface',
        },
        render: (data) => DataDisplay(data.value, data.unit),
      },
      gcr: {
        key: 'gcr',
        label: 'gcr',
        converter: {
          name: 'percentage',
        },
        render: (data) => DataDisplay(data.value, data.unit),
      },
    },
    layout: {
      unusedPeakPower: {
        key: 'peakPowerRemaining',
        label: 'unusedPeakPower-total',
        converter: {
          name: 'dimension',
          options: {
            decimals: 3,
          },
        },
        render: (data) => DataDisplay(data.value, data.unit, 'Wdc'),
      },
      maxPeakPower: {
        key: 'peakPowerMax',
        label: 'maximumPeakPower-total',
        converter: {
          name: 'dimension',
          options: {
            decimals: 3,
          },
        },
        render: (data) => DataDisplay(data.value, data.unit, 'Wdc'),
      },
    },
    batteries: {
      batteryInstalledCapacity: {
        key: 'batteryInstalledCapacity',
        label: 'battery-installed-capacity',
        converter: {
          name: 'dimension',
          options: {
            decimals: 3,
          },
        },
        render: (data) => DataDisplay(data.value, data.unit, 'Wac'),
      },
      batteryInstalledEnergy: {
        key: 'batteryInstalledEnergy',
        label: 'bess-capacity',
        converter: {
          name: 'dimension',
          options: {
            decimals: 3,
          },
        },
        render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
      },
      batterySupplyDuration: {
        key: 'batterySupplyDuration',
        label: 'battery-supply-duration',
        converter: {
          name: 'decimal',
          options: {
            decimals: 1,
          },
        },
        render: (data) => DataDisplay(data.value, t('common:hours')),
      },
    },
  };
}

export function outputAttributes(t: TFunction): OutputAttributes {
  return {
    ratedPower: {
      key: 'ratedPower',
      label: 'ratedPower',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wac'),
    },
    activePowerAtPoi: {
      key: 'activePowerAtPoi',
      label: 'activePowerAtPoi',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wac'),
    },
    dcAcRatioAtPoi: {
      key: 'dcAcRatioAtPoi',
      label: 'dcAcRatioAtPoi',
      render: (data) => (data ? `${data.toFixed(3)}` : data),
    },
    peakPower: {
      key: 'peakPower',
      label: 'peakPower',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wdc'),
    },
    ratioDcAc: {
      key: 'ratioDcAc',
      label: 'ratioDcAc',
      render: (data) => (data ? `${data.toFixed(3)}` : data),
    },
    totalUnusedPeakPower: {
      key: 'totalUnusedPeakPower',
      label: 'unusedPeakPower-total',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wdc'),
    },
    maximumPeakPower: {
      key: 'maximumPeakPower',
      label: 'maximumPeakPower-total',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wdc'),
    },
    specificProduction: {
      key: 'specificProduction',
      label: 'specificProduction',
      converter: {
        name: 'dimension',
        options: {
          dimension: 'default',
          decimals: 1,
        },
      },
      render: (data) => DataDisplayOrEmpty(data.value, 'kWh/kWp'),
    },
    yearZeroEnergyToGridAfterAvailability: {
      key: 'yearZeroEnergyToGridAfterAvailability',
      label: 'yearZeroEnergyToGridAfterAvailability',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplayOrEmpty(data.value, data.unit, 'Wh'),
    },
    performanceRatio: {
      key: 'performanceRatio',
      label: 'performanceRatio',
      converter: {
        name: 'percentage',
      },
      render: (data) => DataDisplayOrEmpty(data.value, data.unit),
    },
    totalPrice: {
      key: 'totalPrice',
      label: 'totalPrice',
      render: renderTotalCostAttribute,
    },
    specificPrice: {
      key: 'specificPrice',
      label: 'specificPrice',
      render: (data, currency) => renderCostAttribute(data, currency, 'kWp'),
    },
    lcoe: {
      key: ['lcoe', 'lcoeCalculationResult'],
      label: 'lcoe',
      render: ([lcoe, lcoeCalculationResult], currency) =>
        !isNaN(lcoe) && lcoeCalculationResult === CalculationResult.CORRECT
          ? renderCostAttribute(lcoe, currency, 'MWh')
          : '-',
    },
    npv: {
      key: 'npv',
      label: 'npv',
      render: renderTotalCostAttribute,
    },
    irr: {
      key: ['irr', 'irrCalculationResult'],
      label: 'irr',
      render: ([irr, irrCalculationResult]) => (
        <>
          {!isNaN(irr) && irrCalculationResult === CalculationResult.CORRECT ? (
            <>
              {(irr * 100).toFixed(2)}
              <span className="unit">{' %'}</span>
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    roi: {
      key: ['roi', 'roiCalculationResult'],
      label: 'roi',
      render: ([roi, roiCalculationResult]) => (
        <>
          {!isNaN(roi) && roiCalculationResult === CalculationResult.CORRECT ? (
            <>
              {(roi * 100).toFixed(2)}
              <span className="unit">{' %'}</span>
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    payback: {
      key: ['payback', 'paybackCalculationResult'],
      label: 'payback',
      render: ([payback, paybackCalculationResult]) => (
        <>{!isNaN(payback) && paybackCalculationResult === CalculationResult.CORRECT ? payback.toFixed(2) : '-'}</>
      ),
    },
    discountedPayback: {
      key: ['discountedPayback', 'discountedPaybackCalculationResult'],
      label: 'discountedPayback',
      render: ([discountedPayback, discountedPaybackCalculationResult]) => (
        <>
          {!isNaN(discountedPayback) && discountedPaybackCalculationResult === CalculationResult.CORRECT
            ? discountedPayback.toFixed(2)
            : '-'}
        </>
      ),
    },
    activePowerAc: {
      key: 'activePowerAc',
      label: 'activePowerAc',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'W'),
    },
    energyCapacity: {
      key: 'energyCapacity',
      label: 'energyCapacity',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
    },
    operationHours: {
      key: 'operationHours',
      label: 'operationHours',
      converter: {
        name: 'decimal',
        options: {
          decimals: 1,
        },
      },
      render: (data) => DataDisplay(data.value, t('common:hours')),
    },
    activePowerDc: {
      key: 'activePowerDc',
      label: 'activePowerAc',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'W'),
    },
    energyCapacityDc: {
      key: 'energyCapacityDc',
      label: 'energyCapacity',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
    },
    operationHoursDc: {
      key: 'operationHoursDc',
      label: 'operationHours',
      converter: {
        name: 'decimal',
        options: {
          decimals: 1,
        },
      },
      render: (data) => DataDisplay(data.value, t('common:hours')),
    },
    substationArrangement: {
      key: 'substationArrangement',
      label: 'substationArrangement',
      render: (data) => {
        switch (data) {
          case 'SWITCHING_BREAKING':
            return t('design:switchingAndBreaking');
          case 'LINE_TO_TRANSFORMER':
          case 'POOLING_LINE_TO_TRANSFORMER':
            return t('design:lineToTransformer');
          case 'SINGLE_BUSBAR':
          case 'POOLING_SINGLE_BUSBAR':
            return t('design:singleBusbar');
          case 'DOUBLE_BUSBAR':
          case 'POOLING_DOUBLE_BUSBAR':
            return t('design:doubleBusbar');
          default:
            return data;
        }
      },
    },
    numberTransformers: {
      key: 'numberTransformers',
      label: 'numberTransformers',
      render: (data) => data,
    },
    transformerType: {
      key: 'transformerType',
      label: 'transformerType',
      render: (data) => {
        switch (data) {
          case 'THREE_WINDING':
            return t('design:threeWinding');
          case 'TWO_WINDING':
            return t('design:twoWinding');
          default:
            return data;
        }
      },
    },
    biggestTransformerRatedPower: {
      key: 'biggestTransformerRatedPower',
      label: 'biggestTransformerRatedPower',
      converter: {
        name: 'dimension',
        options: {
          decimals: 2,
          size: 'L',
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'W'),
    },
    breakingStationCapacity: {
      key: 'breakingStationCapacity',
      label: 'breakingStationCapacity',
      converter: {
        name: 'dimension',
        options: {
          decimals: 2,
          size: 'L',
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'W'),
    },
    numberFeeders: {
      key: 'numberFeeders',
      label: 'numberFeeders',
      render: (data) => data,
    },
    mvCableCrossSection: {
      key: 'mvCableCrossSection',
      label: 'mvCableCrossSection',
      converter: {
        name: 'area',
        options: {
          decimals: 3,
          size: 'XS',
        },
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    lineCapacity: {
      key: 'lineCapacity',
      label: 'lineCapacity',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'VA'),
    },
    lineLength: {
      key: 'lineLength',
      label: 'lineLength',
      converter: {
        name: 'length',
        options: {
          size: 'L',
          decimals: 2,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    numTowers: {
      key: 'numTowers',
      label: 'numTowers',
      render: (data) => data,
    },
    voltageDrop: {
      key: 'voltageDrop',
      label: 'voltageDrop',
      converter: {
        name: 'decimal',
        options: {
          decimals: 3,
        },
      },
      render: (data) => (
        <>
          {data.value}
          <span className="unit">{'%'}</span>
        </>
      ),
    },
    losses: {
      key: 'losses',
      label: 'losses',
      converter: {
        name: 'dimension',
        options: {
          decimals: 2,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'W'),
    },
    totalDischargedEnergy: {
      key: 'totalDischargedEnergy',
      label: 'totalDischargedEnergy',
      converter: {
        name: 'dimension',
        options: {
          decimals: 2,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
    },
    dischargedEnergyToGrid: {
      key: 'dischargedEnergyToGrid',
      label: 'dischargedEnergyToGrid',
      converter: {
        name: 'dimension',
        options: {
          decimals: 2,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
    },
    consumedEnergyFromGrid: {
      key: 'consumedEnergyFromGrid',
      label: 'consumedEnergyFromGrid',
      converter: {
        name: 'dimension',
        options: {
          decimals: 2,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
    },
    averageDailyPriceSpread: {
      key: 'averageDailyPriceSpread',
      label: 'averageDailyPriceSpread',
      render: (averageDailyPriceSpread, currency) => (
        <>
          {!isNaN(averageDailyPriceSpread) ? (
            <>
              {(averageDailyPriceSpread * 1000000).toFixed(2)}
              <span className="unit">{` ${currency}/MWh`}</span>
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    averageDailyCycles: {
      key: 'averageDailyCycles',
      label: 'averageDailyCycles',
      render: (data) => (data ? `${data.toFixed(2)}` : data),
    },
    batteryLifeSpan: {
      key: 'batteryLifeSpan',
      label: 'batteryLifeSpan',
      render: (data) => (!isNaN(data) ? DataDisplay(data, 'years') : '-'),
    },
    lcos: {
      key: 'lcos',
      label: 'lcos',
      converter: {
        name: 'dimension',
        options: {
          decimals: 2,
        },
      },
      render: (data, currency) => renderCostAttribute(data.value, currency, 'MWh'),
    },
    bessCapexTotalPrice: {
      key: 'bessCapexTotalPrice',
      label: 'bessCapexTotalPrice',
      render: renderTotalCostAttribute,
    },
    bessCapexSpecificPrice: {
      key: 'bessCapexSpecificPrice',
      label: 'bessCapexSpecificPrice',
      render: (data, currency) => renderCostAttribute(data, currency, 'kWp'),
    },
  };
}

export type OutputAttributes = {
  ratedPower: IAttribute;
  peakPower: IAttribute;
  activePowerAtPoi: IAttribute;
  dcAcRatioAtPoi: IAttribute;
  ratioDcAc: IAttribute;
  specificProduction: IAttribute;
  yearZeroEnergyToGridAfterAvailability: IAttribute;
  performanceRatio: IAttribute;
  totalPrice: IAttribute;
  specificPrice: IAttribute;
  npv: IAttribute;
  irr: IAttribute;
  roi: IAttribute;
  payback: IAttribute;
  discountedPayback: IAttribute;
  lcoe: IAttribute;
  activePowerAc: IAttribute;
  energyCapacity: IAttribute;
  operationHours: IAttribute;
  totalUnusedPeakPower: IAttribute;
  maximumPeakPower: IAttribute;
  activePowerDc: IAttribute;
  energyCapacityDc: IAttribute;
  operationHoursDc: IAttribute;
  substationArrangement: IAttribute;
  numberTransformers: IAttribute;
  transformerType: IAttribute;
  biggestTransformerRatedPower: IAttribute;
  breakingStationCapacity: IAttribute;
  numberFeeders: IAttribute;
  mvCableCrossSection: IAttribute;
  lineCapacity: IAttribute;
  lineLength: IAttribute;
  numTowers: IAttribute;
  voltageDrop: IAttribute;
  losses: IAttribute;
  dischargedEnergyToGrid: IAttribute;
  averageDailyPriceSpread: IAttribute;
  averageDailyCycles: IAttribute;
  consumedEnergyFromGrid: IAttribute;
  totalDischargedEnergy: IAttribute;
  batteryLifeSpan: IAttribute;
  lcos: IAttribute;
  bessCapexTotalPrice: IAttribute;
  bessCapexSpecificPrice: IAttribute;
};

enum PowerCategory {
  ratedPower,
  peakPower,
  ratioDcAc,
  totalUnusedPeakPower,
  maximumPeakPower,
}

enum EnergyCategory {
  yearZeroEnergyToGridAfterAvailability,
  specificProduction,
  performanceRatio,
}

enum FinancialCategory {
  totalPrice,
  specificPrice,
  lcoe,
  npv,
  irr,
  roi,
  payback,
  discountedPayback,
}

enum BessCategory {
  activePowerAc,
  energyCapacity,
  operationHours,
}

enum DCBessCategory {
  activePowerDc,
  energyCapacityDc,
  operationHoursDc,
}

enum SubstationCategory {
  substationArrangement,
  numberTransformers,
  transformerType,
  biggestTransformerRatedPower,
}

enum BreakingStationCategory {
  breakingStationCapacity,
  numberFeeders,
  mvCableCrossSection,
}

enum OhlCategory {
  lineCapacity,
  lineLength,
  numTowers,
  voltageDrop,
  losses,
}

enum StandaloneBessCategory {
  activePowerAc,
  energyCapacity,
  operationHours,
}

enum BatteryEnergyCategory {
  totalDischargedEnergy,
  dischargedEnergyToGrid,
  consumedEnergyFromGrid,
  averageDailyPriceSpread,
  averageDailyCycles,
  batteryLifeSpan,
}

enum FinancialCategoryForStandalone {
  bessCapexTotalPrice,
  bessCapexSpecificPrice,
  lcoe,
  npv,
  irr,
  roi,
  payback,
  discountedPayback,
  lcos,
}

export const LIST_DATA_EMPTY_KEY = 'default';
const INTERCONNECTION_DATA_KEY = 'interconnectionOutputData';

export type OutputInCategories = {
  power: { [key: string]: Array<{ [key: string]: any }> };
  energy: { [key: string]: Array<{ [key: string]: any }> };
  financial: { [key: string]: Array<{ [key: string]: any }> };
  bess: { [key: string]: Array<{ [key: string]: any }> };
  dcbess: { [key: string]: Array<{ [key: string]: any }> };
  // Interconnection Results
  substation: { [key: string]: Array<{ [key: string]: any }> };
  breakingStation: { [key: string]: Array<{ [key: string]: any }> };
  ohl: { [key: string]: Array<{ [key: string]: any }> };
};

export enum StandaloneBessOutputCategories {
  STANDALONE_BESS = 'standaloneBess',
  FINANCIAL = 'financial',
  BATTERY_ENERGY = 'batteryEnergy',
}

const DISPATCH_OUTPUT_KEY: keyof ResultOutputsType = 'dispatchOutputDataDTO';
const FINANCIAL_OUTPUT_KEY: keyof ResultOutputsType = 'financialOutputDTO';

export type StandaloneBessOutputInCategories = {
  [StandaloneBessOutputCategories.STANDALONE_BESS]: { [key: string]: Array<{ [key: string]: any }> };
  [StandaloneBessOutputCategories.FINANCIAL]: { [key: string]: Array<{ [key: string]: any }> };
  [StandaloneBessOutputCategories.BATTERY_ENERGY]: { [key: string]: Array<{ [key: string]: any }> };
};

export const getStandaloneBessOutputsInCategories = (outputs: ResultOutputsType) => {
  const outputsInCategories: StandaloneBessOutputInCategories = {
    standaloneBess: { [LIST_DATA_EMPTY_KEY]: [] },
    financial: { [LIST_DATA_EMPTY_KEY]: [] },
    batteryEnergy: { [LIST_DATA_EMPTY_KEY]: [] },
  };

  const addToCategory = (categoryKey: string, item: object) => {
    outputsInCategories[categoryKey][LIST_DATA_EMPTY_KEY].push(item);
  };

  Object.entries(outputs).forEach(([outputKey, outputValue]) => {
    if (Object.values(StandaloneBessCategory).includes(outputKey)) {
      addToCategory('standaloneBess', { [outputKey]: outputValue });
    }

    if (outputKey === DISPATCH_OUTPUT_KEY && outputs[DISPATCH_OUTPUT_KEY]) {
      Object.entries(outputs.dispatchOutputDataDTO).forEach(([nestedKey, nestedValue]) => {
        if (Object.values(BatteryEnergyCategory).includes(nestedKey)) {
          addToCategory('batteryEnergy', { [nestedKey]: nestedValue });
        }
      });
    }

    if (outputKey === FINANCIAL_OUTPUT_KEY && outputs[FINANCIAL_OUTPUT_KEY]) {
      Object.entries(outputs.financialOutputDTO).forEach(([nestedKey, nestedValue]) => {
        if (Object.values(FinancialCategoryForStandalone).includes(nestedKey)) {
          addToCategory('financial', {
            [nestedKey]: nestedValue,
            [`${nestedKey}CalculationResult`]: outputs[FINANCIAL_OUTPUT_KEY][`${nestedKey}CalculationResult`],
          });
        }
      });
    }
  });

  return outputsInCategories;
};

export const getOutputsInCategories = (outputs: ResultOutputs) => {
  // Sets of results which are always a single set are given the name 'default',
  // results which can be a list are given a name dynamically according to the relevant data
  const outputsInCategories: OutputInCategories = {
    power: { [LIST_DATA_EMPTY_KEY]: [] },
    energy: { [LIST_DATA_EMPTY_KEY]: [] },
    financial: { [LIST_DATA_EMPTY_KEY]: [] },
    bess: { [LIST_DATA_EMPTY_KEY]: [] },
    dcbess: { [LIST_DATA_EMPTY_KEY]: [] },
    substation: {},
    breakingStation: {},
    ohl: {},
  };

  for (const [outputKey, outputValue] of Object.entries(outputs)) {
    if (Object.values(PowerCategory).includes(outputKey)) {
      outputsInCategories.power[LIST_DATA_EMPTY_KEY].push({ [outputKey]: outputValue });
    }
    if (Object.values(EnergyCategory).includes(outputKey)) {
      outputsInCategories.energy[LIST_DATA_EMPTY_KEY].push({ [outputKey]: outputValue });
    }
    if (Object.values(FinancialCategory).includes(outputKey)) {
      const financialCategoryField = {
        [outputKey]: outputValue,
        [`${outputKey}CalculationResult`]: outputs[`${outputKey}CalculationResult`],
      };
      outputsInCategories.financial[LIST_DATA_EMPTY_KEY].push(financialCategoryField);
    }
    if (Object.values(BessCategory).includes(outputKey)) {
      outputsInCategories.bess[LIST_DATA_EMPTY_KEY].push({ [outputKey]: outputValue });
    }
    if (Object.values(DCBessCategory).includes(outputKey)) {
      outputsInCategories.dcbess[LIST_DATA_EMPTY_KEY].push({ [outputKey]: outputValue });
    }
    if (INTERCONNECTION_DATA_KEY === outputKey) {
      const interconnectionData = outputValue as InterconnectionOutputData;

      const substationResults = interconnectionData.substationResults;
      const breakingStationResults = interconnectionData.switchingBreakingStationResults;
      const ohlResults = interconnectionData.ohlResults;

      if (substationResults) {
        const listData = interconnectionData.substationResults;
        listData.forEach((data) => {
          outputsInCategories.substation[data.substationName] = buildOutputsInListCategory(
            data,
            Object.values(SubstationCategory)
          );
        });
      }
      if (breakingStationResults) {
        const listData = interconnectionData.switchingBreakingStationResults;
        listData.forEach((data) => {
          outputsInCategories.breakingStation[data.switchingBreakingStationName] = buildOutputsInListCategory(
            data,
            Object.values(BreakingStationCategory)
          );
        });
      }
      if (ohlResults) {
        const listData = interconnectionData.ohlResults;
        listData.forEach((data) => {
          outputsInCategories.ohl[data.lineName] = buildOutputsInListCategory(data, Object.values(OhlCategory));
        });
      }
    }
  }
  return outputsInCategories;
};

function buildOutputsInListCategory(
  data: { [key: string]: string | number },
  categoryFields: any[]
): { [x: string]: string | number }[] {
  const dataInCategory: { [x: string]: string | number }[] = [];
  for (const [outputKey, outputValue] of Object.entries(data)) {
    if (categoryFields.includes(outputKey)) {
      dataInCategory.push({ [outputKey]: outputValue });
    }
  }
  return dataInCategory;
}

export const outputsEmptyTemplate: ResultOutputs = {
  activePowerAc: 0,
  energyCapacity: 0,
  maximumPeakPower: 0,
  operationHours: 0,
  peakPower: 0,
  performanceRatio: 0,
  ratedPower: 0,
  ratioDcAc: 0,
  totalPrice: 0,
  specificPrice: 0,
  lcoe: 0,
  lcoeCalculationResult: CalculationResult.CORRECT,
  npv: 0,
  irr: 0,
  irrCalculationResult: CalculationResult.CORRECT,
  roi: 0,
  roiCalculationResult: CalculationResult.CORRECT,
  payback: 0,
  paybackCalculationResult: CalculationResult.CORRECT,
  discountedPayback: 0,
  discountedPaybackCalculationResult: CalculationResult.CORRECT,
  specificProduction: 0,
  totalUnusedPeakPower: 0,
  yearZeroEnergyToGridAfterAvailability: 0,
  activePowerDc: 0,
  energyCapacityDc: 0,
  operationHoursDc: 0,
  interconnectionOutputData: {
    substationResults: [
      {
        substationName: LIST_DATA_EMPTY_KEY,
        substationArrangement: '',
        numberTransformers: 0,
        transformerType: '',
        biggestTransformerRatedPower: 0,
      },
    ],
    switchingBreakingStationResults: [
      {
        switchingBreakingStationName: LIST_DATA_EMPTY_KEY,
        breakingStationCapacity: 0,
        numberFeeders: 0,
        mvCableCrossSection: 0,
      },
    ],
    ohlResults: [
      {
        lineName: LIST_DATA_EMPTY_KEY,
        lineCapacity: 0,
        lineLength: 0,
        numTowers: 0,
        voltageDrop: 0,
        losses: 0,
      },
    ],
    interconnectionResultsGeneral: {
      activePowerPointOfInterconnection: 0,
      ratioDcAcPointOfInterconnection: 0,
    },
  },
  outputPlotAreaData: [],
};

export const standaloneBessOutputsEmptyTemplate: ResultOutputsType = {
  activePowerAc: 0,
  energyCapacity: 0,
  operationHours: 0,
  dispatchOutputDataDTO: {
    totalDischargedEnergy: 0,
    dischargedEnergyToGrid: 0,
    consumedEnergyFromGrid: 0,
    averageDailyPriceSpread: 0,
    averageDailyCycles: 0,
    batteryLifeSpan: 0,
  },
  financialOutputDTO: {
    bessCapexTotalPrice: 0,
    bessCapexSpecificPrice: 0,
    lcos: 0,
    npv: 0,
    irr: 0,
    payback: 0,
    discountedPayback: 0,
    roi: 0,
  },
};

export const BatteryEnergyLoadingResults = {
  [LIST_DATA_EMPTY_KEY]: [
    {
      totalDischargedEnergy: null,
    },
    {
      dischargedEnergyToGrid: null,
    },
    {
      averageDailyPriceSpread: null,
    },
    {
      averageDailyCycles: null,
    },
    {
      consumedEnergyFromGrid: null,
    },
    {
      batteryLifeSpan: null,
    },
  ],
};

export const FinancialLoadingResults = {
  [LIST_DATA_EMPTY_KEY]: [
    {
      bessCapexTotalPrice: null,
    },
    {
      bessCapexSpecificPrice: null,
    },
    {
      lcos: null,
    },
    {
      npv: null,
    },
    {
      irr: null,
    },
    {
      payback: null,
    },
    {
      discountedPayback: null,
    },
    {
      roi: null,
    },
  ],
};
