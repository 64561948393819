import React from 'react';
import * as S from './design-tag.styled';

interface IDesignTag extends React.PropsWithChildren {
  color: string;
  position: { top: number; right: number | 'auto' };
  _static?: boolean;
}

const DesignTag: React.FC<IDesignTag> = ({ color, position, _static, children }) => {
  return (
    <S.PositionedTag color={color} $position={position} $static={_static}>
      {children}
    </S.PositionedTag>
  );
};

export { DesignTag };
