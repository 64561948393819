import { useToasts } from 'utils/hooks/use-toasts';
import {
  GET_PROJECT_CREATORS,
  GET_USER_PROJECTS,
  UPDATE_PROJECTS,
  UPDATE_PROJECT_NAME,
  TOGGLE_FAVORITE_PROJECT,
} from 'services/projects-gql';
import { useModal } from 'utils/hooks/use-modal';
import { useMutation } from '@apollo/client';
import { IProjectListItem } from 'types/projects';
import { GenericStatus } from 'utils/constants/status';
import { DESIGN_ACTIONS } from 'utils/constants/designs';
import { useTranslation } from 'react-i18next';
import { track } from 'rudderstack/utils';
import { PageLocations, RudderstackEvent } from 'rudderstack/types';

export const useProjectsActions = () => {
  const { addSuccessToast, addErrorToast, removeToast } = useToasts();
  const { t } = useTranslation();
  const newProjectFormModal = useModal();
  const deleteProjectModal = useModal();

  const [updateProjects, { loading: loadingUpdate }] = useMutation(UPDATE_PROJECTS, {
    refetchQueries: [GET_USER_PROJECTS, GET_PROJECT_CREATORS],
  });

  const [updateProjectName, { loading: loadingUpdateName }] = useMutation(UPDATE_PROJECT_NAME, {
    refetchQueries: [GET_USER_PROJECTS],
    onError: () => addErrorToast(t('project:error-changing-name')),
  });

  const [toggleFavoriteProject, { loading: loadingFavoriteChange }] = useMutation(TOGGLE_FAVORITE_PROJECT, {
    refetchQueries: [GET_USER_PROJECTS, GET_PROJECT_CREATORS],
  });

  const handleNewProjectModal = () => {
    newProjectFormModal.setModalOpen(true);
  };
  const handleCloseNewProjectModal = () => {
    newProjectFormModal.setModalOpen(false);
  };

  // PROJECT ACTIONS
  const handleSwitchFavorite = (project: IProjectListItem) => {
    const { id } = project;
    track(RudderstackEvent.PROJECT_MARKED_FAVORITE, { location: PageLocations.PROJECTS });
    toggleFavoriteProject({ variables: { projectId: id } });
  };

  const handleArchive = (project: IProjectListItem) => {
    const { id, status } = project;
    if (status === GenericStatus.ARCHIVED) return;
    const projectForms = [{ id, status: GenericStatus.ARCHIVED }];

    track(RudderstackEvent.PROJECT_ARCHIVED);
    updateProjects({ variables: { projectForms } });
    addSuccessToast(t('project:project-has-been-archived'));
  };

  const handleUnarchive = (project: IProjectListItem, toastToRemove?: any) => {
    const { id, status } = project;
    if (status === GenericStatus.REVIEWED) return;
    const projectForms = [{ id, status: GenericStatus.REVIEWED }];

    track(RudderstackEvent.PROJECT_RESTORED);
    updateProjects({ variables: { projectForms } });
    if (!!toastToRemove) removeToast(toastToRemove);
  };

  const handleModalDelete = (project: IProjectListItem) => {
    deleteProjectModal.setModalOpen(true);
    deleteProjectModal.setData(project);
  };
  const handleDelete = (id: string) => {
    deleteProjectModal.setModalOpen(false);
    const projectForms = [{ id, status: GenericStatus.DELETED_USER }];

    updateProjects({ variables: { projectForms } });
  };

  const handleEditName = (name: string, id: string) => {
    updateProjectName({ variables: { id, name } });
  };

  const handlePerform = (action: string, item: IProjectListItem) => {
    const { click, select, favorite, clone, archive, unarchive, delete: delItem, confirmDelete } = DESIGN_ACTIONS;
    const availableActions = [click, select, favorite, clone, archive, unarchive, delItem, confirmDelete];
    if (!availableActions.includes(action)) return;

    switch (action) {
      case favorite:
        handleSwitchFavorite(item);
        break;
      case archive:
        handleArchive(item);
        break;
      case unarchive:
        handleUnarchive(item);
        break;
      case delItem:
        handleModalDelete(item);
        break;
      case confirmDelete:
        handleDelete(item.id);
        break;
    }
  };

  return {
    handlePerform,
    handleNewProjectModal,
    handleCloseNewProjectModal,
    handleEditName,
    loadingUpdateProjects: loadingUpdateName || loadingUpdate || loadingFavoriteChange,
    newProjectFormModal,
    deleteProjectModal,
  };
};
