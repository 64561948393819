import { create, StoreApi } from 'zustand';
import { fetchLayout } from './utils/fetch-layout';
import { getApiUrl } from 'utils/api_url';
import { v4 as uuidv4 } from 'uuid';
import {
  Action,
  AddStructureData,
  AddStructurePayload,
  AvailableAreasLayer,
  BatteryAreaLayer,
  BboxAndOffset,
  DesignInputs,
  DesignKPI,
  GenerateDesignResponse,
  IlcLayoutAction,
  IlcValidationError,
  Point,
  PowerStationElement,
  PowerStationElementWithId,
  PowerStationsLayer,
  RoadElement,
  RoadsLayer,
  SelectedElement,
  SessionInputs,
  Setbacks,
  StructureElement,
  StructureElementWithId,
  StructuresLayer,
  SubstationAreasLayer,
  SwitchingStationLayer,
  UserRestrictedAreasLayer,
  KPISortByValue,
  SortDirection,
} from './ilc-types';
import { pointInPolygon } from './ilc-utils';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';

const handleBackendUpdate = async (
  elements: { [elementId: string]: StructureElement | PowerStationElement | RoadElement },
  action: IlcLayoutAction['action'],
  sessionId: string,
  postRest: any,
  set: StoreApi<CanvasStore & CanvasState>['setState'],
  get: StoreApi<CanvasStore & CanvasState>['getState']
) => {
  const entities: Record<string, IlcLayoutAction['entity']> = {
    STRUCTURES: 'STRUCTURE',
    POWER_STATIONS: 'PS',
    ROADS: 'ROAD',
  };
  const updatesArray: IlcLayoutAction[] = Object.keys(elements).map((key) => {
    const entityName = entities[elements[key].type];
    return {
      entity: entityName,
      action: action,
      timestamp: Date.now(),
      modifiedElement: {
        [key]: elements[key],
      },
    };
  });

  const url = `${getApiUrl()}/interactive-layout/movement/${sessionId}`;

  set((state) => ({ ...state, loadingUpdateOperation: true }));
  await postRest(url, updatesArray);
  set((state) => ({ ...state, loadingUpdateOperation: false }));

  const validationErrors = get().validationErrors;
  if (Object.keys(validationErrors).length > 0) {
    set((state) => ({ ...state, validationErrorsStatus: 'requires-update' }));
  }
};

const handleProcessKPIsFromSession = (
  sessionResponse: IlcLayoutAction[],
  structures: StructuresLayer,
  kpisData: DesignKPI,
  stringDcPower: number
): DesignKPI => {
  let kpis = kpisData;
  for (const element of sessionResponse) {
    if (element.action === 'REGROUP') {
      const modifiedElementKey = Object.keys(element.modifiedElement)[0];
      const currentElement = structures[modifiedElementKey];
      const updatedElement = element.modifiedElement[modifiedElementKey] as StructureElement;
      kpis = recalculateKpisAfterStructureAction(kpis, [currentElement], stringDcPower, 'remove');
      kpis = recalculateKpisAfterStructureAction(kpis, [updatedElement], stringDcPower, 'add');
    }
    if (element.action === 'CREATE' && element.entity === 'STRUCTURE') {
      const modifiedElement = Object.values(element.modifiedElement)[0] as StructureElement;
      kpis = recalculateKpisAfterStructureAction(kpis, [modifiedElement], stringDcPower, 'add');
    }
    if (element.action === 'DELETE' && element.entity === 'STRUCTURE') {
      const modifiedElement = Object.values(element.modifiedElement)[0] as StructureElement;
      kpis = recalculateKpisAfterStructureAction(kpis, [modifiedElement], stringDcPower, 'remove');
    }
  }
  return kpis;
};

const handleProcessActionsFromSession = (
  sessionResponse: IlcLayoutAction[],
  structures: StructuresLayer,
  powerStations: PowerStationsLayer,
  roads: RoadsLayer
): void => {
  for (const element of sessionResponse) {
    if (element.action === 'REGROUP') {
      const modifiedElementKey = Object.keys(element.modifiedElement)[0];
      const updatedElement = element.modifiedElement[modifiedElementKey] as StructureElement;
      if (element.entity === 'STRUCTURE') {
        structures[modifiedElementKey] = updatedElement;
      }
    }
    if (element.action === 'MOVE') {
      const modifiedElementKey = Object.keys(element.modifiedElement)[0];
      if (element.entity === 'STRUCTURE') {
        structures[modifiedElementKey] = element.modifiedElement[modifiedElementKey] as StructureElement;
      }
      if (element.entity === 'PS') {
        powerStations[modifiedElementKey] = element.modifiedElement[modifiedElementKey] as PowerStationElement;
      }
      if (element.entity === 'ROAD') {
        roads[modifiedElementKey] = element.modifiedElement[modifiedElementKey] as RoadElement;
      }
    }
    if (element.action === 'CREATE' && element.entity === 'STRUCTURE') {
      const modifiedElementKey = Object.keys(element.modifiedElement)[0];
      structures[modifiedElementKey] = element.modifiedElement[modifiedElementKey] as StructureElement;
    }
    if (element.action === 'DELETE' && element.entity === 'STRUCTURE') {
      const modifiedElementKey = Object.keys(element.modifiedElement)[0];
      delete structures[modifiedElementKey];
    }
  }
};

const handleGenerateDesign = async (
  designId: string,
  projectId: string,
  bbox: BboxAndOffset,
  sessionId: string,
  userEmail: string,
  postRest: any
): Promise<GenerateDesignResponse> => {
  const url = `${getApiUrl()}/interactive-layout/calculate/${sessionId}`;

  const objectToSendToBackend = {
    simulationId: designId,
    projectId: projectId,
    bbox: bbox,
    userEmail,
  };

  const { error, data } = await postRest(url, objectToSendToBackend);
  if (error) {
    return { data: null, error: error?.response?.data?.codeError ?? 'fallback-generation-error' };
  }
  return { data: data as { simulationId: string }, error: null };
};

type SelectedRectangleStateUpdate =
  | { action: 'update'; payload: (StructureElementWithId | PowerStationElementWithId)[] }
  | { action: 'reset' }
  | null;

const getNewRectangleSelectedStateAction = (
  get: () => CanvasStore & CanvasState,
  elementId: string,
  isFromDragSelection?: boolean
): SelectedRectangleStateUpdate => {
  const rectangleElements = get().rectangleElements;
  if (!rectangleElements) return null;
  const selectedElement: PowerStationElementWithId | StructureElementWithId = {
    ...rectangleElements[elementId],
    id: elementId,
  };
  const modeState = get().modeState;
  if (modeState.mode.type === PrimaryActionTypes.SELECT_RECTANGLES) {
    const payload = modeState.mode.payload;
    const someRectangleIsSelected = payload.length > 0;
    if (someRectangleIsSelected) {
      const isAlreadySelected = payload.find(
        (element: PowerStationElementWithId | StructureElementWithId) => element.id === elementId
      );
      if (isAlreadySelected && isFromDragSelection) return null;
      if (isAlreadySelected) {
        const newPayload = payload.filter((element) => element.id !== elementId);
        if (newPayload.length === 0) {
          return { action: 'reset' };
        }
        return { action: 'update', payload: newPayload };
      }
      return { action: 'update', payload: [...payload, selectedElement] };
    }
  }
  return { action: 'update', payload: [selectedElement] };
};

function recalculateKpisAfterStructureAction(
  currentKpis: DesignKPI,
  elements: (StructureElement | PowerStationElement)[],
  stringDcPower: number,
  action: 'add' | 'remove'
): DesignKPI {
  return elements.reduce((acc: DesignKPI, structure) => {
    if (structure.type === 'POWER_STATIONS') return acc;
    const psKey = structure.psKey;

    const currentPowerStationKpis = acc.powerStationKpis[psKey];

    const updatedPeakDCPower =
      currentPowerStationKpis.powerKpis.peakDcPower + structure.strings * stringDcPower * (action === 'add' ? 1 : -1);
    const updatedDcAcRatio = updatedPeakDCPower / currentPowerStationKpis.powerKpis.ratedPower;

    const updatedPowerStationKpis = {
      ...currentPowerStationKpis,
      strings: currentPowerStationKpis.strings - structure.strings,
      powerKpis: {
        ...currentPowerStationKpis.powerKpis,
        peakDcPower: updatedPeakDCPower,
        dcAcRatio: updatedDcAcRatio,
      },
    };

    const areaKey = structure.areaKey;

    const currentAreaKpi = acc.areaKpis[areaKey];

    const updatedAreaPeakDCPower =
      currentAreaKpi.powerKpis.peakDcPower + structure.strings * stringDcPower * (action === 'add' ? 1 : -1);
    const updatedAreaDcAcRatio = updatedAreaPeakDCPower / currentAreaKpi.powerKpis.ratedPower;

    const updateAreaKpi = {
      ...currentAreaKpi,
      powerKpis: {
        ...currentAreaKpi.powerKpis,
        peakDcPower: updatedAreaPeakDCPower,
        dcAcRatio: updatedAreaDcAcRatio,
      },
    };

    const currentGlobalKpi = acc.powerKpis;

    const updatedGlobalPeakPower =
      currentGlobalKpi.peakDcPower + structure.strings * stringDcPower * (action === 'add' ? 1 : -1);
    const updatedGlobalDcAcRatio = updatedGlobalPeakPower / currentGlobalKpi.ratedPower;

    const updatedGlobalKpi = {
      ...currentGlobalKpi,
      peakDcPower: updatedGlobalPeakPower,
      dcAcRatio: updatedGlobalDcAcRatio,
    };

    return {
      ...acc,
      powerStationKpis: { ...acc.powerStationKpis, [psKey]: updatedPowerStationKpis },
      areaKpis: { ...acc.areaKpis, [areaKey]: updateAreaKpi },
      powerKpis: updatedGlobalKpi,
    };
  }, currentKpis);
}

type ValidationErrorsStatus = 'error' | 'success' | 'requires-update' | null;

interface ActionHistory {
  past: Action[];
  future: Action[];
}

interface CanvasStore {
  fetchLayout: (designId: string, projectId: string, userEmail: string, sessionId: string, get: any, post: any) => void;
  updateDesignName: (designName: string) => void;
  setPointerType: (pointerType: PointerType) => void;
  setMovementType: (movementType: MovementType) => void;
  removeElement: (elementId: string) => void;
  addStructure: (args: AddStructureData, isUndo?: boolean) => void;
  restoreDeletedStructures: (structures: StructureElement[]) => void;
  reset: () => void;
  updateNodeCoordinate: (roadId: string, nodeId: string, newCoordinates: Point) => void;
  addNodeAndEdgeToClosestNode: (newNode: Point, roadId: string) => void;
  deleteNodeAndEdges: (nodeId: string, roadId: string) => void;
  deleteNodeAndConnectingEdges: (nodeId: string, roadId: string) => void;
  addNodeBetweenTwoNodes: (newNode: Point, roadId: string, source: string, target: string) => void;
  sendRoadsUpdate: () => void;
  detectIfClickIsInsideAvailableArea: (coordinates: Point) => string | null;
  getRoadOfAvailableArea: (areaId: string) => string | undefined;
  moveElements: (elementsIds: string[], offset: Point, isUndo?: boolean) => void;
  moveMultipleElements: (elements: SelectedElement[], isUndo?: boolean) => Promise<void>;
  removeElements: (selectedElementsPayload?: any, isUndo?: boolean) => Promise<void>;
  generateIlcDesign: () => Promise<GenerateDesignResponse>;
  onSelectMultipleRectanglesByIndexes: (elementsIndex: number[]) => void;
  onSelectMultipleRectanglesById: (elementsIds: string[]) => void;
  addNodeAndEdgeToOtherNode: (newNode: Point, connectedNodeId: string, roadId: string) => string | undefined;
  addEdgeBetweenTwoNodes: (source: string, target: string, roadId: string) => void;
  changePsOfStructures: (structureIds: string[], psId: string, isUndo?: boolean) => void;
  callValidation: () => Promise<IlcValidationError[] | null>;
  setMode: (modeType: ModeTypesWithoutPayload['type']) => void;
  setSecondaryMode: (secondaryMode: SecondaryModeTypes) => void;
  addStructureMode: (payload: AddStructurePayload) => void;
  resetMode: () => void;
  resetModeByStep: () => void;
  onSelectElement: (elementId: string, isFromDragSelection?: boolean) => void;
  addNodeToClosestMode: () => void;
  addLineBetweenNodesMode: (payload: { initialNodeId: string; initialNodeAreaId: string } | null) => void;
  addNodeToOtherNodeMode: (
    payload: { connectedNode: { initialNodeId: string; roadId: string; initialNodeAreaId: string } } | null
  ) => void;
  selectReferenceElementMode: (elementId: string, referencePoint: Point | null) => void;
  selectReferenceElementForRoadsMode: (payload: string | null) => void;
  loadingUpdateOperation: boolean;
  loadingValidateOperation: boolean;
  updateRoads: (newRoads: RoadsLayer, isUndo?: boolean) => void;
  updateRoadsWithPreviousState: (previousRoads: RoadsLayer) => void;
  addToHistory: (action: Action) => void;
  undo: () => void;
  redo: () => void;
  validationErrors: Record<string, IlcValidationError[]>;
  validationErrorsStatus: ValidationErrorsStatus;
  setTopToolbarTab: (tab: TopToolbarTab) => void;
  setRightSideBarSelection: (selection: 'kpis' | 'validations') => void;
  alignSelectedStructureWithSetbacks: (type: 'closest' | 'parallel') => void;
  alignUniformly: () => void;
  openModal: (modalState: ModalState) => void;
  closeModal: () => void;
  getRectangleElement: (id: string) => PowerStationElementWithId | StructureElementWithId | null;
  updateKpisPowerStationsFilterState: (
    open: boolean,
    sorting?: { sortBy: KPISortByValue; sortDirection: SortDirection }
  ) => void;
}

const findClosestNodeToCoordinates = (nodes: { [nodeId: string]: Point }, coordinates: Point) => {
  let closestNode: string | null = null;
  let closestDistance = Infinity;
  for (const nodeId in nodes) {
    const node = nodes[nodeId];
    const distance = Math.sqrt(Math.pow(node.x - coordinates.x, 2) + Math.pow(node.y - coordinates.y, 2));
    if (distance < closestDistance) {
      closestDistance = distance;
      closestNode = nodeId;
    }
  }
  return closestNode;
};

export enum PrimaryActionTypes {
  'NONE' = 'NONE',
  'SELECT_RECTANGLES' = 'SELECT_RECTANGLES',
  'ADD_STRUCTURES' = 'ADD_STRUCTURES',
  'ADD_NODE_TO_CLOSEST' = 'ADD_NODE_TO_CLOSEST',
  'ADD_EDGE_BETWEEN_NODES' = 'ADD_EDGE_BETWEEN_NODES',
  'DELETE_NODE' = 'DELETE_NODE',
  'SELECTING_ROADS' = 'SELECTING_ROADS',
  'ADD_NODE_TO_OTHER_NODE' = 'ADD_NODE_TO_OTHER_NODE',
  'DELETE_NODE_AND_CONNECTING_EDGES' = 'DELETE_NODE_AND_CONNECTING_EDGES',
  'SELECT_REFERENCE_FOR_ROADS' = 'SELECT_REFERENCE_FOR_ROADS',
}

export enum SecondaryActionTypes {
  'SELECT_REFERENCE' = 'SELECT_REFERENCE',
  'SELECT_PS_FOR_STRUCTURES' = 'SELECT_PS_FOR_STRUCTURES',
  'ALIGN_SELECTED_STRUCTURES_WITH_SETBACKS' = 'ALIGN_SELECTED_STRUCTURES_WITH_SETBACKS',
  'ALIGN_UNIFORMLY' = 'ALIGN_UNIFORMLY',
}

type ModeTypesWithoutPayload =
  | {
      type: PrimaryActionTypes.NONE;
      payload: null;
    }
  | { type: PrimaryActionTypes.DELETE_NODE; payload: null }
  | { type: PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES; payload: null }
  | { type: PrimaryActionTypes.SELECTING_ROADS; payload: null }
  | { type: PrimaryActionTypes.ADD_NODE_TO_CLOSEST; payload: null };

type ModeTypesWithPayload =
  | { type: PrimaryActionTypes.SELECT_RECTANGLES; payload: (PowerStationElementWithId | StructureElementWithId)[] }
  | {
      type: PrimaryActionTypes.ADD_STRUCTURES;
      payload: AddStructurePayload;
    }
  | {
      type: PrimaryActionTypes.ADD_NODE_TO_OTHER_NODE;
      payload: { connectedNode: { initialNodeId: string; roadId: string; initialNodeAreaId: string } } | null;
    }
  | {
      type: PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES;
      payload: { initialNodeId: string; initialNodeAreaId: string } | null;
    }
  | {
      type: PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS;
      payload: PowerStationElementWithId | StructureElementWithId | null;
    };

type ModeTypes = ModeTypesWithoutPayload | ModeTypesWithPayload;

type SecondaryModeTypes =
  | {
      type: SecondaryActionTypes.SELECT_REFERENCE;
      payload: { element: PowerStationElementWithId | StructureElementWithId | null; referencePoint: Point | null };
    }
  | {
      type: SecondaryActionTypes.SELECT_PS_FOR_STRUCTURES;
    }
  | {
      type: PrimaryActionTypes.NONE;
      payload: null;
    }
  | { type: SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_SETBACKS; payload: 'closest' | 'parallel' }
  | { type: SecondaryActionTypes.ALIGN_UNIFORMLY };

interface ModeState {
  mode: ModeTypes;
  secondaryMode: SecondaryModeTypes;
}

type PointerType = 'SELECT' | 'MOVE';

type MovementType = 'ORTHOGONAL' | 'FREE';

export type TopToolbarTab = 'structures' | 'roads' | 'power-stations' | 'all';
export type SidebarOptions = 'kpis' | 'validations';

export type CustomRotationModalState = {
  modalName: 'custom-ps-rotation';
  data: (PowerStationElementWithId | StructureElementWithId)[];
};

export type ModalState = CustomRotationModalState;

const initialModeState = {
  mode: { type: PrimaryActionTypes.NONE, payload: null },
  secondaryMode: { type: PrimaryActionTypes.NONE, payload: null },
} as const;

interface CanvasState {
  modeState: ModeState;
  actionHistory: ActionHistory;
  pointerType: PointerType;
  movementType: MovementType;
  rectangleElements: { [x: string]: PowerStationElement | StructureElement } | null;
  bboxAndOffset: BboxAndOffset | null;
  availableAreas: AvailableAreasLayer | null;
  restrictedAreas: UserRestrictedAreasLayer | null;
  substationAreas: SubstationAreasLayer | null;
  switchingStationAreas: SwitchingStationLayer | null;
  batteryAreas: BatteryAreaLayer | null;
  roads: RoadsLayer | null;
  randomPsKey: string | null;
  offset: { x: number; y: number } | null;
  bbox: { min: Point; max: Point } | null;
  designId: string | null;
  designName: string | null;
  setbacks: Setbacks | null;
  designInputs: DesignInputs | null;
  kpis: DesignKPI | null;
  kpisPowerStationsFilterState: {
    open: boolean;
    sortBy: KPISortByValue;
    sortDirection: SortDirection;
  };
  projectId: string | null;
  projectName: string | null;
  sessionId: string | null;
  userEmail: string | null;
  utmZone: string | null;
  getRest: any;
  postRest: any;
  loadingUpdateOperation: boolean;
  loadingValidateOperation: boolean;
  validationErrors: Record<string, IlcValidationError[]>;
  validationErrorsStatus: ValidationErrorsStatus;
  loadingInputsData: boolean;
  topToolbarTab: TopToolbarTab;
  rightSideBarSelection: SidebarOptions;
  modalState: ModalState | null;
  isGeneratingDesign: boolean;
}

const initialState: CanvasState = {
  modeState: initialModeState,
  pointerType: 'SELECT',
  movementType: 'ORTHOGONAL',
  rectangleElements: null,
  actionHistory: {
    past: [],
    future: [],
  },
  roads: null,
  substationAreas: null,
  switchingStationAreas: null,
  batteryAreas: null,
  bboxAndOffset: null,
  setbacks: null,
  restrictedAreas: null,
  randomPsKey: null,
  designId: null,
  designName: null,
  offset: null,
  designInputs: null,
  kpis: null,
  kpisPowerStationsFilterState: { open: false, sortBy: 'name', sortDirection: 'asc' },
  sessionId: null,
  availableAreas: null,
  utmZone: null,
  userEmail: null,
  projectId: null,
  projectName: null,
  bbox: null,
  getRest: null,
  postRest: null,
  loadingUpdateOperation: false,
  loadingValidateOperation: false,
  loadingInputsData: false,
  validationErrors: {},
  validationErrorsStatus: null,
  topToolbarTab: 'structures',
  rightSideBarSelection: 'kpis',
  modalState: null,
  isGeneratingDesign: false,
};

export const useILCStore = create<CanvasStore & CanvasState>((set, get) => ({
  ...initialState,

  fetchLayout: async (
    designId: string,
    projectId: string,
    userEmail: string,
    sessionId: string,
    getRest: any,
    postRest: any
  ) => {
    const response = await fetchLayout(designId, getRest);

    const sessionUrl = `${getApiUrl()}/interactive-layout/retrieve/${designId}/${sessionId}`;

    const sessionResponse = await getRest(sessionUrl);

    const parametricInputsUrl = `${getApiUrl()}/interactive-layout/inputs/${designId}`;

    const inputsResponse = await getRest(parametricInputsUrl);

    const kpisUrl = `${getApiUrl()}/interactive-layout/kpis/${designId}`;

    const kpisResponse = await getRest(kpisUrl);

    const sessionInputsUrl = `${getApiUrl()}/interactive-layout/inputs/${sessionId}/${designId}`;

    set((state) => ({ ...state, loadingInputsData: true }));
    const sessionInputsResponse = await getRest(sessionInputsUrl);
    const sessionInputs: SessionInputs = sessionInputsResponse.data;
    set((state) => ({ ...state, loadingInputsData: false }));

    if (response && inputsResponse && inputsResponse.data) {
      const structures = response.layers.STRUCTURES;
      const powerStations = response.layers.POWER_STATIONS;
      const roads = response.layers.ROADS;
      let kpisData = kpisResponse.data;
      const stringDcPower = inputsResponse.data.designParametricInputs.stringDcPower;
      if (sessionResponse?.data && sessionResponse.data.length > 0) {
        kpisData = handleProcessKPIsFromSession(sessionResponse.data, structures, kpisData, stringDcPower);
        handleProcessActionsFromSession(sessionResponse.data, structures, powerStations, roads);
      }
      set((state) => ({
        ...state,
        availableAreas: response.layers.AVAILABLE_AREAS,
        restrictedAreas: {
          ...response.layers.USER_RESTRICTED_AREAS,
          ...response.layers.AUTO_GENERATED_RESTRICTED_AREAS,
        },
        designInputs: inputsResponse.data,
        kpis: kpisData,
        randomPsKey: Object.keys(powerStations)[0],
        userEmail: userEmail,
        setbacks: response.setbacks,
        roads: roads,
        sessionId: sessionId,
        powerStations: response.layers.POWER_STATIONS,
        substationAreas: response.layers.SUBSTATION_AREAS,
        switchingStationAreas: response.layers.SWITCHING_STATION_AREAS,
        batteryAreas: response.layers.BATTERY_AREAS,
        rectangleElements: { ...structures, ...powerStations },
        bbox: response.bbox,
        offset: { x: response.bboxAndOffset.offsetEasting, y: response.bboxAndOffset.offsetNorthing },
        designId: designId,
        designName: sessionInputs.designName,
        projectId: projectId,
        projectName: sessionInputs.projectName,
        bboxAndOffset: response.bboxAndOffset,
        getRest: getRest,
        postRest: postRest,
        utmZone: response.utmZone,
      }));
    }
  },
  updateDesignName: async (designName: string) => {
    const sessionId = get().sessionId;
    const postRest = get().postRest;

    if (sessionId && postRest) {
      const url = `${getApiUrl()}/interactive-layout/inputs/${sessionId}`;
      await postRest(url, { designName });
    }
  },
  setMode: (modeType) => {
    set((state) => ({
      ...state,
      modeState: { ...state.modeState, mode: { type: modeType, payload: null } },
    }));
  },
  setPointerType: (pointerType: PointerType) => {
    set((state) => ({
      ...state,
      pointerType: pointerType,
    }));
  },
  setMovementType: (movementType: MovementType) => {
    set((state) => ({
      ...state,
      movementType: movementType,
    }));
  },
  setSecondaryMode: (secondaryMode: SecondaryModeTypes) => {
    set((state) => ({
      ...state,
      modeState: { ...state.modeState, secondaryMode },
    }));
  },
  addStructureMode: (payload: AddStructurePayload) => {
    const currentAction = get().modeState.mode;
    const currentData = currentAction.type === PrimaryActionTypes.ADD_STRUCTURES ? currentAction.payload : {};
    set((state) => ({
      ...state,
      modeState: {
        ...state.modeState,
        mode: {
          type: PrimaryActionTypes.ADD_STRUCTURES,
          payload: {
            ...currentData,
            ...payload,
          },
        },
      },
    }));
  },
  addNodeToClosestMode: () => {
    set((state) => ({
      ...state,
      modeState: {
        mode: {
          type: PrimaryActionTypes.ADD_NODE_TO_CLOSEST,
          payload: null,
        },
        secondaryMode: initialModeState.secondaryMode,
      },
    }));
  },
  addNodeToOtherNodeMode: (
    payload: { connectedNode: { initialNodeId: string; roadId: string; initialNodeAreaId: string } } | null
  ) => {
    set((state) => ({
      ...state,
      modeState: {
        mode: {
          type: PrimaryActionTypes.ADD_NODE_TO_OTHER_NODE,
          payload,
        },
        secondaryMode: initialModeState.secondaryMode,
      },
    }));
  },
  selectReferenceElementMode(elementId, point) {
    const rectangleElements = get().rectangleElements;
    if (!rectangleElements) return;
    const selectedElement = elementId ? { ...rectangleElements[elementId], id: elementId } : null;

    set((state) => ({
      ...state,
      modeState: {
        ...state.modeState,
        secondaryMode: {
          type: SecondaryActionTypes.SELECT_REFERENCE,
          payload: { element: selectedElement, referencePoint: point },
        },
      },
    }));
  },
  selectReferenceElementForRoadsMode(payload) {
    const rectangleElements = get().rectangleElements;
    if (!rectangleElements) return;
    const selectedElement = payload ? { ...rectangleElements[payload], id: payload } : null;

    set((state) => ({
      ...state,
      modeState: {
        ...state.modeState,
        mode: {
          type: PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS,
          payload: selectedElement,
        },
      },
    }));
  },
  addLineBetweenNodesMode: (payload: { initialNodeId: string; initialNodeAreaId: string } | null) => {
    set((state) => ({
      ...state,
      modeState: {
        mode: {
          type: PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES,
          payload: payload,
        },
        secondaryMode: initialModeState.secondaryMode,
      },
    }));
  },
  resetModeByStep: () => {
    const currentAction = get().modeState.secondaryMode;
    const modalState = get().modalState;
    if (modalState !== null) {
      get().closeModal();
      return;
    }
    if (currentAction.type !== PrimaryActionTypes.NONE) {
      set((state) => ({
        ...state,
        modeState: {
          ...state.modeState,
          secondaryMode: initialModeState.secondaryMode,
        },
      }));
      return;
    }
    set((state) => ({
      ...state,
      modeState: initialModeState,
    }));
  },
  resetMode: () => {
    set((state) => ({
      ...state,
      modeState: initialModeState,
    }));
  },
  callValidation: async () => {
    track(RudderstackEvent.PV_EDITED_VALIDATE);

    const sessionId = get().sessionId;
    const simulationId = get().designId;
    const getRest = get().getRest;

    if (sessionId && simulationId) {
      const validationUrl = `${getApiUrl()}/interactive-layout/validate/${sessionId}/${simulationId}`;
      set((state) => ({ ...state, loadingValidateOperation: true }));
      const validationResponse = await getRest(validationUrl);
      set((state) => ({ ...state, loadingValidateOperation: false }));

      const { data, error } = validationResponse;
      if (error) {
        set((state) => ({ ...state, validationErrorsStatus: 'error' }));
        return null;
      }
      const validationErrors: Record<string, IlcValidationError[]> = data;
      const noValidationErrors = Object.keys(validationErrors).length === 0;
      set((state) => ({
        ...state,
        validationErrors: validationErrors,
        validationErrorsStatus: noValidationErrors ? 'success' : null,
      }));
      return Object.values(validationErrors).flat();
    }
    return null;
  },
  changePsOfStructures: (structureIds: string[], psId: string, isUndo?: boolean) => {
    const elements = get().rectangleElements;
    const kpis = get().kpis;
    const stringDcPower = get().designInputs?.designParametricInputs.stringDcPower ?? null;
    if (elements && kpis && stringDcPower) {
      const updatedStructures: { [x: string]: StructureElement } = {};
      const selectedPs = elements[psId];
      structureIds.forEach((elementId) => {
        const elementToEdit = elements[elementId];
        if (elementToEdit && elementToEdit.type === 'STRUCTURES') {
          updatedStructures[elementId] = { ...elementToEdit, psKey: psId, color: selectedPs.color };
        }
      });
      const newElements = { ...elements, ...updatedStructures };

      const originalElements = Object.keys(updatedStructures).map((key) => elements[key]) as StructureElement[];
      const updatedElements = Object.values(updatedStructures);

      if (!isUndo) {
        get().addToHistory({
          type: 'REGROUP',
          previousState: originalElements,
          newState: updatedElements,
        });
      }

      const kpisAfterDelete = recalculateKpisAfterStructureAction(kpis, originalElements, stringDcPower, 'remove');
      const kpisAfterAdd = recalculateKpisAfterStructureAction(kpisAfterDelete, updatedElements, stringDcPower, 'add');

      set((state) => ({
        ...state,
        rectangleElements: newElements,
        kpis: kpisAfterAdd,
      }));

      const sessionId = get().sessionId;
      const postRest = get().postRest;

      if (sessionId && postRest) {
        handleBackendUpdate(updatedStructures, 'REGROUP', sessionId, postRest, set, get);
      }
    }
  },
  addToHistory: (action: Action) => {
    set((state) => {
      const newActionHistory = {
        past: [...state.actionHistory.past, action],
        future: [],
      };
      return {
        ...state,
        actionHistory: newActionHistory,
      };
    });
  },
  moveElements: async (elementsIds: string[], offset: Point, isUndo?: boolean) => {
    const elements = get().rectangleElements;
    if (!elements) return;

    const updatedElementsObject = elementsIds.reduce(
      (acc: Record<string, PowerStationElement | StructureElement>, elementId) => {
        const elementToEdit = elements[elementId];
        if (elementToEdit) {
          const updatedElement = {
            ...elementToEdit,
            centroid: { x: elementToEdit.centroid.x + offset.x, y: elementToEdit.centroid.y + offset.y },
          };
          return { ...acc, [elementId]: updatedElement };
        }
        return acc;
      },
      {}
    );

    const newElements = { ...elements, ...updatedElementsObject };

    if (!isUndo) {
      get().addToHistory({
        type: 'MOVE',
        previousState: Object.keys(updatedElementsObject).map((key) => elements[key]),
        newState: Object.values(updatedElementsObject),
      });
    }

    set((state) => ({
      ...state,
      rectangleElements: newElements,
    }));

    const sessionId = get().sessionId;
    const postRest = get().postRest;

    if (sessionId && postRest) {
      if (Object.keys(updatedElementsObject).length > 0) {
        await handleBackendUpdate(updatedElementsObject, 'MOVE', sessionId, postRest, set, get);
      }
    }
  },
  moveMultipleElements: async (elements: SelectedElement[], isUndo?: boolean) => {
    const currentElements = get().rectangleElements;
    if (!currentElements) return;

    const updatedItems: { [x: string]: PowerStationElement | StructureElement } = {};

    elements.forEach((element) => {
      updatedItems[element.id] = element;
    });
    const newElements = { ...currentElements, ...updatedItems };

    if (!isUndo) {
      get().addToHistory({
        type: 'MOVE',
        previousState: Object.keys(updatedItems).map((key) => currentElements[key]),
        newState: Object.values(updatedItems),
      });
    }

    set((state) => ({
      ...state,
      rectangleElements: newElements,
    }));

    const sessionId = get().sessionId;
    const postRest = get().postRest;

    if (sessionId && postRest) {
      if (Object.keys(updatedItems).length > 0) {
        await handleBackendUpdate(updatedItems, 'MOVE', sessionId, postRest, set, get);
      }
    }
  },
  undo: () => {
    const actionHistory = get().actionHistory;
    if (actionHistory.past.length === 0) return;

    const previous = actionHistory.past[actionHistory.past.length - 1];
    const newPast = actionHistory.past.slice(0, -1);

    if (previous.type === 'MOVE') {
      const previousItems = previous.previousState.map((item) => ({ ...item, id: item.key }));
      get().moveMultipleElements(previousItems, true);
    }

    if (previous.type === 'ADD') {
      get().addStructureMode({ referenceStructureId: previous.previousState.referenceStructureId });
      if (previous.newState.structureId) {
        get().removeElement(previous.newState.structureId);
      }
    }

    if (previous.type === 'DELETE') {
      get().restoreDeletedStructures(previous.previousState);
    }

    if (previous.type === 'REGROUP') {
      get().changePsOfStructures(
        previous.previousState.map((element: any) => element.key),
        previous.previousState[0].psKey
      );
    }

    if (previous.type === 'ROADS_CHANGE') {
      get().updateRoads(previous.previousState, true);
    }
    set((state: any) => {
      return {
        ...state,
        actionHistory: {
          past: newPast,
          future: [previous, ...state.actionHistory.future],
        },
      };
    });
    get().resetMode();
  },

  redo: () => {
    const actionHistory = get().actionHistory;
    if (actionHistory.future.length === 0) return;
    const next = actionHistory.future[0];
    const newFuture = actionHistory.future.slice(1);
    if (next.type === 'MOVE') {
      const nextItems = next.newState.map((item) => ({ ...item, id: item.key }));
      get().moveMultipleElements(nextItems, true);
    }

    if (next.type === 'ADD') {
      get().addStructure(next.newState, true);
    }

    if (next.type === 'DELETE') {
      get().removeElements(next.newState, true);
    }

    if (next.type === 'REGROUP') {
      get().changePsOfStructures(
        next.newState.map((element: any) => element.key),
        next.newState[0].psKey,
        true
      );
    }
    if (next.type === 'ROADS_CHANGE') {
      get().updateRoads(next.newState, true);
    }
    set((state) => ({
      ...state,
      actionHistory: {
        past: [...state.actionHistory.past, next],
        future: newFuture,
      },
    }));
    get().resetMode();
  },
  updateRoads: (newRoads: RoadsLayer, isUndo?: boolean) => {
    if (!isUndo) {
      const roads = get().roads;
      if (!roads) return;

      get().addToHistory({
        type: 'ROADS_CHANGE',
        previousState: roads,
        newState: newRoads,
      });
    }
    set((state) => ({
      ...state,
      roads: newRoads,
    }));

    get().sendRoadsUpdate();
  },
  updateRoadsWithPreviousState: (previousRoads: RoadsLayer) => {
    const roads = get().roads;
    if (!roads) return;

    get().addToHistory({
      type: 'ROADS_CHANGE',
      previousState: previousRoads,
      newState: roads,
    });

    get().sendRoadsUpdate();
  },
  removeElements: async (selectedElementsPayload?: (StructureElement | PowerStationElement)[], isUndo?: boolean) => {
    const selectedMode = get().modeState.mode;
    const elements = get().rectangleElements;
    const sessionId = get().sessionId;
    const elementsFromSelection =
      selectedMode.type === PrimaryActionTypes.SELECT_RECTANGLES ? selectedMode.payload : null;
    const selectedElements = selectedElementsPayload ?? elementsFromSelection;
    const kpis = get().kpis;
    const stringDcPower = get().designInputs?.designParametricInputs.stringDcPower ?? null;
    if (selectedElements && elements && sessionId && kpis && stringDcPower) {
      const elementsIds = selectedElements
        .filter((element) => element.type !== 'POWER_STATIONS')
        .map((element) => element.key);

      const objectsToSend: any = {};
      const newObj = { ...elements };

      for (let i = 0; i < elementsIds.length; i++) {
        const elementId = elementsIds[i];
        objectsToSend[elementId] = JSON.parse(JSON.stringify(elements[elementId]));
        delete newObj[elementId];
      }

      if (!isUndo) {
        get().addToHistory({
          type: 'DELETE',
          previousState: Object.values(objectsToSend),
          newState: Object.values(objectsToSend),
        });
      }

      const updatedKpis = recalculateKpisAfterStructureAction(kpis, selectedElements, stringDcPower, 'remove');

      set((state) => ({
        ...state,
        rectangleElements: newObj,
        kpis: updatedKpis,
      }));

      const postRest = get().postRest;

      if (postRest) {
        await handleBackendUpdate(objectsToSend, 'DELETE', sessionId, postRest, set, get);
      }
    }
  },
  removeElement: (elementId: string) => {
    const elements = get().rectangleElements;
    const kpis = get().kpis;
    const stringDcPower = get().designInputs?.designParametricInputs.stringDcPower ?? null;

    const removeElementOfObjectById = (obj: any, id: string) => {
      const newObj = { ...obj };
      delete newObj[id];
      return newObj;
    };

    const sessionId = get().sessionId;

    if (!elements || !sessionId || !kpis || !stringDcPower) return;

    const selectedElement = JSON.parse(JSON.stringify(elements[elementId]));

    const updatedElements = removeElementOfObjectById(elements, elementId);

    const updatedKpis = recalculateKpisAfterStructureAction(kpis, [selectedElement], stringDcPower, 'remove');

    set((state) => ({
      ...state,
      rectangleElements: updatedElements,
      kpis: updatedKpis,
    }));

    const postRest = get().postRest;

    if (postRest) {
      handleBackendUpdate({ [elementId]: selectedElement }, 'DELETE', sessionId, postRest, set, get);
    }
  },

  onSelectElement: (elementId, isFromDragSelection) => {
    const newSelectedElementsPayload = getNewRectangleSelectedStateAction(get, elementId, isFromDragSelection);
    if (newSelectedElementsPayload === null) return;
    if (newSelectedElementsPayload.action === 'reset') {
      set((state) => ({
        ...state,
        modeState: initialModeState,
      }));
      return;
    }
    const onlyStructuresSelected = newSelectedElementsPayload.payload.every((item) => item.type === 'STRUCTURES');
    const onlyPSSelected = newSelectedElementsPayload.payload.every((item) => item.type === 'POWER_STATIONS');
    set((state) => ({
      ...state,
      modeState: {
        ...state.modeState,
        mode: {
          type: PrimaryActionTypes.SELECT_RECTANGLES,
          payload: newSelectedElementsPayload.payload,
        },
      },
      topToolbarTab: onlyStructuresSelected ? 'structures' : onlyPSSelected ? 'power-stations' : 'all',
    }));
  },
  onSelectMultipleRectanglesByIndexes: (elementsIndex: number[]) => {
    const rectangleElements = get().rectangleElements;
    if (rectangleElements) {
      const rectangleElementsKeys = Object.keys(rectangleElements);
      for (let i = 0; i < elementsIndex.length; i++) {
        get().onSelectElement(rectangleElementsKeys[elementsIndex[i]], true);
      }
    }
  },
  onSelectMultipleRectanglesById: (elementsIds: string[]) => {
    elementsIds.forEach((id) => {
      get().onSelectElement(id, false);
    });
  },
  addStructure: (args: AddStructureData, isUndo?: boolean) => {
    const { coordinates, selectedPs, selectedStructureOption, structureId } = args;
    const designInputs = get().designInputs;
    const sessionId = get().sessionId;
    const postRest = get().postRest;
    const rectangleElements = get().rectangleElements;
    const availableAreas = get().availableAreas;
    const kpis = get().kpis;
    const stringDcPower = designInputs?.designParametricInputs.stringDcPower ?? null;
    if (designInputs && sessionId && postRest && rectangleElements && availableAreas && kpis && stringDcPower) {
      const areaKey = Object.keys(availableAreas).find((key) =>
        pointInPolygon(coordinates, availableAreas[key].exteriorRing)
      );

      if (!areaKey) return;

      const angle = designInputs.areaParametricInputs[areaKey].structureAngle;
      const connectedPS = rectangleElements[selectedPs];
      const newStructure: StructureElement = {
        areaKey: areaKey,
        angle: angle,
        centroid: { x: coordinates.x, y: coordinates.y },
        color: connectedPS.color,
        key: structureId ? structureId : uuidv4(),
        psKey: selectedPs,
        length: selectedStructureOption.length,
        width: selectedStructureOption.width,
        strings: selectedStructureOption.strings,
        type: 'STRUCTURES',
      };

      const updatedKpis = recalculateKpisAfterStructureAction(kpis, [newStructure], stringDcPower, 'add');

      set((state) => {
        return {
          ...state,
          rectangleElements: { ...state.rectangleElements, [newStructure.key]: newStructure },
          kpis: updatedKpis,
          modeState: {
            ...state.modeState,
            mode: {
              ...state.modeState.mode,
              type: PrimaryActionTypes.ADD_STRUCTURES,
              payload: {
                ...state.modeState.mode.payload,
                referenceStructureId: undefined,
              },
            },
          },
        };
      });

      handleBackendUpdate({ [newStructure.key]: newStructure }, 'CREATE', sessionId, postRest, set, get);

      if (!isUndo) {
        get().addToHistory({
          type: 'ADD',
          previousState: args,
          newState: { ...args, structureId: newStructure.key },
        });
      }
    }
  },
  restoreDeletedStructures: async (structures: StructureElement[]) => {
    const sessionId = get().sessionId;
    const postRest = get().postRest;
    const kpis = get().kpis;
    const stringDcPower = get().designInputs?.designParametricInputs.stringDcPower ?? null;
    if (sessionId && postRest && kpis && stringDcPower) {
      const restoredStructureState = structures.reduce((acc, structure) => {
        return { ...acc, [structure.key]: structure };
      }, {});

      const updatedKpis = recalculateKpisAfterStructureAction(kpis, structures, stringDcPower, 'add');

      set((state) => {
        return {
          ...state,
          rectangleElements: { ...state.rectangleElements, ...restoredStructureState },
          kpis: updatedKpis,
        };
      });

      await handleBackendUpdate(restoredStructureState, 'CREATE', sessionId, postRest, set, get);
      get().callValidation();
    }
  },
  updateNodeCoordinate: (roadId: string, nodeId: string, newCoordinates: Point) => {
    const roads = get().roads;

    const roadsClone = JSON.parse(JSON.stringify(roads));
    roadsClone[roadId].nodes[nodeId] = newCoordinates;

    set((state) => ({
      ...state,
      roads: roadsClone,
    }));
  },
  addNodeAndEdgeToClosestNode: (newNode: Point, roadId: string) => {
    const roads = get().roads;
    if (roads) {
      const newRoads = JSON.parse(JSON.stringify(roads));
      const newNodeId = `${newNode.x}_${newNode.y}`;
      const newRoad = newRoads[roadId];
      const newNodes = { ...newRoad.nodes, [newNodeId]: newNode };
      const closestNode = findClosestNodeToCoordinates(newRoad.nodes, newNode);
      if (!closestNode) return;
      const newEdges = [...newRoad.edges, { source: closestNode, target: newNodeId, type: 'ILC_ROAD' }];
      newRoad.nodes = newNodes;
      newRoad.edges = newEdges;
      newRoads[roadId] = newRoad;
      get().updateRoads(newRoads);
    }
  },
  addNodeAndEdgeToOtherNode: (newNode: Point, connectedNodeId: string, roadId: string): string | undefined => {
    const roads = get().roads;
    if (roads) {
      const newRoads = JSON.parse(JSON.stringify(roads));
      const newNodeId = `${newNode.x}_${newNode.y}`;
      const newRoad = newRoads[roadId];
      const newNodes = { ...newRoad.nodes, [newNodeId]: newNode };
      const newEdges = [...newRoad.edges, { source: connectedNodeId, target: newNodeId, type: 'ILC_ROAD' }];
      newRoad.nodes = newNodes;
      newRoad.edges = newEdges;
      newRoads[roadId] = newRoad;
      get().updateRoads(newRoads);
      return newNodeId;
    }
  },
  addEdgeBetweenTwoNodes: (source: string, target: string, roadId: string) => {
    const roads = get().roads;
    if (roads) {
      const newRoads = JSON.parse(JSON.stringify(roads));
      const newRoad = newRoads[roadId];
      const newEdges = [...newRoad.edges, { source: source, target: target, type: 'ILC_ROAD' }];
      newRoad.edges = newEdges;
      newRoads[roadId] = newRoad;
      get().updateRoads(newRoads);
    }
  },
  addNodeBetweenTwoNodes: (newNode: Point, roadId: string, source: string, target: string) => {
    const roads = get().roads;
    if (roads) {
      const newRoads = JSON.parse(JSON.stringify(roads));
      const newRoad = newRoads[roadId];
      const newNodeId = uuidv4();
      const newNodes = { ...newRoad.nodes, [newNodeId]: newNode };
      const edges = newRoad.edges;
      const findIndexOfEdge = edges.findIndex(
        (edge: any) =>
          (edge.source === source && edge.target === target) || (edge.source === target && edge.target === source)
      );

      edges.splice(findIndexOfEdge, 1);

      const newEdges = [
        ...edges,
        { source: source, target: newNodeId, type: 'ILC_ROAD' },
        { source: newNodeId, target: target, type: 'ILC_ROAD' },
      ];

      newRoad.nodes = newNodes;
      newRoad.edges = newEdges;
      newRoads[roadId] = newRoad;

      get().updateRoads(newRoads);
    }
  },
  detectIfClickIsInsideAvailableArea: (coordinates: Point) => {
    const availableAreas = get().availableAreas;
    if (availableAreas) {
      for (const areaId in availableAreas) {
        const area = availableAreas[areaId];
        if (pointInPolygon(coordinates, area.exteriorRing)) {
          return areaId;
        }
      }
    }
    return null;
  },
  getRoadOfAvailableArea: (areaId: string) => {
    const roads = get().roads;
    if (roads) {
      for (const roadId in roads) {
        const road = roads[roadId];
        if (road.areaKey === areaId) {
          return roadId;
        }
      }
    }
  },
  deleteNodeAndEdges: (nodeId: string, roadId: string) => {
    const roads = get().roads;
    if (roads) {
      const newRoads = JSON.parse(JSON.stringify(roads));
      const newRoad = newRoads[roadId];
      const newNodes = { ...newRoad.nodes };
      delete newNodes[nodeId];
      const edgesConnectedToDeletedNode = newRoad.edges.filter(
        (edge: any) => edge.source === nodeId || edge.target === nodeId
      );
      if (edgesConnectedToDeletedNode.length === 2) {
        const node1 =
          edgesConnectedToDeletedNode[0].source === nodeId
            ? edgesConnectedToDeletedNode[0].target
            : edgesConnectedToDeletedNode[0].source;
        const node2 =
          edgesConnectedToDeletedNode[1].source === nodeId
            ? edgesConnectedToDeletedNode[1].target
            : edgesConnectedToDeletedNode[1].source;

        const newEdges = newRoad.edges.filter((edge: any) => edge.source !== nodeId && edge.target !== nodeId);
        const newEdge = { source: node1, target: node2, type: 'ILC_ROAD' };
        newRoad.nodes = newNodes;
        newRoad.edges = [...newEdges, newEdge];
        newRoads[roadId] = newRoad;
        get().updateRoads(newRoads);
      } else {
        const newEdges = newRoad.edges.filter((edge: any) => edge.source !== nodeId && edge.target !== nodeId);
        newRoad.nodes = newNodes;
        newRoad.edges = newEdges;
        newRoads[roadId] = newRoad;
        get().updateRoads(newRoads);
      }
    }
  },
  deleteNodeAndConnectingEdges: (nodeId: string, roadId: string) => {
    const roads = get().roads;
    if (roads) {
      const newRoads = JSON.parse(JSON.stringify(roads));
      const newRoad = newRoads[roadId];
      const newNodes = { ...newRoad.nodes };
      delete newNodes[nodeId];
      const newEdges = newRoad.edges.filter((edge: any) => edge.source !== nodeId && edge.target !== nodeId);
      newRoad.nodes = newNodes;
      newRoad.edges = newEdges;
      newRoads[roadId] = newRoad;
      get().updateRoads(newRoads);
    }
  },
  sendRoadsUpdate: () => {
    const roads = get().roads;
    const sessionId = get().sessionId;
    const postRest = get().postRest;
    if (roads && sessionId && postRest) handleBackendUpdate(roads, 'MOVE', sessionId, postRest, set, get);
  },
  generateIlcDesign: async (): Promise<GenerateDesignResponse> => {
    const designId = get().designId;
    const sessionId = get().sessionId;
    const bboxAndOffset = get().bboxAndOffset;
    const projectId = get().projectId;
    const userEmail = get().userEmail;

    const postRest = get().postRest;

    if (designId && sessionId && bboxAndOffset && projectId && userEmail && postRest) {
      set((state) => ({ ...state, isGeneratingDesign: true }));
      const result = await handleGenerateDesign(designId, projectId, bboxAndOffset, sessionId, userEmail, postRest);
      set((state) => ({ ...state, isGeneratingDesign: false }));
      return result;
    }
    return { error: 'fallback-generation-error', data: null };
  },
  reset: () => {
    set(() => initialState);
  },
  setTopToolbarTab: (tab: TopToolbarTab) => {
    set((state) => ({
      ...state,
      topToolbarTab: tab,
    }));
  },
  setRightSideBarSelection: (selection: SidebarOptions) => {
    set((state) => ({
      ...state,
      rightSideBarSelection: selection,
    }));
  },
  alignSelectedStructureWithSetbacks: (selection: 'closest' | 'parallel') => {
    set((state) => ({
      ...state,
      modeState: {
        ...state.modeState,
        secondaryMode: {
          type: SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_SETBACKS,
          payload: selection,
        },
      },
    }));
  },
  alignUniformly: () => {
    set((state) => ({
      ...state,
      modeState: {
        ...state.modeState,
        secondaryMode: {
          type: SecondaryActionTypes.ALIGN_UNIFORMLY,
        },
      },
    }));
  },
  openModal: (modalState: ModalState) => {
    set((state) => ({
      ...state,
      modalState: modalState,
    }));
  },
  closeModal: () => {
    set((state) => ({
      ...state,
      modalState: null,
    }));
  },
  getRectangleElement: (elementId: string) => {
    const elements = get().rectangleElements;
    return elements ? { ...elements[elementId], id: elementId } : null;
  },
  updateKpisPowerStationsFilterState: (
    open: boolean,
    sorting?: { sortBy: KPISortByValue; sortDirection: SortDirection }
  ) => {
    set((state) => ({
      ...state,
      kpisPowerStationsFilterState: {
        open,
        sortBy: sorting ? sorting.sortBy : state.kpisPowerStationsFilterState.sortBy,
        sortDirection: sorting ? sorting.sortDirection : state.kpisPowerStationsFilterState.sortDirection,
      },
    }));
  },
}));

// State
export const useProjectName = () => useILCStore((state) => state.projectName);
export const useDesignName = () => useILCStore((state) => state.designName);
export const useBbox = () => useILCStore((state) => state.bbox);
export const useReset = () => useILCStore((state) => state.reset);
export const useOffset = () => useILCStore((state) => state.offset);
export const useActionHistory = () => useILCStore((state) => state.actionHistory);

export const useFetchLayout = () => useILCStore((state) => state.fetchLayout);

export const useAddStructure = () => useILCStore((state) => state.addStructure);

export const useAvailableAreas = () => useILCStore((state) => state.availableAreas);
export const useRectangleElements = () => useILCStore((state) => state.rectangleElements);
export const useValidationErrors = () => useILCStore((state) => state.validationErrors);
export const useValidationErrorStatus = () => useILCStore((state) => state.validationErrorsStatus);
export const useGetRectangleElement = () => useILCStore((state) => state.getRectangleElement);

export const useUpdateNodeCoordinate = () => useILCStore((state) => state.updateNodeCoordinate);

// LOADING
export const useLoadingUpdateOperation = () => useILCStore((state) => state.loadingUpdateOperation);
export const useIsGeneratingDesign = () => useILCStore((state) => state.isGeneratingDesign);
export const useLoadingValidateOperation = () => useILCStore((state) => state.loadingValidateOperation);
export const useLoadingInputsData = () => useILCStore((state) => state.loadingInputsData);

// ELEMENTS
export const useRestrictedAreas = () => useILCStore((state) => state.restrictedAreas);
export const useSubstationAreas = () => useILCStore((state) => state.substationAreas);
export const useSwitchingStationsAreas = () => useILCStore((state) => state.switchingStationAreas);
export const useBatteryAreas = () => useILCStore((state) => state.batteryAreas);

// ACTIONS
export const useMoveElements = () => useILCStore((state) => state.moveElements);
export const useMoveMultipleElements = () => useILCStore((state) => state.moveMultipleElements);
export const useRemoveElements = () => useILCStore((state) => state.removeElements);

// GET
export const useDetectIfClickIsInsideAvailableArea = () =>
  useILCStore((state) => state.detectIfClickIsInsideAvailableArea);
export const useGenerateIlcDesign = () => useILCStore((state) => state.generateIlcDesign);
export const useOnSelectMultipleRectanglesByIndexes = () =>
  useILCStore((state) => state.onSelectMultipleRectanglesByIndexes);
export const useOnSelectMultipleRectanglesById = () => useILCStore((state) => state.onSelectMultipleRectanglesById);

// ROADS
// ACTIONS
export const useAddNodeToClosestNode = () => useILCStore((state) => state.addNodeAndEdgeToClosestNode);
export const useDeleteNodeAndEdges = () => useILCStore((state) => state.deleteNodeAndEdges);
export const useDeleteNodeAndConnectingEdges = () => useILCStore((state) => state.deleteNodeAndConnectingEdges);
export const useAddNodeAndEdgeToOtherNode = () => useILCStore((state) => state.addNodeAndEdgeToOtherNode);
export const useAddNodeBetweenTwoNodes = () => useILCStore((state) => state.addNodeBetweenTwoNodes);
export const useChangePsOfStructures = () => useILCStore((state) => state.changePsOfStructures);
export const useAlignSelectedStructureWithSetbacks = () =>
  useILCStore((state) => state.alignSelectedStructureWithSetbacks);
export const useAlignUniformly = () => useILCStore((state) => state.alignUniformly);

// INPUT ACTIONS
export const useUpdateDesignName = () => useILCStore((state) => state.updateDesignName);

// GET
export const useRoads = () => useILCStore((state) => state.roads);
export const useGetRoadOfAvailableArea = () => useILCStore((state) => state.getRoadOfAvailableArea);
export const useAddEdgeBetweenNodes = () => useILCStore((state) => state.addEdgeBetweenTwoNodes);
export const useSetbacks = () => useILCStore((state) => state.setbacks);
export const useDesignInputs = () => useILCStore((state) => state.designInputs);
export const useKpis = () => useILCStore((state) => state.kpis);
export const useUtmZone = () => useILCStore((state) => state.utmZone);
export const useCallValidation = () => useILCStore((state) => state.callValidation);

// MODES
export const useModes = () => useILCStore((state) => state.modeState);
export const useAddStructuresMode = () => useILCStore((state) => state.addStructureMode);
export const useSetMode = () => useILCStore((state) => state.setMode);
export const useResetMode = () => useILCStore((state) => state.resetMode);
export const useResetModeByStep = () => useILCStore((state) => state.resetModeByStep);
export const useOnSelectElement = () => useILCStore((state) => state.onSelectElement);
export const useAddNodeToClosestMode = () => useILCStore((state) => state.addNodeToClosestMode);
export const useAddLineBetweenNodesMode = () => useILCStore((state) => state.addLineBetweenNodesMode);
export const useAddNodeToOtherNodeMode = () => useILCStore((state) => state.addNodeToOtherNodeMode);
export const useSetSecondaryMode = () => useILCStore((state) => state.setSecondaryMode);
export const useSelectReferenceElementMode = () => useILCStore((state) => state.selectReferenceElementMode);
export const useSelectReferenceElementForRoadsMode = () =>
  useILCStore((state) => state.selectReferenceElementForRoadsMode);
export const useUndo = () => useILCStore((state) => state.undo);
export const useRedo = () => useILCStore((state) => state.redo);
export const useUpdateWithPreviousRoads = () => useILCStore((state) => state.updateRoadsWithPreviousState);
export const usePointerType = () => useILCStore((state) => state.pointerType);
export const useMovementType = () => useILCStore((state) => state.movementType);
export const useSetPointerType = () => useILCStore((state) => state.setPointerType);
export const useSetMovementType = () => useILCStore((state) => state.setMovementType);
export const useTopToolbarTab = () => useILCStore((state) => state.topToolbarTab);
export const useSetTopToolbarTab = () => useILCStore((state) => state.setTopToolbarTab);
export const useRightSideBarSelection = () => useILCStore((state) => state.rightSideBarSelection);
export const useSetRightSideBarSelection = () => useILCStore((state) => state.setRightSideBarSelection);

// MODALS
export const useOpenModal = () => useILCStore((state) => state.openModal);
export const useCloseModal = () => useILCStore((state) => state.closeModal);
export const useModalState = () => useILCStore((state) => state.modalState);

// FILTERS
export const useKpisPowerStationsFilterState = () => useILCStore((state) => state.kpisPowerStationsFilterState);
export const useUpdateKpisPowerStationsFilterState = () =>
  useILCStore((state) => state.updateKpisPowerStationsFilterState);
