import React from 'react';
import * as S from './right-side-bar.styled';
import { Button } from '@ratedpower/components';
import { KpisSvg, ClipboardSvg } from 'assets/icons';
import { ValidationErrorsDisplay } from '../validation-errors-display';
import { useRightSideBarSelection, useSetRightSideBarSelection, useValidationErrors } from '../../ilc-store';
import { CameraControls } from '@react-three/drei';
import { KpisDisplay } from '../kpis-display';

interface IRightSideBar {
  controls: typeof CameraControls | undefined;
}

export function RightSideBar({ controls }: IRightSideBar) {
  const validationErrors = useValidationErrors();
  const validationErrorsValues = Object.values(validationErrors);
  const flatValidationErrorsValues = validationErrorsValues.flat();
  const rightSideBarSelection = useRightSideBarSelection();
  const setRightSideBarSelection = useSetRightSideBarSelection();

  return (
    <S.Wrapper>
      <Button
        Icon={KpisSvg}
        variant="ghost"
        size="l"
        selected={rightSideBarSelection === 'kpis'}
        onClick={() => setRightSideBarSelection('kpis')}
        id="layout-editor-results"
      />
      <Button
        Icon={ClipboardSvg}
        variant="ghost"
        size="l"
        selected={rightSideBarSelection === 'validations'}
        onClick={() => setRightSideBarSelection('validations')}
        id="layout-editor-validations"
      />
      {rightSideBarSelection === 'kpis' && <KpisDisplay controls={controls} />}
      {rightSideBarSelection === 'validations' && (
        <ValidationErrorsDisplay validationErrors={flatValidationErrorsValues} controls={controls} />
      )}
    </S.Wrapper>
  );
}
