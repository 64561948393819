import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { isDateBetweenDates } from 'utils/date';

export const usePlanIsExpired = (): boolean => {
  const { subscriptionPlan } = useSelector((state: RootState) => state.currentUser);

  if (subscriptionPlan) {
    const nowDate = new Date();

    const { expirationDate, startDate, hasFuturePlan } = subscriptionPlan;
    const licensePeriodValid = isDateBetweenDates(nowDate, new Date(startDate), new Date(expirationDate));

    return !licensePeriodValid && !hasFuturePlan;
  }

  return false;
};
