import { IOption } from '@ratedpower/components';
import { useDefaultSkus } from './use-default-skus';
import { SKUS } from 'types/support.types';
import { IDefaultSkusPerPlan, IStockKeepingUnitDTO, NewPlan } from 'types/subscription-plan';

export interface IPlanCardOption extends IOption {
  bold?: boolean;
}

export const usePvDesignPlans = () => {
  const { allSkus, currentPlan, otherPlansSkus, loading, error } = useDefaultSkus(true);

  const getOfferedPlans = (): IDefaultSkusPerPlan[] => {
    const newPlansArray = Object.keys(NewPlan);
    const currentPlanIndex = newPlansArray.indexOf(String(currentPlan?.plan));

    if (currentPlan?.isLegacy || currentPlanIndex === -1) return otherPlansSkus;

    const oferredPlansFromCurrentPlan = newPlansArray.slice(currentPlanIndex + 1);
    return otherPlansSkus?.filter((otherPlan) => oferredPlansFromCurrentPlan.includes(otherPlan.plan));
  };

  const fromSkusToOptions = (notTheCurrentPlan: boolean, planSkus: string[]): IPlanCardOption[] => [
    {
      label: 'company:projects-purchased',
      value: notTheCurrentPlan || currentPlan?.unlimitedProjects ? 'unlimited' : currentPlan?.projectsPurchased,
      bold: true,
    },
    {
      label: 'company:number-of-users',
      value: notTheCurrentPlan || !currentPlan?.userLimit ? 'unlimited' : currentPlan?.userLimit,
      bold: true,
    },
    {
      label: `company:skus.${SKUS.CORE_PV}`,
      value: true,
    },
    ...(allSkus?.map((sku) => ({ label: `company:skus.${sku.name}`, value: planSkus?.includes(sku.name) })) || []),
  ];
  const planSkusStrings = currentPlan?.stockKeepingUnits?.map((sku: IStockKeepingUnitDTO) => sku.name) || [];
  const currentPlanEntries: IPlanCardOption[] = fromSkusToOptions(false, planSkusStrings);
  currentPlanEntries.push({
    label: 'company:starting-date',
    value: currentPlan?.startDate,
    bold: true,
  });
  currentPlanEntries.push({
    label: 'expiration-date',
    value: currentPlan?.expirationDate,
    bold: true,
  });

  const oferredPlans = getOfferedPlans();

  return {
    error,
    loading,
    currentPlanEntries,
    currentPlan: currentPlan,
    oferredPlans,
    fromSkusToOptions,
  };
};
