import { ButtonContainer, Typography } from '@ratedpower/components';
import styled from 'styled-components';

export const PageContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

export const TopBarContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.4rem;

  ${ButtonContainer} {
    margin-right: auto;
    margin-left: 1rem;
  }
`;

export const TopbarSections = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const TableContainer = styled.div`
  padding: 0 2rem;
  overflow-y: auto;
  height: calc(100% - 147px);
`;

export const CenteredText = styled(Typography)`
  text-align: center;
`;

export const ProjectCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180px;

  button {
    justify-content: flex-start;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 70%;

  input,
  .suffix {
    padding: 1px 2px !important;
  }

  .input-distance-to-margin {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    width: 100%;

    .input-container {
      width: 100%;
    }
  }
`;
