import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { GET_SKUS_OF_SPECIFIC_COMPANY } from 'services/support-gql';
import { ISubscriptionPlanCard, Plan } from 'types/subscription-plan';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';

export const useGetCompanyCurrentPlan = (): ISubscriptionPlanCard => {
  const { subscriptionPlan } = useSelector((state: RootState) => state.currentUser);
  const companyId = useQuerySearchParams().get('companyId');

  const { data } = useQuery(GET_SKUS_OF_SPECIFIC_COMPANY, { variables: { id: companyId }, skip: !companyId });

  if (!companyId && subscriptionPlan) {
    return {
      projectsPurchased: subscriptionPlan.projectsPurchased,
      userLimit: subscriptionPlan.userLimit,
      unlimitedProjects: subscriptionPlan.unlimitedProjects,
      startDate: subscriptionPlan.startDate,
      expirationDate: subscriptionPlan.expirationDate,
      plan: subscriptionPlan.plan as Plan,
      isLegacy: subscriptionPlan.isLegacy,
      stockKeepingUnits: subscriptionPlan.stockKeepingUnits,
      unlimitedUsers: subscriptionPlan.unlimitedUsers,
    };
  }
  const otherCompanyPlan = data?.result.currentPlan;
  return {
    projectsPurchased: otherCompanyPlan?.projectsPurchased,
    userLimit: otherCompanyPlan?.userLimit,
    unlimitedProjects: otherCompanyPlan?.unlimitedProjects,
    startDate: otherCompanyPlan?.startDate,
    expirationDate: otherCompanyPlan?.expirationDate,
    plan: otherCompanyPlan?.plan,
    isLegacy: otherCompanyPlan?.isLegacy,
    stockKeepingUnits: otherCompanyPlan?.stockKeepingUnits,
    unlimitedUsers: otherCompanyPlan?.unlimitedUsers,
  };
};
