import {
  LayoutEditorTag,
  SimulationPrimaryCharacteristicsTag,
  SimulationSecondaryCharacteristicsTag,
} from 'types/design';

export interface HasTagInfo {
  primaryTag: SimulationPrimaryCharacteristicsTag;
  secondaryTag: SimulationSecondaryCharacteristicsTag;
  [key: string]: any;
}

export const getPrimaryTagText = (design: HasTagInfo): string => {
  switch (design.primaryTag) {
    case SimulationPrimaryCharacteristicsTag.PV:
      return 'design-process:tags.primary.pv-tag';
    case SimulationPrimaryCharacteristicsTag.BESS:
      return 'design-process:tags.primary.bess-tag';
    case SimulationPrimaryCharacteristicsTag.PV_BATCH:
      return 'design-process:tags.primary.pv-batch-tag';
    case SimulationPrimaryCharacteristicsTag.PV_BESS:
      return 'design-process:tags.primary.pv-bess-tag';
    case SimulationPrimaryCharacteristicsTag.PV_BESS_BATCH:
      return 'design-process:tags.primary.pv-bess-batch-tag';
  }
};

export const getSecondaryTagText = (design: HasTagInfo): string => {
  switch (design.secondaryTag) {
    case SimulationSecondaryCharacteristicsTag.EDITED:
      return 'design-process:tags.secondary.edited-tag';
    case SimulationSecondaryCharacteristicsTag.NO_EDITED:
      return '';
  }
};

export const selectedPrimaryTagColor = (design: HasTagInfo, disabled?: boolean): string => {
  if (disabled) {
    return 'gray';
  }
  switch (design.primaryTag) {
    case SimulationPrimaryCharacteristicsTag.PV:
      return 'brand';
    case SimulationPrimaryCharacteristicsTag.BESS:
      return 'yellow';
    case SimulationPrimaryCharacteristicsTag.PV_BATCH:
      return 'brand';
    case SimulationPrimaryCharacteristicsTag.PV_BESS:
      return 'pink';
    case SimulationPrimaryCharacteristicsTag.PV_BESS_BATCH:
      return 'pink';
  }
};

export const selectedSecondaryTagColor = (design: HasTagInfo, disabled?: boolean): string => {
  if (disabled) {
    return 'gray';
  }
  switch (design.secondaryTag) {
    case SimulationSecondaryCharacteristicsTag.EDITED:
      return 'teal';
    case SimulationSecondaryCharacteristicsTag.NO_EDITED:
      return '';
  }
};

export const getDesignIsILC = (secondaryTag: SimulationSecondaryCharacteristicsTag): boolean => {
  return secondaryTag === SimulationSecondaryCharacteristicsTag.EDITED;
};

export const getIsPVPlant = (primaryTag: SimulationPrimaryCharacteristicsTag): boolean => {
  switch (primaryTag) {
    case SimulationPrimaryCharacteristicsTag.PV:
    case SimulationPrimaryCharacteristicsTag.PV_BATCH:
    case SimulationPrimaryCharacteristicsTag.PV_BESS:
    case SimulationPrimaryCharacteristicsTag.PV_BESS_BATCH: {
      return true;
    }
    case SimulationPrimaryCharacteristicsTag.BESS: {
      return false;
    }
  }
};

export const getAccessToIlcIsDisabled = (design: HasTagInfo): boolean => {
  if (getIsPVPlant(design.primaryTag)) {
    return design.layoutEditorTag !== LayoutEditorTag.AVAILABLE;
  }
  return true;
};

export const getAccessToIlcMessage = (layoutEditorTag: LayoutEditorTag): string => {
  switch (layoutEditorTag) {
    case LayoutEditorTag.AVAILABLE:
      return 'design:open-in-ilc';
    case LayoutEditorTag.REQUIRED_FILES:
      return 'design:clone-to-edit';
    case LayoutEditorTag.STAND_ALONE_BESS_DESIGN:
      return 'design:stand-alone-bess-cannot-edit';
    case LayoutEditorTag.AAS_SHARING_LV_DEFINED:
      return 'design:aa-sharing-lv-defined';
  }
};
