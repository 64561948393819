/* eslint-disable max-len */
import React from 'react';
import * as S from './sso-sku-notification.styled';
import { LinkText, Notification, Typography } from '@ratedpower/components';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { openExternalLink } from 'utils/navigation';

interface IProps {
  planIncludesSSO: boolean;
}

export const SSOSkuNotification: React.FC<IProps> = ({ planIncludesSSO }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Notification
      status="informative"
      title={t('support:skus.sso-notification-title')}
      heightFitContent
      width="fit-content"
      hideCloseButton
      description={
        <S.NotificationDescription>
          <Typography color={theme.v2.text.secondary.default} size="s">
            {!planIncludesSSO ? (
              <Trans i18nKey="sso-notification-description">
                SSO can only be activated by the DevSecOps through this
              </Trans>
            ) : (
              <Trans i18nKey="sso-included-notification-description">
                This plan includes Single sign-on. SSO can only be activated by the DevSecOps through this
              </Trans>
            )}
          </Typography>
          <LinkText
            size="s"
            color={theme.v2.text.accent.default}
            textUnderlined
            onClick={() =>
              openExternalLink(
                'https://forms.office.com/pages/responsepage.aspx?id=EQ_5YXaNBUubCZCvfQcyigLdycJlNq1GqdCwlPphlWBUNjJOUU42T1dYR05RVVFYTEJWRlpPUUQ5My4u&route=shorturl'
              )
            }
          >
            form.
          </LinkText>
        </S.NotificationDescription>
      }
    />
  );
};
