import React, { memo } from 'react';
import { InputText, Typography } from '@ratedpower/components';
import { useDebouncedInput } from 'utils/hooks/use-debounced-input';

const EditableInputTextCell: React.FC<{ info: any; error?: boolean }> = ({ info, error }) => {
  const {
    getValue,
    row: { index, original },
    column: { id },
    table,
  } = info;
  const initialValue = getValue();

  const handleChangeRowValue = (value: string) => {
    table.options.meta.updateData(index, id, value);
  };

  const { onChange, value } = useDebouncedInput(800, handleChangeRowValue, initialValue);

  return !original.editable ? (
    <Typography>{getValue()}</Typography>
  ) : (
    <InputText type="text" value={value} warning={error} onChange={onChange} />
  );
};

export default memo(EditableInputTextCell);
