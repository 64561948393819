import React from 'react';
import { LV_CONFIG } from './designs';
import { RemoteImage } from '../hooks/use-remote-image';
import { Converter, IConvertToShowOptions } from 'types/preferences.types';
import { formatThousandsNumbers, fourDigitNotationForLargeNumbers } from '../numbers';
import { CollapsibleCellTable } from '@ratedpower/components';
import { CalculationResult } from 'pages/design-process/outputs/financial/types';
import { t } from 'i18next';
import { SIMULATION_TYPE } from 'types/design';

const DataDisplay = (value: any, unit: string, suffix = '') => {
  return (
    <>
      {value}
      <span className="unit">{` ${unit}${suffix}`}</span>
    </>
  );
};

const DataDisplayOrEmpty = (value: any, unit: string, suffix = '') => {
  const displayValue = !!value && !isNaN(value) ? value : '-';

  return (
    <>
      {displayValue}
      <span className="unit">{` ${unit}${suffix}`}</span>
    </>
  );
};

export interface IAttribute {
  key: string | string[];
  label: string;
  sortKeys?: string[];
  className?: string;
  collapsible?: boolean;
  converter?: {
    name: Converter;
    options?: IConvertToShowOptions;
  };
  render: (data: any, currency?: string, unit?: string) => any;
}

export interface IDesignAttributes {
  simulationType: IAttribute;
  notes: IAttribute;
  satellitePicture: {
    high: IAttribute;
    thumbnail: IAttribute;
    low: IAttribute;
  };
  power: {
    ratedPower: IAttribute;
    peakPower: IAttribute;
    ratioDcAc: IAttribute;
    dcAcRatioAtPoi: IAttribute;
    activePowerAtPoi: IAttribute;
  };
  energy: {
    specificProduction: IAttribute;
    eneryYield: IAttribute;
    plantPR: IAttribute;
    monthlyProduction: IAttribute;
  };
  meteo: {
    resolution: IAttribute;
    source: IAttribute;
    yearGhi: IAttribute;
    yearBhi: IAttribute;
    yearDhi: IAttribute;
    avgTemp: IAttribute;
    maxTemp: IAttribute;
    minTemp: IAttribute;
  };
  albedo: {
    source: IAttribute;
    resolution: IAttribute;
    avgValuePerMonth: IAttribute;
    totalSamples: IAttribute;
    maxSamplesPerMonth: IAttribute;
    minSamplesPerMonth: IAttribute;
  };
  horizon: {
    source: IAttribute;
    heightAvg: IAttribute;
    heightMax: IAttribute;
  };
  equipment: {
    moduleName: IAttribute;
    modulePower: IAttribute;
    moduleType: IAttribute;
    inverterName: IAttribute;
    invertersPower: IAttribute;
    inverter1Power: IAttribute;
    structureName: IAttribute;
    structureConfig: IAttribute;
    structureType: IAttribute;
    lvConfig: IAttribute;
    modulesPerString: IAttribute;
    tiltAngle: IAttribute;
    minGroundClearance: IAttribute;
    pitchDistance: IAttribute;
    pitchDistanceMinMax: IAttribute;
    gcr: IAttribute;
  };
  financial: {
    specificPrice: IAttribute;
    totalPrice: IAttribute;
    lcoe: IAttribute;
    npv: IAttribute;
    irr: IAttribute;
    roi: IAttribute;
    payback: IAttribute;
    discountedPayback: IAttribute;
    currency: IAttribute;
  };
  site: {
    availableArea: IAttribute;
    suitableArea: IAttribute;
    fenceArea: IAttribute;
  };
}

export const renderCostAttribute = (data: any, currency?: string, unit?: string) => (
  <>
    {data && formatThousandsNumbers(data.toFixed(2))}
    <span className="unit">
      {` ${currency}`}
      {unit && `/${unit}`}
    </span>
  </>
);

export const renderTotalCostAttribute = (data: any, currency?: string) => (
  <div className="text-overflow-ellipsis">
    {!!data ? fourDigitNotationForLargeNumbers(+data.toFixed()) : '-'}
    <span className="unit">{` ${currency}`}</span>
  </div>
);

export const designAttributes: IDesignAttributes = {
  simulationType: {
    key: 'simulationType',
    label: 'type-of-design',
    render: () =>
      t(SIMULATION_TYPE.SINGLE ? 'design-process:batch.single-design' : 'design-process:batch.multiple-pv-designs'),
  },
  notes: {
    key: 'notes',
    label: 'notes',
    render: (data) => data || '-',
  },
  satellitePicture: {
    low: {
      key: 'satellitePicture.low',
      label: 'layout-view',
      className: 'img-layout',
      render: (data) => <RemoteImage url={data} className="img-design-layout" alt="Layout view" />,
    },
    high: {
      key: 'satellitePicture.high',
      label: 'layout-view',
      className: 'img-layout',
      render: (data) => <RemoteImage url={data} className="img-design-layout" alt="Layout view" />,
    },
    thumbnail: {
      key: 'satellitePicture.thumbnail',
      label: 'layout-view',
      className: 'img-layout',
      render: (data) => <RemoteImage url={data} className="img-design-layout" alt="Layout view" />,
    },
  },
  power: {
    ratedPower: {
      key: 'outputs.ratedPower',
      label: 'ratedPower',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wac'),
    },
    peakPower: {
      key: 'outputs.peakPower',
      label: 'peakPower',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wdc'),
    },
    ratioDcAc: {
      key: 'outputs.ratioDcAc',
      label: 'ratioDcAcInverters',
      render: (data) => `${data.toFixed(3)}`,
    },
    dcAcRatioAtPoi: {
      key: 'interconnection.dcAcRatioAtPoi',
      label: 'dcAcRatioAtPoi',
      render: (data) => `${data.toFixed(3)}`,
    },
    activePowerAtPoi: {
      key: 'interconnection.activePowerAtPoi',
      label: 'activePowerAtPoi',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wac'),
    },
  },
  energy: {
    specificProduction: {
      key: 'outputs.specificProduction',
      label: 'specificProduction',
      converter: {
        name: 'dimension',
        options: {
          dimension: 'default',
          decimals: 1,
        },
      },
      render: (data) => DataDisplayOrEmpty(data.value, 'kWh/kWp'),
    },
    eneryYield: {
      key: 'energy.energyYield',
      label: 'energyYield',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplayOrEmpty(data.value, data.unit, 'Wh'),
    },
    plantPR: {
      key: 'outputs.plantPR',
      label: 'plantPR_short',
      converter: {
        name: 'percentage',
      },
      render: (data) => DataDisplayOrEmpty(data.value, data.unit),
    },
    monthlyProduction: {
      key: 'energy.monthlyProduction',
      label: 'monthlyProduction',
      render: (data) => data,
    },
  },
  meteo: {
    source: {
      key: 'meteoData.summary.source',
      label: 'meteoSource',
      render: (data) => data,
    },
    resolution: {
      key: 'meteoData.summary.resolution',
      label: 'resolution',
      render: (data) => data,
    },
    yearGhi: {
      key: 'meteoData.summary.ghi',
      label: 'meteoGhi_short',
      converter: {
        name: 'dimension',
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh/m2'),
    },
    yearBhi: {
      key: 'meteoData.summary.bhi',
      label: 'meteoBhi_short',
      converter: {
        name: 'dimension',
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh/m2'),
    },
    yearDhi: {
      key: 'meteoData.summary.dhi',
      label: 'meteoDhi_short',
      converter: {
        name: 'dimension',
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh/m2'),
    },
    avgTemp: {
      key: 'meteoData.summary.tempAvg',
      label: 'meteoTempAvg',
      converter: {
        name: 'temperature',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    maxTemp: {
      key: 'meteoData.summary.tempMax',
      label: 'meteoTempMax',
      converter: {
        name: 'temperature',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    minTemp: {
      key: 'meteoData.summary.tempMin',
      label: 'meteoTempMin',
      converter: {
        name: 'temperature',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
  },
  albedo: {
    source: {
      key: 'albedoData.definition.source',
      label: 'meteoSource',
      render: (data) => data,
    },
    resolution: {
      key: 'albedoData.definition.resolution',
      label: 'resolution',
      render: (data) => data,
    },
    avgValuePerMonth: {
      key: 'albedoData.yearlyResults.mean',
      label: 'albedoAvgValuePerMonth',
      converter: {
        name: 'percentage',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    totalSamples: {
      key: 'albedoData.yearlyResults.totalSamples',
      label: 'albedoTotalSamples',
      render: (data) => data,
    },
    maxSamplesPerMonth: {
      key: 'albedoData.yearlyResults.maxSamplesPerMonth',
      label: 'albedoMaxSamplesPerMonth',
      render: (data) => data,
    },
    minSamplesPerMonth: {
      key: 'albedoData.yearlyResults.minSamplesPerMonth',
      label: 'albedoMinSamplesPerMonth',
      render: (data) => data,
    },
  },
  horizon: {
    source: {
      key: 'horizon.horizonSummary.source',
      label: 'horizonSource',
      render: (data) => data,
    },
    heightAvg: {
      key: 'horizon.horizonSummary.horizonAvg',
      label: 'horizonHeightAvg',
      render: (data) => `${data ? data.toFixed(1) : data} º`,
    },
    heightMax: {
      key: 'horizon.horizonSummary.horizonMax',
      label: 'horizonHeightMax',
      render: (data) => `${data ? data.toFixed(1) : data} º`,
    },
  },
  equipment: {
    moduleName: {
      key: 'inputs.module',
      label: 'moduleName',
      sortKeys: ['inputs.module.manufacturer', 'inputs.module.model', 'inputs.module.voltage'],
      render: (data) => data.manufacturer + '-' + data.voltage,
    },
    modulePower: {
      key: 'inputs.module.power',
      label: 'modulePower',
      converter: {
        name: 'dimension',
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wp'),
    },
    moduleType: {
      key: 'inputs.module.type',
      label: 'moduleType',
      render: (data) => data,
    },
    inverterName: {
      key: ['inputs.inverter1', 'inputs.inverter2'],
      sortKeys: ['inputs.inverter1.manufacturer', 'inputs.inverter1.model', 'inputs.inverter1.type'],
      label: 'inverterName',
      render: (data) => (
        <>
          {data[0].manufacturer} - {data[0].type} / {data[0].model}
          {!data[1] ? (
            ''
          ) : (
            <small>
              {data[1].manufacturer} - {data[1].type} / {data[1].model}
            </small>
          )}
        </>
      ),
    },
    invertersPower: {
      key: ['inputs.inverter1.power', 'inputs.inverter2.power'],
      label: 'inverterPower',
      converter: {
        name: 'dimension',
      },
      render: (data) => (
        <>
          <p>{DataDisplay(data[0].value, data[0].unit, 'VA')}</p>
          {!data[1] ? (
            ''
          ) : (
            <small>
              <p>{DataDisplay(data[1].value, data[1].unit, 'VA')}</p>
            </small>
          )}
        </>
      ),
    },
    inverter1Power: {
      key: 'inputs.inverter1.power',
      label: 'inverterPower',
      converter: {
        name: 'dimension',
      },
      render: (data) => DataDisplay(data.value, data.unit, 'VA'),
    },
    structureName: {
      key: 'inputs.structure',
      label: 'structureName',
      render: (data) => data.manufacturer + '/' + data.model,
    },
    structureType: {
      key: ['inputs.structure.isTracker', 'otherInputs.eastWestStructureEnabled'],
      label: 'structureType',
      render: (data) => {
        const [isTracker, isEastWest] = data;

        const structureType = !isTracker && isEastWest ? 'EAST-WEST' : isTracker && !isEastWest ? 'TRACKER' : 'FIXED';

        return <p>{structureType}</p>;
      },
    },
    structureConfig: {
      key: ['inputs.structure.positionNumber', 'inputs.structure.positionLetter'],
      label: 'structureConfig',
      render: (data) => (
        <p>
          {data[0]}
          {data[1]}
        </p>
      ),
    },
    lvConfig: {
      key: 'inputs.lvConfig',
      label: 'lvConfig',
      render: (data) => LV_CONFIG[data],
    },
    modulesPerString: {
      key: 'inputs.electricalConfig.modulesPerString',
      label: 'structureModulesPerString',
      render: (data) => data,
    },
    minGroundClearance: {
      key: 'otherInputs.minGroundClearance',
      label: 'minGroundClearance',
      converter: {
        name: 'length',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    tiltAngle: {
      key: 'inputs.tiltAngle',
      label: 'structureTilt',
      render: (data) => (data ? `${data} º` : '-'),
    },
    pitchDistance: {
      key: 'inputs.pitchDistance',
      label: 'structurePitch',
      converter: {
        name: 'length',
        options: {
          decimals: 2,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    pitchDistanceMinMax: {
      key: 'pitchDistanceMinMax',
      label: 'structurePitch',
      render: (data: any) => {
        return data.definedGlobally ? (
          <>
            {data.value}
            <span className="unit">{data.unit}</span>
          </>
        ) : (
          <CollapsibleCellTable cellPadding="10px 6px" items={data.value} title={data.label} />
        );
      },
    },
    gcr: {
      key: 'outputs.gcr',
      label: 'gcr',
      converter: {
        name: 'percentage',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
  },
  financial: {
    totalPrice: {
      key: 'outputs.capexResult.totalPrice',
      label: 'totalPrice',
      render: renderTotalCostAttribute,
    },
    specificPrice: {
      key: 'outputs.capexResult.specificPrice',
      label: 'specificPrice',
      render: (data, currency) => renderCostAttribute(data, currency, 'kWp'),
    },
    npv: {
      key: 'outputs.financialResultsDTO.npv',
      label: 'npv',
      render: renderTotalCostAttribute,
    },
    irr: {
      key: ['outputs.financialResultsDTO.irr', 'outputs.financialResultsDTO.irrCalculationResult'],
      label: 'irr',
      render: ([irr, irrCalculationResult]) => (
        <>
          {!isNaN(irr) && irrCalculationResult === CalculationResult.CORRECT ? (
            <>
              {(irr * 100).toFixed(2)}
              <span className="unit">{' %'}</span>
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    roi: {
      key: ['outputs.financialResultsDTO.roi', 'outputs.financialResultsDTO.roiCalculationResult'],
      label: 'roi',
      render: ([roi, roiCalculationResult]) => (
        <>
          {!isNaN(roi) && roiCalculationResult === CalculationResult.CORRECT ? (
            <>
              {(roi * 100).toFixed(2)}
              <span className="unit">{' %'}</span>
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    payback: {
      key: ['outputs.financialResultsDTO.payback', 'outputs.financialResultsDTO.paybackCalculationResult'],
      label: 'payback',
      render: ([payback, paybackCalculationResult]) => (
        <>{!isNaN(payback) && paybackCalculationResult === CalculationResult.CORRECT ? payback.toFixed(2) : '-'}</>
      ),
    },
    discountedPayback: {
      key: [
        'outputs.financialResultsDTO.discountedPayback',
        'outputs.financialResultsDTO.discountedPaybackCalculationResult',
      ],
      label: 'discountedPayback',
      render: ([discountedPayback, discountedPaybackCalculationResult]) => (
        <>
          {!isNaN(discountedPayback) && discountedPaybackCalculationResult === CalculationResult.CORRECT
            ? discountedPayback.toFixed(2)
            : '-'}
        </>
      ),
    },
    lcoe: {
      key: ['outputs.financialResultsDTO.lcoe', 'outputs.financialResultsDTO.lcoeCalculationResult'],
      label: 'lcoe',
      render: ([lcoe, lcoeCalculationResult], currency) =>
        !isNaN(lcoe) && lcoeCalculationResult === CalculationResult.CORRECT
          ? renderCostAttribute(lcoe, currency, 'MWh')
          : '-',
    },
    currency: {
      key: 'outputs.capexResult.currency',
      label: 'currency',
      render: (data) => data,
    },
  },
  site: {
    availableArea: {
      key: 'site.siteSummary.availableArea',
      label: 'availableArea',
      converter: {
        name: 'surface',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    suitableArea: {
      key: 'site.siteSummary.suitableArea',
      label: 'suitableArea',
      converter: {
        name: 'surface',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
    fenceArea: {
      key: 'resultGlobal.civil.areaInstalled',
      label: 'fenceArea',
      converter: {
        name: 'surface',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
  },
};
