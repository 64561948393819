import { Permission } from 'access-control/permissions';
import { IUseModal } from 'utils/hooks/use-modal';
import { ISearchProps } from 'utils/hooks/use-search';
import { ICompanyDTO } from './company.types';
import { IServerFilter } from './filter';
import { IPermission, IRole, IRoleListItem, ROLE_TYPE } from './role';
import { ApolloError } from '@apollo/client';
import { Table } from '@tanstack/react-table';
import { ITeam } from './teams';

export interface IUserListItem {
  id?: string;
  name: string;
  lastname: string;
  email: string;
  lastLogin?: string;
  status: string;
  jobTitle?: string;
  role?: IRole;
  editable?: boolean;
}

export interface IUserForm {
  id?: string;
  name: string;
  lastname: string;
  email?: string;
  roleId?: string;
  jobTitle?: string;
  status?: string;
}

export interface IUser {
  id: string;
  authId?: string;
  name: string;
  lastname: string;
  email: string;
  permissions: Permission[];
  roleName: string;
  roleType: string;
  roleHierarchy?: number;
  jobTitle?: string;
}

export enum USER_TYPE {
  CS_MANAGER = 'CUS_MANAGER',
  BIS_MANAGER = 'BIS_MANAGER',
  SDR_MANAGER = 'SDR_MANAGER',
  STANDARD_USER = 'STANDARD_USER',
  PRINCIPAL_CONSULTANT = 'PRINCIPAL_CONSULTANT',
  TECHNICAL_ADVISOR = 'TECHNICAL_ADVISOR',
}

export enum USER_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}
export interface IUserMinInfoDTO {
  id: string;
  name?: string;
  surname?: string;
  email?: string;
  codeLanguage?: string;
}

export interface IUserDTO {
  id: string;
  email: string;
  cognitoUsername?: string;
  role?: string;
  name?: string;
  lastname?: string;
  surname?: string;
  jobTitle?: string;
  countryCode?: string;
  codeLanguage?: string;
  numAttempts?: number;
  numManagedCompanies?: number;
  publishedDate?: Date;
  lastLogin?: Date;
  status?: USER_STATUS;
  createdBy?: IUserMinInfoDTO;
  company?: ICompanyDTO;
  interfaceUnits?: {
    id: string;
    length: 'INTERNATIONAL' | 'IMPERIAL';
    surface: 'INTERNATIONAL' | 'IMPERIAL';
    temperature: 'INTERNATIONAL' | 'IMPERIAL';
  };
  availableCodeLanguages: string[];
  roles: string[];
  type?: USER_TYPE;
}

export interface IUsersFilters {
  status: string;
  jobTitles: string[];
}

export interface IRoleFilters {
  status: string;
  type: string;
}

export interface IPreferences {
  language: string;
  unitsSystem: string; // IMPERIAL | INTERNATIONAL
  temperatureUnits: string; // CELSIUS | FAHRENHEIT
}

export interface IUserRoleSummary {
  role: IRole;
  userCounter: number;
}

export interface IUseUsersObject {
  inputSearchProps: ISearchProps;
  loading: boolean;
  paginationComponent: string | JSX.Element;
  hasWritePermission: boolean;
  userRoleSummary: IUserRoleSummary[];
  table: Table<IUserListItem>;
  teams: ITeam[];
  users: IUserListItem[];
  userSummaryLoading: boolean;
  filters: IServerFilter[];
  deleteUserModal: IUseModal;
  updatePasswordConfirmationModal: IUseModal;
  userTeamsAssignedModal: IUseModal;
  rolesByCurrentUser: IRole[];
  selectedRoleHierarchy: React.MutableRefObject<number | undefined>;
  handlePerform: (action: string, id?: string, data?: any) => void;
  handleDeleteUser: () => void;
  handleSendPassword: (email: string) => void;
  setFilters: React.Dispatch<React.SetStateAction<IServerFilter[]>>;
  setTeamSelected: (teamId: string) => void;
  handleNewUserAction: () => void;
  userToDelete?: { id?: string; email?: string };
}

export interface IUseRolesObject {
  table: Table<IRoleListItem>;
  loading: boolean;
  rolesError?: ApolloError;
  roles: IRoleListItem[];
  mapSelectedRoleTypes?: { [id: string]: ROLE_TYPE };
  currentPage: number;
  handleEdit: (roleId: string) => void;
  handleCancel: (roleId: string) => void;
  handleSaveRole: (permissions: IPermission[], id?: string) => void;
  handleDeleteRole: () => void;
  setFilters: React.Dispatch<React.SetStateAction<IServerFilter[]>>;
  inputSearchProps: ISearchProps;
  deleteRoleModal: IUseModal;
  paginationComponent?: string | JSX.Element;
  handleNewRoleAction: () => void;
}
