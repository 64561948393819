import { Dispatch, useEffect, useMemo, useState } from 'react';
import { ISort } from 'types/filter';
import { ISubscriptionPlanDTO, ISubscriptionPlanInputs, Plan } from 'types/subscription-plan';
import { getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';
import { getSortDirection } from 'utils/sort';
import { usePlanHistorySupportColumns } from './plan-history-constants';
import { useParams } from 'react-router-dom';
import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { IUseModal } from 'utils/hooks/use-modal';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { locales, SupportedLanguagesType } from 'utils/constants/locales';
import useForm from 'utils/hooks/use-form';
import { GET_SUPPORT_PLANS } from 'services/subscription-plan-gql';
import { useDeletePlan } from './use-delete-plan';
import { useUpgradePlan } from './use-upgrade-plan';
import { useEditPlan } from './use-edit-plan';
import { pvDesignPlanTitles } from 'pages/company/plan/rated-power-plans-constants';
import { useCompanyActivityState } from '../../company-activity-state';
import { openExternalLink } from 'utils/navigation';
import { useAddPlan } from './use-add-plan';

export interface IUsePlanHistory {
  inputs: ISubscriptionPlanInputs;
  currentPlanInputs: ISubscriptionPlanInputs;
  table: Table<ISubscriptionPlanDTO>;
  error: ApolloError | undefined;
  loading: boolean;
  confirmDeleteModal: IUseModal;
  editPlanModal: IUseModal;
  upgradePlanModal: IUseModal;
  newPlanModal: IUseModal;
  formError: any;
  setFormError: Dispatch<any>;
  resetInputs: () => void;
  handleCreateNewPlan: (inputs: ISubscriptionPlanInputs) => void;
  handleDeletePlan: () => void;
  handleEditPlan: (inputs: ISubscriptionPlanInputs, planId: string) => void;
  handleUpgradePlan: (inputs: ISubscriptionPlanInputs, planId: string) => void;
  handleInputChangeManual: (name: string, value: any) => void;
}

export const usePlanHistory = (): IUsePlanHistory => {
  const { t } = useTranslation();
  const {
    currentUser: { preferences },
  } = useSelector((state: RootState) => state);

  const { contextCompany } = useCompanyActivityState();
  const { id: companyId } = useParams<{ id: string }>();

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('start-date'),
      code: 'startDate',
    },
    ascendent: false,
  });

  const currentPlanInputs: ISubscriptionPlanInputs = {
    plan: contextCompany?.currentPlan.plan as Plan,
    startDate: contextCompany?.currentPlan.startDate,
    expirationDate: contextCompany?.currentPlan.expirationDate,
    projectLimit: { projectsPurchased: contextCompany?.currentPlan.projectsPurchased, unlimited: true },
    userLimit: { numberOfUsers: contextCompany?.currentPlan.userLimit, unlimited: true },
    stockKeepingUnits: contextCompany?.currentPlan.stockKeepingUnits,
    sendWelcomeEmail: false,
    isLegacy: contextCompany?.currentPlan.isLegacy,
  };

  const { inputs, handleInputChangeManual, formError, setFormError, setInputs } = useForm(null);

  const { data, error, loading } = useQuery(GET_SUPPORT_PLANS, {
    variables: {
      companyId,
      pagination: {
        page: 0,
        size: 10,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
    },
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  const subscriptionPlanData: ISubscriptionPlanDTO[] = data?.result?.content;
  const formatedPlansData: ISubscriptionPlanDTO[] = useMemo(
    () =>
      subscriptionPlanData?.map((plan) => {
        return {
          ...plan,
          title: t(`support:${pvDesignPlanTitles[plan.plan]}`),
        };
      }),
    [subscriptionPlanData]
  );

  const onUpgradePlan = (id: string) => {
    const plan = formatedPlansData.find((plan) => plan.id === id);
    if (!plan) return;
    handleOpenUpgradeModal(plan);
  };

  const onEditPlan = (id: string) => {
    const plan = formatedPlansData.find((plan) => plan.id === id);
    if (!plan) return;
    handleOpenEditModal(plan);
  };

  const onClickViewSkus = () => {
    openExternalLink(`/my-company/plan?companyId=${companyId}`);
  };

  const { confirmDeleteModal, handleDeletePlan, handleOpenConfirmDeleteModal } = useDeletePlan();
  const { editPlanModal, handleEditPlan, handleOpenEditModal } = useEditPlan(setInputs);
  const { upgradePlanModal, handleUpgradePlan, handleOpenUpgradeModal } = useUpgradePlan(setInputs);
  const { newPlanModal, handleCreateNewPlan } = useAddPlan();

  useEffect(() => {
    if (!contextCompany) return;
    resetInputs();
  }, [contextCompany, newPlanModal.isOpen]);

  const planHistoryActions = {
    'edit': onEditPlan,
    'delete': handleOpenConfirmDeleteModal,
    'upgrade': onUpgradePlan,
    'view-skus': onClickViewSkus,
  };

  const resetInputs = () => {
    setInputs(currentPlanInputs);
  };

  const planHistorySupportColumns = usePlanHistorySupportColumns({
    sortedBy,
    setSortedBy,
    locale: locales[preferences.language as SupportedLanguagesType],
    planHistoryActions,
  });

  const table = useReactTable({
    data: formatedPlansData,
    columns: planHistorySupportColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    inputs: inputs as ISubscriptionPlanInputs,
    currentPlanInputs,
    handleInputChangeManual,
    formError,
    setFormError,
    table,
    error,
    loading,
    confirmDeleteModal,
    editPlanModal,
    upgradePlanModal,
    newPlanModal,
    resetInputs,
    handleCreateNewPlan,
    handleDeletePlan,
    handleEditPlan,
    handleUpgradePlan,
  };
};
