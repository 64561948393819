import { useMutation } from '@apollo/client';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import React, { useEffect, useRef, useState } from 'react';
import { CREATE_ROLE, DELETE_ROLE, GET_ROLES, GET_ROLES_BY_CURRENT_USER, UPDATE_ROLE } from 'services/roles-gql';
import { useTheme } from 'styled-components';
import { IServerFilter, ISort } from 'types/filter';
import { IPermission, IRoleListItem, ROLE_TYPE } from 'types/role';
import { IUseRolesObject, IUserRoleSummary } from 'types/users';
import { NEW_ROLE_ROW, getOriginalRolesColors, RepeatedRoleError } from 'utils/constants/users';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useModal } from 'utils/hooks/use-modal';
import { useSearch } from 'utils/hooks/use-search';
import { getSortDirection } from 'utils/sort';
import { useGetRolesTableColumns } from '../../table-columns/roles-table-columns';
import { useToasts } from 'utils/hooks/use-toasts';
import { getCoreRowModel, getExpandedRowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { SortableHeader } from '@ratedpower/components';
import { useEditableRPTable } from 'utils/hooks/use-editable-rptable';

export const useRoles = (userRoleSummary: IUserRoleSummary[]): IUseRolesObject => {
  const theme = useTheme();
  const deleteRoleModal = useModal();
  const { addErrorToast, addSuccessToast } = useToasts();
  const { t } = useTranslation();

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('name'),
      code: 'name',
    },
    ascendent: true,
  });
  const [roles, setRoles] = useState<IRoleListItem[]>([]);
  const [mapSelectedRoleTypes, setSelectedRoleTypes] = useState<{ [id: string]: ROLE_TYPE }>();
  const [filters, setFilters] = useState<IServerFilter[]>([]);

  const roleToDelete = useRef<string | undefined>();
  const { getSearchFilter, inputSearchProps } = useSearch();
  const { isAuthorized } = useAuthorization();

  const hasWritePermission = isAuthorized(Permission.ROLE_SUPPORT_WRITE);

  const {
    data: fetchedData,
    loading,
    paginationComponent,
    currentPage,
    error: rolesError,
  } = useCustomQuery(GET_ROLES, {
    variables: {
      pagination: {
        page: 0,
        size: 10,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
      search: getSearchFilter(),
      filters,
    },
    skip: !isAuthorized(Permission.ROLE_SUPPORT_READ),
    fetchPolicy: 'network-only',
  });

  const [createRole] = useMutation(CREATE_ROLE, {
    refetchQueries: [GET_ROLES, GET_ROLES_BY_CURRENT_USER],
    onCompleted: () => {
      collapseAllRows();
      addSuccessToast(t('user:roles.role-created-successfully'));
    },
    onError: (error) => {
      if (error.message === RepeatedRoleError) {
        addErrorToast(t('user:roles.error-name-repeated'));
        return;
      }
      addErrorToast(t('errors:GENERIC'));
    },
  });
  const [updateRole] = useMutation(UPDATE_ROLE, {
    refetchQueries: [GET_ROLES, GET_ROLES_BY_CURRENT_USER],
    onCompleted: () => {
      collapseAllRows();
      addSuccessToast(t('user:roles.role-updated-successfully'));
    },
    onError: (error) => {
      if (error.message === RepeatedRoleError) {
        addErrorToast(t('user:roles.error-name-repeated'));
        return;
      }
      addErrorToast(t('errors:GENERIC'));
    },
  });
  const [deleteRole] = useMutation(DELETE_ROLE, {
    refetchQueries: [GET_ROLES, GET_ROLES_BY_CURRENT_USER],
    onCompleted: () => {
      collapseAllRows();
      addSuccessToast(t('user:roles.role-deleted-successfully'));
    },
  });

  const sortableHeader = (label: string, code: string) => (
    <SortableHeader
      {...{ sortedBy, onSort: setSortedBy }}
      selector={{
        label: t(`${label}`),
        code,
      }}
    />
  );

  useEffect(() => {
    if (fetchedData && fetchedData.result?.content && !loading) {
      setRoles(fetchedData.result?.content);
    }
  }, [fetchedData, loading]);

  useEffect(() => {
    if (roles.length) {
      setInitialRoleTypes();
    }
  }, [roles.length]);

  const setInitialRoleTypes = () => {
    const selectedRoleTypes: { [id: string]: ROLE_TYPE } = {};
    roles.forEach((role) => {
      selectedRoleTypes[role?.id as string] = role.type;
    });
    setSelectedRoleTypes(selectedRoleTypes);
  };

  const handleDeleteAction = (id?: string) => {
    roleToDelete.current = id;
    deleteRoleModal.setModalOpen(true);
  };

  const handleSaveRole = (permissions: IPermission[], id?: string) => {
    const role = roles.find((currentRole) => currentRole.id === id);
    if (role) {
      const { name, hierarchyLevel, color, type, status } = role;
      !id
        ? createRole({
            variables: { createRoleDTO: { name, hierarchyLevel, color, status, type, permissions } },
          })
        : updateRole({
            variables: { updateRoleDTO: { id, hierarchyLevel, name, color, status, type, permissions } },
          });
    }
  };

  const columns = useGetRolesTableColumns(
    sortableHeader,
    userRoleSummary,
    handleDeleteAction,
    hasWritePermission,
    setSelectedRoleTypes
  );

  const table = useEditableRPTable(roles, columns, setRoles, {
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const originalRolesColors = getOriginalRolesColors(theme);

  const handleNewRoleAction = () => {
    if (!roles[0].id) return;
    table.options.meta?.addRow({ ...NEW_ROLE_ROW, color: originalRolesColors.User });
    setSelectedRoleTypes({ ...mapSelectedRoleTypes, New: ROLE_TYPE.EXTERNAL });
    collapseRow('0', false);
  };

  const handleCancel = (id: string) => {
    const roleIdx = roles.findIndex((roleFound: IRoleListItem) => roleFound.id === id);
    table.options.meta?.editRow(roleIdx, false);
    collapseRow('0', true);
    if (id) {
      const role = roles.find((roleFound: IRoleListItem) => roleFound.id === id);
      if (role) setSelectedRoleTypes({ ...mapSelectedRoleTypes, [id]: role.type });
    }
  };

  const handleEdit = (id: string) => {
    const roleIdx = roles.findIndex((roleFound: IRoleListItem) => roleFound.id === id);
    setInitialRoleTypes();
    table.options.meta?.editRow(roleIdx, true);
  };

  const handleDeleteRole = async () => {
    const selectedRoleType = { ...mapSelectedRoleTypes };
    const role = roles.find((roleFound) => roleFound.id === roleToDelete.current);
    await deleteRole({ variables: { id: roleToDelete.current } });

    if (role) {
      delete selectedRoleType[role?.id as string];
      setSelectedRoleTypes({ ...selectedRoleType });
    }
  };

  const collapseAllRows = () => {
    table.getRowModel().rows.map((row) => row.toggleExpanded(false));
  };

  const collapseRow = (rowId: string, collapse: boolean) => {
    table.getRow(rowId).toggleExpanded(!collapse);
  };

  return {
    loading,
    rolesError,
    mapSelectedRoleTypes,
    table,
    roles,
    currentPage,
    handleNewRoleAction,
    handleSaveRole,
    handleEdit,
    handleCancel,
    handleDeleteRole,
    setFilters,
    inputSearchProps,
    deleteRoleModal,
    paginationComponent: fetchedData && fetchedData.result?.pageInfo.totalPages > 1 ? paginationComponent : undefined,
  };
};
