import { Typography } from '@ratedpower/components';
import { StepType } from '@reactour/tour';
import { TFunction } from 'i18next';
import React from 'react';
import { styles } from 'utils/tours';

const distances: Record<string, number> = {
  popoverWidth: 312,
  bottomToolbarWidth: 372,
};

const { bottomToolbarWidth, popoverWidth } = distances;

export const steps = (t: TFunction): StepType[] => [
  {
    selector: '#layout-editor-bottom-toolbar',
    padding: 0,
    styles: styles('top', 'right', window.innerWidth / 2 - bottomToolbarWidth / 2, window.innerHeight - 280),
    content: (
      <>
        <Typography size="l" weight="bold">
          {t('tour.steps.bottom-toolbar.title')}
        </Typography>
        <Typography>{t('tour.steps.bottom-toolbar.description')}</Typography>
      </>
    ),
  },
  {
    selector: '#layout-editor-top-toolbar',
    styles: styles('bottom', 'left', window.innerWidth / 2 - popoverWidth / 2, 140),
    content: (
      <>
        <Typography size="l" weight="bold">
          {t('tour.steps.top-toolbar.title')}
        </Typography>
        <Typography>{t('tour.steps.top-toolbar.description')}</Typography>
      </>
    ),
  },
  {
    selector: '#layout-editor-results',
    padding: { mask: 4 },
    styles: styles('left', 'top', window.innerWidth - 70 - popoverWidth, 100),
    content: (
      <>
        <Typography size="l" weight="bold">
          {t('tour.steps.results.title')}
        </Typography>
        <Typography>{t('tour.steps.results.description')}</Typography>
      </>
    ),
  },
  {
    selector: '#layout-editor-validations',
    padding: { mask: 4 },
    styles: styles('left', 'top', window.innerWidth - 70 - popoverWidth, 145),
    content: (
      <>
        <Typography size="l" weight="bold">
          {t('tour.steps.validations.title')}
        </Typography>
        <Typography>{t('tour.steps.validations.description')}</Typography>
      </>
    ),
  },
  {
    selector: '#layout-editor-validate-and-generate',
    padding: { mask: 4 },
    styles: styles('bottom', 'left', window.innerWidth - popoverWidth - 40, 60),
    content: (
      <>
        <Typography size="l" weight="bold">
          {t('tour.steps.validate-and-generate.title')}
        </Typography>
        <Typography>{t('tour.steps.validate-and-generate.description')}</Typography>
      </>
    ),
  },
];
