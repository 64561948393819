import styled, { css } from 'styled-components';

export const CurrentPlanLoading = styled.div`
  display: flex;
  row-gap: 16px;
  width: 100%;
  flex-direction: column;
`;

export const PvPlanContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.m};
  padding: ${({ theme }) => theme.v2.spacing.m} 0;
  flex-direction: column;
  overflow-y: auto;
  height: max-content;
  margin-top: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const PvPlanCard = styled.div<{ $current?: boolean }>`
  background: ${({ theme }) => theme.v2.bg.primary.default};
  padding: ${({ theme }) => `${theme.v2.spacing.m} ${theme.v2.spacing.xs} ${theme.v2.spacing.xs}`};
  width: 276px;

  ${({ $current, theme }) =>
    $current &&
    css`
      box-shadow: ${theme.v2.elevation.shadow.l};
    `}

  .request-more-info-btn {
    margin: auto;
  }
`;

export const PvPlanTitle = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.xs};
  align-items: center;
`;

export const PlanRowWrapper = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;
