import { GET_ACTIVITY_COMPANY, UPDATE_ACTIVITY } from 'services/support-gql';
import { IServerFilter, ISort } from 'types/filter';
import { getSortDirection } from 'utils/sort';
import { t } from 'i18next';
import { useCompanyActivityState } from '../../company-activity-state';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { useSearch } from 'utils/hooks/use-search';
import { activityOptions, ACTIVITY_PER_PAGE_OPTIONS, companyActivityTabColumns } from 'utils/constants/support';
import { useModal } from 'utils/hooks/use-modal';
import { IUseActivityTab, ISupportAlert, ISupportAlertSend } from 'types/support.types';
import { useTheme } from 'styled-components';
import { getOnCurrentTimezoneDate, toDayEnd, toMidnight } from 'utils/date';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useNavigate } from 'react-router-dom';

export const useActivityTab = (): IUseActivityTab => {
  const { isAuthorized } = useAuthorization();
  const theme = useTheme();
  const navigate = useNavigate();
  const [fetchedData, setFetchedData] = useState<ISupportAlert[]>([]);
  const [optionSelected, setOptionSelected] = useState<string>(activityOptions[0].value);
  const [activitiesPerPage, setActivitiesPerPage] = useState(ACTIVITY_PER_PAGE_OPTIONS[0]);
  const [filtersPanelOpened, setFiltersPanelOpened] = useState(false);
  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('support:date'),
      code: 'publishedDate',
    },
    ascendent: false,
  });

  const { inputSearchProps, getSearchFilter } = useSearch();
  const { companyId } = useCompanyActivityState();
  const infoModal = useModal();
  const notesModal = useModal();

  const table = useReactTable({
    data: fetchedData,
    columns: companyActivityTabColumns(t, theme, {
      onArchive: handleArchive,
      onSort: setSortedBy,
      hasWritePermissions: isAuthorized(Permission.SUPPORT_ALERT_SUPPORT_WRITE),
      sortedBy,
      infoModal,
      notesModal,
      activityView: optionSelected,
      navigate,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  const defaultFilter: IServerFilter = {
    field: 'COMPANY_ID',
    operator: 'EQUAL',
    values: [companyId],
  };

  const initialFilter: IServerFilter[] = [
    {
      field: 'IS_ARCHIVED',
      operator: 'EQUAL',
      values: ['false'],
    },
    {
      field: 'PUBLISHED_DATE',
      operator: 'GREATER_OR_EQUAL',
      values: [getOnCurrentTimezoneDate(toMidnight(new Date(new Date().setDate(new Date().getDate() - 7))))],
    },
    {
      field: 'PUBLISHED_DATE',
      operator: 'LESS_OR_EQUAL',
      values: [getOnCurrentTimezoneDate(toDayEnd(new Date()))],
    },
  ];

  const [serverFilters, setServerFilters] = useState<IServerFilter[]>(initialFilter);
  const { data, loading, paginationComponent, error, refetch } = useCustomQuery(
    GET_ACTIVITY_COMPANY,
    {
      variables: {
        pagination: {
          page: 0,
          size: activitiesPerPage.value === 'unlimited' ? 20 : activitiesPerPage.value,
        },
        sorting: {
          property: sortedBy?.selector.code,
          direction: getSortDirection(sortedBy?.ascendent),
        },
        filters: [defaultFilter, ...serverFilters.filter((filter) => filter.values.length)],
        search: getSearchFilter(),
      },
      fetchPolicy: 'network-only',
    },
    activitiesPerPage.value === 'unlimited'
  );

  const [updateActivity] = useMutation(UPDATE_ACTIVITY, {
    refetchQueries: [GET_ACTIVITY_COMPANY],
  });

  const companyName = data?.result?.content?.length > 0 ? data.result.content[0].company.name : '';

  useEffect(() => {
    if (!data?.result) return;
    const newData: ISupportAlert[] = data.result.content.map((item: ISupportAlert) => {
      return { ...item, active: false, rowProps: { className: item.alertSeverity.toLowerCase() } };
    });
    setFetchedData(newData);
  }, [data]);

  function handleChangeView(newValue: string) {
    setOptionSelected(newValue);
    const newServerFilters: IServerFilter[] = [...serverFilters];
    const filterIsArchivedIdx = newServerFilters.findIndex((filter: IServerFilter) => filter.field === 'IS_ARCHIVED');
    if (filterIsArchivedIdx !== -1) {
      newServerFilters[filterIsArchivedIdx] = {
        field: 'IS_ARCHIVED',
        operator: 'EQUAL',
        values: newValue === activityOptions[0].value ? ['false'] : ['true'],
      };
    } else {
      newServerFilters.push({
        field: 'IS_ARCHIVED',
        operator: 'EQUAL',
        values: newValue === activityOptions[0].value ? ['false'] : ['true'],
      });
    }
    setServerFilters(newServerFilters);
  }

  function handleArchive(id?: string) {
    let supportAlertUpdateDTOList: ISupportAlertSend[] = [];

    if (id) {
      const activity = fetchedData.find((elem) => elem.id === id);
      if (activity) {
        supportAlertUpdateDTOList = [
          {
            id,
            description: activity?.description,
            statusSupport: activity.statusSupport,
            archived: optionSelected === activityOptions[0].value,
            communicationChannel: activity.communicationChannel,
          },
        ];
      }
    } else {
      const activities = fetchedData.filter((elem) => elem.active);
      if (activities) {
        supportAlertUpdateDTOList = activities.map((activity: ISupportAlert) => {
          return {
            id: activity.id,
            description: activity.description,
            statusSupport: activity.statusSupport,
            archived: optionSelected === activityOptions[0].value,
            communicationChannel: activity.communicationChannel,
          };
        });
      }
    }
    updateActivity({ variables: { supportAlertUpdateDTOList } });
  }

  function handleEditNotes(id: string, notes: string) {
    const activity = fetchedData.find((elem) => elem.id === id);
    if (activity) {
      const supportAlertUpdateDTOList: ISupportAlertSend[] = [
        {
          id,
          description: notes,
          statusSupport: activity.statusSupport,
          archived: activity.archived,
          communicationChannel: activity.communicationChannel,
        },
      ];
      updateActivity({ variables: { supportAlertUpdateDTOList } });
    }
  }

  return {
    query: {
      fetchedData,
      paginationComponent,
      loading,
      error,
      fetchData: refetch,
    },
    infoModal,
    notesModal,
    inputSearchProps,
    optionSelected,
    companyName,
    filtersPanelOpened,
    setServerFilters,
    setFiltersPanelOpened,
    handleEditNotes,
    handleChangeView,
    table,
    activitiesPerPage,
    setActivitiesPerPage,
  };
};
