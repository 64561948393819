import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { GET_USER } from 'services/user-gql';
import { ICurrentLanguage, ICurrentUser, setCurrentUser } from 'redux/current-user-slice';
import { IUnitSystemPreferences } from 'types/preferences.types';
import { useTranslation } from 'react-i18next';
import useSubscriptionPlanBanner from './use-subscription-plan-banner';
import { IPermission } from 'types/role';
import { useAuth0 } from '@auth0/auth0-react';
import rudderstack from 'rudderstack/utils';
import { getRudderstackScreenResolution } from 'utils/constants/rudderstack';
import { RudderstackEvent } from 'rudderstack/types';

const useCurrentUser = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  const {
    data: fetchedData,
    loading,
    error,
    refetch: refetchUser,
  } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      rudderstack.identify(fetchedData.user.authId, {
        user_email: fetchedData.user.email,
        user_first_name: fetchedData.user.name,
        user_last_name: fetchedData.user.lastname,
        user_roles: [fetchedData.user.role.name],
        user_role_type: fetchedData.user.role.type,
        user_permissions: fetchedData.user.role.permissions.map((permission: IPermission) => permission.name),
        user_id_type: 'user_enverus_id',
        user_enverus_id: fetchedData.user.authId,
        user_auth0_id: fetchedData.user.azId,
        user_ratedPower_id: fetchedData.user.id,
        screen_resolution: getRudderstackScreenResolution(),
      });

      rudderstack.group(fetchedData.user.company.organizationId, {
        organization_id: fetchedData.user.company.organizationId,
        organization_plan: fetchedData.user.company.currentPlan.plan,
        organization_enverus_id: fetchedData.user.company.enverusId,
        organization_name: fetchedData.user.company.name,
        number_contracted_projects: fetchedData.user.company.currentPlan.projectsPurchased,
        number_projects_used: fetchedData.user.company.currentPlan.projectsDone,
      });

      if (!localStorage.getItem('rudderstackInit')) {
        rudderstack.track(RudderstackEvent.LOGIN);
      }
      localStorage.setItem('rudderstackInit', 'true');
    },
    skip: !isAuthenticated,
  });

  useSubscriptionPlanBanner();

  useEffect(() => {
    if (!fetchedData) return;
    const { user } = fetchedData;
    if (!user || !user.id) return;
    const {
      id,
      authId,
      role,
      name,
      lastname,
      email,
      jobTitle,
      company: {
        id: companyId,
        name: companyName,
        companyWarningMessage,
        logo,
        hasFuturePlan,
        organizationId,
        currentPlan: {
          userLimit,
          plan,
          expirationDate,
          projectsDone,
          projectsPurchased,
          usersCreated,
          publishedDate,
          isLegacy,
          startDate,
          unlimitedProjects,
          unlimitedUsers,
          stockKeepingUnits,
        },
      },
    } = user;
    const currentUser: ICurrentUser = {
      id,
      authId,
      companyName,
      organizationId,
      name,
      lastname,
      email,
      permissions: role.permissions.map((permission: IPermission) => permission.name),
      roleName: role.name,
      roleType: role.type,
      roleHierarchy: role.hierarchyLevel,
      jobTitle,
      companyId,
      logo: logo.low,
    };
    const currentPreferences: ICurrentLanguage & IUnitSystemPreferences = {
      language: user.lng,
      unitsId: user.units.id,
      temperature: user.units.temperature,
      length: user.units.length,
      surface: user.units.surface,
    };
    const currentSubscriptionPlan = {
      hasFuturePlan,
      projectsDone,
      projectsPurchased,
      usersCreated,
      startDate,
      plan,
      expirationDate,
      unlimitedProjects,
      publishedDate,
      unlimitedUsers,
      paymentOverdue: companyWarningMessage === 'OVERDUE_PAYMENT',
      stockKeepingUnits,
      isLegacy,
      userLimit,
    };

    i18n.changeLanguage(currentPreferences.language);

    dispatch(
      setCurrentUser({
        profile: currentUser,
        preferences: currentPreferences,
        subscriptionPlan: currentSubscriptionPlan,
      })
    );
  }, [fetchedData]);

  return {
    loading,
    error,
    refetchUser,
  };
};

export default useCurrentUser;
