/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { Checkbox, Notification } from '@ratedpower/components';
import { useDefaultSkus } from 'pages/company/plan/hooks/use-default-skus';
import { SSOSkuNotification } from 'pages/support/companies-activity/new-company-modal/skus-step/sso-sku-notification/sso-sku-notification';
import { useTranslation } from 'react-i18next';
import { IStockKeepingUnitDTO } from 'types/subscription-plan';
import { SKUS } from 'types/support.types';
import { additionalDisabledSkus, mainDisabledSkus } from 'utils/constants/support';

interface IProps {
  id: string;
  checkedSkus: IStockKeepingUnitDTO[];
  planIncludesSSO: boolean;
  showTeamManagementNotification?: boolean;
  onSelectSkus: (selectedSkus: IStockKeepingUnitDTO[]) => void;
}

export const SkusListSection: React.FC<IProps> = ({
  id,
  checkedSkus,
  planIncludesSSO,
  showTeamManagementNotification = true,
  onSelectSkus,
}) => {
  const { t } = useTranslation();
  const { allSkus } = useDefaultSkus(false);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const reorderedSkus = [
    ...(allSkus || []).filter((sku) => sku.name !== SKUS.SSO),
    ...(allSkus || []).filter((sku) => sku.name === SKUS.SSO),
  ];

  const handleChangeSelectedSkus = (value: string, checked: boolean) => {
    if (value === SKUS.TEAM_MANAGEMENT) {
      setNotificationVisible(!checked);
    }
    const currentSelectedSku = reorderedSkus.find((sku) => sku.name === value);
    if (currentSelectedSku) {
      if (checked) {
        onSelectSkus([...checkedSkus, currentSelectedSku]);
      } else {
        onSelectSkus(checkedSkus.filter((sku) => sku.name !== currentSelectedSku.name));
      }
    }
  };

  const isSkuChecked = (skuName: string) => checkedSkus.some((selectedSku) => selectedSku.name === skuName);

  return (
    <>
      <Checkbox label={t(`company:skus.${SKUS.CORE_PV}`)} checked disabled onChange={() => {}} />
      {reorderedSkus.map((sku) => (
        <>
          <Checkbox
            id={`plan-skus-section-${id}-${sku.name}`}
            key={sku.name}
            label={t(`company:skus.${sku.name}`)}
            checked={isSkuChecked(sku.name)}
            disabled={mainDisabledSkus.includes(sku.name as SKUS) || additionalDisabledSkus.includes(sku.name as SKUS)}
            onChange={() => handleChangeSelectedSkus(sku.name, !isSkuChecked(sku.name))}
          />
          {notificationVisible && sku.name === SKUS.TEAM_MANAGEMENT && showTeamManagementNotification && (
            <Notification
              status="warning"
              hideCloseButton
              heightFitContent
              width="400px"
              description={t('support:team-management-unchecked-warning')}
              title={t('support:team-management-unchecked-warning-title')}
            />
          )}
        </>
      ))}
      <SSOSkuNotification planIncludesSSO={planIncludesSSO} />
    </>
  );
};
