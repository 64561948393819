import { useFlags } from 'launchdarkly-react-client-sdk';
import { SimulationPrimaryCharacteristicsTag } from 'types/design';
import { HasTagInfo } from 'utils/design-tags';

export const useHybridBessSku = () => {
  const flags = useFlags();

  const restrictHybridBessSku = (item: HasTagInfo) => {
    return !(
      (item.primaryTag !== SimulationPrimaryCharacteristicsTag.PV_BESS &&
        item.primaryTag !== SimulationPrimaryCharacteristicsTag.PV_BESS_BATCH) ||
      flags?.skuHybridBess
    );
  };

  return {
    restrictHybridBessSku,
  };
};
