import { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { GET_CAPEX_CREATORS, SAVE_CAPEX_TEMPLATE, UPDATE_CAPEX_TEMPLATE } from 'services/capex-gql';
import { ICapexTemplateCreate, ICapexTemplateUpdate, IUseCapexTemplateEditorObject, ICapexTemplate } from 'types/capex';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useToasts } from 'utils/hooks/use-toasts';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { dataToCapexTemplate } from 'utils/constants/capex-constants';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';
import { useNavigate, useLocation, useBlocker } from 'react-router-dom';
import { useCapexTemplateState } from './use-capex-template-state';
import { useTranslation } from 'react-i18next';
import { SIMULATION_CATEGORY } from 'types/design';
import { compareDesignProcessUrls } from 'utils/navigation/navigation';
import { track } from 'rudderstack/utils';
import { CapexCreateSource, RudderstackEvent } from 'rudderstack/types';

export function useCapexTemplateEditor(
  templateData?: ICapexTemplate,
  skip?: boolean,
  simulationCategory?: SIMULATION_CATEGORY
): IUseCapexTemplateEditorObject {
  const modalExitWarning: IUseModal = useModal(false);
  const { t } = useTranslation();
  const { addSuccessToast } = useToasts();
  const { isAuthorized } = useAuthorization();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { pathname } = useLocation();
  const letMeOut = useRef<boolean>(true);
  const redirectToCapexAfterCreate = useRef<boolean>(false);
  const userIsCreating = useRef<boolean>(!pathname.includes('edit'));
  const { currencyOptions } = useSelector((currencyState: RootState) => currencyState.currencies);

  const templateCreatedId = useRef<string>();
  const templateModified = useRef<ICapexTemplate>();

  const { useCapexFormObject, changeTemplateProperty, editableTemplate, setEditableTemplate, resetTemplate, canUndo } =
    useCapexTemplateState(templateData, skip, simulationCategory);

  const { getEntries } = useCapexFormObject;
  const user = useSelector((userState: RootState) => userState.currentUser.profile);
  const companyIdQueryParam = useQuerySearchParams().get('companyId');
  const { companyId: userCompanyId } = { ...user };
  const companyId = companyIdQueryParam || userCompanyId;

  const [updateCapexTemplate] = useMutation<{ updateCapexTemplate: ICapexTemplate }, { form: ICapexTemplateUpdate }>(
    UPDATE_CAPEX_TEMPLATE,
    {
      onCompleted: (data) => {
        showToastSuccess(false, data.updateCapexTemplate);
        resetTemplate(dataToCapexTemplate(data.updateCapexTemplate, currencyOptions));
        if (redirectToCapexAfterCreate.current) {
          navigate(-1);
        }
      },
    }
  );

  const [saveTemplate, { data: templateCreated, loading: loadingCreation }] = useMutation<
    { createCapexTemplate: ICapexTemplate },
    { form: ICapexTemplateCreate }
  >(SAVE_CAPEX_TEMPLATE, {
    refetchQueries: [GET_CAPEX_CREATORS],
    onCompleted: (data) => {
      showToastSuccess(true, data.createCapexTemplate);
      templateCreatedId.current = data.createCapexTemplate.id;
      userIsCreating.current = false;
      resetTemplate(dataToCapexTemplate(data.createCapexTemplate, currencyOptions));
      if (redirectToCapexAfterCreate.current) {
        const historyState = state as { backToCapexHome?: boolean };
        historyState.backToCapexHome ? navigate('/capex') : navigate(-1);
      }
    },
  });

  useEffect(() => {
    letMeOut.current = !canUndo;
  }, [canUndo]);

  useEffect(() => {
    if (!!templateData && templateData.status === 'IMMUTABLE') {
      templateModified.current = templateData;
    }
  }, [templateData]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !compareDesignProcessUrls(currentLocation.pathname, nextLocation.pathname) && canUndo && !letMeOut.current
  );

  useEffect(() => {
    if (blocker.state !== 'blocked') return;
    modalExitWarning.setModalOpen(true);
  }, [blocker.state]);

  const handleAcceptUnsavedChanges = () => {
    if (blocker && blocker.proceed && blocker.location) {
      blocker.proceed();
      navigate(blocker.location.pathname);
    }
    modalExitWarning.setModalOpen(false);
  };

  const handleCancelUnsavedChanges = () => {
    if (blocker && blocker.reset) blocker.reset();
    modalExitWarning.setModalOpen(false);
  };

  const showToastSuccess = (creation: boolean, newTemplate: any) => {
    const defaultName = newTemplate?.company ? t('capex:untitled') : t('capex:rest-of-the-world');
    const templateName = newTemplate?.name || newTemplate?.country?.translation || defaultName;

    addSuccessToast(
      t(`capex:${!creation ? 'saved-changes-template' : 'template-created-succesfully'}`, {
        templateName,
      })
    );
  };

  const updateTemplateChanges = (redirectToCapex: boolean) => {
    if (editableTemplate) {
      const form: ICapexTemplateUpdate = {
        name: editableTemplate.countryCode ? null : editableTemplate.name,
        countryCode: editableTemplate.countryCode,
        currency: editableTemplate.currency.value,
        entries: getEntries(false),
        countryDefault: editableTemplate?.countryDefault,
        folder: editableTemplate?.folder?.name,
      };
      letMeOut.current = true;
      redirectToCapexAfterCreate.current = redirectToCapex;
      updateCapexTemplate({ variables: { form: { ...form, id: templateCreatedId.current || editableTemplate.id } } });
    }
  };

  const changeTemplateProperties = (newProperties: string[], newValues: any[]) => {
    let newTemplate: ICapexTemplate = { ...editableTemplate };
    newProperties.forEach((property, index) => {
      const templateProperty = property as keyof ICapexTemplate;
      newTemplate = { ...newTemplate, [templateProperty]: newValues[index] };
    });
    return setEditableTemplate(newTemplate);
  };

  const handleSaveChanges = (redirectToCapex: boolean) => {
    if (!userIsCreating.current) {
      updateTemplateChanges(redirectToCapex);
      return;
    }
    letMeOut.current = true;
    redirectToCapexAfterCreate.current = redirectToCapex;
    track(RudderstackEvent.CREATE_CAPEX_TEMPLATE, {
      source: state.fromExistingSheet ? CapexCreateSource.EXISTING_SHEET : CapexCreateSource.SCRATCH,
    });
    saveTemplate({
      variables: {
        form: {
          name: editableTemplate.countryCode ? null : editableTemplate.name,
          currency: editableTemplate.currency?.value,
          folder: editableTemplate.folder?.name,
          companyId:
            editableTemplate?.type === 'public' && isAuthorized(Permission.FINANCIAL_SUPPORT_WRITE)
              ? undefined
              : companyId,
          countryDefault: editableTemplate.countryDefault,
          entries: getEntries(true),
          countryCode: editableTemplate.countryCode,
        },
      },
    });
  };

  return {
    handleSaveChanges,
    resetTemplate,
    setEditableTemplate,
    changeTemplateProperty,
    changeTemplateProperties,
    handleAcceptUnsavedChanges,
    handleCancelUnsavedChanges,
    loadingCreation,
    templateModified: templateModified?.current,
    modalExitWarning,
    editableTemplate: editableTemplate || templateData,
    useCapexFormObject,
    canUndo: canUndo && editableTemplate !== undefined,
    templateCreated: templateCreated ? templateCreated?.createCapexTemplate : undefined,
  };
}
