import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

import store from './redux/store';
import { setLanguage } from './redux/current-user-slice';
import { supportedLanguages } from 'utils/constants/locales';

const supportedNamespaces = [
  'action',
  'analytics',
  'auth',
  'capex',
  'civil',
  'common',
  'communication',
  'company',
  'design',
  'design-process',
  'design-results',
  'electrical',
  'energy',
  'equipment',
  'errors',
  'home',
  'maintenance',
  'preferences',
  'project',
  'statistics',
  'support',
  'units',
  'user',
  'ilc',
];

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./assets/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: false,
      projectId: '53499e965a93cf440821332d41c4233c',
      fullReparse: true,
    })
  )
  .init({
    returnNull: false,
    debug: false,
    supportedLngs: supportedLanguages,
    fallbackLng: 'en',
    ns: supportedNamespaces,
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    postProcess: ['phraseInContextEditor'],
    react: {
      useSuspense: false,
    },
  });

i18n.on('languageChanged', (language) => {
  store.dispatch(setLanguage({ language }));
});

export default i18n;
