import React from 'react';
import { DotsSvg, NewTabSvg } from 'assets/icons';
import { ISort } from 'types/filter';
import { PopupWrapper, SortableHeader, Typography, StatusSVGWrapper, IOption } from '@ratedpower/components';
import { TFunction } from 'i18next';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { getDateShortMonthDDYYYY, toDayEnd, toMidnight } from 'utils/date';
import { ISubscriptionPlanExtensionDTO, ISubscriptionPlanDTO, Plan } from 'types/subscription-plan';
import { InfoText } from 'components/text-components/info-text';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ThreeDotsCell, TimelapseCell } from './plan-history.styled';
import { pvDesignPlanTitles } from 'pages/company/plan/rated-power-plans-constants';

export const usePlanHistorySupportColumns = (options: {
  sortedBy: ISort;
  locale: string;
  setSortedBy: React.Dispatch<React.SetStateAction<ISort>>;
  planHistoryActions: { [x: string]: (id: string, inputs?: any) => void };
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const columnHelper = createColumnHelper<ISubscriptionPlanDTO>();

  const getSimpleCell = (value: React.ReactNode) => {
    return <Typography>{value}</Typography>;
  };

  const getOptions = (
    t: TFunction,
    planHistoryActions: { [x: string]: (id: string, inputs?: any) => void },
    info: CellContext<ISubscriptionPlanDTO, string>
  ) => {
    const isPastPlan =
      new Date() > toMidnight(new Date(info.row.original.startDate)) &&
      new Date() > toDayEnd(new Date(info.row.original.expirationDate));

    const isFuturePlan =
      new Date() < toMidnight(new Date(info.row.original.startDate)) &&
      new Date() < toDayEnd(new Date(info.row.original.expirationDate));

    const getPopupOptions = (): IOption[] => {
      if (isPastPlan) return [];
      if (isFuturePlan) {
        return [
          { value: 'edit', label: t('action:edit') },
          { value: 'upgrade', label: t('action:upgrade'), disabled: true },
          { value: 'delete', label: t('action:delete') },
          { value: 'view-skus', label: t('support:view-skus'), disabled: true, rightIcon: NewTabSvg },
        ];
      }
      return [
        { value: 'edit', label: t('action:edit') },
        { value: 'upgrade', label: t('action:upgrade') },
        { value: 'delete', label: t('action:delete'), disabled: true },
        { value: 'view-skus', label: t('support:view-skus'), rightIcon: NewTabSvg },
      ];
    };

    return (
      <ThreeDotsCell>
        {info.row.original.extensions.map((extension, index) => (
          <PopupWrapper
            key={extension.id}
            dataTest="plan-actions-id"
            popupPosition="leftCenter"
            onClickOption={(option) => planHistoryActions[option.value](info.getValue(), info.row.original.id)}
            options={getPopupOptions()}
            size="s"
          >
            <StatusSVGWrapper active={false} icon={DotsSvg} size="m" disabled={isPastPlan || index > 0} />
          </PopupWrapper>
        ))}
      </ThreeDotsCell>
    );
  };

  const getMultiCell = (value: React.ReactNode, column: string, plan: ISubscriptionPlanDTO) => {
    const { extensions } = plan;
    const isCurrentPlan = (expirationDate: string) => {
      return plan.startDate <= new Date().toISOString() && expirationDate >= new Date().toISOString();
    };
    const getValueToDisplay = (extension: ISubscriptionPlanExtensionDTO) => {
      if (column === 'projectsPurchased' && extension.unlimitedProjects) return t('company:unlimited');
      if (column === 'plan') {
        const planName = pvDesignPlanTitles[extension.planType as Plan];
        if (extension.isLegacy) {
          return (
            t(`support:${extension.planType === Plan.DEMO ? pvDesignPlanTitles[Plan.FREE_TRIAL] : planName}`) +
            ` (${t('company:legacy')}` +
            ')'
          );
        }
        return extension.planType ? t('support:' + planName) : '';
      }
      return String(extension[column as keyof ISubscriptionPlanExtensionDTO] ?? value);
    };

    if (extensions.length === 1)
      return (
        <Typography weight={isCurrentPlan(plan.expirationDate) && column === 'plan' ? 'bold' : 'regular'}>
          {getValueToDisplay(extensions[0])}
        </Typography>
      );

    const firstExtensionIsCurrentPlan = (expirationDate: string, column: string) =>
      column === 'plan' && isCurrentPlan(expirationDate) && column === 'plan';

    return (
      <TimelapseCell>
        {extensions.map((extension, index) => {
          return (
            <Typography
              weight={index === 0 && firstExtensionIsCurrentPlan(extension.expirationDate, column) ? 'bold' : 'regular'}
              key={extension.id}
            >
              {getValueToDisplay(extension)}
            </Typography>
          );
        })}
      </TimelapseCell>
    );
  };

  const getExpirationDate = (expDate: string, extensions: ISubscriptionPlanExtensionDTO[], locale: string) => {
    if (extensions.length === 1)
      return <Typography>{getDateShortMonthDDYYYY(new Date(expDate), locale) || '-'}</Typography>;
    return (
      <TimelapseCell>
        {extensions.map((extension) => {
          return (
            <Typography key={extension.id}>
              {getDateShortMonthDDYYYY(new Date(extension.expirationDate), locale)}
            </Typography>
          );
        })}
      </TimelapseCell>
    );
  };

  return [
    columnHelper.accessor((row) => row.title, {
      id: 'plan',
      meta: {
        cellClassName: 'top-aligned',
      },
      cell: (info) => {
        return getMultiCell(info.getValue(), info.column.id, info.row.original);
      },
      header: () => <Typography color={theme.v2.text.secondary.default}>{t('company:plan')}</Typography>,
    }),
    columnHelper.accessor((row) => row.startDate, {
      id: 'startDate',
      cell: (info) => getSimpleCell(getDateShortMonthDDYYYY(new Date(info.getValue()), options.locale)),
      meta: {
        cellClassName: 'top-aligned',
      },
      header: () => (
        <SortableHeader
          selector={{ label: t('company:starting-date'), code: 'startDate' }}
          sortedBy={options.sortedBy}
          onSort={options.setSortedBy}
        />
      ),
    }),
    columnHelper.accessor((row) => row.expirationDate, {
      id: 'expirationDate',
      cell: (info) => getExpirationDate(info.getValue(), info.row.original.extensions, options.locale),
      header: () => (
        <SortableHeader
          selector={{ label: t('expiration-date'), code: 'expirationDate' }}
          sortedBy={options.sortedBy}
          onSort={options.setSortedBy}
        />
      ),
    }),
    columnHelper.accessor((row) => row.projectsPurchased, {
      id: 'projectsPurchased',
      cell: (info) => getMultiCell(info.getValue(), info.column.id, info.row.original),
      header: () => <Typography color={theme.v2.text.secondary.default}>{t('company:projects-purchased')}</Typography>,
    }),
    columnHelper.accessor((row) => row.projectsDone, {
      id: 'projectsDone',
      cell: (info) => getMultiCell(info.getValue(), info.column.id, info.row.original),
      header: () => <Typography color={theme.v2.text.secondary.default}>{t('company:projects-created')}</Typography>,
    }),
    columnHelper.accessor((row) => row.designsDone, {
      id: 'designsDone',
      cell: (info) => getMultiCell(info.getValue(), info.column.id, info.row.original),
      header: () => <Typography color={theme.v2.text.secondary.default}>{t('company:created-designs')}</Typography>,
    }),
    columnHelper.accessor((row) => row.usersCreated, {
      id: 'usersCreated',
      cell: (info) => getMultiCell(info.getValue(), info.column.id, info.row.original),
      header: () => (
        <InfoText color={theme.v2.text.secondary.default} info={t('support:users-created-tooltip')}>
          {t('company:users-created')}
        </InfoText>
      ),
    }),
    columnHelper.accessor((row) => row.activeUsers, {
      id: 'activeUsers',
      cell: (info) => getMultiCell(info.getValue(), info.column.id, info.row.original),
      header: () => (
        <InfoText color={theme.v2.text.secondary.default} info={t('support:plans-active-users-tooltip')}>
          {t('company:active-users')}
        </InfoText>
      ),
    }),
    columnHelper.accessor('id', {
      meta: {
        cellClassName: 'sticky-column',
      },
      cell: (info) => {
        return getOptions(t, options.planHistoryActions, info);
      },
      header: () => <></>,
    }),
  ];
};
