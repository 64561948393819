import { IBessDesign, IDesign, SIMULATION_CATEGORY } from 'types/design';

export interface HasTypeCategory {
  simulationType: string;
  simulationCategory: string;
  [key: string]: any;
}

export const getTagTextConfig = (design: IDesign | IBessDesign): string => {
  const simulationTypeCategory = getSimulationTypeCategory(design);
  switch (simulationTypeCategory) {
    case 'SINGLE_PV':
      return 'design-process:batch.single-pv-design';
    case 'SINGLE_BESS':
      return 'design-process:batch.bess-single';
    case 'BATCH_PV':
      return 'design-process:batch.multiple-pv-design';
    case 'ILC':
      return 'design-process:batch.ilc-design';
  }
};

export const selectedColor = (design: HasTypeCategory, disabled?: boolean): string => {
  if (disabled) {
    return 'gray';
  }
  const simulationTypeCategory = getSimulationTypeCategory(design);
  switch (simulationTypeCategory) {
    case 'SINGLE_PV':
      return 'brand';
    case 'SINGLE_BESS':
      return 'yellow';
    case 'BATCH_PV':
      return 'accent';
    case 'ILC':
      return 'accent';
  }
};

export const getSimulationTypeCategory = (design: HasTypeCategory): SimulationTypeCategory => {
  const simulationType = design.simulationType;
  const simulationCategory = design.simulationCategory;

  if (simulationCategory === 'PV' && simulationType === 'SINGLE') {
    return 'SINGLE_PV';
  }
  if (simulationCategory === 'STANDALONE_BATTERY') {
    return 'SINGLE_BESS';
  }
  if (simulationCategory === 'ILC') {
    return 'ILC';
  }
  return 'BATCH_PV';
};

export function isPvDesign(item: IDesign | IBessDesign): item is IDesign {
  return item.simulationCategory === SIMULATION_CATEGORY.PV || item.simulationCategory === SIMULATION_CATEGORY.ILC;
}

export const categoryLabels: Record<SIMULATION_CATEGORY, string> = {
  PV: 'design:pv',
  STANDALONE_BATTERY: 'project:standalone-bess',
  ILC: 'design-process:batch.ilc-design',
};

type SimulationTypeCategory = 'SINGLE_PV' | 'SINGLE_BESS' | 'BATCH_PV' | 'ILC';
