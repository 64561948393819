import { ITypographyProps, SVGWrapper, Tooltip, Typography } from '@ratedpower/components';
import { InfoSvg } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';

interface IProps extends ITypographyProps {
  icon?: React.ElementType;
  iconSize?: 'xxs' | 'xs' | 's' | 'm' | 'l';
  info: string;
  tooltipMaxWidth?: string;
  children: React.ReactNode;
}

const InfoTitle = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xxs};
  display: flex;
  align-items: center;
`;

export const InfoText: React.FC<IProps> = ({ info, icon = InfoSvg, iconSize = 's', children, ...rest }) => (
  <InfoTitle>
    <Typography {...rest}>{children}</Typography>
    <Tooltip description={info} maxWidth={rest.tooltipMaxWidth}>
      <SVGWrapper icon={icon} size={iconSize} color={rest.color} />
    </Tooltip>
  </InfoTitle>
);
