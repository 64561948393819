import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: gray;
  padding: 8px 12px;
  position: fixed;
  max-width: 380px;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 14px;
  margin-top: 48px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const Content = styled.div``;
