import { useFlags } from 'launchdarkly-react-client-sdk';
import { SimulationPrimaryCharacteristicsTag } from 'types/design';
import { HasTagInfo } from 'utils/design-tags';

export const useStandaloneBessSku = () => {
  const flags = useFlags();

  const restrictStandaloneBessSku = (item: HasTagInfo) => {
    return !(item.primaryTag !== SimulationPrimaryCharacteristicsTag.BESS || flags?.skuStandaloneBess);
  };

  return {
    restrictStandaloneBessSku,
  };
};
