import { GET_EXTERNAL_ROLES_WITH_PERMISSIONS } from 'services/roles-gql';
import { useQuery } from '@apollo/client';
import { ReadOnlyRoles } from 'pages/users-management/read-only-roles-tab/config/use-read-only-roles-config';
import { Permission } from 'access-control/permissions';

export type ReadOnlyRolesPermissionMap = Map<ReadOnlyRoles, Set<Permission>>;

const readOnlyRolesMap = new Map<ReadOnlyRoles, Set<Permission>>(
  Object.values(ReadOnlyRoles).map((role) => [role, new Set<Permission>()])
);

interface UseGetExternalRolesWithPermissionsResult {
  error: any;
  data: ReadOnlyRolesPermissionMap;
  loading: boolean;
}

export const useGetExternalRolesWithPermissions = (): UseGetExternalRolesWithPermissionsResult => {
  const { error, data, loading } = useQuery(GET_EXTERNAL_ROLES_WITH_PERMISSIONS);

  const result: ReadOnlyRolesPermissionMap = new Map(readOnlyRolesMap);

  data?.getExternalRolesWithPermissions.forEach((role: any) => {
    const permissionsSet = result.get(role.name as ReadOnlyRoles);

    if (permissionsSet) {
      role.permissions.forEach((permission: any) => {
        permissionsSet.add(permission.name as Permission);
      });
    }
  });

  return { error, data: result, loading };
};
