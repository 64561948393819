/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { ReactThreeFiber, ThreeElements, extend } from '@react-three/fiber';
import { BufferGeometry } from 'three';

extend({ LineMat_: LineMaterial, LineGeom_: LineGeometry, Line2_: Line2 });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      lineMat_: any;
      lineGeom_: any;
      line2_: ReactThreeFiber.Object3DNode<Line2, typeof Line2>;
    }
  }
}
const FatLine = ({
  coordinates,
  width,
  color,
  visible,
  userData,
  ...props
}: {
  coordinates: any[];
  width: number;
  color: string;
  userData?: { node1: any; node2: any };
  visible?: boolean;
  onClick?: any;
}) => {
  const geometry = new BufferGeometry().setFromPoints(coordinates);
  const lRef = useRef<ThreeElements['group']>(null);
  const lineGeom = new LineGeometry();
  lineGeom.fromLine(new THREE.Line(geometry));
  const lineMat = new LineMaterial({
    color: color,
    linewidth: width,
    worldUnits: true,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
  } as const);
  const makeLine = () => new Line2(lineGeom, lineMat);
  const line = makeLine();

  useEffect(() => {
    if (!lRef?.current) return;

    lRef.current.clear();
    lRef.current.add(line);
  }, [coordinates]);

  return <group {...props} userData={userData} visible={visible} ref={lRef}></group>;
};

export default FatLine;
