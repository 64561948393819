import React, { useState } from 'react';
import * as S from './teams-tab.styled';
import { RatedPowerLoader, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import TeamsCardsView from './teams-card-view';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';
import TeamsListView from './teams-list-view';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import TeamsFilterPanel from './teams-filter.panel';
import TeamModals from './team-management/team-modals';
import { ITeamsFilters } from 'types/teams';
import { useListTeams } from './hooks/use-list-teams';
import { CARD_VIEW_PAGE_SIZE, LIST_VIEW_PAGE_SIZE, viewOptions } from 'utils/teams';
import { ContentTopBar } from 'components/content-top-bar';
import { IndexIds } from 'index.ids';
import ZeroCreated2 from 'components/error-components/zero-created-2/zero-created-2';
import { ZeroDesignsSvg } from 'assets/icons';

const Teams: React.FC = () => {
  const { t } = useTranslation();
  const viewQueryParam = useQuerySearchParams().get('view');
  const [filtersPanelOpened, setFiltersPanelOpened] = useState(false);

  const isCardsView = viewQueryParam === viewOptions[0].value || !viewQueryParam;
  const isListView = viewQueryParam === viewOptions[1].value;

  const { loading, teams, error, sortedBy, paginationComponent, setSortedBy, setTeamsFilters, inputSearchProps } =
    useListTeams({
      infiniteScroll: false,
      pageSize: isCardsView ? CARD_VIEW_PAGE_SIZE : LIST_VIEW_PAGE_SIZE,
    });

  const canDisplayCardsView = teams && isCardsView;
  const canDisplayListView = teams && isListView;

  const handleOpenFiltersPanel = (open: boolean) => () => setFiltersPanelOpened(open);

  if (loading || (!teams && !error)) {
    return (
      <S.TeamsTabContainer>
        <RatedPowerLoader />
      </S.TeamsTabContainer>
    );
  }

  return (
    <>
      <S.TeamsTabContainer>
        <S.TeamsTabContent>
          {teams?.length !== 0 && (
            <S.ContentTopbarWrapper>
              <Typography weight="bold" dataTest={IndexIds.Users.TeamsTitle}>
                {t('user:teams.all-teams')}
              </Typography>
              <ContentTopBar
                searchPlaceholder={t('user:teams.search-team')}
                searchOnChange={(search: string) => inputSearchProps.onChange(search)}
                filterOnClick={handleOpenFiltersPanel(true)}
              />
            </S.ContentTopbarWrapper>
          )}
          {error && !teams && (
            <S.ErrorContainer>
              <ErrorRetrievingData />
            </S.ErrorContainer>
          )}
          {!error && teams.length === 0 && (
            <ZeroCreated2
              Icon={ZeroDesignsSvg}
              title={t('user:teams.no-teams-yet')}
              subtitle={
                <>
                  <Typography className="no-teams-description" size="s">
                    {t('user:teams.no-teams-description-1')}
                  </Typography>
                  <Typography size="s">{t('user:teams.no-teams-description-2')}</Typography>
                </>
              }
            />
          )}
          {canDisplayCardsView && <TeamsCardsView teams={teams} />}
          {canDisplayListView && <TeamsListView teams={teams} sortedBy={sortedBy} setSortedBy={setSortedBy} />}
        </S.TeamsTabContent>
        <TeamModals />
        <TeamsFilterPanel
          isOpen={filtersPanelOpened}
          onApply={(filters: ITeamsFilters) => {
            setTeamsFilters(filters);
            handleOpenFiltersPanel(false)();
          }}
          onClose={handleOpenFiltersPanel(false)}
        />
      </S.TeamsTabContainer>
      {paginationComponent}
    </>
  );
};

export default Teams;
