import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_SUPPORT_PLANS, UPGRADE_CURRENT_PLAN } from 'services/subscription-plan-gql';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useNotificationToast } from './use-notification-toast';
import { ISubscriptionPlanDTO, ISubscriptionPlanInputs } from 'types/subscription-plan';
import { GET_SUPPORT_COMPANY } from 'services/support-gql';
import { useRef } from 'react';

export interface IUseUpgradePlan {
  upgradePlanModal: IUseModal;
  handleUpgradePlan: (inputs: ISubscriptionPlanInputs, planId: string) => void;
  handleOpenUpgradeModal: (plan: ISubscriptionPlanDTO) => void;
}

export const useUpgradePlan = (setInputs: (inputs: ISubscriptionPlanInputs) => void): IUseUpgradePlan => {
  const { id: supportPagesCompanyId } = useParams<{ id: string }>();
  const { errorNotification, successNotification } = useNotificationToast();

  const upgradePlanModal = useModal();
  const currentInputs = useRef<ISubscriptionPlanInputs | null>(null);

  const handleOpenUpgradeModal = (plan: ISubscriptionPlanDTO) => {
    setInputs({
      plan: plan.plan,
      startDate: plan.startDate,
      expirationDate: plan.expirationDate,
      isLegacy: plan.isLegacy,
      sendWelcomeEmail: false,
      stockKeepingUnits: plan.stockKeepingUnits,
      projectLimit: { projectsPurchased: plan.projectsPurchased, unlimited: plan.unlimitedProjects },
      userLimit: { numberOfUsers: plan.userLimit, unlimited: plan.unlimitedUsers },
    });
    upgradePlanModal.setData({ planId: plan.id });
    upgradePlanModal.setModalOpen(true);
  };

  const handleUpgradePlan = (inputs: ISubscriptionPlanInputs, planId: string) => {
    currentInputs.current = inputs;
    const variables = {
      companyId: supportPagesCompanyId,
      subscriptionPlanUpdateDTO: {
        id: planId,
        plan: inputs.plan,
        startDate: inputs.startDate,
        expirationDate: inputs.expirationDate,
        stockKeepingUnits: inputs.stockKeepingUnits || null,
        projectsPurchased: Number(inputs.projectLimit.projectsPurchased) || 0,
        unlimitedProjects: inputs.projectLimit.unlimited,
        unlimitedUsers: inputs.userLimit.unlimited,
        userLimit: Number(inputs.userLimit.numberOfUsers) || 0,
      },
      sendWelcomeEmail: inputs.sendWelcomeEmail,
    };
    upgradeCurrentPlan({ variables });
  };

  const [upgradeCurrentPlan] = useMutation(UPGRADE_CURRENT_PLAN, {
    onCompleted: () => {
      upgradePlanModal.setModalOpen(false);
      successNotification('support:update-company-success');
    },
    onError: (apolloError: any) => errorNotification(apolloError),
    refetchQueries: [GET_SUPPORT_PLANS, GET_SUPPORT_COMPANY],
  });

  return {
    upgradePlanModal,
    handleUpgradePlan,
    handleOpenUpgradeModal,
  };
};
