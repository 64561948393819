import React, { useEffect, useMemo, useState } from 'react';
import { useConverter } from 'utils/conversor';
import { IComparisonSortBy } from 'types/comparison';
import SortAttributeLabel from './sort-attribute-label/sort-attribute-label';
import { isFilterActive } from './attributes-panel/attributes-panel';
import { sortByKey } from 'utils/sort';
import { IAttribute, designAttributes } from 'utils/constants/design-attributes';
import {
  IAttributeCellRow,
  IAttributeLabelRow,
  IComparisonColumn as IColumn,
} from './comparison-table/data-table-types';
import { IDesign } from 'types/design';
import { IComparisonFilter } from 'types/design-comparison.types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

const defaultSortSelector = {
  selector: {
    key: 'name',
    label: 'name',
  },
  ascendent: true,
};

const useDesignComparisonTable = (designs: IDesign[], activeAttributes: IComparisonFilter[]) => {
  const { getValueOf, getOnlyValueOf } = useConverter();
  const { currencies } = useSelector((state: RootState) => state.currencies);

  const [designColumnsSorted, setDesignColumnsSorted] = useState<IColumn[]>([]);

  // Mapped data for displaying it as a table
  const [rowLabels, setRowLabels] = useState<IAttributeLabelRow[]>([]);
  const [rows, setRows] = useState<IAttributeCellRow[]>([]);

  // attributes
  const [layoutShown, setLayoutShown] = useState(true);
  const [sortLoading, setSortLoading] = useState(false);

  // sort
  const [sortedBy, setSortedBy] = useState<IComparisonSortBy>(defaultSortSelector);

  /**
   * Update attributes showed at designs panel
   * - on add/remove designs
   * - onClick attribute chips (add/remove attributes show)
   * - onSort designs
   */

  const noLayoutAttributes = useMemo(
    () => activeAttributes.filter((e) => e.key !== designAttributes.satellitePicture.low.key),
    [activeAttributes]
  );

  useEffect(() => {
    if (designs) {
      // sort designs
      const {
        selector: { key },
        ascendent,
      } = sortedBy;
      setSortLoading(true);
      const designsCopy = [...designs];
      const sortedData: IDesign[] = sortByKey(designsCopy, { ascendent, selector: key });
      // get columns
      const columnsSorted = getDesignColumns(sortedData);
      setDesignColumnsSorted(columnsSorted);
      // designs values
      const newRowLabels: any[] = [];
      const dataRows: any[] = [];
      for (const attr of noLayoutAttributes) {
        if ('render' in attr && typeof attr.render === 'function') {
          const castedAttr = attr as IAttribute;
          newRowLabels.push(getLabelRowFor(castedAttr));
          dataRows.push(getValuesRowFor(castedAttr));
        }
      }
      // set layout img as column top value
      const isLayoutAttrActive = isFilterActive(designAttributes.satellitePicture.low, activeAttributes);

      setRowLabels(newRowLabels);
      setRows(dataRows);
      setLayoutShown(isLayoutAttrActive);
    }
  }, [noLayoutAttributes, designs, sortedBy]);
  useEffect(() => {
    setSortLoading(false);
  }, [rowLabels]);

  const getLabelRowFor = (attribute: IAttribute): IAttributeLabelRow => {
    const { key: attrKey, label, className, sortKeys } = attribute;
    const key = sortKeys || attrKey;

    return {
      // Row custom style
      ...{ className },
      label,
      // Attribute label cell
      cell:
        label === 'layout-view' ? (
          ''
        ) : (
          <SortAttributeLabel {...{ sortedBy, cell: { key, label }, onSort: setSortedBy }} />
        ),
    };
  };

  const getValuesRowFor = (attribute: IAttribute): IAttributeCellRow => {
    const { className, key } = attribute;
    const greatestBetter: any = [
      'outputs.specificProduction',
      'energy.energyYield',
      'outputs.plantPR',
      'outputs.financialResultsDTO.npv',
      'outputs.financialResultsDTO.irr',
      'outputs.financialResultsDTO.roi',
    ];
    const lowestBetter: any = [
      'outputs.capexResult.specificPrice',
      'outputs.financialResultsDTO.lcoe',
      'outputs.financialResultsDTO.payback',
      'outputs.financialResultsDTO.discountedPayback',
    ];
    return {
      // Row custom style
      ...{ className },
      ...{ key },
      // Design value cells
      values: designs.reduce((result: any, item: IDesign) => {
        const designCurrency = getDesignCurrency(item);

        const value = getValueOf(attribute, item, designCurrency);
        result[item.id] = { render: value, csvValue: getOnlyValueOf(attribute, item) };
        if (
          greatestBetter.includes(attribute.key) ||
          greatestBetter.some((element) => attribute.key.includes(element))
        ) {
          addValueToGreatest(result, attribute, item);
        }
        if (lowestBetter.includes(attribute.key) || lowestBetter.some((element) => attribute.key.includes(element))) {
          addValueToLowest(result, attribute, item);
        }
        return result;
      }, {}),
    };
  };

  const addValueToLowest = (result: any, attribute: IAttribute, item: IDesign) => {
    const onlyValue = getOnlyValueOf(attribute, item);
    if (onlyValue !== 0) {
      if (!result.greatest?.value || result.greatest.value > onlyValue) {
        result.greatest = { value: onlyValue, id: [item.id] };
      } else if (result.greatest.value === onlyValue) {
        result.greatest = { value: onlyValue, id: [...result.greatest.id, item.id] };
      }
    }
  };

  const addValueToGreatest = (result: any, attribute: IAttribute, item: IDesign) => {
    const onlyValue = getOnlyValueOf(attribute, item);
    if (!result.greatest?.value) {
      result.greatest = { value: onlyValue, id: [item.id] };
    } else if (result.greatest.value === onlyValue) {
      result.greatest = { value: onlyValue, id: [...result.greatest.id, item.id] };
    } else if (result.greatest.value < onlyValue) {
      result.greatest = { value: onlyValue, id: [item.id] };
    }
  };

  const getDesignCurrency = (item: IDesign) => {
    return !!item.outputs?.capexResult && !!item.outputs.capexResult.currency
      ? currencies[item.outputs.capexResult.currency]
      : undefined;
  };

  // Design columns (only header data)
  const getDesignColumns = (sortedDesigns: IDesign[]): IColumn[] => {
    return [
      ...sortedDesigns.map((design) => ({
        name: design.name,
        data: design,
        selector: design.id,
      })),
    ];
  };

  return {
    rowLabels,
    rowData: rows,
    columnHeaders: designColumnsSorted,
    isLayoutShown: layoutShown,
    sortLoading,
    noLayoutAttributes,
  };
};

export default useDesignComparisonTable;
