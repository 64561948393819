import { useState } from 'react';
import { ISelectorOptions } from 'types/selector.types';
import { useQuery } from '@apollo/client';
import { USER_TYPE } from 'types/users';
import { GET_BIS_MANAGERS, GET_CS_MANAGERS } from 'services/support-gql';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import { csUserTypes } from 'utils/constants/support';

interface ICompanyFormHelper {
  businessUsers: ISelectorOptions[];
  customerSupportUsers: ISelectorOptions[];
  newCompanyLogo: File | undefined;
  loadingOptionsData: boolean;
  setNewCompanyLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export const useCompanyFormData = (): ICompanyFormHelper => {
  const { isAuthorized } = useAuthorization();
  const [newCompanyLogo, setNewCompanyLogo] = useState<File | undefined>(undefined);

  const convertDataToOptions = (data: any): ISelectorOptions[] => {
    if (!data?.result) return [];

    return data.result.map((e: any) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
  };
  const { data: csManagersData, loading: loadingCS } = useQuery(GET_CS_MANAGERS, {
    variables: {
      type: Array.from(csUserTypes),
    },
    fetchPolicy: 'cache-and-network',
    skip: !isAuthorized(Permission.USER_SUPPORT_READ),
  });

  const { data: bisManagersData, loading: loadingBis } = useQuery(GET_BIS_MANAGERS, {
    variables: {
      type: [USER_TYPE.BIS_MANAGER],
    },
    fetchPolicy: 'cache-and-network',
    skip: !isAuthorized(Permission.USER_SUPPORT_READ),
  });

  const loadingOptionsData = loadingBis || loadingCS;

  return {
    businessUsers: [{ label: 'Unselected', value: '' }, ...convertDataToOptions(bisManagersData)],
    customerSupportUsers: [{ label: 'Unselected', value: '' }, ...convertDataToOptions(csManagersData)],
    newCompanyLogo,
    loadingOptionsData,
    setNewCompanyLogo,
  };
};
