import React, { Dispatch, useRef } from 'react';
import * as S from '../plan-modal.styled';
import {
  IModalProps,
  Modal,
  Checkbox,
  InputNumber,
  Dropdown,
  LabelWrapper,
  Typography,
  BaseTemplateInput,
  Separator,
  Notification,
} from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { ISubscriptionPlanInputs, Plan } from 'types/subscription-plan';
import { plansOptions } from 'utils/constants/support';
import { IOption } from 'types/common';
import { getOptionForValue } from 'utils/selector';
import { useChangePlanOrDates } from 'utils/hooks/use-change-plan-or-dates';
import { InputWithUnlimitedOption } from '../../company-info-tab.styled';
import { SkusListSection } from '../skus-list-section';
import { pvDesignPlanTitles } from 'pages/company/plan/rated-power-plans-constants';

export interface IRenewPlanModalProps extends IModalProps {
  inputs: ISubscriptionPlanInputs;
  handleSubmitForm: (plan: ISubscriptionPlanInputs) => void;
  handleInputChangeManual: (name: string, value: any) => void;
  setFormError: Dispatch<Record<string, string | undefined>>;
  formError: Record<string, string>;
  plansInfo?: string;
  legacyPlanNeedsChange?: boolean;
  disablePlanChangeIfLegacy?: boolean;
}

const PlanModal: React.FC<IRenewPlanModalProps> = ({
  inputs,
  handleSubmitForm,
  handleInputChangeManual,
  setFormError,
  formError,
  plansInfo,
  legacyPlanNeedsChange = false,
  disablePlanChangeIfLegacy = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const initialPlanChangedRef = useRef(false);

  const planOptions = plansOptions.map((plan: IOption) => ({
    label: t(`support:${plan.label}`),
    value: plan.value,
  }));

  const currentPlanIsLegacy = inputs.isLegacy && !initialPlanChangedRef.current;

  const { onChangeExpirationDate, onChangeStartDate, autoChangeExpirationDate } = useChangePlanOrDates({
    plan: inputs.plan,
    handleSetExpirationDate: (newDate: string) => handleInputChangeManual('expirationDate', newDate),
    handleSetStartDate: (newDate: string) => handleInputChangeManual('startDate', newDate),
  });

  const isFormValid = () => {
    if (!inputs.projectLimit.projectsPurchased && !inputs.projectLimit.unlimited) {
      setFormError({ projectsPurchased: 'field-empty-plural' });
      return false;
    }

    const { expirationDate, startDate } = inputs;

    const wrongDateFields =
      new Date(startDate).getTime() > new Date(expirationDate).getTime()
        ? {
            startDate: 'start-date-past-expiration',
            expirationDate: 'start-date-past-expiration',
          }
        : {};

    const errors = { ...wrongDateFields };
    if (Object.keys(errors).length > 0) {
      setFormError(errors);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!isFormValid()) return;

    const newPlan: ISubscriptionPlanInputs = {
      ...inputs,
      projectLimit: {
        projectsPurchased: inputs.projectLimit.unlimited ? 0 : inputs.projectLimit.projectsPurchased,
        unlimited: inputs.projectLimit.unlimited,
      },
      userLimit: {
        numberOfUsers: inputs.userLimit.unlimited ? 0 : inputs.userLimit.numberOfUsers,
        unlimited: inputs.userLimit.unlimited,
      },
    };

    handleSubmitForm(newPlan);
  };

  const getCurrentPlanLabel = () => {
    if (currentPlanIsLegacy) {
      const plan = inputs.plan === Plan.DEMO ? Plan.FREE_TRIAL : inputs.plan;
      return [
        {
          label: t(`support:${pvDesignPlanTitles[plan]}`) + ` (${t('company:legacy')})`,
          value: plan,
        },
      ];
    }
    return [getOptionForValue(inputs.plan, planOptions)];
  };

  const displayedFormErrors = [...new Set(Object.values(formError ?? {}))];
  const noProjectsLimit = inputs.projectLimit.projectsPurchased === 0 && !inputs.projectLimit.unlimited;
  const noUsersLimit = inputs.userLimit.numberOfUsers === 0 && !inputs.userLimit.unlimited;

  return (
    <Modal
      {...rest}
      size="fitContent"
      disableClickOutside
      buttons={{
        primary: {
          text: t('action:save'),
          type: 'submit',
          onClick: handleSubmit,
          disabled: (currentPlanIsLegacy && legacyPlanNeedsChange) || noProjectsLimit || noUsersLimit,
        },
        secondary: {
          text: t('action:cancel'),
          onClick: rest.onClose,
        },
      }}
    >
      <Typography weight={700}>{t('company:plan-details')}</Typography>
      <S.ModalPlanForm onSubmit={handleSubmit}>
        {currentPlanIsLegacy && plansInfo && (
          <Notification description={plansInfo} status="informative" hideCloseButton />
        )}
        <LabelWrapper title={t('company:plan')} htmlFor="plan">
          <Dropdown
            options={planOptions}
            name="plan"
            error={!!formError?.['plan']}
            value={getCurrentPlanLabel()}
            disabled={disablePlanChangeIfLegacy && currentPlanIsLegacy}
            onChange={([selectedItem]) => {
              initialPlanChangedRef.current = true;
              autoChangeExpirationDate(inputs.startDate, selectedItem.value);
              handleInputChangeManual('plan', selectedItem.value);
            }}
          />
        </LabelWrapper>
        <SkusListSection
          id="skus-modal-section"
          planIncludesSSO={false}
          onSelectSkus={(selectedSkus) => handleInputChangeManual('stockKeepingUnits', selectedSkus)}
          checkedSkus={inputs.stockKeepingUnits}
        />
        <Separator />
        <LabelWrapper title={t('start-date')} htmlFor="startDate">
          <BaseTemplateInput
            warning={!!formError?.['startDate']}
            type="date"
            name="startDate"
            value={inputs.startDate}
            onChange={onChangeStartDate}
            max="2999-12-31"
          />
        </LabelWrapper>
        <LabelWrapper title={t('expiration-date')} htmlFor="expirationDate">
          <BaseTemplateInput
            warning={!!formError?.['expirationDate']}
            type="date"
            name="expirationDate"
            value={inputs.expirationDate}
            onChange={onChangeExpirationDate}
            max="2999-12-31"
          />
        </LabelWrapper>
        <LabelWrapper alignItems="baseline" title={t('company:projects-purchased')} htmlFor="projectsPurchased">
          <InputWithUnlimitedOption>
            <InputNumber
              name="projectsPurchased"
              warning={!!formError?.['projectsPurchased']}
              value={inputs.projectLimit.projectsPurchased || ''}
              onChange={(newValue) =>
                handleInputChangeManual('projectLimit', {
                  unlimited: false,
                  projectsPurchased: parseInt(newValue),
                })
              }
              disabled={inputs.projectLimit.unlimited}
            />
            <Checkbox
              id="unlimitedProjects"
              name="unlimitedProjects"
              checked={inputs.projectLimit.unlimited}
              onChange={() =>
                handleInputChangeManual('projectLimit', {
                  ...inputs.projectLimit,
                  unlimited: !inputs.projectLimit.unlimited,
                })
              }
              label={t('company:unlimited')}
            />
          </InputWithUnlimitedOption>
        </LabelWrapper>
        <LabelWrapper alignItems="baseline" title={t('company:number-of-users')}>
          <InputWithUnlimitedOption>
            <InputNumber
              warning={!!formError?.['userLimit']}
              name="userLimit"
              defaultValue={0}
              min={0}
              disabled={inputs.userLimit.unlimited}
              value={inputs.userLimit.numberOfUsers || ''}
              onChange={(newValue) =>
                handleInputChangeManual('userLimit', { unlimited: false, numberOfUsers: Number(newValue) })
              }
            />
            <Checkbox
              id="unlimitedUsers"
              name="unlimitedUsers"
              checked={inputs.userLimit.unlimited}
              onChange={() =>
                handleInputChangeManual('userLimit', { ...inputs.userLimit, unlimited: !inputs.userLimit.unlimited })
              }
              label={t('company:unlimited')}
            />
          </InputWithUnlimitedOption>
        </LabelWrapper>
        <Separator />
        <S.SendWelcomeEmailCheckbox>
          <Typography>{t('support:send-welcome-mail')}</Typography>
          <Checkbox
            id="sendWelcomeEmail"
            name="sendWelcomeEmail"
            checked={inputs.sendWelcomeEmail}
            onChange={() => handleInputChangeManual('sendWelcomeEmail', !inputs.sendWelcomeEmail)}
          />
        </S.SendWelcomeEmailCheckbox>

        <div className="errors">
          {displayedFormErrors.map((error, index) => (
            <S.FormError key={`${error}-${index}`} size="s">
              {t(`errors:${error}`)}
            </S.FormError>
          ))}
        </div>
      </S.ModalPlanForm>
    </Modal>
  );
};

export default PlanModal;
